import { createSvgIcon } from "@mui/material";

export const AutostoreRobotIcon = createSvgIcon(
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66667 5.33337H0V8.00004V21.3334V29.3334H5.33333V24H21.3333V48C21.3333 48.0384 21.3337 48.0766 21.3345 48.1148C21.3337 48.1653 21.3333 48.2159 21.3333 48.2666C21.3333 53.4213 25.512 57.6 30.6667 57.6C33.9535 57.6 36.8434 55.901 38.5063 53.3334H46.827C48.4899 55.901 51.3799 57.6 54.6667 57.6C59.8213 57.6 64 53.4213 64 48.2666C64 48.2159 63.9996 48.1653 63.9988 48.1148C63.9996 48.0766 64 48.0384 64 48V24V21.3334V18.6667V8.00004V5.33337H61.3333H2.66667ZM58.6667 39.8315V24H26.6667V39.8315C27.879 39.2556 29.2352 38.9333 30.6667 38.9333C35.7322 38.9333 39.8551 42.9687 39.9963 48H45.3371C45.4782 42.9687 49.6012 38.9333 54.6667 38.9333C56.0981 38.9333 57.4543 39.2556 58.6667 39.8315ZM24 18.6667H21.3333H5.33333V10.6667H58.6667V18.6667H24ZM26.6667 48.2666C26.6667 46.0575 28.4575 44.2666 30.6667 44.2666C32.8758 44.2666 34.6667 46.0575 34.6667 48.2666C34.6667 50.4758 32.8758 52.2666 30.6667 52.2666C28.4575 52.2666 26.6667 50.4758 26.6667 48.2666ZM50.6667 48.2666C50.6667 46.0575 52.4575 44.2666 54.6667 44.2666C56.8758 44.2666 58.6667 46.0575 58.6667 48.2666C58.6667 50.4758 56.8758 52.2666 54.6667 52.2666C52.4575 52.2666 50.6667 50.4758 50.6667 48.2666Z"
      fill="currentcolor"
    />
  </svg>,
  "AutostoreRobot"
);
