import { ConfirmationModal } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

type PickBatchModalProps = { pickBatch: () => void; closeModal: () => void };

export const PickBatchModal = ({
  pickBatch,
  closeModal
}: PickBatchModalProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      isOpen={true}
      confirmCb={() => {
        closeModal();
        pickBatch();
      }}
      closeCb={closeModal}
      modalTitle={t("ready to start picking")}
      modalText={t("you are about to be redirected to picking")}
    />
  );
};
