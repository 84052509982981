import { LoosePick } from "~/features/stage/loosePick.type";
import { LoosePickSummary } from "~/redux/warehouse/loosePicks.openApi";
import { LoosePickSummaryDto, ToteSummaryDto } from "~/types/api";

export type ToteOrLoosePick =
  | ToteSummaryDto
  | LoosePickSummaryDto
  | LoosePickSummary;
export type ToteOrLoosePickType = "Tote" | "LoosePick";

export function combineTotesAndLoosePicks(
  totes: ToteSummaryDto[],
  loosePicks: (LoosePickSummaryDto | LoosePickSummary)[]
): ToteOrLoosePick[] {
  return totes
    .map((tote) => tote as ToteOrLoosePick)
    .concat(loosePicks.map((loosePick) => loosePick as ToteOrLoosePick));
}

export function getToteOrLoosePickId(toteOrLoosePick: ToteOrLoosePick): string {
  return (
    (toteOrLoosePick as ToteSummaryDto).toteId ||
    (toteOrLoosePick as LoosePickSummaryDto).loosePickId
  );
}

export function getPickId(toteOrLoosePick: ToteOrLoosePick): string | null {
  return (toteOrLoosePick as LoosePick).pickId || null;
}

export function getType(toteOrLoosePick: ToteOrLoosePick): ToteOrLoosePickType {
  return (toteOrLoosePick as ToteSummaryDto).toteId ? "Tote" : "LoosePick";
}

export function getPosition(
  toteOrLoosePick: ToteOrLoosePick
): number | "Bulk Pick" {
  const { totePosition } = toteOrLoosePick as ToteSummaryDto;
  return totePosition || "Bulk Pick";
}
