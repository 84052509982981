import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertColor,
  AlertTitle,
  IconButton,
  Snackbar,
  SnackbarProps
} from "@mui/material";

import * as React from "react";
import { FC } from "react";

import { ToastMessage } from "./useToast";

export type ToastStyle = Omit<
  SnackbarProps,
  "TransitionProps" | "onClose" | "open" | "children" | "message"
>;

export type ToastProps = {
  message: ToastMessage;
  onExited: () => void;
} & ToastStyle;

const defaultDuration: { [Property in AlertColor]: number } = {
  error: 10000,
  warning: 10000,
  info: 3000,
  success: 3000
};

// https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
export const Toast: FC<ToastProps> = ({ message, onExited, ...props }) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const duration = message.duration || defaultDuration[message.severity];

  return (
    <Snackbar
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        left: "50%"
      }}
      key={message.key}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={duration}
      {...props}
    >
      <Alert
        variant="filled"
        severity={message.severity}
        action={
          <IconButton aria-label="close" onClick={handleClose} color="inherit">
            <CloseIcon fontSize="large" />
          </IconButton>
        }
      >
        {message.title && <AlertTitle>{message.title}</AlertTitle>}
        {message.description}
      </Alert>
    </Snackbar>
  );
};
