type PageState =
  | "initial"
  | "all cycle counts complete"
  | "bin arrived"
  | "bin here"
  | "close bin"
  | "close port"
  | "create task group with autostore bins from cycleCountsUncountedBins"
  | "fetch cycle counts uncounted bins"
  | "get bin request"
  | "get next bin"
  | "open port"
  | "start polling";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AutostoreCycleCountsState = {
  binPresent: boolean;
  isAdjustPanelOpen: boolean;
  isBinHoldModalOpen: boolean;
  pageState: PageState;
  portOpened: boolean;
  portPollingActive: boolean;
  selectedBinRow: Guid | null;
  selectedRows: Guid[];
  offset: number;
  limit: number;
  page: number;
};

const initialState: AutostoreCycleCountsState = {
  binPresent: false,
  isAdjustPanelOpen: false,
  isBinHoldModalOpen: false,
  pageState: "initial",
  portOpened: false,
  portPollingActive: false,
  selectedBinRow: null,
  selectedRows: [],
  offset: 0,
  limit: 10,
  page: 1
};

export const autostoreCycleCountsSlice = createSlice({
  name: "autostoreCycleCounts",
  initialState,
  reducers: {
    clearSelectedBinRow(state) {
      state.selectedBinRow = null;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
    },
    selectBinRow(state, { payload }: PayloadAction<Guid>) {
      state.selectedBinRow = payload;
    },
    selectRows(state, { payload }: PayloadAction<Guid[]>) {
      state.selectedRows = payload;
    },
    setBinPresent(state, { payload }: PayloadAction<boolean>) {
      state.binPresent = payload;
    },
    setIsAdjustPanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.isAdjustPanelOpen = payload;
    },
    setIsBinHoldModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isBinHoldModalOpen = payload;
    },
    setPageState(state, { payload }: PayloadAction<PageState>) {
      state.pageState = payload;
    },
    setPortOpened(state, { payload }: PayloadAction<boolean>) {
      state.portOpened = payload;
    },
    setPortPollingActive(state, { payload }: PayloadAction<boolean>) {
      state.portPollingActive = payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
      state.offset = (payload - 1) * state.limit;
    }
  },
  selectors: {
    selectedBinRow: (state: AutostoreCycleCountsState) => state.selectedBinRow
  }
});
export const {
  clearSelectedBinRow,
  clearSelectedRows,
  selectBinRow,
  selectRows,
  setBinPresent,
  setIsAdjustPanelOpen,
  setIsBinHoldModalOpen,
  setPageState,
  setPortOpened,
  setPortPollingActive,
  setPage
} = autostoreCycleCountsSlice.actions;

export const { selectedBinRow } = autostoreCycleCountsSlice.selectors;
