import { Box, styled } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import AdminSummariesTab from "~/features/andon/AdminSummariesTab";

import { selectFetchWorkstationsInterval } from "~/features/andon/andon.slice";
import AndonSettings from "~/features/settings/AndonSettings";
import SettingsPanel from "~/features/settings/SettingsPanel";
import { SystemStatus } from "~/features/status/SystemStatus";
import { GridView } from "~/features/status/gridView/GridView";
import { setSelectedAutostoreGridId } from "~/features/status/status.slice";
import { selectUserIsWorkstationConfigurer } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";

import {
  useGetAutostoreGridConnectionModeQuery,
  useGetAutostoreGridsQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

const ServiceSupportContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(3),
  alignItems: "flex-start",
  gridTemplateRows: "auto auto",
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "1fr"
  },
  padding: theme.spacing(3),
  paddingBottom: 0
}));

const ServiceSupport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const clientConfig = useAppSelector(selectClientConfig);
  const isWorkstationConfigurer = useAppSelector(
    selectUserIsWorkstationConfigurer
  );

  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const fetchWorkstationsInterval = useAppSelector(
    selectFetchWorkstationsInterval
  );

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  useGetWorkstationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skipPollingIfUnfocused: true,
    pollingInterval: fetchWorkstationsInterval || 0
  });
  const { data: gridConnectionData } = useGetAutostoreGridConnectionModeQuery(
    workstationAutostoreGridId || skipToken
  );
  const { an_andonFeaturesEnabled } = clientConfig;
  const pickingConfigurations = usersFulfillmentCenter?.pickingConfigurations;

  const shouldRenderGridView =
    pickingConfigurations?.includes("Autostore") &&
    gridConnectionData?.connectionMode === "Active";

  useEffect(() => {
    if (!selectedAutostoreGridId && autostoreGrids?.length) {
      dispatch(
        setSelectedAutostoreGridId(
          workstationAutostoreGridId || autostoreGrids[0].autostoreGridId
        )
      );
    }
  }, [
    autostoreGrids,
    workstationAutostoreGridId,
    selectedAutostoreGridId,
    dispatch
  ]);

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none"
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none"
      }}
    >
      <ServiceSupportContainer>
        {shouldRenderGridView && (
          <Box
            sx={{
              gridColumn: "span 2"
            }}
          >
            <SettingsPanel panelTitle={t("grid view")} enableFullHeight>
              <GridView />
            </SettingsPanel>
          </Box>
        )}
        {pickingConfigurations?.includes("Autostore") && (
          <SettingsPanel panelTitle={t("system status")} enableFullHeight>
            <SystemStatus />
          </SettingsPanel>
        )}
        {an_andonFeaturesEnabled && isWorkstationConfigurer && (
          <SettingsPanel panelTitle={t("andon settings")} enableFullHeight>
            <AndonSettings />
          </SettingsPanel>
        )}
        {an_andonFeaturesEnabled && (
          <Box
            sx={{
              gridColumn: "span 2"
            }}
          >
            <SettingsPanel panelTitle={t("admin summaries")} enableFullHeight>
              <AdminSummariesTab />
            </SettingsPanel>
          </Box>
        )}
      </ServiceSupportContainer>
    </Box>
  );
};

export default ServiceSupport;
