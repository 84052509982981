import ASButton from "@locaisolutions/button";
import { Undo20Px } from "@locaisolutions/icons";
import { Box, Container, Pagination, Stack, Typography } from "@mui/material";
import {
  ProgressButton,
  CycleCountFrequency,
  AutostoreTable
} from "@qubit/autoparts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import UniversalProductCard, {
  PickInfoIsLoading
} from "~/components/productCard/UniversalProductCard";
import useFlag from "~/config/flags";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar } from "~/hooks/useNavbar";
import { getVariantDisplayNameByDtoFe } from "~/lib/helpers";
import {
  clearSelectedCountingTasks,
  getCycleCountFrequencyByVid,
  setSelectedCountingTasks
} from "~/redux/actions/cycleCounts";
import {
  clearSelectedVariant,
  getVariantByVariantId,
  getVariantData
} from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";
import { useGetOverhaulIncompleteCycleCountsQuery } from "~/redux/warehouse/cycleCountsOverhaul.hooks";
import { useVariantsByIdQuery } from "~/redux/warehouse/variant.hooks";
import {
  FulfillmentCenterDto,
  IncompleteCycleCountResponseDto,
  VariantFrontendDto
} from "~/types/api";

import { CycleCountsSearch } from "./CycleCountsSearch";
import { setPage, setSearchedVariant } from "./cycleCountsNew.slice";

interface CycleCountTaskDto
  extends IncompleteCycleCountResponseDto,
    VariantFrontendDto {}

const mapStateToProps = (
  state: StoreState
): {
  selectedAutostoreGridId: Guid | undefined;
  thisWorkstationId: Guid | undefined;
  usersFulfillmentCenter: FulfillmentCenterDto | null;
  initialFrequency: number | null;
  selectedVariant: VariantFrontendDto | null;
} => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  thisWorkstationId: state.workstations.siteWorkstation?.id,
  initialFrequency: state.cycleCounts.cycleCountFrequency?.frequency,
  selectedVariant: state.inventory.selectedVariant
});

const connector = connect(mapStateToProps, {
  getVariantData,
  getCycleCountFrequencyByVid,
  getVariantByVariantId,
  clearSelectedVariant,
  setSelectedCountingTasks
});

type PropsFromRedux = ConnectedProps<typeof connector>;
export type CycleCountProps = PropsFromRedux;

export function CycleCountsV2(props: CycleCountProps) {
  const {
    selectedAutostoreGridId,
    usersFulfillmentCenter,
    initialFrequency,
    selectedVariant
  } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const page = useAppSelector((state) => state.cycleCountsNewSlice.page);
  const searchedVariant = useAppSelector(
    (state) => state.cycleCountsNewSlice.searchedVariant
  );

  const showCycleCountTaskPage = useFlag().cycleCountTaskDisplay;

  const [initialFetchComplete, setInitialFetchComplete] =
    useState<boolean>(false);

  // table row data
  const [variantIds, setVariantIds] = useState<Guid[] | null>(null);
  const [selectedRows, setSelectedRows] = useState<
    {
      cycleCountId: string;
      variantId: string;
      binCount: number;
    }[]
  >([]);
  const [mergedData, setMergedData] = useState<CycleCountTaskDto[]>([]);
  const countingTaskBinCount = selectedRows?.length
    ? selectedRows.reduce((acc, row) => acc + row.binCount, 0)
    : 0;

  const navigate = useNavigate();
  const locationInfo = useLocation();
  const { search } = locationInfo;
  // page vars
  const limit = 5;
  const offset = page >= 2 ? (page - 1) * limit : 0;

  const {
    data: cycleCountData,
    isLoading,
    refetch,
    isSuccess: isGetCycleCountsSuccess,
    isError: isGetCycleCountsError
  } = useGetOverhaulIncompleteCycleCountsQuery({
    offset,
    limit,
    gridId: selectedAutostoreGridId,
    AutoStoreBins: !!selectedAutostoreGridId,
    variantId: searchedVariant?.variantId
  });

  const { data: initialCycleCountData } =
    useGetOverhaulIncompleteCycleCountsQuery({
      gridId: selectedAutostoreGridId,
      AutoStoreBins: !!selectedAutostoreGridId,
      variantId: searchedVariant?.variantId
    });

  const { data: cycleCountVariantData, isLoading: loadingVariantData } =
    useVariantsByIdQuery({
      variantIds: variantIds?.length ? variantIds : []
    });

  const totalPageCount = cycleCountData?.totalCycleCounts
    ? Math.ceil(cycleCountData?.totalCycleCounts / limit)
    : 0;

  // maybe when this is called, if there is an acive-task group, we delete it and start a new one.
  const resetPage = useCallback(() => {
    setSelectedRows([]);
    dispatch(setPage(1));
    dispatch(setSearchedVariant(null));
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearSelectedCountingTasks());
  }, [dispatch]);

  // fetch incomplete counting tasks
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    if (!initialFetchComplete) refetch();
    setInitialFetchComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetchComplete]);

  // Fetch variants if we have counting tasks.
  useEffect(() => {
    if (initialCycleCountData?.cycleCounts?.length) {
      const allVariantIds = initialCycleCountData.cycleCounts.map(
        (task: IncompleteCycleCountResponseDto) => task.variantId
      );
      setVariantIds(allVariantIds);
    }
  }, [initialCycleCountData?.cycleCounts, page]);

  // pagination
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  useEffect(() => {
    const getVariantRecordInformation = async () => {
      if (selectedRows?.length) {
        // get the last selected variantId to pass into the product card
        const lastSelectedRow = selectedRows[selectedRows.length - 1];
        await props.getVariantData({
          variantId: lastSelectedRow.variantId,
          limit: 150,
          offset: 0,
          autostoreGridId: selectedAutostoreGridId
        });
        await props.getVariantByVariantId(lastSelectedRow.variantId);
        await props.getCycleCountFrequencyByVid(lastSelectedRow.variantId);
      } else {
        props.clearSelectedVariant();
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    getVariantRecordInformation();

    // merge variant & cc task data together
    const reducedVariantTaskData: CycleCountTaskDto[] = selectedRows.reduce(
      (result: CycleCountTaskDto[], row) => {
        const selectedTask: IncompleteCycleCountResponseDto | undefined =
          initialCycleCountData?.cycleCounts.find(
            (data) => data.cycleCountId === row.cycleCountId
          );
        const product = cycleCountVariantData?.find(
          (cycleCount) => cycleCount.variantId === row.variantId
        );

        if (selectedTask && product) {
          const mergedItem: CycleCountTaskDto = {
            ...selectedTask,
            ...product
          };
          result.push(mergedItem);
        }

        return result;
      },
      []
    );
    setMergedData(reducedVariantTaskData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows?.length]);

  const { setMenuItems } = useNavbar({
    centerComponent: useMemo(() => <CycleCountsSearch />, []),
    viewTitle: "nav.viewname.autostore cyclecounts"
  });
  useDevCheats({ showAutostoreStatus: true });
  useEffect(() => {
    setMenuItems([
      {
        textContent: t("refresh"),
        actionCb: resetPage
      }
    ]);
  }, [t, setMenuItems, resetPage]);

  useEffect(() => {
    // reset page on unmount
    return resetPage();
  }, [resetPage]);

  const showCycleCountFrequencyCardDetails = useFlag().cycleCountFrequency;

  return (
    <Stack padding={4} spacing={3} direction="row">
      {/* left side */}
      <Container maxWidth="sm" sx={{ height: 480 }}>
        {selectedVariant ? (
          <UniversalProductCard
            productName={
              getVariantDisplayNameByDtoFe(
                selectedVariant,
                usersFulfillmentCenter
              ) ?? ""
            }
            imageFileName={selectedVariant.imageFilename}
            sku={selectedVariant.sku}
            upc={selectedVariant.sku}
            allUpcs={selectedVariant.allUpcs}
            hideProductCount
          >
            {showCycleCountFrequencyCardDetails && (
              <CycleCountFrequency cycleCountFrequency={initialFrequency} />
            )}
          </UniversalProductCard>
        ) : (
          <PickInfoIsLoading height={480} />
        )}
      </Container>
      {/* right side */}
      <Stack direction="column" width="100%">
        <>
          {!!searchedVariant && (
            <ASButton
              sx={{ alignSelf: "flex-end", mb: 2 }}
              onClick={() => resetPage()}
              startIcon={<Undo20Px fill="black" />}
              variant="contained"
              color="primary"
              kind="subtle"
              style={{ fontWeight: "normal" }}
            >
              {t("return to all cycle counts")}
            </ASButton>
          )}
          {cycleCountData?.cycleCounts?.length &&
          cycleCountData.totalCycleCounts > 0 ? (
            <>
              <AutostoreTable<IncompleteCycleCountResponseDto>
                headerColNames={[t("product"), t("bin count"), t("due by")]}
                rowId={(row: IncompleteCycleCountResponseDto) =>
                  row.cycleCountId
                }
                renderColumns={[
                  (row) => {
                    const product =
                      cycleCountVariantData &&
                      Array.isArray(cycleCountVariantData)
                        ? cycleCountVariantData.find(
                            (cycleCount) =>
                              cycleCount.variantId === row.variantId
                          )
                        : undefined;
                    return (
                      <div>
                        <Typography variant="body2">
                          {product?.productName}
                        </Typography>
                      </div>
                    );
                  },
                  (row: IncompleteCycleCountResponseDto) => {
                    return (
                      <div>
                        <Typography variant="body2">
                          {row.countingTasks.length}
                        </Typography>
                      </div>
                    );
                  },
                  (row: IncompleteCycleCountResponseDto) => {
                    return (
                      <div>
                        <Typography variant="body2">{row.dueDate}</Typography>
                      </div>
                    );
                  }
                ]}
                widthOfCols={["40%", "20%", "20%", "20%"]}
                headerVariant="overline"
                bodyVariant="body2"
                rowData={cycleCountData.cycleCounts}
                selectedRows={selectedRows.map((row) => row.cycleCountId)}
                selectRowCallback={(row: IncompleteCycleCountResponseDto) => {
                  if (
                    selectedRows.some(
                      (selectedRow) =>
                        selectedRow.cycleCountId === row.cycleCountId
                    )
                  ) {
                    setSelectedRows(
                      selectedRows.filter(
                        (selectedRow) =>
                          selectedRow.cycleCountId !== row.cycleCountId
                      )
                    );
                  } else {
                    setSelectedRows([
                      ...selectedRows,
                      {
                        cycleCountId: row.cycleCountId,
                        variantId: row.variantId,
                        binCount: row.countingTasks.length
                      }
                    ]);
                  }
                }}
                loading={isLoading || loadingVariantData}
              />

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {totalPageCount > 1 && (
                  <Pagination
                    count={totalPageCount}
                    page={page}
                    onChange={(_e, p) => {
                      dispatch(setPage(p));
                      if (window.scrollTo) window.scrollTo(0, 0);
                    }}
                    shape="rounded"
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <ProgressButton
                  variant="contained"
                  color="primary"
                  buttonSize="large"
                  sx={{ paddingLeft: "20px" }}
                  onClick={() => {
                    if (
                      showCycleCountTaskPage &&
                      selectedRows?.length &&
                      cycleCountData?.cycleCounts.length
                    ) {
                      // reset variantId
                      props.clearSelectedVariant();
                      if (mergedData?.length) {
                        props.setSelectedCountingTasks(mergedData);
                        navigate({
                          pathname: `/autostore-cyclecountv2/cycle-count-task`,
                          search
                        });
                      }
                    }
                  }}
                  disabled={!selectedRows.length}
                >
                  <Typography variant="body2" style={{ color: "#fff" }}>
                    {t("start counting")}
                    {!!selectedRows?.length && ` (${countingTaskBinCount})`}
                  </Typography>
                </ProgressButton>
              </Box>
            </>
          ) : isGetCycleCountsSuccess || isGetCycleCountsError ? (
            <Typography variant="h3">{t("no cycle counts found")}</Typography>
          ) : null}
        </>
      </Stack>
    </Stack>
  );
}

export default connector(CycleCountsV2);
