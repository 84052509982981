import { Grid } from "@mui/material";

import { TinyIconGenerator } from "@qubit/autoparts";

export function TemperatureZoneHint(props: { temperatureZones: string[] }) {
  const { temperatureZones } = props;
  return temperatureZones.length === 0 ? null : (
    <Grid container direction="column">
      {temperatureZones.toSorted().map((temperatureZone, i) => (
        <Grid item key={temperatureZone}>
          <TinyIconGenerator
            tempZone={
              temperatureZone.toLowerCase() as "ambient" | "chilled" | "frozen"
            }
            type="Tote"
            key={`${temperatureZone}-${i}`}
          />
        </Grid>
      ))}
    </Grid>
  );
}
