import { Outlet } from "react-router";

import { AutostoreInactivityResetTimerProvider } from "~/hooks/useInactivityResetTimer";

export const RootLayout = () => {
  return (
    <>
      <AutostoreInactivityResetTimerProvider>
        <Outlet />
      </AutostoreInactivityResetTimerProvider>
    </>
  );
};
