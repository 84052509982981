import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ProgressButton, useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { StoreState } from "~/redux/reducers";

import { sendUserMessages } from "./andon.slice";

export type Recipient = {
  workstationUserId: string; // auth0|12341341234124|my.email@somewhere.com
  recipientId: string; // auth0|12341341234124
  displayName: string; // my.email@somewhere.com
};

const mapStateToProps = (state: StoreState) => ({
  usersFcId: state.store.usersFulfillmentCenter?.fulfillmentCenterId || null,
  siteFulfillmentCenterId: state.workstations.siteFulfillmentCenterId
});

const connector = connect(mapStateToProps, {
  sendUserMessages
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type MessengerProps = PropsFromRedux & {
  recipients: Recipient[];
  onCloseCb: () => void;
  isAll?: boolean;
};

export function Messenger(props: MessengerProps): React.ReactElement {
  const { recipients, onCloseCb, siteFulfillmentCenterId, isAll } = props;

  const { t } = useTranslation();
  const { successToast } = useToast();

  const respectablePhrases = [
    "break time",
    "team meeting",
    "safety stop",
    "please finish task and close port"
  ] as const;

  const [message, setMessage] = useState<
    (typeof respectablePhrases)[number] | ""
  >("");

  const handleSend = async () => {
    if (!siteFulfillmentCenterId) return;

    await props.sendUserMessages({
      fcId: siteFulfillmentCenterId,
      recipientIds: recipients.map((recipient) => recipient.recipientId),
      message: message ? t(message) : ""
    });
    successToast("Sent Message");
    onCloseCb();
  };

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={onCloseCb}
      aria-labelledby="messenger-modal-title"
    >
      <DialogTitle id="messenger-modal-title">Send Message</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseCb}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ mx: 6 }}>
        <Stack gap={2}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            <Typography>To: </Typography>
            {!isAll &&
              recipients.map((recipient) => (
                <Box key={recipient.workstationUserId} sx={{ display: "flex" }}>
                  <AccountCircle
                    sx={{
                      color: "gray",
                      marginLeft: "15px",
                      marginRight: "5px"
                    }}
                  />
                  <Typography>{recipient.displayName}</Typography>
                </Box>
              ))}
            {isAll && (
              <Box sx={{ marginLeft: "15px" }}>
                <Typography variant="h5">All</Typography>
              </Box>
            )}
          </Box>
          <Stack direction="column" gap={2} flexGrow={1}>
            <Typography>Type Message Here:</Typography>

            <TextField
              sx={{
                backgroundColor: "gray.light",
                border: "1px solid black",
                color: "black",
                flexGrow: 1
              }}
              variant="standard"
              value={message}
              multiline
              rows={4}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMessage(
                  e.target.value as (typeof respectablePhrases)[number]
                )
              }
            />
          </Stack>

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexWrap: "wrap"
            }}
          >
            {respectablePhrases.map((phrase) => (
              <Box key={phrase} sx={{ marginTop: "20px", marginLeft: "20px" }}>
                <ProgressButton
                  buttonSize="tiny"
                  emphasis="medium"
                  responsive
                  color="primary"
                  onClick={() => setMessage(phrase)}
                >
                  {t(phrase)}
                </ProgressButton>
              </Box>
            ))}
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <ProgressButton
          buttonSize="medium"
          emphasis="high"
          responsive
          variant="contained"
          color="primary"
          fullWidth
          disabled={!message || !siteFulfillmentCenterId}
          startIcon={<SendIcon style={{ fontSize: 22 }} />}
          onClick={() => handleSend()}
        >
          {t("send")}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}

export default connector(Messenger);
