import { Paper, Stack, Typography } from "@mui/material";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowCollapse
} from "@qubit/autoparts";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { ShapedTasksByBinIds } from "./TaskAssessment";

type TaskGroupTableByBinProps = {
  shapedTasksByBinIds: ShapedTasksByBinIds[];
};

export const TaskGroupTableByBin = (props: TaskGroupTableByBinProps) => {
  const { shapedTasksByBinIds } = props;
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<Guid[]>([]);

  return (
    <ASTableV2
      gridTemplateColumns="repeat(3, auto)"
      isLoading={false}
      isEmpty={!shapedTasksByBinIds.length}
      isEmptyMessage={t("no tasks found")}
    >
      <ASTableV2Header>
        <ASTableV2Row>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("BinId")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("TaskIds")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Tasks")}</Typography>
          </ASTableV2Cell>
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {shapedTasksByBinIds.map((row) => (
          <Fragment key={row.binId}>
            <ASTableV2Row
              selected={selectedRows.includes(`${row.binId}`)}
              onClick={() => {
                if (
                  selectedRows.length &&
                  selectedRows.includes(`${row.binId}`)
                ) {
                  setSelectedRows([]);
                } else {
                  setSelectedRows([`${row.binId}`]);
                }
              }}
            >
              <ASTableV2Cell>
                <Typography variant="tableBody">{row.binId}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {row.newTaskGroups.length}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{row.tasks.length}</Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
            <ASTableV2RowCollapse
              expanded={selectedRows.includes(`${row.binId}`)}
              selected={selectedRows.includes(`${row.binId}`)}
            >
              <Stack
                flexDirection="row"
                gap={2}
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {row.newTaskGroups.map((taskGroup) => (
                  <Paper
                    key={taskGroup.taskGroupId}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1
                    }}
                  >
                    <Typography variant="tableBody">
                      Task Group Id: {taskGroup.taskGroupId}
                    </Typography>
                    <Typography variant="tableBody">
                      Port: {taskGroup.selectedPortId ?? ""}
                    </Typography>
                    <Typography variant="tableBody">
                      Task Ids: {`[ ${taskGroup.tasks.join(" , ")} ]`}
                    </Typography>
                    <Typography variant="tableBody">
                      Batch Name: {taskGroup.batchName ?? ""}
                    </Typography>
                    <Typography variant="tableBody">
                      Batch Status: {taskGroup.batchStatus ?? ""}
                    </Typography>
                  </Paper>
                ))}
              </Stack>
            </ASTableV2RowCollapse>
          </Fragment>
        ))}
      </ASTableV2Body>
    </ASTableV2>
  );
};
