import { Box, BoxProps, styled, useTheme } from "@mui/material";

import { AutostoreRobotIcon } from "@qubit/autoparts";

import { BouncingDotsLoader } from "./BouncingDotsLoader";

const Container = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 20,
  "> div:nth-of-type(1)": {
    alignSelf: "end"
  }
});

/**
 * Renders an Autostore robot icon with bouncing marbles.
 *
 * The icon and marbles are scaled to the font size provided, defaulting to 1.
 */
export function RobotLoader(props: BoxProps) {
  const { palette } = useTheme();

  return (
    <Container {...props}>
      <AutostoreRobotIcon
        sx={{ fontSize: "4em", color: palette.autostoreRed.main }}
      />
      <BouncingDotsLoader />
    </Container>
  );
}
