import { SearchBinRecord } from "~/types/api";

export const createBinSearchOptions = (
  binRecords: SearchBinRecord[]
): {
  type: "bin" | "product";
  binId: string;
  displayText: string;
  autostore_compartment_number: number | undefined;
}[] =>
  binRecords.map((b: SearchBinRecord) => ({
    type: "bin",
    displayText: b.location,
    binId: b.bin_id,
    autostore_compartment_number: b.autostore_compartment_number
  }));
