import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import { useAppSelector } from "./store";

export function RedirectToAutostoreMain({ children }: PropsWithChildren) {
  const manualOpsEnabled = useAppSelector(selectClientConfig)?.manualOpsEnabled;

  return manualOpsEnabled === false ? (
    <Navigate to="/autostore-main" replace />
  ) : (
    children
  );
}
