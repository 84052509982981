import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MovementTaskState = "MovingProduct" | "ConfirmingMovement";

type BinMaintenanceWorkstationState = {
  movementTaskState: MovementTaskState;
};

const initialState: BinMaintenanceWorkstationState = {
  movementTaskState: "MovingProduct"
};

export const binMaintenanceWorkstationSlice = createSlice({
  name: "binMaintenanceWorkstation",
  initialState,
  reducers: {
    setTaskState(state, { payload }: PayloadAction<MovementTaskState>) {
      state.movementTaskState = payload;
    }
  }
});
export const { setTaskState } = binMaintenanceWorkstationSlice.actions;
