import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary, ToastProvider, autostoreTheme } from "@qubit/autoparts";
import * as Sentry from "@sentry/react";
import * as LDClient from "launchdarkly-react-client-sdk";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { compose } from "redux";
import { PersistGate } from "redux-persist/es/integration/react";

// initialize i18n must be imported first to ensure it is initialized before the translation t function is called
import "./initializeI18N";
import App from "./app/App";
import { startOtelInstrumentation } from "./app/otel";
import { store, persistor } from "./app/store";
import envConstants from "./config/envConstants";

import { mixpanelInit } from "./lib/mixpanel-tracking";
import { setupSimScan } from "./lib/simScan";

import "@qubit/autoparts/src/assets/index.css";

// this is generated by the unfont vite plugin
// eslint-disable-next-line import-x/no-unresolved
import "unfonts.css";

const isDevelopment = envConstants.DEV;

// Sentry
Sentry.init({
  dsn: "https://7618629676a54b71a940c9499eb786ba@o262631.ingest.sentry.io/5477579",
  release: envConstants.VERSION_TAG || "",
  environment: isDevelopment ? "development" : envConstants.RUNTIME_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false })
  ],
  tracesSampleRate: isDevelopment ? 0 : 0.5,
  profilesSampleRate: isDevelopment ? 0 : 0.5,
  replaysOnErrorSampleRate:
    ["local", "staging"].includes(envConstants.RUNTIME_ENVIRONMENT || "") ||
    isDevelopment
      ? 0
      : 1.0,
  beforeSend(event) {
    if (isDevelopment) {
      console.debug("Sentry event:", event);
      return null;
    }
    return event;
  }
});

mixpanelInit();

startOtelInstrumentation();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    simScan: (barcode: string) => void;
    simScanGuid: (guid: Guid) => void | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    speech?: any;
  }
}

const container = document.getElementById("react-root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

void (async () => {
  const LDProvider = await LDClient.asyncWithLDProvider({
    clientSideID: envConstants.LAUNCH_DARKLY_CLIENT_SIDE_ID,
    context: {
      kind: "user",
      key: "unauthenticated"
    }
  });

  if (envConstants.DEV_CHEATS_UAT === "true") {
    setupSimScan();
  }

  document.title =
    envConstants.RUNTIME_ENVIRONMENT === "production"
      ? "Qubit"
      : `Qubit ${envConstants.RUNTIME_ENVIRONMENT}`;

  // <Suspense> was added to allow time for localization to initialize
  root.render(
    <ErrorBoundary>
      <LDProvider>
        <Suspense fallback="">
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={autostoreTheme}>
                <ToastProvider>
                  <App />
                </ToastProvider>
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </Suspense>
      </LDProvider>
    </ErrorBoundary>
  );
})();
