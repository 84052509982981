import Button from "@locaisolutions/button";
import { Add, Clear } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator
} from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import {
  autostoreTheme,
  ConfirmationModal,
  ErrorPanel,
  useToast
} from "@qubit/autoparts";
import moment, { Duration } from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  dayOfWeekAsString,
  daysOfTheWeek,
  DayOfWeekMap,
  DayOfWeekValue
} from "~/lib/daysOfTheWeek";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { groupBy } from "~/lib/shared";
import {
  useDeleteRecurringScheduleMutation,
  useGetRecurringSchedulesQuery
} from "~/redux/warehouse/recurringSchedule.hooks";
import { useGetStagingAreasQuery } from "~/redux/warehouse/stagingArea.hooks";
import { RecurringScheduleDto } from "~/types/api";

import CreateRecurringScheduleModal from "./CreateRecurringScheduleModal";
import EditRecurringScheduleModal from "./EditRecurringScheduleModal";
import OrderCount from "./OrderCount";

function startOfWindowDuration(
  recurringSchedule: RecurringScheduleDto
): Duration {
  return moment.duration(recurringSchedule.scheduleWindow?.[0] ?? 0);
}

function endOfWindowDuration(
  recurringSchedule: RecurringScheduleDto
): Duration {
  return moment.duration(recurringSchedule.scheduleWindow?.[1] ?? 0);
}

function RecurringSchedulesLoading() {
  return (
    <Stack direction="column" spacing={3}>
      {Array.from({ length: 7 }, (_, i) => (
        <Skeleton key={i} variant="rectangular" />
      ))}
    </Stack>
  );
}

export function RecurringSchedules() {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const {
    batchesByDay,
    isLoading: isRecurringSchedulesLoading,
    error: recurringSchedulesError,
    refetch: refetchRecurringSchedules
  } = useGetRecurringSchedulesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      batchesByDay: groupBy(data || [], (recurringWave) =>
        dayOfWeekAsString(recurringWave.dayOfWeek)
      )
    })
  });
  const {
    data: stagingAreas,
    isLoading: isStagingAreasLoading,
    error: stagingAreaError
  } = useGetStagingAreasQuery();
  const [deleteRecurringSchedule] = useDeleteRecurringScheduleMutation();

  const [recurringScheduleToEdit, setRecurringScheduleToEdit] =
    useState<RecurringScheduleDto | null>(null);
  const [recurringScheduleDayToCreate, setRecurringScheduleDayToCreate] =
    useState<DayOfWeekValue | null>(null);
  const [recurringScheduleToDelete, setRecurringScheduleToDelete] =
    useState<RecurringScheduleDto | null>(null);

  if (stagingAreaError || recurringSchedulesError) {
    return (
      <ErrorPanel
        message={getMessageFromRtkError(
          stagingAreaError || recurringSchedulesError
        )}
      />
    );
  }

  if (
    isRecurringSchedulesLoading ||
    isStagingAreasLoading ||
    Object.keys(batchesByDay).length === 0 ||
    !stagingAreas
  ) {
    return <RecurringSchedulesLoading />;
  }

  function lookupStagingArea(recurringSchedule: RecurringScheduleDto) {
    const stagingArea = stagingAreas?.find(
      (sa) => sa.stagingAreaId === recurringSchedule.stagingAreaId
    );
    return stagingArea ? stagingArea.stagingAreaName : "N/A";
  }

  const handleDeleteSchedule = async () => {
    if (!recurringScheduleToDelete) {
      return;
    }
    try {
      await deleteRecurringSchedule(
        recurringScheduleToDelete.recurringScheduleId
      ).unwrap();
      await refetchRecurringSchedules();
      successToast(t("batch setting deleted"));
      setRecurringScheduleToDelete(null);
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Box pb="20px">
      {daysOfTheWeek.map((dayOfWeek) => (
        <Accordion key={dayOfWeek}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontSize={20} sx={{ pl: "16px" }}>
              {t(dayOfWeek)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box maxWidth="70%" m="0 auto">
              {!batchesByDay[dayOfWeek]?.length ? (
                <Typography fontSize={20} textAlign="center">
                  {t("there are no schedules for this day")}
                </Typography>
              ) : (
                <Timeline
                  position="right"
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2
                    }
                  }}
                >
                  {batchesByDay[dayOfWeek]
                    .sort(
                      (a, b) =>
                        startOfWindowDuration(a).milliseconds() -
                        startOfWindowDuration(b).milliseconds()
                    )
                    .map((recurringSchedule, i) => (
                      <TimelineItem key={recurringSchedule.recurringScheduleId}>
                        <TimelineOppositeContent>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                          >
                            {moment
                              .utc(
                                startOfWindowDuration(
                                  recurringSchedule
                                ).asMilliseconds()
                              )
                              .format("hh:mm A")}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="primary" />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Paper elevation={3}>
                            <Stack
                              justifyContent="space-between"
                              direction="row"
                            >
                              <OrderCount
                                recurringSchedule={recurringSchedule}
                              />

                              <Stack direction="row">
                                <IconButton
                                  aria-label={`edit-${dayOfWeek}-${i}`}
                                  onClick={() => {
                                    setRecurringScheduleToEdit(
                                      recurringSchedule
                                    );
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  aria-label={`delete-${dayOfWeek}-${i}`}
                                  onClick={() => {
                                    setRecurringScheduleToDelete(
                                      recurringSchedule
                                    );
                                  }}
                                >
                                  <Clear
                                    sx={{
                                      color: autostoreTheme.palette.error.main
                                    }}
                                  />
                                </IconButton>
                              </Stack>
                            </Stack>

                            {recurringSchedule.stagingAreaId && (
                              <Box paddingX={3} paddingY={1}>
                                <Typography>
                                  {`Assigned to staging area
                              ${lookupStagingArea(recurringSchedule)}`}
                                </Typography>
                              </Box>
                            )}
                          </Paper>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment
                          .utc(
                            endOfWindowDuration(
                              batchesByDay[dayOfWeek][
                                batchesByDay[dayOfWeek].length - 1
                              ]
                            ).asMilliseconds()
                          )
                          .format("hh:mm A")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent />
                  </TimelineItem>
                </Timeline>
              )}
            </Box>

            <Stack>
              <Button
                startIcon={<Add />}
                sx={{ m: "0 16px 16px", alignSelf: "flex-end" }}
                onClick={() =>
                  setRecurringScheduleDayToCreate(DayOfWeekMap[dayOfWeek])
                }
              >
                <Typography lineHeight="28px">
                  {t("add schedule to")} {dayOfWeek}
                </Typography>
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}

      {recurringScheduleToEdit && (
        <EditRecurringScheduleModal
          recurringSchedule={recurringScheduleToEdit}
          onClose={() => setRecurringScheduleToEdit(null)}
        />
      )}
      {recurringScheduleDayToCreate !== null && (
        <CreateRecurringScheduleModal
          dayOfWeekToCreate={recurringScheduleDayToCreate}
          onClose={() => setRecurringScheduleDayToCreate(null)}
        />
      )}
      {recurringScheduleToDelete && (
        <ConfirmationModal
          isOpen
          confirmCb={handleDeleteSchedule}
          closeCb={() => setRecurringScheduleToDelete(null)}
          modalTitle="Delete Schedule"
          modalText="Are you sure you want to delete this schedule?"
        />
      )}
    </Box>
  );
}
