import {
  CreateRecurringScheduleRequest,
  RecurringScheduleDto
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const recurringScheduleApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getRecurringSchedules: build.query<RecurringScheduleDto[], void>({
      query: () => ({
        url: `/recurring-schedules`,
        method: "GET"
      }),
      providesTags: ["recurring schedule"]
    }),
    deleteRecurringSchedule: build.mutation<void, Guid>({
      query: (recurringScheduleId) => ({
        url: `/recurring-schedules/${recurringScheduleId}`,
        method: "DELETE"
        // Caution with adding invalidatesTags here. Since there is no PATCH
        // endpoint, when we edit a schedule we actually delete and then create
        // (two calls) which has the potential to refetch twice if we invalidate both
      })
    }),
    postRecurringSchedule: build.mutation<void, CreateRecurringScheduleRequest>(
      {
        query: (body) => ({
          url: `/recurring-schedules`,
          method: "POST",
          body
        }),
        invalidatesTags: ["recurring schedule"]
      }
    )
  }),
  overrideExisting: false
});
