import { useEffect, useState } from "react";

import { hubs } from "~/lib/signalr";

import LEDIndicator from "./LEDIndicator";

export const ActivityIndicator = () => {
  const [eventReceived, setEventReceived] = useState(false);

  useEffect(() => {
    if (eventReceived) {
      setTimeout(() => setEventReceived(false), 1);
    }
  }, [eventReceived]);

  hubs.gridV2.subscribe(() => setEventReceived(true));
  hubs.tote.subscribe(() => setEventReceived(true));
  hubs.pick.subscribe(() => setEventReceived(true));
  hubs.userMessage.subscribe(() => setEventReceived(true));
  hubs.ptl.subscribe(() => setEventReceived(true));

  return <LEDIndicator status={eventReceived ? "good" : "inactive"} />;
};
