import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { PreviousBinInfo } from "~/api/warehouseTypes/previousBin";

import {
  AutostoreOutOfStockAction,
  AutostoreOutOfStockFailureAction,
  AutostoreOutOfStockSuccessAction,
  BinAtPortEventAction,
  BinNotAtPortAction,
  ClearPickingStateAction,
  ClosePort,
  ClosePortSuccessAction,
  CloseWorkstationSuccessAction,
  CompletePickFailureAction,
  CompletePickSuccessAction,
  DisplayToteConfirmationModalForToteAction,
  EmptyBinBtnState,
  FetchNextPickingBin,
  FetchNextPickingBinFailureAction,
  FetchNextPickingBinSuccessAction,
  FlagBinAction,
  FlagBinFailureAction,
  FlagBinSuccessAction,
  GetNextBinAction,
  GetNextBinFailureAction,
  GetNextBinSuccessAction,
  GetPortAction,
  GetPortFailureAction,
  GetPortResponse,
  GetPortSuccessAction,
  LeftOrRightPort,
  NextEmptyBinAction,
  NextEmptyBinSuccessAction,
  OpenAutostoreBinAction,
  OpenBinSuccessAction,
  OpenPort,
  OpenPortByContentCode,
  OpenPortByContentCodeFailureAction,
  OpenPortByContentCodeSuccessAction,
  OpenPortFailureAction,
  OpenPortSuccessAction,
  PickQuantityConfirmed,
  ReprintLabelAction,
  ReprintLabelFailureAction,
  ReprintLabelSuccessAction,
  ResetBinAndPortData,
  RestartNextPickingBinFailureMessageAction,
  RestartSplitAutostoreAction,
  SetBinIsPresentAction,
  SetConfirmationModalVisibilityStatusAction,
  SetCurrentEmptyBinAction,
  SetIsPreppedCartConfirmed,
  SetNextPickingBinLoadingAction,
  SetNoMoreBinsAction,
  SetPreviousBinAction,
  SetSelectedAutostoreBinIdAction,
  SetSelectedBinConfigurations,
  SetSignalrAction,
  ShowPickToLighFailureAction,
  ShowPickToLightAction,
  ShowPickToLightSuccessAction,
  ShowTwoWbinComponentsAction,
  SplitAutostoreAction,
  SplitAutostoreFailureAction,
  SplitAutostoreSuccessAction,
  StartPickingOnWorkstationAction,
  StartPickingOnWorkstationFailureAction,
  StartPickingOnWorkstationSuccessAction,
  SuspendBatchAction,
  SuspendBatchFailureAction,
  SuspendBatchSuccessAction,
  TotePickedAction,
  TotePickingStartedAction,
  UpdatePickingStateAction,
  UpdatePickingStateFailureAction,
  UpdatePickingStateFromEventAction,
  UpdatePickingStateSuccessAction
} from "~/redux/actions";

import { PlaceInventoryHoldSuccessAction } from "~/redux/actions/inventory";
import {
  AutostoreBinConfigurationDto,
  AutostorePickingState,
  FulfillmentCenterDto,
  InventoryDto,
  NextBinResponse,
  NextEmptyBinResponse,
  PickingStateV2Tote,
  PortSide,
  SplitPickResponse,
  SuggestBinConfigurationResponse,
  ToteDto
} from "~/types/api";

export type SignalRState = "Connected" | "Disconnected";

type Actions =
  | SetSelectedBinConfigurations
  | NextEmptyBinAction
  | NextEmptyBinSuccessAction
  | SetCurrentEmptyBinAction
  | ClosePort
  | ClosePortSuccessAction
  | CloseWorkstationSuccessAction
  | OpenPort
  | OpenPortSuccessAction
  | OpenPortFailureAction
  | OpenPortByContentCode
  | OpenPortByContentCodeSuccessAction
  | OpenPortByContentCodeFailureAction
  | SetIsPreppedCartConfirmed
  | SetSelectedAutostoreBinIdAction
  | GetPortAction
  | GetPortFailureAction
  | GetPortSuccessAction
  | GetNextBinAction
  | GetNextBinSuccessAction
  | GetNextBinFailureAction
  | SetSignalrAction
  | AutostoreOutOfStockAction
  | AutostoreOutOfStockSuccessAction
  | AutostoreOutOfStockFailureAction
  | SplitAutostoreAction
  | SplitAutostoreSuccessAction
  | SplitAutostoreFailureAction
  | SetPreviousBinAction
  | SuspendBatchAction
  | SuspendBatchSuccessAction
  | SuspendBatchFailureAction
  | EmptyBinBtnState
  | UpdatePickingStateAction
  | UpdatePickingStateSuccessAction
  | UpdatePickingStateFailureAction
  | UpdatePickingStateFromEventAction
  | ClearPickingStateAction
  | OpenAutostoreBinAction
  | OpenBinSuccessAction
  | BinAtPortEventAction
  | BinNotAtPortAction
  | ShowPickToLightAction
  | ShowPickToLightSuccessAction
  | ShowPickToLighFailureAction
  | SetNoMoreBinsAction
  | CompletePickSuccessAction
  | CompletePickFailureAction
  | PlaceInventoryHoldSuccessAction
  | SetConfirmationModalVisibilityStatusAction
  | PickQuantityConfirmed
  | SetBinIsPresentAction
  | SetNextPickingBinLoadingAction
  | LeftOrRightPort
  | StartPickingOnWorkstationAction
  | StartPickingOnWorkstationSuccessAction
  | StartPickingOnWorkstationFailureAction
  | TotePickedAction
  | TotePickingStartedAction
  | ReprintLabelAction
  | ReprintLabelSuccessAction
  | ReprintLabelFailureAction
  | FetchNextPickingBin
  | FetchNextPickingBinSuccessAction
  | FetchNextPickingBinFailureAction
  | RestartNextPickingBinFailureMessageAction
  | ResetBinAndPortData
  | ShowTwoWbinComponentsAction
  | FlagBinAction
  | FlagBinSuccessAction
  | FlagBinFailureAction
  | RestartSplitAutostoreAction
  | DisplayToteConfirmationModalForToteAction;

export type NextBinInventory = {
  nextBinInventory: InventoryDto[];
  portId: number;
  nextBinConfiguration: AutostoreBinConfigurationDto | null;
  nextBinResponse: NextBinResponse;
};

export type AutostoreState = {
  cartNumberConfirmed: boolean;
  nextBinInventoryResponse: InventoryDto[];
  nextBinInventoryByPort: {
    [portId: number]: NextBinInventory;
  };
  nextBinConfiguration: AutostoreBinConfigurationDto | null;
  selectedBinConfigurations: number[];
  fulfillmentCenters: FulfillmentCenterDto[];
  currentEmptyBin: NextEmptyBinResponse | null;
  nextEmptyBinByPort: {
    [portId: number]: NextEmptyBinResponse | null;
  };
  signalr: { state: SignalRState };
  portState: GetPortResponse | null;
  portStateByPort: {
    [portId: number]: { getPortResponse: GetPortResponse; timestamp: Date };
  };
  portStateLoading: boolean;
  portStateTimestamp: Date | null;
  openingPort: boolean;
  selectedAutostoreBinId: number | null;
  requestedAutostoreBin: AutostoreBinConfigurationDto | null;
  splitAutostore: SplitPickResponse | null;
  splitAutostoreLoading: boolean;
  outOfStockAutostore: boolean | null;
  previousBin: PreviousBinInfo | null;
  suspendBatch: boolean;
  getEmptyBinBtn: boolean;
  pickingState: AutostorePickingState | null;
  pickingStateLoading: boolean;
  binAtPort: boolean;
  showPickToLightSuccess: boolean;
  showPickToLightLoading: boolean;
  noMoreBins: boolean;
  isConfirmationModalOpen: boolean;
  isPickQuantityConfirmed: boolean;
  nextPickingStateLoading: boolean;
  binIsPresent: {
    status: boolean;
    reporter: string;
    timestamp: Date;
    binId?: number;
    millisecondsSinceDelta: number | null;
    confirmations: { reporter: string; timestamp: Date }[];
  };
  previousBinIsPresents: {
    status: boolean;
    reporter: string;
    timestamp: Date;
    binId?: number;
    millisecondsSinceDelta: number | null;
    confirmations: { reporter: string; timestamp: Date }[];
  }[];
  leftOrRightPortActive: PortSide;
  pickedTote: ToteDto | null;
  pickingStartedToteId: Guid | null;
  reprintLabelFinished: boolean;
  reprintLabelLoading: boolean;
  availableTemperatureZones: string[] | null;
  availableTemperatureZonesLoading: boolean;
  allPicksCompleted: boolean;
  allPicksCompletedErrorMessage: string | null;
  suggestedBin: SuggestBinConfigurationResponse | null;
  showTwoBinComponents: boolean;
  isBinFlagged: boolean | null;
  displayToteConfirmationModalForTote: PickingStateV2Tote | null;
};

export const initialState: AutostoreState = {
  allPicksCompleted: false,
  allPicksCompletedErrorMessage: null,
  availableTemperatureZones: null,
  availableTemperatureZonesLoading: false,
  binAtPort: false,
  binIsPresent: {
    confirmations: [],
    millisecondsSinceDelta: null,
    reporter: "initial",
    status: false,
    timestamp: new Date()
  },
  cartNumberConfirmed: false,
  currentEmptyBin: null,
  fulfillmentCenters: [],
  getEmptyBinBtn: true,
  isConfirmationModalOpen: false,
  isPickQuantityConfirmed: false,
  leftOrRightPortActive: "Unknown",
  nextBinConfiguration: null,
  nextBinInventoryByPort: {},
  nextBinInventoryResponse: [],
  nextEmptyBinByPort: {},
  nextPickingStateLoading: false,
  noMoreBins: false,
  openingPort: false,
  outOfStockAutostore: null,
  pickedTote: null,
  pickingStartedToteId: null,
  pickingState: null,
  pickingStateLoading: false,
  portState: null,
  portStateByPort: {},
  portStateLoading: false,
  portStateTimestamp: null,
  previousBin: null,
  previousBinIsPresents: [],
  reprintLabelFinished: false,
  reprintLabelLoading: false,
  requestedAutostoreBin: null,
  selectedAutostoreBinId: null,
  selectedBinConfigurations: [],
  showPickToLightLoading: false,
  showPickToLightSuccess: false,
  signalr: { state: "Disconnected" as SignalRState },
  splitAutostore: null,
  splitAutostoreLoading: false,
  suspendBatch: false,
  suggestedBin: null,
  showTwoBinComponents: false,
  isBinFlagged: null,
  displayToteConfirmationModalForTote: null
};

const reducer = (
  state: AutostoreState | undefined,
  action: Actions
): AutostoreState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "autostore/GET_NEXT_BIN_FAILURE":
      // eslint-disable-next-line no-case-declarations
      const newInvByPort = Object.entries(state.nextBinInventoryByPort).reduce(
        (acc: { [portId: number]: NextBinInventory }, [port, inv]) => {
          if (!action.payload || `${action.payload}` !== port) {
            // eslint-disable-next-line no-param-reassign
            acc[+port] = inv;
          }
          return acc;
        },
        {}
      );
      return {
        ...state,
        nextBinConfiguration: null,
        nextBinInventoryResponse: [],
        nextBinInventoryByPort: newInvByPort
      };
    case "autostore/SET_SELECTED_BIN_CONFIGURATIONS":
      return { ...state, selectedBinConfigurations: action.payload };
    case "autostore/SET_PREVIOUS_BIN":
      return { ...state, previousBin: action.payload };
    case "batch/SUSPEND_BATCH":
      return {
        ...state,
        suspendBatch: false
      };
    case "batch/SUSPEND_BATCH_SUCCESS":
      return {
        ...state,
        suspendBatch: true
      };
    case "batch/SUSPEND_BATCH_FAILURE":
      return {
        ...state,
        suspendBatch: false
      };
    case "autostore/NEXT_EMPTY_BIN_SUCCESS":
      return {
        ...state,
        currentEmptyBin: action.payload.nextEmptyBin,
        nextEmptyBinByPort: {
          ...state.nextEmptyBinByPort,
          [action.payload.portId]: action.payload.nextEmptyBin
        }
      };
    case "autostore/SET_CURRENT_EMPTY_BIN":
      return {
        ...state,
        currentEmptyBin: action.payload
      };
    case "autostore/GET_PORT":
      return {
        ...state,
        portStateLoading: true
      };

    case "autostore/GET_PORT_SUCCESS":
      return {
        ...state,
        binAtPort: action.payload.getPortResponse.isReady,
        portState: action.payload.getPortResponse,
        portStateByPort: {
          ...state.portStateByPort,
          [action.payload.getPortResponse.portId]: {
            getPortResponse: action.payload.getPortResponse,
            timestamp: action.payload.timestamp
          }
        },
        portStateLoading: false,
        portStateTimestamp: action.payload.timestamp
      };
    case "autostore/GET_PORT_FAILURE":
      return { ...state, portState: null, portStateLoading: false };
    case "autostore/GET_EMPTY_BIN_BTN_STATE":
      return { ...state, getEmptyBinBtn: action.payload };
    case "autostore/CLOSE_PORT":
      return {
        ...state,
        currentEmptyBin: null
      };
    case "autostore/CLOSE_PORT_SUCCESS":
      return {
        ...state,
        nextEmptyBinByPort: {},
        portState: action.payload.portState,
        portStateByPort: action.payload.doNotClear ? state.portStateByPort : {}
      };
    case "autostore/CLOSE_WORKSTATION_SUCCESS":
      return {
        ...state,
        nextEmptyBinByPort: {},
        nextBinInventoryByPort: {},
        portStateByPort: {},
        currentEmptyBin: null
      };
    case "autostore/OPEN_AUTOSTORE_BIN":
      return { ...state, requestedAutostoreBin: null };
    case "autostore/OPEN_AUTOSTORE_BIN_SUCCESS":
      return { ...state, requestedAutostoreBin: action.payload };
    case "autostore/OPEN_PORT_BY_CONTENT_CODE":
      return {
        ...state,
        currentEmptyBin: null,
        openingPort: true
      };
    case "autostore/OPEN_PORT_BY_CONTENT_CODE_FAILURE":
      return { ...state, openingPort: false };
    case "autostore/OPEN_PORT_BY_CONTENT_CODE_SUCCESS":
      return { ...state, openingPort: false };
    case "autostore/OPEN_PORT":
      return {
        ...state,
        currentEmptyBin: null,
        openingPort: true
      };
    case "autostore/OPEN_PORT_FAILURE":
      return { ...state, openingPort: false };
    case "autostore/OPEN_PORT_SUCCESS":
      return { ...state, openingPort: false };
    case "autostore/SET_IS_PREPPED_CART_CONFIRMED":
      return { ...state, cartNumberConfirmed: action.payload };
    case "autostore/COMPLETE_PICK_SUCCESS":
      return {
        ...state
      };
    case "autostore/COMPLETE_PICK_FAILURE":
      return {
        ...state,
        nextPickingStateLoading: false
      };
    case "autostore/SET_SELECTED_AUTOSTORE_BIN_ID":
      return {
        ...state,
        selectedAutostoreBinId: action.payload
      };
    case "autostore/SET_SIGNALR":
      return {
        ...state,
        signalr: action.payload
      };
    case "autostore/GET_NEXT_BIN":
      return {
        ...state,
        nextBinInventoryResponse: []
      };
    case "autostore/GET_NEXT_BIN_SUCCESS":
      return {
        ...state,
        nextBinConfiguration: action.payload.nextBinConfiguration,
        nextBinInventoryByPort: {
          ...state.nextBinInventoryByPort,
          [action.payload.portId]: action.payload
        },
        nextBinInventoryResponse: action.payload.nextBinInventory
      };
    case "inventory/PLACE_INVENTORY_HOLD_SUCCESS":
      return {
        ...state,
        nextBinInventoryResponse: [
          ...state.nextBinInventoryResponse.map((inv) => {
            return inv;
          })
        ]
      };
    case "autostore/AUTOSTORE_OUT_OF_STOCK":
      return {
        ...state
      };

    case "autostore/AUTOSTORE_OUT_OF_STOCK_FAILURE":
      return {
        ...state,
        outOfStockAutostore: null
      };
    case "autostore/AUTOSTORE_OUT_OF_STOCK_SUCCESS":
      return {
        ...state,
        outOfStockAutostore: true
      };
    case "autostore/SPLIT_AUTOSTORE":
      return {
        ...state,
        splitAutostore: null,
        splitAutostoreLoading: true
      };
    case "autostore/SPLIT_AUTOSTORE_FAILURE":
    case "autostore/RESTART_SPLIT_AUTOSTORE":
      return {
        ...state,
        splitAutostore: null,
        splitAutostoreLoading: false
      };
    case "autostore/SPLIT_AUTOSTORE_SUCCESS":
      return {
        ...state,
        splitAutostore: action.payload,
        splitAutostoreLoading: false
      };
    case "autostore/UPDATE_PICKING_STATE":
      return {
        ...state,
        pickingStateLoading: true
      };
    case "autostore/UPDATE_PICKING_STATE_SUCCESS":
      return {
        ...state,
        pickingState: action.payload.pickingState,
        pickingStateLoading: false
      };
    case "autostore/UPDATE_PICKING_STATE_FAILURE":
      return {
        ...state,
        pickingStateLoading: false
      };
    case "autostore/START_PICKING_ON_WORKSTATION":
      return {
        ...state,
        pickingState: null,
        pickingStateLoading: true
      };
    case "autostore/START_PICKING_ON_WORKSTATION_SUCCESS":
      return {
        ...state,
        pickingState: action.payload.pickingState,
        pickingStateLoading: false
      };
    case "autostore/START_PICKING_ON_WORKSTATION_FAILURE":
      return {
        ...state,
        pickingState: null,
        pickingStateLoading: false
      };
    case "autostore/UPDATE_PICKING_STATE_FROM_EVENT":
      return {
        ...state,
        pickingState: action.payload.pickingState,
        pickingStateLoading: false
      };
    case "autostore/CLEAR_PICKING_STATE":
      return {
        ...state,
        pickingState: null,
        pickingStateLoading: false
      };
    case "autostore/BIN_AT_PORT_EVENT":
      return {
        ...state,
        binAtPort: true
      };
    case "autostore/BIN_NOT_AT_PORT":
      return {
        ...state,
        binAtPort: false
      };
    case "autostore/SHOW_PICK_TO_LIGHT":
      return {
        ...state,
        showPickToLightLoading: true,
        showPickToLightSuccess: false
      };
    case "autostore/SHOW_PICK_TO_LIGHT_SUCCESS":
      return {
        ...state,
        showPickToLightLoading: false,
        showPickToLightSuccess: true
      };
    case "autostore/SHOW_PICK_TO_LIGHT_FAILURE":
      return {
        ...state,
        showPickToLightLoading: false,
        showPickToLightSuccess: false
      };
    case "autostore/SET_NO_MORE_BINS":
      return {
        ...state,
        noMoreBins: action.payload
      };
    case "autostore/SET_CONFIRMATION_MODAL_VISIBILITY_STATUS":
      return {
        ...state,
        isConfirmationModalOpen: action.payload
      };
    case "autostore/PICK_QUANTITY_CONFIRMED":
      return {
        ...state,
        isPickQuantityConfirmed: action.payload
      };
    case "autostore/SET_NEXT_PICKING_BIN_LOADING":
      return {
        ...state,
        nextPickingStateLoading: action.payload
      };
    case "autostore/SET_BIN_IS_PRESENT": {
      const {
        status: oldStatus,
        timestamp: oldTimestamp,
        reporter: newReporter
      } = state.binIsPresent;

      const { status: newStatus, timestamp: newTimestamp } = action.payload;

      if (newStatus === oldStatus) {
        return {
          ...state,
          binIsPresent: {
            ...state.binIsPresent,
            confirmations: [
              ...state.binIsPresent.confirmations,
              { reporter: newReporter, timestamp: newTimestamp }
            ]
          }
        };
      }

      const newArray =
        newReporter !== "initial"
          ? [...state.previousBinIsPresents, state.binIsPresent]
          : state.previousBinIsPresents;

      if (newArray.length === 100) newArray.shift();

      return {
        ...state,
        binIsPresent: {
          ...state.binIsPresent,
          ...action.payload,
          confirmations: [],
          millisecondsSinceDelta:
            newTimestamp.valueOf() - oldTimestamp.valueOf()
        },
        previousBinIsPresents: newArray
      };
    }
    case "autostore/LEFT_OR_RIGHT_PORT":
      return {
        ...state,
        leftOrRightPortActive: action.payload
      };
    case "autostore/TOTE_PICKED":
      return {
        ...state,
        pickedTote: action.payload
      };
    case "autostore/TOTE_PICKING_STARTED":
      return {
        ...state,
        pickingStartedToteId: action.payload
      };
    case "autostore/REPRINT_LABEL":
      return {
        ...state,
        reprintLabelFinished: false,
        reprintLabelLoading: true
      };
    case "autostore/REPRINT_LABEL_SUCCESS":
      return {
        ...state,
        reprintLabelFinished: true,
        reprintLabelLoading: false
      };
    case "autostore/REPRINT_LABEL_FAILURE":
      return {
        ...state,
        reprintLabelFinished: false,
        reprintLabelLoading: false
      };
    case "autostore/FETCH_NEXT_PICKING_BIN": {
      return {
        ...state,
        allPicksCompleted: false,
        allPicksCompletedErrorMessage: null
      };
    }
    case "autostore/FETCH_NEXT_PICKING_BIN_SUCCESS": {
      return {
        ...state,
        allPicksCompleted: action.payload,
        allPicksCompletedErrorMessage: null
      };
    }
    case "autostore/FETCH_NEXT_PICKING_BIN_FAILURE": {
      return {
        ...state,
        allPicksCompleted: false,
        allPicksCompletedErrorMessage: action.payload
      };
    }
    case "autostore/RESTART_NEXT_PICKING_BIN_MESSAGE_FAILURE": {
      return {
        ...state,
        allPicksCompletedErrorMessage: null
      };
    }
    case "autostore/RESET_BIN_AND_PORT_DATA": {
      return {
        ...state,
        nextEmptyBinByPort: {},
        portStateByPort: {},
        nextBinInventoryByPort: {}
      };
    }
    case "autostore/SHOW_TWO_BIN_COMPONENTS": {
      return {
        ...state,
        showTwoBinComponents: action.payload
      };
    }
    case "autostore/FLAG_BIN":
      return {
        ...state,
        isBinFlagged: null
      };
    case "autostore/FLAG_BIN_SUCCESS":
      return {
        ...state,
        isBinFlagged: true
      };
    case "autostore/FLAG_BIN_FAILURE":
      return {
        ...state,
        isBinFlagged: false
      };
    case "autostore/DISPLAY_TOTE_CONFIRMATION_MODAL_FOR_TOTE":
      return {
        ...state,
        displayToteConfirmationModalForTote: action.payload
      };
    default:
      return state;
  }
};

const autostorePersistConfig = {
  key: "autostore",
  storage,
  whitelist: [
    "pickingState",
    "isPickQuantityConfirmed",
    "addedTotes",
    "cartNumberConfirmed",
    "leftOrRightPortActive",
    "previousBinIsPresents",
    "pickedTote",
    "pickingStartedToteId",
    "showTwoBinComponents"
  ]
};

export const autostore = persistReducer(autostorePersistConfig, reducer);
