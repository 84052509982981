import { useLoading } from "@agney/react-loading";
import LoadingGrid from "@agney/react-loading/src/svg-loaders/grid.svg?react";

import { useTheme } from "@mui/material";

export type AutostoreLoadingIconProps = {
  loading?: boolean;
  height?: number;
  randomColor?: boolean;
  color?: string;
};

export function AutostoreLoadingIcon(props: AutostoreLoadingIconProps) {
  const { palette } = useTheme();
  const {
    loading = true,
    color = palette.autostoreRed.main,
    height = 20,
    randomColor = false
  } = props;

  const colorToUse = randomColor
    ? "#000000".replace(/0/g, () =>
        // eslint-disable-next-line no-bitwise
        (~~(Math.random() * 16)).toString(16)
      )
    : color;

  const { containerProps: containerProps1, indicatorEl: indicatorEl1 } =
    useLoading({
      loading: true,
      indicator: <LoadingGrid color={colorToUse} height={height} />
    });

  return loading ? (
    <section {...containerProps1} style={{}}>
      {indicatorEl1}
    </section>
  ) : null;
}
