import {
  Cart20Px,
  ManualPick20Px,
  Print20Px,
  Requirement20Px
} from "@locaisolutions/icons";
import { Button, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";

import { canBatchBePicked } from "~/lib/fcConfigHelpers";
import { canPrepBatchOption } from "~/lib/helpers";
import { downloadBlob } from "~/lib/shared";
import { getBatchLabels } from "~/redux/actions/labels";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { resetSelectedBatch } from "./pickBatches.slice";

export function PickBatchesToolbar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const {
    cartPrepEnabled,
    bat_printEnabled,
    bat_pickFromBatchesEnabled,
    bat_stageFromBatchesEnabled
  } = useAppSelector(selectClientConfig);

  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const selectedBatch = useAppSelector(
    (state) => state.pickBatches.selectedBatch
  );

  const canPickBatch =
    fulfillmentCenter &&
    selectedBatch &&
    canBatchBePicked(fulfillmentCenter, selectedBatch) &&
    ["scheduled", "cart ready", "processing"].includes(
      (selectedBatch && selectedBatch.status.toLowerCase()) || ""
    );

  const canStageBatch = ["picked", "dropped"].includes(
    (selectedBatch && selectedBatch.status.toLowerCase()) || ""
  );

  const canPrepBatch = canPrepBatchOption(selectedBatch);

  const [printMenuAnchorEl, setPrintMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handlePrintBatchSheetClick = (): void => {
    navigate("/batch-pick-sheet");
  };

  const handlePrintBatchLabels = async (): Promise<void> => {
    if (selectedBatch) {
      const labelData = await dispatch(getBatchLabels(selectedBatch.batchId));

      if (labelData) {
        downloadBlob(labelData, `${selectedBatch.batchName}.labels.zpl`);
      }
    }
  };

  const handleUnselectBatch = useCallback(() => {
    dispatch(resetSelectedBatch());
  }, [dispatch]);

  return (
    <Toolbar>
      <UnselectButton onClick={handleUnselectBatch} />
      <Menu
        id="simple-menu"
        anchorEl={printMenuAnchorEl}
        keepMounted
        open={!!printMenuAnchorEl}
        onClose={() => setPrintMenuAnchorEl(null)}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {selectedBatch && (
          <MenuItem
            onClick={() => {
              setPrintMenuAnchorEl(null);
              handlePrintBatchSheetClick();
            }}
          >
            {t("batch pick sheets")}
          </MenuItem>
        )}
        {selectedBatch && selectedBatch.batchType.toUpperCase() !== "STORE" && (
          <MenuItem
            onClick={() => {
              setPrintMenuAnchorEl(null);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              handlePrintBatchLabels();
            }}
          >
            {t("batch labels")}
          </MenuItem>
        )}
      </Menu>
      <NavBarButtonGroup>
        {selectedBatch && bat_printEnabled && (
          <Button
            color="secondary"
            onClick={(e): void => setPrintMenuAnchorEl(e.currentTarget)}
            startIcon={<Print20Px fill="#fff" />}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {t("print")}
            </Typography>
          </Button>
        )}

        {selectedBatch &&
          selectedBatch.batchType !== "Bulk" &&
          cartPrepEnabled &&
          canPrepBatch && (
            <Button
              color="secondary"
              onClick={(): void =>
                navigate(`/batches/${selectedBatch.batchName}/cart-prep`)
              }
              startIcon={<Cart20Px fill="#fff" />}
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {t("prep")}
              </Typography>
            </Button>
          )}
        {bat_pickFromBatchesEnabled &&
          selectedBatch &&
          selectedBatch.batchType !== "Autostore" &&
          canPickBatch && (
            <Button
              color="secondary"
              onClick={(): void =>
                navigate(`/batches/${selectedBatch.batchName}/pick`)
              }
              startIcon={<ManualPick20Px fill="#fff" />}
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {t("pick verb")}
              </Typography>
            </Button>
          )}
        {bat_stageFromBatchesEnabled && selectedBatch && canStageBatch && (
          <Button
            color="secondary"
            onClick={(): void =>
              navigate(`/batches/${selectedBatch.batchName}/stage`)
            }
            startIcon={<Requirement20Px fill="#fff" />}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {t("stage_verb")}
            </Typography>
          </Button>
        )}
      </NavBarButtonGroup>
    </Toolbar>
  );
}
