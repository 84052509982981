import { createSelector } from "@reduxjs/toolkit";

import { getSelectedToteIds } from "~/features/stage/stageTotes.slice";

import {
  getToteOrLoosePickId,
  getType,
  ToteOrLoosePick,
  ToteOrLoosePickType
} from "~/lib/toteOrLoosePick";

import { LoosePickSummary } from "~/redux/warehouse/loosePicks.openApi";
import { ToteSummaryDto } from "~/types/api";

export type SelectedToteOrLoosePick = {
  toteOrLoosePickId: Guid;
  orderId: Guid;
  putWallId?: string | null;
  putWallLaneId?: string | null;
  putWallIdentifier: string;
  putWallLaneIdentifier: string;
  type: ToteOrLoosePickType;
};

export const emptyToteArray: ToteSummaryDto[] = [];
export const emptyLoosePickArray: LoosePickSummary[] = [];

export const selectedTotesAndLoosePicks = createSelector(
  getSelectedToteIds,
  (_, totesAndLoosePicks: ToteOrLoosePick[]) => totesAndLoosePicks,
  (selectedToteIds: string[], totesAndLoosePicks: ToteOrLoosePick[]) => {
    return totesAndLoosePicks.reduce<SelectedToteOrLoosePick[]>(
      (acc, toteOrLoosePick) => {
        if (selectedToteIds.includes(getToteOrLoosePickId(toteOrLoosePick))) {
          acc.push({
            toteOrLoosePickId: getToteOrLoosePickId(toteOrLoosePick),
            orderId: toteOrLoosePick.orderId,
            putWallId: toteOrLoosePick.putWallId,
            putWallLaneId: toteOrLoosePick.putWallLaneId,
            putWallIdentifier: toteOrLoosePick.putWallIdentifier,
            putWallLaneIdentifier: toteOrLoosePick.putWallLaneIdentifier,
            type: getType(toteOrLoosePick)
          });
        }
        return acc;
      },
      []
    );
  }
);

export const singleSelectedToteOrLoosePick = createSelector(
  getSelectedToteIds,
  (_, totesAndLoosePicks: ToteOrLoosePick[]) => totesAndLoosePicks,
  (selectedToteIds: string[], totesAndLoosePicks: ToteOrLoosePick[]) => {
    const singleSelectedToteId =
      selectedToteIds.length === 1 && selectedToteIds[0];
    return totesAndLoosePicks.find(
      (tote) => getToteOrLoosePickId(tote) === singleSelectedToteId
    );
  }
);
