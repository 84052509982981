import { warehouseApi as api } from "./warehouseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLoosePicks: build.query<GetLoosePicksApiResponse, GetLoosePicksApiArg>({
      query: (queryArg) => ({
        url: `/loose-picks`,
        params: {
          orderId: queryArg.orderId,
          batchId: queryArg.batchId
        }
      })
    }),
    loadLoosePicks: build.mutation<
      LoadLoosePicksApiResponse,
      LoadLoosePicksApiArg
    >({
      query: (queryArg) => ({
        url: `/loose-picks/load`,
        method: "POST",
        body: queryArg.body
      })
    }),
    stageLoosePicks: build.mutation<
      StageLoosePicksApiResponse,
      StageLoosePicksApiArg
    >({
      query: (queryArg) => ({
        url: `/loose-picks/stage`,
        method: "POST",
        body: queryArg.stageLoosePicks
      })
    }),
    verifyLoosePicks: build.mutation<
      VerifyLoosePicksApiResponse,
      VerifyLoosePicksApiArg
    >({
      query: (queryArg) => ({
        url: `/loose-picks/verify`,
        method: "POST",
        body: queryArg.body
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as loosePicksApi };
export type GetLoosePicksApiResponse = /** status 200 OK */ LoosePickSummary[];
export type GetLoosePicksApiArg = {
  /** Apply an order query to loose picks */
  orderId?: string;
  /** Apply a batch filter to loose picks */
  batchId?: string;
};
export type LoadLoosePicksApiResponse = unknown;
export type LoadLoosePicksApiArg = {
  /** loose picks to load */
  body: string[];
};
export type StageLoosePicksApiResponse = unknown;
export type StageLoosePicksApiArg = {
  /** loose picks to stage */
  stageLoosePicks: StageLoosePicks;
};
export type VerifyLoosePicksApiResponse = unknown;
export type VerifyLoosePicksApiArg = {
  /** loose picks to verify */
  body: string[];
};
export type Quantity = {
  value: number;
  units: "cd" | "pk" | "cs" | "ea" | "lb";
};
export type LoosePickSummary = {
  /** The name of the customer the loose pick is for */
  customerName: string;
  /** The external order id used by the customer */
  externalOrderId: string;
  /** The timestamp when a Loose Pick was loaded */
  loadedTimestamp?: string | null;
  /** The loose pick id */
  loosePickId: string;
  /** The number of the Loose Pick within the total number of totes for an order */
  loosePickNumber: number;
  /** The id of the order that the Loose Pick belongs to */
  orderId: string;
  /** The specific pick this Loose Pick is related to */
  pickId: string;
  /** The pick name */
  pickName: string;
  /** The quantity that has been picked */
  pickQuantity: Quantity;
  /** The timestamp when a Loose Pick was picked */
  pickedTimestamp?: string | null;
  pickingEndTime: string;
  /** The ID of the put wall */
  putWallId?: string | null;
  /** Identifier for the put wall */
  putWallIdentifier: string;
  /** The ID of the put wall lane */
  putWallLaneId?: string | null;
  /** Identifier for the put wall lane */
  putWallLaneIdentifier: string;
  /** The timestamp when a Loose Pick was staged */
  stagedTimestamp?: string | null;
  /** The ID of a staging area when a Loose Pick is in one (Staged, Verified) */
  stagingAreaId?: string | null;
  /** Loose Pick status. One of Assigned, Picked, Staged, Verified, or Loaded */
  status: string;
  /** The temperature zone of the Loose Pick */
  temperatureZone: string;
  /** The timestamp when a Loose Pick was verified */
  verifiedTimestamp?: string | null;
};
export type StageLoosePicks = {
  loosePickIds: string[];
  putWallId: string;
  putWallLaneId: string;
};
export const {
  useGetLoosePicksQuery,
  useLoadLoosePicksMutation,
  useStageLoosePicksMutation,
  useVerifyLoosePicksMutation
} = injectedRtkApi;
