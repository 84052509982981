import { Box } from "@mui/material";

import { ModalWrapper } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import WorkstationSelector from "./WorkstationSelector";

type WorkstationSelectorProps = {
  isOpen: boolean;
  workstationSelectorCb: (selectedWorkstations: string[]) => void;
  setModalOpen: (isOpen: boolean) => void;
  selectedWorkstationIds: string[];
  footer?: React.ReactNode;
};

function WorkstationSelectorModal(props: WorkstationSelectorProps) {
  const { t } = useTranslation();

  const {
    workstationSelectorCb,
    setModalOpen,
    isOpen,
    selectedWorkstationIds,
    footer
  } = props;

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t("select workstations")}
      setModalOpen={(isOpen) => {
        setModalOpen(isOpen);
      }}
    >
      <Box sx={{ width: "100%" }}>
        <WorkstationSelector
          selectedWorkstationIds={selectedWorkstationIds}
          setNewIdsCb={workstationSelectorCb}
        />
      </Box>
      {footer}
    </ModalWrapper>
  );
}

export default WorkstationSelectorModal;
