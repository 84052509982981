import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import {
  hasEventType,
  hasText,
  hasDeviceId,
  hasTote,
  isAutostoreEventWithBinId,
  isAutostoreEventWithSourceBinId,
  isAutostoreEvent
} from "~/lib/andonHelpers";
import { getTimeFromDatetime, mapEventColorAndLetters } from "~/lib/helpers";

import { AndonEvent } from "./andon.slice";

type EventInfoBoxProps = {
  andonEvent: AndonEvent;
  onClickCb?: () => void;
};

export function EventInfoBox(props: EventInfoBoxProps) {
  const { andonEvent, onClickCb } = props;

  const { t } = useTranslation();

  const { status, eventData, hub, timestamp } = andonEvent;

  let eventTextColor = "black";
  let eventTypeText = "";
  let eventSupplementalText = "";
  let binId: string | null = null;

  if (isAutostoreEventWithBinId(eventData)) {
    switch (eventData.case) {
      case "BinFlagged":
        binId = `${eventData.event.binGuid || ""}`;
        break;
      default:
        binId = `${eventData.event.binId || ""}`;
        break;
    }
  }

  if (isAutostoreEventWithSourceBinId(eventData)) {
    binId = `${eventData.event.sourceBinId || " "}`;
  }

  if (hasEventType(eventData)) {
    switch (eventData.eventType) {
      case "Completed":
        eventTypeText = t("completed");
        break;
      case "Picked":
        eventTypeText = t("picked");
        break;
      case "pick":
        eventTypeText = t("nav.link.pick");
        break;
      case "Selected":
        eventTypeText = t("selected");
        break;
      case "Position Confirmed":
        eventTypeText = t("position confirmed");
        if (hasTote(eventData)) {
          eventSupplementalText = `pos: ${eventData.tote.totePosition || ""}`;
        }
        break;
      case "LabelPlaced":
        eventTypeText = t("label placed");
        break;
      case "StateChange":
        eventTypeText = t("state change");
        if (hasDeviceId(eventData) && hasText(eventData)) {
          eventSupplementalText = `id: ${eventData.deviceId} - ${
            eventData.data.text || ""
          }`;
        }
        break;
      case "PickingStarted":
        eventTypeText = t("picking started");
        break;
      default:
        eventTypeText = eventData.eventType;
        break;
    }
  }
  if (isAutostoreEvent(eventData)) {
    switch (eventData.case) {
      case "PTLMessageActivated":
        eventTypeText = t("ptl activated");
        break;

      case "PTLMessageRegistered":
        eventTypeText = t("ptl registered");
        break;

      case "BinModeChange":
        switch (eventData.event.binMode) {
          case "P":
            eventTypeText = t("bin in position");
            break;
          case "G":
            eventTypeText = t("bin to grid");
            break;
          case "C":
            eventTypeText = t("bin closed");
            break;
          case "O":
            eventTypeText = t("bin opened");
            break;
        }
        break;
      case "BinRequested":
        eventTypeText = t("bin requested");
        break;
      case "PortError":
        eventTypeText = t("port error");
        break;
      case "PortMode":
        if (eventData.event.portMode === "Open") {
          eventTextColor = "black";
        } else if (eventData.event.portMode === "Closed") {
          eventTextColor = "white";
        } else if (eventData.event.portMode === "MultiPortPuppet") {
          eventTextColor = "black";
        }

        eventSupplementalText = eventData.event.portMode;
        eventTypeText = t("port mode");
        break;
      default:
        eventTypeText = eventData.case;
        break;
    }
  }
  const dateObject = getTimeFromDatetime(timestamp);

  return (
    <Button
      sx={{
        backgroundColor: mapEventColorAndLetters(andonEvent).color,
        height: "100px",
        width: "30%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginTop: "10px",
        flex: "0 0 auto",
        flexDirection: "column",
        color: eventTextColor
      }}
      onClick={onClickCb}
    >
      <Typography>{hub}</Typography>
      <Typography>{`${dateObject.hours}:${dateObject.minutes}:${dateObject.seconds}.${dateObject.milliseconds}`}</Typography>
      <Typography>{`${eventTypeText}`}</Typography>
      <Typography>{`${eventSupplementalText || " "}`}</Typography>
      <Typography>{`${binId || " "}`}</Typography>
      <Typography>{`${status || " "}`}</Typography>
    </Button>
  );
}

export default EventInfoBox;
