import { Box, Collapse, styled } from "@mui/material";

import { PropsWithChildren } from "react";

import { ASTableV2Row } from "./ASTableV2Row";

const StyledRow = styled(ASTableV2Row)<{
  selected?: boolean;
}>(({ theme, selected }) => ({
  display: "contents",
  "& > td:first-of-type": {
    borderTopLeftRadius: theme.spacing(0),
    borderBottomLeftRadius: theme.spacing(1)
  },
  "& > td:last-of-type": {
    borderTopRightRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(1)
  },
  "& > td": {
    marginTop: theme.spacing(-1),
    borderTop: "none",
    borderColor: selected ? "#2260D3" : "transparent",
    padding: 0
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`
}));

export const ASTableV2RowCollapse = ({
  expanded,
  selected,
  children
}: { expanded?: boolean; selected?: boolean } & PropsWithChildren) => {
  return (
    <StyledRow selected={selected}>
      <Collapse
        component="td"
        in={expanded}
        unmountOnExit
        sx={{
          gridColumn: "1 / -1"
        }}
      >
        <StyledBox>{children}</StyledBox>
      </Collapse>
    </StyledRow>
  );
};
