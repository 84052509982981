import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

type DevSettingsState = {
  enableDevInformation: boolean;
};

export const initialState: DevSettingsState = {
  enableDevInformation: false
};

export const devSettingsSlice = createSlice({
  name: "devSettings",
  initialState,
  reducers: {
    toggleDevInfomation(state, { payload }: PayloadAction<boolean>) {
      state.enableDevInformation = payload;
    }
  },
  selectors: {
    selectEnableDevInformation: (state: DevSettingsState) =>
      state.enableDevInformation
  }
});

export const { selectEnableDevInformation } = devSettingsSlice.selectors;
export const { toggleDevInfomation } = devSettingsSlice.actions;

const devSettingsPersistConfig = {
  key: "devSettings",
  storage,
  whitelist: ["enableDevInformation"]
};
export const devSettingsReducer = persistReducer(
  devSettingsPersistConfig,
  devSettingsSlice.reducer
);
