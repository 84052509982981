import { createSvgIcon } from "@mui/material";

export const GridDesignIcon = createSvgIcon(
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 1H2.5H11.5H19.5H20.5V2V6.11091L20.4445 6.05546L18.5 4.11091V3H12.5V8V10V10.1109L11.6109 11H11.5H9.5H3.5V17H5.84419L5.76682 17.6963L5.62197 19H2.5H1.5V18V10V2V1ZM3.5 9H9.5H10.5V8V3H3.5V9ZM19.2071 7.29289L18.5 6.58579L17.7929 7.29289L7.79289 17.2929L7.54485 17.5409L7.50612 17.8896L7.00612 22.3896L6.86808 23.6319L8.11043 23.4939L12.6104 22.9939L12.9591 22.9551L13.2071 22.7071L23.2071 12.7071L23.9142 12L23.2071 11.2929L19.2071 7.29289ZM9.13192 21.3681L9.45515 18.4591L18.5 9.41421L21.0858 12L12.0409 21.0449L9.13192 21.3681Z"
      fill="currentcolor"
    />
  </svg>,
  "GridDesign"
);
