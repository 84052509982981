import CheckIcon from "@mui/icons-material/Check";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  Box,
  Card,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  mobileWidth,
  ProgressButton,
  RoundIconButton,
  useToast
} from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectPutawayReasonCodeOptions } from "~/redux/selectors/inventorySelectors";

import { usePatchInventoryNetAdjustmentMutation } from "~/redux/warehouse/inventory.hooks";
import { PutAwayTaskSummaryDto } from "~/types/api";

type PutawayAdjustModalProps = {
  putawayTask: PutAwayTaskSummaryDto;
  closeModal: () => void;
  successCallback: () => void;
};

export const PutawayAdjustModal = (props: PutawayAdjustModalProps) => {
  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);

  const workstationId = useAppSelector(
    (state) => state.workstations.siteWorkstation?.id
  );
  const putawayReasonCodeOptions = useAppSelector(
    selectPutawayReasonCodeOptions
  );

  const { putawayTask, closeModal } = props;

  const [adjustmentQuantity, setAdjustmentQuantity] = useState<number>(0);
  const [reasonCode, updateReasonCode] = useState<string | null>(null);
  const [adjustInventoryNet] = usePatchInventoryNetAdjustmentMutation();

  // user should not be abe to remove more than the remaining qty
  const downDisabled = adjustmentQuantity === putawayTask.remaining.value * -1;

  let confirmText = `${
    adjustmentQuantity > 0 ? t("add") : t("remove")
  } ${Math.abs(adjustmentQuantity)}`;

  // require reason code and adjustment value to be >= to remaining value
  let confirmDisabled =
    !reasonCode || adjustmentQuantity < putawayTask.remaining.value * -1;

  if (adjustmentQuantity === 0) {
    confirmText = "Confirm";
    confirmDisabled = true;
  }

  const handleReasonCodeChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event?.target?.value === "string") {
      updateReasonCode(event.target.value);
    }
  };

  const handleAdjustInventory = async () => {
    const { inventoryId, receivedQuantity, quantity } = putawayTask;

    const qty = receivedQuantity ?? quantity;

    const netAdjustment = { units: qty.units, value: adjustmentQuantity };
    const netCommittedAdjustment = { units: qty.units, value: 0 };

    if (!inventoryId || !reasonCode) return;

    try {
      await adjustInventoryNet({
        inventoryId,
        netAdjustment,
        netCommittedAdjustment,
        reasonCode,
        workstationId
      }).unwrap();

      successToast(t("inventory adjusted"));

      props.successCallback();
      props.closeModal();
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const quantityTextField = (
    <TextField
      id="quantity"
      label={t("adjustment")}
      type="number"
      variant="outlined"
      sx={{
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none"
          },
        width: "100%"
      }}
      value={adjustmentQuantity === null ? 0 : adjustmentQuantity}
      onChange={(e) =>
        setAdjustmentQuantity(e.target.value ? Number(e.target.value) : 0)
      }
      slotProps={{
        inputLabel: {
          shrink: true
        },
        htmlInput: {
          sx: {
            fontSize: 50,
            padding: "35px 10px 35px 18px"
          }
        }
      }}
    />
  );

  const quantityButtons = (
    <Stack
      flexWrap="nowrap"
      gap={2}
      sx={{ position: "absolute", top: 8, right: 8 }}
    >
      <RoundIconButton
        aria-label="increase quantity"
        onClick={(): void => {
          setAdjustmentQuantity(adjustmentQuantity + 1);
        }}
        size="large"
      >
        <ExpandLessIcon
          sx={{
            fontSize: 27
          }}
        />
      </RoundIconButton>
      <RoundIconButton
        aria-label="decrease quantity"
        onClick={(): void => {
          setAdjustmentQuantity(adjustmentQuantity - 1);
        }}
        disabled={downDisabled}
        size="large"
      >
        <ExpandMoreIcon
          sx={{
            fontSize: 27
          }}
        />
      </RoundIconButton>
    </Stack>
  );

  const reasonCodeField = (
    <TextField
      id="reasonCode"
      label={t("reason code")}
      aria-label={t("reason code")}
      type="string"
      select
      fullWidth
      variant="outlined"
      value={reasonCode || ""}
      slotProps={{
        inputLabel: {
          shrink: true
        },
        htmlInput: {
          sx: {
            fontSize: "1rem"
          }
        },
        select: {
          onChange: handleReasonCodeChange
        }
      }}
    >
      {putawayReasonCodeOptions.map((choiceObj, i, arr) => (
        <MenuItem
          key={`uom-option-${choiceObj.value}`}
          className={`uom-option-${i}`}
          sx={
            i === arr.length - 1
              ? { padding: "10px", fontSize: "24px" }
              : {
                  padding: "10px",
                  fontSize: "24px",
                  borderBottom: `1px solid`,
                  borderBottomColor: "gray.light"
                }
          }
          value={choiceObj.value}
        >
          {choiceObj.label}
        </MenuItem>
      ))}
    </TextField>
  );

  const AdjustInventoryButton = (
    <ProgressButton
      id="adjust-button"
      sx={{ backgroundColor: "primary.main", color: "white" }}
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<CheckIcon />}
      onClick={() => handleAdjustInventory()}
      disabled={confirmDisabled}
    >
      {confirmText}
    </ProgressButton>
  );

  const CancelButton = (
    <ProgressButton
      id="cancel-button"
      color="secondary"
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<HighlightOffIcon />}
      onClick={closeModal}
    >
      {t("cancel")}
    </ProgressButton>
  );

  return (
    <Card>
      <DialogTitle>{t("change quantity")}</DialogTitle>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(50px, 0.5fr))",
          alignItems: "center",
          gap: 2
        }}
        padding={3}
      >
        <Typography
          variant="body1"
          textAlign="center"
          gridColumn={isMobile ? "1/-1" : "unset"}
        >
          {t("how many need to be added or removed")}
        </Typography>
        <Box position="relative" gridColumn={isMobile ? "1/-1" : "unset"}>
          {quantityTextField}
          {quantityButtons}
        </Box>
        <Box gridColumn={"1/-1"}>{reasonCodeField}</Box>
        {CancelButton}
        {AdjustInventoryButton}
      </Box>
    </Card>
  );
};
