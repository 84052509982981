import ASButton from "@locaisolutions/button";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Alert, Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

import { ConfirmBinReconfigurationButton } from "./ConfirmBinReconfigurationButton";

export const ReconfigurationPort = ({
  portId,
  portSideIndex
}: {
  portId: number;
  portSideIndex: number;
}) => {
  const [queryParams] = useSearchParams();
  const targetBinConfigurationId = queryParams.get("targetBinConfigurationId");
  const workstation = useAppSelector(selectThisWorkstation);

  const {
    binPortSideIndex,
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    refetch,
    ...portStatus
  } = usePortStatusQuery(portId, portSideIndex);

  const { data: binConfigurations } = useGetBinConfigurationsQuery();

  const targetBinConfiguration = binConfigurations?.find(
    (bc) => bc.configurationId === targetBinConfigurationId
  );

  return (
    <>
      <Box>
        {!!error && portSideIndex === 0 && (
          <Alert
            sx={{
              gridColumn: workstation?.multiPortEnabled ? "span 2" : "unset"
            }}
            variant="outlined"
            severity="error"
            action={<ASButton onClick={refetch}>Retry</ASButton>}
          >
            {getMessageFromRtkError(error)}
          </Alert>
        )}
        {!error && (
          <AutostoreBin
            state={activityState}
            binId={portStatus?.selectedBin}
            pickQuantity={""}
            pickCompartment={null}
            numberOfRows={
              (portSideIndex === binPortSideIndex &&
                horizontalCompartmentCount) ||
              1
            }
            numberOfColumns={
              (portSideIndex === binPortSideIndex &&
                verticalCompartmentCount) ||
              1
            }
            hideBinId={true}
          />
        )}
        {!error && !!portStatus.selectedBin && (
          <Typography variant="h5" textAlign="center" mt={2}>
            Bin {portStatus.selectedBin}
          </Typography>
        )}
      </Box>
      <KeyboardDoubleArrowDownIcon
        sx={{
          fontSize: "3rem",
          placeSelf: "center"
        }}
      />
      <AutostoreBin
        state={"Port Closed"}
        numberOfColumns={targetBinConfiguration?.verticalCompartmentCount || 1}
        numberOfRows={targetBinConfiguration?.horizontalCompartmentCount || 1}
        pickQuantity=""
        pickCompartment={null}
      />
      <ConfirmBinReconfigurationButton
        portId={portId}
        portSideIndex={portSideIndex}
      />
    </>
  );
};
