import { Box, Grid, Typography, Divider } from "@mui/material";
import { ProgressButton } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { fetchExpectedPTLState, fetchPTLState } from "~/redux/actions/ptl";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

export const PTLStatus = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actualWorkstationPTLState = useAppSelector(
    (state) => state.ptl.actualWorkstationPTLState
  );
  const expectedWorkstationPTLState = useAppSelector(
    (state) => state.ptl.expectedWorkstationPTLState
  );
  const { data: workstations = [] } = useGetWorkstationsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  return (
    <Box sx={{ marginLeft: "10px" }}>
      {workstations
        .slice()
        .sort((w1, w2) =>
          `${w1.autostoreGridName}${w1.ports[0].portId}`.localeCompare(
            `${w2.autostoreGridName}${w2.ports[0].portId}`
          )
        )
        .map((workstation) => (
          <Box key={workstation.id} sx={{ marginTop: "10px" }}>
            <Typography
              style={{ marginRight: 20, fontWeight: 600, fontSize: "1em" }}
            >
              {workstation.deviceId} -{" "}
              {expectedWorkstationPTLState[workstation.id]?.statusMessage ?? ""}
            </Typography>
            <ProgressButton
              onClick={async () => {
                await dispatch(fetchPTLState(workstation));
                await dispatch(fetchExpectedPTLState(workstation));
              }}
            >
              {t("fetch ptl state")}
            </ProgressButton>
            <Grid
              style={{
                border: "1px solid ##777777",
                padding: 5,
                borderRadius: 7,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                // boxShadow:
                //   "5px 5px 15px 5px #FF8080, -9px 5px 15px 5px #FFE488, -7px -5px 15px 5px #8CFF85, 12px -5px 15px 5px #80C7FF, 12px 10px 15px 7px #E488FF, -10px 10px 15px 7px #FF616B, -10px -7px 27px 1px #8E5CFF, 5px 5px 15px 5px rgba(0,0,0,0)",
                zIndex: 1000
              }}
            >
              {actualWorkstationPTLState[workstation.id]?.ptlStates
                .map((ptlState) => ({
                  ptlState,
                  expectedPTLState: expectedWorkstationPTLState[
                    workstation.id
                  ]?.ptlStates.get(ptlState.placement.putToLightModuleId)
                }))
                .map(
                  ({ ptlState: { ptlState, placement }, expectedPTLState }) => (
                    <Grid
                      key={`workstation-ptl-state-${workstation.id}-${
                        placement.putToLightModuleId ?? ""
                      }`}
                      style={{
                        margin: 50,
                        display: "flex",
                        flexDirection: "column",
                        flexBasis: 250,
                        alignItems: "stretch",

                        backgroundColor: "white",
                        border: "1px solid ##777777",
                        padding: 5,
                        borderRadius: 7,
                        boxShadow:
                          "5px 5px 15px 5px #FF8080, -9px 5px 15px 5px #FFE488, -7px -5px 15px 5px #8CFF85, 12px -5px 15px 5px #80C7FF, 12px 10px 15px 7px #E488FF, -10px 10px 15px 7px #FF616B, -10px -7px 27px 1px #8E5CFF, 5px 5px 15px 5px rgba(0,0,0,0)"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%"
                        }}
                      >
                        <Typography style={{ width: "50%" }}>
                          Light Module Id:
                        </Typography>
                        <Typography style={{ width: "50%" }}>{`${
                          placement.putToLightModuleId ?? ""
                        }`}</Typography>
                      </Box>
                      <Divider
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          height: 3
                        }}
                      />
                      <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                        Current State
                      </Typography>
                      <Divider
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          height: 3
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%"
                        }}
                      >
                        <Typography style={{ width: "50%" }}>
                          Display Data:
                        </Typography>
                        {ptlState?.originalText && (
                          <Typography style={{ width: "50%" }}>
                            {`${ptlState.originalText}`}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%"
                        }}
                      >
                        <Typography style={{ width: "50%" }}>
                          Pick Id:
                        </Typography>
                        {ptlState?.pickId && (
                          <Typography style={{ width: "50%" }}>
                            {`${ptlState.pickId}`}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%"
                        }}
                      >
                        <Typography style={{ width: "50%" }}>
                          Tote Id:
                        </Typography>
                        {ptlState?.toteId && (
                          <Typography style={{ width: "50%" }}>
                            {`${ptlState.toteId}`}
                          </Typography>
                        )}
                      </Box>
                      {expectedPTLState && (
                        <>
                          <Divider
                            style={{
                              marginBottom: 5,
                              marginTop: 5,
                              height: 3
                            }}
                          />
                          <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                            Expected State
                          </Typography>
                          <Divider
                            style={{
                              marginBottom: 5,
                              marginTop: 5,
                              height: 3
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%"
                            }}
                          >
                            <Typography style={{ width: "50%" }}>
                              Display Data:
                            </Typography>
                            {expectedPTLState.displayData && (
                              <Typography style={{ width: "50%" }}>
                                {`${expectedPTLState.displayData}`}
                              </Typography>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%"
                            }}
                          >
                            <Typography style={{ width: "50%" }}>
                              Pick Id:
                            </Typography>
                            {expectedPTLState.pickId && (
                              <Typography style={{ width: "50%" }}>
                                {`${expectedPTLState.pickId}`}
                              </Typography>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%"
                            }}
                          >
                            <Typography style={{ width: "50%" }}>
                              Tote Id:
                            </Typography>
                            {expectedPTLState.toteId && (
                              <Typography style={{ width: "50%" }}>
                                {`${expectedPTLState.toteId}`}
                              </Typography>
                            )}
                          </Box>
                        </>
                      )}
                    </Grid>
                  )
                )}
            </Grid>
          </Box>
        ))}
    </Box>
  );
};
