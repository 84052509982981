import { Toolbar, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";

import { selectRow, setChangeQuantity } from "./autostorePutawayOld.slice";

export function AutostorePutawayOldToolbar() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Toolbar>
      <UnselectButton onClick={() => dispatch(selectRow(null))} />
      <NavBarButtonGroup>
        <Button
          color="secondary"
          id="change-qunatity"
          onClick={() => dispatch(setChangeQuantity(true))}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("change quantity")}
          </Typography>
        </Button>
      </NavBarButtonGroup>
    </Toolbar>
  );
}
