import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Typography } from "@mui/material";

import { ProgressButton } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

export function AddWorkstationButton({
  addWorkstation,
  isCreating
}: {
  addWorkstation: () => Promise<void>;
  isCreating: boolean;
}) {
  const { t } = useTranslation();

  return (
    <ProgressButton
      id="add-workstation-button"
      emphasis="high"
      responsive
      color="primary"
      style={{
        width: 400,
        marginTop: 20
      }}
      onClick={async () => {
        await addWorkstation();
        return null;
      }}
      disabled={isCreating}
    >
      <AddCircleIcon />
      <Typography sx={{ ml: 2 }}>{t("add workstation")}</Typography>
    </ProgressButton>
  );
}
