import { Skeleton } from "@mui/material";

import { ASTableV2Cell, ASTableV2Row } from "./index";

export const ASTableV2RowSkeleton = ({
  rows,
  columns
}: {
  rows: number;
  columns: number;
}) => {
  const rowsArray = Array.from({ length: rows }, (_, i) => i);
  const columnsArray = Array.from({ length: columns }, (_, i) => i);
  return rowsArray.map((i) => {
    return (
      <ASTableV2Row key={`loadingRow-${i}`} role="progressbar">
        {columnsArray.map((j) => {
          return (
            <ASTableV2Cell key={`loadingCell-${j}`}>
              <Skeleton variant="text" width="100%" />
            </ASTableV2Cell>
          );
        })}
      </ASTableV2Row>
    );
  });
};
