import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { GetNextBinSuccessAction } from "~/redux/actions";
import {
  GetInventoryReportAction,
  GetInventoryReportFailureAction,
  GetInventoryReportSuccessAction,
  AdjustInventoryAction,
  AdjustInventoryFailureAction,
  AdjustInventorySuccessAction,
  GetVariantByVariantIdAction,
  GetVariantByVariantIdSuccessAction,
  GetVariantByVariantIdFailureAction,
  ClearSelectedVariantAction,
  FindInventoryByAutostoreBinNumberAction,
  FindInventoryByAutostoreBinNumberSuccessAction,
  FindInventoryToModifyByVariantAction,
  FindInventoryToModifyByVariantSuccessAction,
  FindInventoryToModifyByVariantFailureAction,
  FindInventoryByAutostoreBinNumberFailureAction,
  ClearInventoryAction,
  SetSelectedInventoryIdAction,
  PutAwayAction,
  PutAwayFailureAction,
  ClearSelectedInventoryIdAction,
  MoveInventoryAction,
  MoveInventorySuccessAction,
  MoveInventoryFailureAction,
  GetSlottingInfoSuccessAction,
  GetSlottingInfoFailureAction,
  GetSlottingInfoAction,
  UpdateSlottingInfoFailureAction,
  CreateBinSlotFailureAction,
  GetBinByIdAction,
  GetBinByIdSuccessAction,
  GetBinByIdFailureAction,
  GetSlotBinByIdAction,
  GetSlotBinByIdSuccessAction,
  GetSlotBinByIdFailureAction,
  GetInventorySummariesAction,
  GetInventorySummariesSuccessAction,
  GetInventorySummariesFailureAction,
  ClearSlotBin,
  DeleteInventoryHoldFailureAction,
  DeleteInventoryHoldSuccessAction,
  GetLowInventoryAction,
  GetLowInventorySuccessAction,
  GetLowInventoryFailureAction,
  FindPutawaysByVariantAction,
  FindPutawaysByVariantFailureAction,
  FindPutawaysByVariantSuccessAction,
  PlaceInventoryHoldAction,
  PlaceInventoryHoldSuccessAction,
  PlaceInventoryHoldFailureAction,
  ClearInventoryByVariantsAction
} from "~/redux/actions/inventory";
import {
  BinDto,
  BinRecord,
  InventoryDto,
  InventoryReport,
  InventorySummaryDto,
  SlotDto,
  VariantFrontendDto,
  LowInventoryAndVariantRecord,
  VariantPutawayTaskIdMapping
} from "~/types/api";

export type InventoryState = {
  loadingBin: boolean;
  loadingSummary: boolean;
  loadingInventory: boolean;
  loadingInventoryReport: boolean;
  loadingSlottingInfo: boolean;
  placingHold: boolean;
  inventoryReport: InventoryReport[] | null;
  inventorySummaries: InventorySummaryDto[];
  loadingInventorySummaries: boolean;
  inventorySummariesCount: number | null;
  inventory: InventoryDto[];
  selectedInventoryId: string | null;
  selectedVariant: VariantFrontendDto | null;
  binSearchRecords: BinRecord[];
  slottingInfo: SlotDto[];
  slotBin: BinDto | null;
  bin: BinDto | null;
  lowInventory: LowInventoryAndVariantRecord[] | null;
  lowInventoryLoading: boolean;
  lowInventoryRecordCount: number | null;
  inventoryAndPutawayTask: VariantPutawayTaskIdMapping[];
  inventoryAndPutawayLoading: boolean;
  inventoryToModify: InventorySummaryDto | null;
  loadingInvToModify: boolean;
};

const initialState: InventoryState = {
  loadingBin: false,
  loadingSummary: true,
  loadingInventory: true,
  loadingSlottingInfo: false,
  loadingInventoryReport: true,
  placingHold: false,
  inventoryReport: [],
  inventory: [],
  selectedInventoryId: null,
  inventorySummaries: [],
  loadingInventorySummaries: false,
  inventorySummariesCount: null,
  selectedVariant: null,
  binSearchRecords: [],
  slottingInfo: [],
  slotBin: null,
  bin: null,
  lowInventory: null,
  lowInventoryLoading: false,
  lowInventoryRecordCount: null,
  inventoryAndPutawayTask: [],
  inventoryAndPutawayLoading: false,
  inventoryToModify: null,
  loadingInvToModify: false
};

type InventoryActions =
  | GetInventoryReportAction
  | GetInventoryReportFailureAction
  | GetInventoryReportSuccessAction
  | AdjustInventoryAction
  | AdjustInventoryFailureAction
  | AdjustInventorySuccessAction
  | GetVariantByVariantIdAction
  | GetVariantByVariantIdSuccessAction
  | GetVariantByVariantIdFailureAction
  | ClearSelectedVariantAction
  | FindInventoryToModifyByVariantAction
  | FindInventoryToModifyByVariantSuccessAction
  | FindInventoryToModifyByVariantFailureAction
  | ClearInventoryByVariantsAction
  | FindInventoryByAutostoreBinNumberAction
  | FindInventoryByAutostoreBinNumberSuccessAction
  | FindInventoryByAutostoreBinNumberFailureAction
  | ClearInventoryAction
  | SetSelectedInventoryIdAction
  | PutAwayAction
  | PutAwayFailureAction
  | ClearSelectedInventoryIdAction
  | MoveInventoryAction
  | MoveInventorySuccessAction
  | MoveInventoryFailureAction
  | GetSlottingInfoAction
  | GetSlottingInfoSuccessAction
  | GetSlottingInfoFailureAction
  | UpdateSlottingInfoFailureAction
  | CreateBinSlotFailureAction
  | GetBinByIdAction
  | GetBinByIdSuccessAction
  | GetBinByIdFailureAction
  | GetSlotBinByIdAction
  | GetSlotBinByIdSuccessAction
  | GetSlotBinByIdFailureAction
  | GetInventorySummariesAction
  | GetInventorySummariesSuccessAction
  | GetInventorySummariesFailureAction
  | ClearSlotBin
  | DeleteInventoryHoldFailureAction
  | DeleteInventoryHoldSuccessAction
  | GetNextBinSuccessAction
  | GetLowInventoryAction
  | GetLowInventorySuccessAction
  | GetLowInventoryFailureAction
  | FindPutawaysByVariantAction
  | FindPutawaysByVariantFailureAction
  | FindPutawaysByVariantSuccessAction
  | PlaceInventoryHoldAction
  | PlaceInventoryHoldSuccessAction
  | PlaceInventoryHoldFailureAction;

const reducer = (
  state: InventoryState | undefined,
  action: InventoryActions
): InventoryState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "inventory/GET_INVENTORY_REPORT":
      return {
        ...state,
        loadingInventoryReport: true,
        inventoryReport: null
      };
    case "inventory/GET_INVENTORY_REPORT_SUCCESS":
      return {
        ...state,
        loadingInventoryReport: false,
        inventoryReport: action.payload
      };
    case "inventory/ADJUST_INVENTORY":
      return {
        ...state
      };
    case "inventory/ADJUST_INVENTORY_SUCCESS":
      return {
        ...state
      };
    case "inventory/MOVE_INVENTORY_SUCCESS":
      return {
        ...state
      };
    case "inventory/GET_INVENTORY_TO_MODIFY_BY_VID":
      return {
        ...state,
        loadingInvToModify: true
      };
    case "inventory/GET_INVENTORY_TO_MODIFY_BY_VID_SUCCESS":
      return {
        ...state,
        loadingInvToModify: false,
        inventoryToModify: action.payload
      };
    case "inventory/CLEAR_INVENTORY_TO_MODIFY_BY_VID":
      return {
        ...state,
        loadingInvToModify: false,
        inventoryToModify: null
      };
    case "inventory/GET_VARIANT_BY_VARIANT_ID":
      return {
        ...state
      };
    case "inventory/GET_VARIANT_BY_VARIANT_ID_SUCCESS":
      return {
        ...state,
        selectedVariant: action.payload
      };
    case "inventory/CLEAR_SELECTED_VARIANT":
      return {
        ...state,
        selectedVariant: null
      };
    case "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER":
    case "inventory/GET_INVENTORY_SUMMARIES":
      return {
        ...state,
        loadingInventory: true,
        loadingInventorySummaries: true
      };
    case "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER_SUCCESS":
      return {
        ...state,
        inventory: action.payload,
        loadingInventory: false,
        loadingInventorySummaries: false
      };
    case "inventory/GET_INVENTORY_SUMMARIES_SUCCESS":
      return {
        ...state,
        inventorySummaries: action.payload.inventory,
        inventorySummariesCount: action.payload.totalCount,
        loadingInventory: false,
        loadingInventorySummaries: false
      };
    case "inventory/CLEAR_INVENTORY":
      return {
        ...state,
        inventory: [],
        inventorySummaries: [],
        inventorySummariesCount: null
      };
    case "inventory/GET_INVENTORY_TO_MODIFY_BY_VID_FAILURE":
      return {
        ...state,
        inventoryToModify: null,
        loadingInvToModify: false
      };
    case "inventory/ADJUST_INVENTORY_FAILURE":
    case "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER_FAILURE":
    case "inventory/GET_INVENTORY_REPORT_FAILURE":
    case "inventory/PUT_AWAY_FAILURE":
    case "inventory/MOVE_INVENTORY_FAILURE":
    case "inventory/GET_SLOTTING_INFO_FAILURE":
    case "inventory/GET_BIN_BY_ID_FAILURE":
    case "inventory/GET_SLOT_BIN_BY_ID_FAILURE":
    case "inventory/UPDATE_SLOTTING_INFO_FAILURE":
    case "inventory/CREATE_BIN_SLOT_FAILURE":
    case "inventory/GET_INVENTORY_SUMMARIES_FAILURE":
    case "inventory/DELETE_INVENTORY_HOLD_FAILURE":
    case "inventory/GET_LOW_INVENTORY_FAILURE":
    case "inventory/FIND_PUTAWAYS_BY_VARIANT_FAILURE":
    case "inventory/PLACE_INVENTORY_HOLD_FAILURE":
      return {
        ...state,
        loadingBin: false,
        loadingSummary: false,
        loadingInventory: false,
        loadingInventorySummaries: false,
        loadingInventoryReport: false,
        loadingSlottingInfo: false,
        lowInventoryLoading: false,
        placingHold: false,
        inventoryAndPutawayLoading: false,
        lowInventoryRecordCount: null
      };
    case "inventory/GET_VARIANT_BY_VARIANT_ID_FAILURE":
      return {
        ...state,
        selectedVariant: null
      };
    case "inventory/DELETE_INVENTORY_HOLD_SUCCESS":
      return {
        ...state,
        inventory: state.inventory.length
          ? [
              {
                ...state.inventory[0],
                holds: []
              }
            ]
          : []
      };
    case "inventory/SET_SELECTED_INVENTORY_ID":
      return {
        ...state,
        selectedInventoryId: action.payload
      };
    case "inventory/CLEAR_SELECTED_INVENTORY_ID":
      return {
        ...state,
        selectedInventoryId: null
      };
    case "inventory/GET_SLOTTING_INFO":
      return {
        ...state,
        bin: null,
        slottingInfo: [],
        loadingSlottingInfo: true
      };
    case "inventory/GET_SLOTTING_INFO_SUCCESS":
      return {
        ...state,
        slottingInfo: action.payload,
        loadingSlottingInfo: false
      };
    case "inventory/GET_BIN_BY_ID":
      return {
        ...state,
        loadingBin: true,
        bin: null
      };
    case "inventory/GET_BIN_BY_ID_SUCCESS":
      return {
        ...state,
        loadingBin: false,
        bin: action.payload
      };
    case "inventory/GET_SLOT_BIN_BY_ID":
      return {
        ...state,
        loadingBin: true,
        slotBin: null
      };
    case "inventory/GET_SLOT_BIN_BY_ID_SUCCESS":
      return {
        ...state,
        loadingBin: false,
        slotBin: action.payload
      };
    case "autostore/GET_NEXT_BIN_SUCCESS":
      return {
        ...state,
        inventory: action.payload.nextBinInventory
      };
    case "inventory/CLEAR_SLOT_BIN":
      return {
        ...state,
        slotBin: null
      };
    case "inventory/GET_LOW_INVENTORY":
      return {
        ...state,
        lowInventoryLoading: true
      };
    case "inventory/GET_LOW_INVENTORY_SUCCESS":
      return {
        ...state,
        lowInventoryLoading: false,
        lowInventory: action.payload.lowInventorySummaries,
        lowInventoryRecordCount: action.payload.totalRecordCount
      };

    case "inventory/FIND_PUTAWAYS_BY_VARIANT":
      return {
        ...state,
        inventoryAndPutawayLoading: true
      };
    case "inventory/FIND_PUTAWAYS_BY_VARIANT_SUCCESS":
      return {
        ...state,
        inventoryAndPutawayLoading: false,
        inventoryAndPutawayTask: action.payload
      };
    case "inventory/PLACE_INVENTORY_HOLD":
      return {
        ...state,
        placingHold: true
      };
    case "inventory/PLACE_INVENTORY_HOLD_SUCCESS":
      return {
        ...state,
        placingHold: false
      };

    default:
      return state;
  }
};

const inventoryPersistConfig = {
  key: "inventory",
  storage,
  whitelist: []
};

export const inventory = persistReducer(inventoryPersistConfig, reducer);
