import { StagingAreaDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const stagingAreaApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getStagingAreas: build.query<StagingAreaDto[], void>({
      query: () => ({
        url: `/staging-areas`,
        method: "GET"
      }),
      providesTags: ["staging area"]
    })
  }),
  overrideExisting: false
});
