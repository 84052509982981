import {
  AutostorePickingState,
  PickingStateV2FocusedPick,
  PickingStateV2PortState,
  PickingStateV2TotesInPosition,
  RepickProductsRequest
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

const autostorePickingApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getPickingStateV1: build.query<
      AutostorePickingState,
      { gridId: string; portId: number; taskId?: number }
    >({
      query: (data) => {
        const { gridId, portId, taskId } = data;

        const taskQuery = taskId ? `/task/${taskId}` : "";

        return {
          url: `/autostore-grid/${gridId}/port/${portId}${taskQuery}/picking-state`
        };
      }
    }),
    getFocusedPick: build.query<
      PickingStateV2FocusedPick | null,
      { workstationId: string }
    >({
      query: ({ workstationId }) => {
        return {
          url: `/autostore-picking/workstation-focused-pick/${workstationId}`
        };
      },
      providesTags: ["focused pick"]
    }),
    getPortState: build.query<
      PickingStateV2PortState | null,
      { workstationId: string }
    >({
      query: ({ workstationId }) => {
        return {
          url: `/autostore-picking/workstation-port-state/${workstationId}`
        };
      },
      providesTags: ["picking port state"]
    }),
    getTotesForBatch: build.query<
      PickingStateV2TotesInPosition[],
      { batchId: string }
    >({
      query: ({ batchId }) => {
        return {
          url: `/autostore-picking/totes-for-batch/${batchId}`
        };
      },
      providesTags: (result) =>
        result?.flatMap((positionTotes) =>
          positionTotes.totes?.map((tote) => ({
            type: "tote" as const,
            id: tote.toteId
          }))
        ) ?? []
    }),
    repickProducts: build.mutation<string, RepickProductsRequest>({
      query: (request) => {
        return {
          url: `/totes/repick-products`,
          method: "POST",
          body: request
        };
      },
      invalidatesTags: (_result, _error, { toteId }) => [
        { type: "tote", id: toteId }
      ]
    }),
    outOfStockPick: build.mutation<
      void,
      {
        pickId: string;
        pickBinId: string;
        autostoreGridId: string;
        autostorePortId: number;
        hold?: string;
      }
    >({
      query: ({
        pickId,
        pickBinId,
        autostoreGridId,
        autostorePortId,
        hold
      }) => {
        return {
          url: "/picks/out-of-stock",
          method: "POST",
          body: {
            pickId,
            pickBinId,
            autostoreGridId,
            autostorePortId,
            hold
          }
        };
      }
    }),
    pickBatch: build.mutation<
      AutostorePickingState,
      { batchId: Guid; workstationId: Guid; gridId: Guid; portId?: number }
    >({
      query: ({ batchId, workstationId, gridId, portId }) => {
        return {
          url: "/autostore-picking/pick-batch",
          method: "POST",
          body: {
            batchId,
            workstationId,
            gridId,
            portId
          }
        };
      }
    })
  }),
  overrideExisting: false
});

export default autostorePickingApi;
