import { styled } from "@mui/material/styles";

const DivWrapper = styled("div")(() => ({
  flexBasis: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

const CircleCheckmarkCircle = styled("circle")(() => ({
  strokeDasharray: 166,
  strokeDashoffset: 166,
  strokeWidth: 2,
  strokeMiterlimit: 10,
  stroke: "white",
  fill: "none",
  animation: "stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards",

  "@keyframes stroke": {
    "100%": {
      strokeDashoffset: 0
    }
  }
}));

const SvgCheckmark = styled("svg")(() => ({
  borderRadius: "50%",
  display: "block",
  strokeWidth: 8,
  strokeMiterlimit: 10,
  boxShadow: "inset 0px 0px 0px white",

  animation:
    "fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both",

  "@keyframes fill": {
    "100%": {
      boxShadow: "inset 0px 0px 0px 60px white"
    }
  },
  "@keyframes scale": {
    "0%": {
      transform: "none"
    },
    "100%": {
      transform: "none"
    },
    "50%": {
      transform: "scale3d(1.1, 1.1, 1)"
    }
  }
}));

const PathCheckmarkCheck = styled("path")(() => ({
  transformOrigin: "50% 50%",
  strokeDasharray: 48,
  strokeDashoffset: 48,
  animation: "stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.2s forwards",
  strokeLinecap: "round",
  strokeLinejoin: "round",

  "@keyframes stroke": {
    "100%": {
      strokeDashoffset: 0
    }
  }
}));

export function AnimatedCheckMark(props: { checkColor: string; size: number }) {
  const { checkColor, size } = props;
  return (
    <DivWrapper>
      <SvgCheckmark
        role="img"
        aria-label="checkmark"
        sx={{ width: "100px", height: "100px", margin: "10% auto" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        style={{ height: size, width: size }}
      >
        <CircleCheckmarkCircle cx="26" cy="26" r="25" fill="none" />
        <PathCheckmarkCheck
          style={{ stroke: checkColor }}
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </SvgCheckmark>
    </DivWrapper>
  );
}

export function AnimatedX(props: { color: string; size: number }) {
  const { color, size } = props;
  return (
    <DivWrapper>
      <SvgCheckmark
        role="img"
        aria-label="x"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        style={{ height: size, width: size }}
      >
        <PathCheckmarkCheck
          style={{ width: "100px", height: "100px", margin: "10% auto" }}
          fill="white"
          stroke={color}
          d="M16 16 l20 20"
        />
        <PathCheckmarkCheck fill="white" stroke={color} d="M16 36 l20 -20" />
      </SvgCheckmark>
    </DivWrapper>
  );
}
