import { CardMedia, CardMediaProps } from "@mui/material";
import { useState, useEffect } from "react";

import fallbackImg from "../assets/img/no-product-image.png";
import { generateImgUrl } from "../util/generateImgUrl";

/**
 * Displays media inside a mui Card.
 *
 * Extends CardMedia to display a fallback image if the image request fails.
 */
export function CardMediaWithFallback(
  props: Pick<CardMediaProps, "src" | "image" | "sx" | "title">
) {
  const { src, image, sx } = props;
  const imageString = src || image;
  const imgUrl = imageString ? generateImgUrl(imageString) : fallbackImg;

  const [imgError, setImgError] = useState(false);
  useEffect(() => {
    setImgError(false);
  }, [src, image]);

  return (
    <CardMedia
      {...props}
      onError={() => setImgError(true)}
      image={imgError ? fallbackImg : imgUrl}
      component="img"
      sx={{ ...sx, objectFit: "contain" }}
      alt={
        imgError || imgUrl === fallbackImg
          ? "no product image"
          : "product image"
      }
    />
  );
}
