import { Typography, Box } from "@mui/material";

import {
  ConfirmationModal,
  formatUtcDate,
  useToast,
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowSkeleton
} from "@qubit/autoparts";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { warehouseService } from "~/api/warehouse";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { ViewContainer } from "~/components/ViewContainer";
import UniversalProductCard, {
  PickInfoIsLoading
} from "~/components/productCard/UniversalProductCard";
import { AutostoreBin } from "~/features/autostoreBin";
import InventoryAdjustDialog, {
  InventoryToModify
} from "~/features/inventoryAdjustDialog/InventoryAdjustDialog";
import { InventoryHoldModal } from "~/features/inventoryHoldModal/InventoryHoldModal";

import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";

import {
  generateLocationNameFromBin,
  ternaryIff,
  getVariantDisplayNameByDtoFe,
  checkIsExpiration,
  getInventoryDateObj,
  holdTypeCheck,
  formatHoldText,
  HoldType
} from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGridV2Subscription } from "~/lib/signalr";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";

import {
  fetchPortStatus,
  closeBin,
  binAtPortEvent,
  binNotAtPort,
  fetchBinLogPublisherState,
  GetNextBinSuccessAction
} from "~/redux/actions/autostore";
import {
  getInventorySummaries,
  deleteInventoryHold,
  getVariantByVariantId,
  clearSelectedVariant,
  findInventoryByAutostoreBinNumber,
  clearInventory,
  clearInventoryToModify
} from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";

import {
  selectCommonHolds,
  selectSelectedInventoryAtPort,
  selectSelectedSummaries
} from "~/redux/selectors/autostoreInventoryHoldsSelectors";
import { useRequestNextBinMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import {
  AutostoreBinState,
  AutostoreEvent,
  InventoryDto,
  InventorySummaryDto
} from "~/types/api";

import { AutostoreInventoryHoldsToolbar } from "./AutostoreInventoryHoldsToolbar";
import {
  resetSelectedSummaries,
  setIsAdjustPanelOpen,
  setIsBinHoldModalOpen,
  setPortOpened,
  setPortPollingActive,
  setSelectedSummaries
} from "./autostoreInventoryHolds.slice";

const mapStateToProps = (state: StoreState) => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  selectedPortId: state.workstations.sitePortId,
  portState: state.autostore.portState,
  inventorySummaries: state.inventory.inventorySummaries,
  inventorySummariesCount: state.inventory.inventorySummariesCount,
  inventoryAtPort: state.inventory.inventory,
  selectedVariant: state.inventory.selectedVariant,
  loadingInventory: state.inventory.loadingInventory,
  binAtPort: state.autostore.binAtPort,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  siteAllPortIds: state.workstations.siteAllPortIds,
  inv_inventoryDateLabel: state.site.clientConfig.inv_inventoryDateLabel,
  thisWorkstationId: state.workstations.siteWorkstation?.id
});

const connector = connect(mapStateToProps, {
  fetchPortStatus,
  deleteInventoryHold,
  getVariantByVariantId,
  findInventoryByAutostoreBinNumber,
  binAtPortEvent,
  fetchBinLogPublisherState,
  clearInventoryToModify
});
type AutostoreInventoryHoldsInheritedProps = {
  viewTitle?: ViewNameTranslation;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type AutostoreInventoryHoldsProps = PropsFromRedux &
  AutostoreInventoryHoldsInheritedProps;

export function AutostoreInventoryHolds(props: AutostoreInventoryHoldsProps) {
  const {
    selectedAutostoreGridId,
    selectedPortId,
    loadingInventory,
    inventorySummaries,
    inventorySummariesCount,
    inventoryAtPort,
    portState,
    selectedVariant,
    binAtPort,
    usersFulfillmentCenter,
    siteAllPortIds,
    inv_inventoryDateLabel,
    thisWorkstationId,
    viewTitle
  } = props;

  const { t } = useTranslation();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const { successToast, errorToast, warningToast, infoToast } = useToast();
  const dispatch = useAppDispatch();

  const { setMenuItems, setToolbar } = useNavbar({
    viewTitle
  });

  const closeWorkstation = useCloseWorkstationWithErrorToast();
  const [requestNextBin, { data: nextBinResponse }] =
    useRequestNextBinMutation();
  const autostoreBinConfiguration = nextBinResponse?.binConfiguration;
  const nextBinInventoryResponse = nextBinResponse?.inventory || [];

  const commonHolds = useAppSelector(selectCommonHolds);
  const isAdjustPanelOpen = useAppSelector(
    (state) => state.autostoreInventoryHolds.isAdjustPanelOpen
  );
  const isBinHoldModalOpen = useAppSelector(
    (state) => state.autostoreInventoryHolds.isBinHoldModalOpen
  );
  const portOpened = useAppSelector(
    (state) => state.autostoreInventoryHolds.portOpened
  );
  const portPollingActive = useAppSelector(
    (state) => state.autostoreInventoryHolds.portPollingActive
  );
  const selectedSummaries = useAppSelector(selectSelectedSummaries);
  const selectedInventoryAtPort = useAppSelector(selectSelectedInventoryAtPort);

  // Local State
  const [binUse, setBinUse] = useState<{
    inUse: boolean;
    usedByPort: number;
  }>({ inUse: false, usedByPort: 0 });
  const [nextBinId, setNextBinId] = useState<number>(0);
  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);

  useDevCheats({ isPortPolling: portPollingActive, showAutostoreStatus: true });
  const [compartment, setCompartment] = useState<number | null>(null);

  const [page, setPage] = useState<number>(1);

  const limit = 10;
  const offset = (page - 1) * limit;

  const totalPageCount = inventorySummariesCount
    ? Math.ceil(inventorySummariesCount / limit)
    : 0;

  const [isNavigateAwayModalOpen, setIsNavigateAwayModalOpen] = useState(false);

  let selectedLocationName = "";

  if (selectedInventoryAtPort) {
    selectedLocationName =
      selectedInventoryAtPort.bin.binType === "autostore"
        ? "Autostore"
        : generateLocationNameFromBin(selectedInventoryAtPort.bin);
  }

  const selectedInventoryToModify: InventoryToModify = selectedInventoryAtPort
    ? {
        binType: selectedInventoryAtPort.bin.binType,
        binId: selectedInventoryAtPort.bin.binId,
        inventoryId: selectedInventoryAtPort.inventoryId,
        locationName: selectedLocationName,
        count: selectedInventoryAtPort.count,
        committedCount: selectedInventoryAtPort.committedCount,
        ...getInventoryDateObj(
          inv_inventoryDateLabel,
          moment(selectedInventoryAtPort?.expiration) ||
            moment(selectedInventoryAtPort?.manufactureDate)
        ),
        lotNumber: selectedInventoryAtPort.lotNumber,
        variantId: selectedInventoryAtPort.variantId
      }
    : null;

  const handleGetInventorySummaries = useCallback(async () => {
    await dispatch(
      getInventorySummaries({
        autostoreGridId: selectedAutostoreGridId,
        hasHold: true,
        limit,
        offset
      })
    );
  }, [dispatch, offset, selectedAutostoreGridId]);

  const resetPage = useCallback(() => {
    dispatch(clearSelectedVariant());
    dispatch(clearInventory());
    dispatch(clearInventoryToModify());
    dispatch(resetSelectedSummaries());
    dispatch(setPortPollingActive(false));
    void handleGetInventorySummaries();
    dispatch(setPortOpened(false));
  }, [dispatch, handleGetInventorySummaries]);

  useEffect(() => {
    dispatch(resetSelectedSummaries());
  }, [dispatch]);

  useEffect(() => {
    if (shouldListenToGridEvents) {
      dispatch(binNotAtPort());
      dispatch(setPortPollingActive(false));
    }
  }, [dispatch, shouldListenToGridEvents]);

  useEffect(() => {
    void handleGetInventorySummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  // fetch initial inventory list for held inventory
  useEffect(() => {
    if (selectedAutostoreGridId) {
      void handleGetInventorySummaries();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAutostoreGridId]);

  // fetch variant info for inventory at port
  useEffect(() => {
    if (selectedInventoryAtPort) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getVariantByVariantId(selectedInventoryAtPort.variantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventoryAtPort]);

  const handleCloseWorkstation = useCallback(
    async (confirm: boolean = false) => {
      dispatch(setPortOpened(false));
      dispatch(setPortPollingActive(false));
      if (binAtPort) {
        dispatch(binNotAtPort());
      }
      const portStatus = await dispatch(fetchPortStatus());
      if (
        portStatus &&
        (portStatus.selectedBin > 0 || portStatus.selectedTask > 0) &&
        !confirm
      )
        setIsNavigateAwayModalOpen(true);
      else {
        const error = await closeWorkstation();
        if (!error) successToast(t("workstation closed"));
      }
    },
    [binAtPort, closeWorkstation, dispatch, successToast, t]
  );

  useEffect(() => {
    setMenuItems([
      {
        textContent: t("close workstation"),
        actionCb: handleCloseWorkstation
      }
    ]);
  }, [t, handleCloseWorkstation, setMenuItems]);

  // Start polling port when held-inventory is requested
  usePromiseInterval(
    async () => {
      if (!selectedAutostoreGridId || !selectedPortId) return;

      await props.fetchPortStatus();
    },
    500,
    portPollingActive && !shouldListenToGridEvents
  );

  // POLL FOR BIN AT PORT INFORMATION IF EVENT ISN'T RECEIVED LONGER THAN 7 SECONDS
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > 7) {
        await props.fetchPortStatus().then((portStatus) => {
          if (portStatus && selectedAutostoreGridId) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            props
              .fetchBinLogPublisherState(
                selectedAutostoreGridId,
                portStatus.selectedBin
              )
              .then((binStateResponse) => {
                setNextBinId(portStatus.selectedBin);
                if (
                  binStateResponse &&
                  binStateResponse.binState.binMode === "O" &&
                  binStateResponse.binState.portId === selectedPortId
                ) {
                  props.binAtPortEvent();
                }
              });
          }
        });
      }
    },
    1000,
    !binAtPort && shouldListenToGridEvents && portOpened
  );

  useEffect(() => {
    if (binAtPort && binAtPortSeconds > 0) {
      setBinAtPortSeconds(0);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.fetchPortStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binAtPort]);

  // Start polling bin when held-inventory is requested
  usePromiseInterval(
    async () => {
      if (!selectedAutostoreGridId || !selectedPortId || !portState) return;
      const { selectedBin } = portState;
      const binStateResponse = await warehouseService.get<AutostoreBinState>(
        `/api/autostore-grid/${selectedAutostoreGridId}/bin/${selectedBin}`
      );
      const { portId } = binStateResponse.data;
      if (portId && portId !== selectedPortId && !binUse.inUse) {
        setBinUse({ inUse: true, usedByPort: portId });
        warningToast(
          `This bin is currently selected by port ${portId} and will not arrive until it is released.`
        );
      } else if (portId === selectedPortId && binUse.inUse) {
        infoToast(
          `This bin has been released by ${binUse.usedByPort} and should be on it's way.`
        );
        setBinUse({ inUse: false, usedByPort: 0 });
      }
    },
    5000,
    portPollingActive
  );

  // Stop polling when bin arrives
  useEffect(() => {
    if (
      !shouldListenToGridEvents &&
      portState &&
      portState.isReady &&
      portState.selectedBin
    ) {
      dispatch(setPortPollingActive(false));
    }
  }, [dispatch, portState, shouldListenToGridEvents]);

  const handleRemoveFlags = (
    inventoryToRemove: InventoryDto[] | InventorySummaryDto[],
    holdType?: HoldType,
    refetchSummaries = false
  ) => {
    if (selectedAutostoreGridId && selectedPortId) {
      let wasSuccessToastShown = false;
      for (const inv of inventoryToRemove) {
        if (holdType) {
          let includesHold = false;

          for (const hold of inv.holds) {
            // hold can be a string or a HoldObject
            const holdString =
              typeof hold === "object" ? hold.reasonCode : hold;

            // check that the text includes the full hold text
            if (
              holdType.reasonCode?.toLowerCase() === holdString?.toLowerCase()
            ) {
              includesHold = true;
              break;
            }
          }

          if (includesHold) {
            if (holdTypeCheck(holdType, errorToast, moment(inv.expiration))) {
              return;
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            props
              .deleteInventoryHold(
                inv.inventoryId,
                thisWorkstationId || null,
                holdType.reasonCode,
                selectedAutostoreGridId,
                selectedPortId
              )
              .then(() => {
                // don't spam the user with success toasts
                // noting that this is ran inside a loop
                if (!wasSuccessToastShown) {
                  wasSuccessToastShown = true;
                  successToast(t("inventory hold removed"));
                }
                if (isAdjustPanelOpen) {
                  dispatch(setIsAdjustPanelOpen(false));
                }
                if (isBinHoldModalOpen) {
                  dispatch(setIsBinHoldModalOpen(false));
                }
                // once we delete the hold, fetch inventory information to update the product on the table
                if (
                  inventoryAtPort.length &&
                  inventoryAtPort[0].bin.autostoreBin &&
                  selectedAutostoreGridId
                )
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  props.findInventoryByAutostoreBinNumber(
                    selectedAutostoreGridId,
                    inventoryAtPort[0].bin.autostoreBin?.autostoreBinId
                  );

                // if we don't have product at the port. reset the selected inventory
                if (!selectedInventoryAtPort) {
                  dispatch(resetSelectedSummaries());
                }

                // calculate the current page after some holds are removed
                if (refetchSummaries) {
                  const numberOfPages = inventorySummariesCount
                    ? Math.ceil(
                        (inventorySummariesCount - inventoryToRemove.length) /
                          limit
                      )
                    : 0;

                  if (numberOfPages < page && numberOfPages > 0)
                    setPage(numberOfPages);
                  else {
                    void handleGetInventorySummaries();
                  }
                }
              });
          } else if (!includesHold) {
            errorToast(
              `${t(holdType.reasonCode)} ${t(
                "doesn't exist on inventory record"
              )}`
            );
          }
        }
      }
    }
  };

  const removeHoldCb = (holdType: HoldType | null | undefined) => {
    if (selectedSummaries.length && holdType) {
      const selectedInventory = selectedInventoryAtPort
        ? [selectedInventoryAtPort]
        : selectedSummaries;

      handleRemoveFlags(selectedInventory, holdType, true);
    }
  };

  const gridSub = (data: AutostoreEvent) => {
    if (data.case !== "BinModeChange" || data.event.binMode !== "O") {
      return;
    }
    const binId = nextBinResponse?.openBinResponse.binId;
    if (binId) setNextBinId(binId);
    if (
      data.event.binId === binId &&
      data.event.gridId === props.selectedAutostoreGridId &&
      !!data.event.portId &&
      siteAllPortIds.includes(data.event.portId)
    ) {
      // Dispatch redux action
      props.binAtPortEvent();
    }
  };
  useGridV2Subscription(gridSub);

  const handleGetNextBin = useCallback(async () => {
    if (!selectedAutostoreGridId) return;

    const portStatus = await dispatch(fetchPortStatus());
    if (!portStatus) return;

    const { portId, selectedBin, selectedTask } = portStatus;
    // close bin
    if (selectedBin)
      await dispatch(closeBin({ binId: selectedBin, taskId: selectedTask }));

    // get next bin
    try {
      const data = await requestNextBin({
        autostoreGridId: selectedAutostoreGridId,
        portId: portId
      }).unwrap();
      // shim the success action here until we hook the rest of the data into rtk
      dispatch<GetNextBinSuccessAction>({
        type: "autostore/GET_NEXT_BIN_SUCCESS",
        payload: {
          nextBinInventory: data.inventory,
          portId: portId,
          nextBinConfiguration: data.binConfiguration ?? null,
          nextBinResponse: data
        }
      });

      // start polling
      if (!shouldListenToGridEvents) {
        dispatch(setPortPollingActive(true));
      } else {
        dispatch(binNotAtPort());
      }
    } catch (err) {
      const message = getMessageFromRtkError(err);
      // if the
      if (message.includes("There is nothing left to pick")) {
        successToast("Selected Bins Completed");
        await closeWorkstation();
        resetPage();
      } else {
        errorToast(message);
      }
    }
  }, [
    selectedAutostoreGridId,
    dispatch,
    shouldListenToGridEvents,
    requestNextBin,
    closeWorkstation,
    resetPage,
    successToast,
    errorToast
  ]);

  useEffect(() => {
    if (selectedSummaries.length > 0) {
      setToolbar(
        <AutostoreInventoryHoldsToolbar
          handleGetNextBin={handleGetNextBin}
          resetPage={resetPage}
        />
      );
    } else {
      setToolbar(undefined);
    }
  }, [handleGetNextBin, resetPage, selectedSummaries.length, setToolbar]);

  useEffect(() => {
    if (selectedVariant) {
      const selectedInventory = nextBinInventoryResponse.find(
        (i) => i.variantId === selectedVariant.variantId
      );
      setCompartment(
        selectedInventory?.bin.autostoreCompartmentNumber
          ? selectedInventory.bin.autostoreCompartmentNumber - 1
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  let thirdColumnName = "";

  switch (inv_inventoryDateLabel) {
    case "manufacture":
      thirdColumnName = t("manufacture date");
      break;
    case "expiration":
    default:
      thirdColumnName = t("expiration");
      break;
  }

  const selectRow = (row: InventorySummaryDto) => {
    const rowId = row.inventoryId;
    const thisSelectedSummary = selectedSummaries.find(
      (sum) => sum.inventoryId === rowId
    );

    if (thisSelectedSummary) {
      dispatch(
        setSelectedSummaries(
          selectedSummaries.filter((sum) => sum.inventoryId !== rowId)
        )
      );
    } else {
      dispatch(setSelectedSummaries([...selectedSummaries, row]));
    }
  };

  return (
    <ViewContainer
      maxWidth="xl"
      sx={{
        gridAutoFlow: "column",
        gridTemplateRows: "1fr auto 1fr",
        gridTemplateColumns: "0.5fr 1fr",
        gridTemplateAreas: `
          "variant table"
          "binid table"
          "bin table"
        `,
        gap: 2
      }}
    >
      {selectedVariant && selectedInventoryAtPort ? (
        <UniversalProductCard
          productName={
            getVariantDisplayNameByDtoFe(
              selectedVariant,
              usersFulfillmentCenter
            ) ?? ""
          }
          imageFileName={selectedVariant.imageFilename}
          sku={selectedVariant.sku}
          weight={`${selectedInventoryAtPort.count?.value || ""} ${
            selectedInventoryAtPort.count?.units || ""
          }`}
          upc={selectedVariant.sku}
          cardHeight="480px"
        />
      ) : (
        <PickInfoIsLoading height={480} />
      )}
      <Box>
        {!portPollingActive &&
        selectedVariant &&
        portState &&
        portState.selectedBin &&
        ((!portPollingActive && !shouldListenToGridEvents) ||
          (binAtPort && shouldListenToGridEvents)) ? (
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              width: "100%"
            }}
          >
            {t("bin")}{" "}
            {portState
              ? ternaryIff(
                  !shouldListenToGridEvents,
                  portState.selectedBin,
                  nextBinId
                )
              : undefined}
          </Typography>
        ) : (
          ""
        )}
      </Box>
      <Box justifySelf="center" display="grid">
        <AutostoreBin
          state={
            !portOpened
              ? "Port Closed"
              : !binAtPort || portPollingActive
                ? "Waiting for Bin"
                : "Bin Opened"
          }
          pickQuantity={selectedInventoryAtPort?.count?.value || ""}
          pickCompartment={compartment}
          numberOfColumns={
            autostoreBinConfiguration?.verticalCompartmentCount || 1
          }
          numberOfRows={
            autostoreBinConfiguration?.horizontalCompartmentCount || 1
          }
          binId={
            portOpened && portState
              ? ternaryIff(
                  !portPollingActive && !shouldListenToGridEvents,
                  nextBinId,
                  ternaryIff(
                    binAtPort && shouldListenToGridEvents,
                    portState.selectedBin,
                    undefined
                  )
                )
              : undefined
          }
          hideBinId
        />
      </Box>
      <Box gridArea="table">
        <ASTableV2
          gridTemplateColumns="1fr repeat(3, auto)"
          gridTemplateRows={`50px repeat(${limit}, 1fr)`}
          isLoading={false}
          isEmpty={false}
          pagination={{
            pageCount: selectedInventoryAtPort ? 0 : totalPageCount,
            page: page,
            setPage: (p) => {
              setPage(p);
              if (window.scrollTo) window.scrollTo(0, 0);
            }
          }}
        >
          <ASTableV2Header>
            <ASTableV2Row>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {selectedInventoryAtPort ? t("bin") : t("product")}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {selectedInventoryAtPort ? t("quantityAbbr") : t("quantity")}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{thirdColumnName}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {selectedInventoryAtPort ? t("flag") : t("hold type")}
                </Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
          </ASTableV2Header>
          <ASTableV2Body>
            {loadingInventory && (
              <ASTableV2RowSkeleton rows={limit} columns={4} />
            )}
            {!loadingInventory && !inventorySummaries.length && (
              <ASTableV2Row>
                <ASTableV2Cell
                  sx={{ gridColumn: "1/-1", justifyContent: "center" }}
                >
                  <Typography variant="tableBody">
                    {t("no inventory holds")}
                  </Typography>
                </ASTableV2Cell>
              </ASTableV2Row>
            )}
            {!loadingInventory &&
              (selectedInventoryAtPort ? (
                <ASTableV2Row>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">
                      {selectedInventoryAtPort.bin?.autostoreBin?.autostoreBinId?.toString() ??
                        ""}
                    </Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">
                      {selectedInventoryAtPort.count?.value.toString() || ""}
                    </Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell>
                    <Typography variant="tableBody">
                      {formatUtcDate(
                        checkIsExpiration(inv_inventoryDateLabel)
                          ? selectedInventoryAtPort.expiration
                          : selectedInventoryAtPort.manufactureDate
                      )}
                    </Typography>
                  </ASTableV2Cell>
                  <ASTableV2Cell
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start"
                    }}
                  >
                    {selectedInventoryAtPort.holds?.map((hold, index) => (
                      <Box key={`hold-${index}`}>
                        <Typography variant="tableBody">
                          {formatHoldText(hold)}
                        </Typography>
                      </Box>
                    )) || ""}
                  </ASTableV2Cell>
                </ASTableV2Row>
              ) : (
                inventorySummaries.map((is) => (
                  <ASTableV2Row
                    key={is.inventoryId}
                    selected={selectedSummaries.some(
                      (ss) => ss.inventoryId === is.inventoryId
                    )}
                    onClick={() => selectRow(is)}
                  >
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {is.productName}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {is.count?.value.toString() || ""}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {formatUtcDate(
                          checkIsExpiration(inv_inventoryDateLabel)
                            ? is.expiration
                            : is.manufactureDate
                        )}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell
                      sx={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}
                    >
                      {is.holds?.map((hold, index) => (
                        <Box key={`hold-${index}`}>
                          <Typography>{formatHoldText(hold)}</Typography>
                        </Box>
                      )) || ""}
                    </ASTableV2Cell>
                  </ASTableV2Row>
                ))
              ))}
          </ASTableV2Body>
        </ASTableV2>
      </Box>
      {selectedInventoryAtPort && (
        <InventoryAdjustDialog
          open={isAdjustPanelOpen}
          onClose={() => dispatch(setIsAdjustPanelOpen(false))}
          disabled={!selectedInventoryToModify || portPollingActive}
          invToModify={selectedInventoryToModify}
          selectedVariant={selectedVariant || null}
          refreshCb={({ inventoryWasEmptied }) => {
            dispatch(setIsAdjustPanelOpen(false));
            if (
              !inventoryWasEmptied &&
              inventoryAtPort.length &&
              inventoryAtPort[0].bin.autostoreBin &&
              selectedAutostoreGridId
            )
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              props.findInventoryByAutostoreBinNumber(
                selectedAutostoreGridId,
                inventoryAtPort[0].bin.autostoreBin?.autostoreBinId
              );
            if (inventoryWasEmptied) {
              props.clearInventoryToModify();
              void handleGetNextBin();
            }
          }}
          canceledReason=""
        />
      )}
      <ConfirmationModal
        isOpen={isNavigateAwayModalOpen}
        confirmCb={() => {
          void handleCloseWorkstation(true);
          setIsNavigateAwayModalOpen(false);
        }}
        closeCb={() => {
          setIsNavigateAwayModalOpen(false);
        }}
        modalTitle={t("are you sure you want to close the workstation")}
        modalText=""
      />
      <InventoryHoldModal
        confirmCb={(holdType) => removeHoldCb(holdType)}
        open={isBinHoldModalOpen}
        closeModalCallback={() => {
          dispatch(setIsBinHoldModalOpen(false));
        }}
        maxWidth="sm"
        options={commonHolds}
        confirmButtonText={t("remove hold")}
        currentHold=""
      />
    </ViewContainer>
  );
}

export default connector(AutostoreInventoryHolds);
