import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  ClearErrorMessageAction,
  FetchOrderSummariesFilterLockedAction,
  FetchOrderSummariesFilterLockedSuccessAction,
  FetchOrderSummariesFilterLockedFailureAction,
  CreateBatchesFromOrdersAction,
  CreateBatchesFromOrdersSuccessAction,
  CreateBatchesFromOrdersFailureAction,
  FetchToteFailureAction
} from "~/redux/actions/home";

import {
  GetBatchLabelsAction,
  GetBatchLabelsFailureAction
} from "~/redux/actions/labels";
import {
  BatchSummaryDto,
  OrderSummaryDto,
  BatchOrdersResponse
} from "~/types/api";

export type HomeState = {
  batches: BatchSummaryDto[];
  orders: OrderSummaryDto[];
  ordersLoading: boolean;
  ordersError: string | null;
  selectedOrderIds: Guid[];
  createBatchesResponse: BatchOrdersResponse | null;
  createBatchesLoading: boolean;
  getLabelsError: string | null;
};

const initialState: HomeState = {
  batches: [],
  orders: [],
  ordersLoading: true,
  ordersError: null,
  createBatchesResponse: null,
  createBatchesLoading: false,
  selectedOrderIds: [],
  getLabelsError: null
};

type Actions =
  | FetchOrderSummariesFilterLockedAction
  | FetchOrderSummariesFilterLockedSuccessAction
  | FetchOrderSummariesFilterLockedFailureAction
  | CreateBatchesFromOrdersAction
  | CreateBatchesFromOrdersSuccessAction
  | CreateBatchesFromOrdersFailureAction
  | ClearErrorMessageAction
  | GetBatchLabelsAction
  | GetBatchLabelsFailureAction
  | FetchToteFailureAction;

const reducer = (state: HomeState | undefined, action: Actions): HomeState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "home/FETCH_ORDER_SUMMARIES_FILTER_LOCKED":
      return {
        ...state,
        selectedOrderIds: [],
        ordersLoading: true,
        orders: [],
        ordersError: null
      };
    case "home/FETCH_ORDER_SUMMARIES_FILTER_LOCKED_SUCCESS":
      return {
        ...state,
        orders: action.payload.filter(
          (o) => o.status === "Locked" || o.status === "Received"
        ),
        ordersLoading: false,
        ordersError: null
      };
    case "home/CREATE_BATCHES_FROM_ORDERS":
      return {
        ...state,
        createBatchesResponse: null,
        createBatchesLoading: true
      };
    case "home/CREATE_BATCHES_FROM_ORDERS_FAILURE":
      return {
        ...state,
        createBatchesLoading: false
      };
    case "home/CREATE_BATCHES_FROM_ORDERS_SUCCESS":
      return {
        ...state,
        createBatchesResponse: action.payload,
        selectedOrderIds: [],
        createBatchesLoading: false
      };
    case "batch/GET_LABELS":
      return { ...state, getLabelsError: null };
    case "batch/GET_LABELS_FAILURE":
      return { ...state, getLabelsError: action.payload };
    case "home/CLEAR_ERROR_MESSAGE":
      return {
        ...state,
        getLabelsError: null
      };
    default:
      return state;
  }
};

const autostoreHomePersistConfig = {
  key: "home",
  storage
};

export const home = persistReducer(autostoreHomePersistConfig, reducer);
