import { warehouseService, handleWarehouseError } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import {
  UncountedBinDto,
  CycleCountV1Dto,
  InventoryDto,
  MeasuredValueDto,
  CompleteCycleCountRequest,
  VariantFrontendDto,
  IncompleteCycleCountResponseDto,
  IncompleteCycleCountsResponse
} from "~/types/api";

import { SetUserMessageAction } from "./site";

export interface CycleCountTaskDto
  extends IncompleteCycleCountResponseDto,
    VariantFrontendDto {}

export interface ShapedCycleCountInfo {
  cycleCountId: Guid;
  status: string;
  uncountedBinsInfo: {
    binId: number | string;
    status: string;
    uncountedBin: UncountedBinDto;
  }[];
}

// cycle count overhaul.
export interface FetchIncompleteCycleCountCountingTasksActionV2 {
  type: "cycleCounts/FETCH_INCOMPLETE_CYCLE_COUNT_COUNTING_TASKS_V2";
}

export interface FetchIncompleteCycleCountCountingTasksSuccessActionV2 {
  type: "cycleCounts/FETCH_INCOMPLETE_CYCLE_COUNT_COUNTING_TASKS_SUCCESS_V2";
  payload: IncompleteCycleCountsResponse;
}

export interface FetchIncompleteCycleCountCountingTasksFailureActionV2 {
  type: "cycleCounts/FETCH_INCOMPLETE_CYCLE_COUNT_COUNTING_TASKS_FAILURE_V2";
}

export interface FetchCycleCountAction {
  type: "cycleCounts/FETCH_CYCLE_COUNT";
  doNotNull?: boolean;
}
export interface FetchCycleCountSuccessAction {
  type: "cycleCounts/FETCH_CYCLE_COUNT_SUCCESS";
  payload: CycleCountV1Dto;
}

export interface FetchCycleCountFailureAction {
  type: "cycleCounts/FETCH_CYCLE_COUNT_FAILURE";
}

export interface SetSelectedCountingTaskAction {
  type: "cycleCounts/SET_SELECTED_COUNTING_TASKS";
  payload: CycleCountTaskDto[];
}

export interface ClearSelectedCountingTasksAction {
  type: "cycleCounts/CLEAR_SELECTED_COUNTED_TASKS";
}
export interface GetCycleCountFrequencyAction {
  type: "cycleCounts/GET_CYCLE_COUNT_FREQUENCY";
}

export interface GetCycleCountFrequencySuccessAction {
  type: "cycleCounts/GET_CYCLE_COUNT_FREQUENCY_SUCCESS";
  payload: { variantId: Guid; frequency: number | null };
}

export interface GetCycleCountFrequencyFailureAction {
  type: "cycleCounts/GET_CYCLE_COUNT_FREQUENCY_FAILURE";
  payload: { variantId: Guid };
}

export interface FetchCycleCountsUncountedBinsAction {
  type: "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS";
}
export interface FetchCycleCountsUncountedBinsSuccessAction {
  type: "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS_SUCCESS";
  payload: {
    cycleCountsUncountedBins: UncountedBinDto[];
    shapedCycleCountInfos: ShapedCycleCountInfo[];
  };
}

export interface FetchCycleCountsUncountedBinsFailureAction {
  type: "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS_FAILURE";
}

export interface FetchCycleCountInventoryRecordsAction {
  type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS";
}

export interface FetchCycleCountInventoryRecordsSuccessAction {
  type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_SUCCESS";
  payload: InventoryDto[];
}

export interface FetchCycleCountInventoryRecordsFailureAction {
  type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_FAILURE";
}

export interface ModifyUncountedBinAction {
  type: "cycleCounts/MODIFY_UNCOUNTED_BIN";
  payload: {
    inventoryId: Guid;
    cycleCountId: Guid;
    newQuantity?: number;
    newDate?: {
      expirationDate?: Date;
      manufactureDate?: Date;
    };
  };
}

export interface ModifyShapedCycleCountInfoAction {
  type: "cycleCounts/MODIFY_SHAPED_CYCLE_COUNT_INFO";
  payload: {
    cycleCountId: Guid;
    inventoryId?: Guid;
    newUncountedBinStatus?: string;
    newCycleCountStatus?: string;
  };
}

export interface PostBinCountAction {
  type: "cycleCounts/POST_BIN_COUNT";
}

export interface PostBinCountFailureAction {
  type: "cycleCounts/POST_BIN_COUNT_FAILURE";
}

export interface CompleteCycleCountSuccessAction {
  type: "cycleCounts/COMPLETE_CYCLE_COUNT_SUCCESS";
  payload: {
    cycleCountIds: Guid[];
  };
}

export interface CompleteCycleCountFailureAction {
  type: "cycleCounts/COMPLETE_CYCLE_COUNT_FAILURE";
}

export interface RemoveCycleCountSuccessAction {
  type: "cycleCounts/REMOVE_CYCLE_COUNT_SUCCESS";
}

export interface RemoveCycleCountFailureAction {
  type: "cycleCounts/REMOVE_CYCLE_COUNT_FAILURE";
}

export interface ClearCycleCountsStateAction {
  type: "cycleCounts/CLEAR_CYCLE_COUNTS_STATE";
}

export interface ClearMessageAction {
  type: "cycleCounts/CLEAR_MESSAGE";
}

export interface ClearActiveCountingTask {
  type: "cycleCounts/CLEAR_ACTIVE_COUNTING_TASK";
  payload: boolean | undefined;
}

// used by non-autostore cycle counts and needs repaired
export const fetchCycleCountAndBins =
  (cycleCountId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    let progressRecord = "";

    try {
      dispatch<FetchCycleCountAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT"
      });

      const response = await warehouseService.get<CycleCountV1Dto>(
        `/api/cycle-counts/${cycleCountId}`
      );

      const { data } = response;

      dispatch<FetchCycleCountSuccessAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT_SUCCESS",
        payload: data
      });

      progressRecord = "fetched cycle counts";

      const cycleCountVariantId = data.variant.variantId;

      dispatch<FetchCycleCountInventoryRecordsAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS"
      });

      const cycleCountInventoryResponse2 = await warehouseService.post<
        InventoryDto[]
      >(`/api/inventory/find`, { variantIds: [cycleCountVariantId] });

      dispatch<FetchCycleCountInventoryRecordsSuccessAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_SUCCESS",
        payload: cycleCountInventoryResponse2.data
      });
    } catch (err) {
      if (progressRecord === "fetched cycle counts") {
        handleWarehouseError(err, (message) => {
          dispatch<FetchCycleCountInventoryRecordsFailureAction>({
            type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_FAILURE"
          });
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error",
              origin: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_FAILURE"
            }
          });
        });
      } else {
        handleWarehouseError(err, (message) => {
          dispatch<FetchCycleCountFailureAction>({
            type: "cycleCounts/FETCH_CYCLE_COUNT_FAILURE"
          });
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error",
              origin: "cycleCounts/FETCH_CYCLE_COUNT_FAILURE"
            }
          });
        });
      }
    }
  };

// used by autostore cycle counts
export const fetchCycleCountsUncountedBins =
  (cycleCountIds: Guid[], autostoreGridId?: Guid): AsyncAppThunk =>
  async (dispatch) => {
    try {
      dispatch<FetchCycleCountsUncountedBinsAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS"
      });

      // fetch uncounted bins
      const uncountedBinsResponses = await Promise.all(
        cycleCountIds.map((cycleCountId) =>
          warehouseService.get<UncountedBinDto[]>(
            `/api/cycle-counts/${cycleCountId}/uncounted-bins`,
            {
              params: {
                includeUnchangedInventory: false,
                ...(autostoreGridId && { autostoreGridId })
              }
            }
          )
        )
      );

      const responsesWithUncountedBins = uncountedBinsResponses
        .map((response) => response.data)
        .filter((response) => response);

      const cycleCountIdsWithoutUncountedBins = uncountedBinsResponses
        .map((response, i) => ({
          uncountedBins: response.data,
          index: i
        }))
        .filter((obj) => !obj.uncountedBins)
        .map((obj) => cycleCountIds[obj.index]);

      /*
        responses:

        [
          [{cycleCountId: 1, inventoryId: a}, {cycleCountId: 1, inventoryId: b}],
          [{cycleCountId: 2, inventoryId: c}, {cycleCountId: 2, inventoryId: d}]
        ]

        ShapedUncountedBins:
        [
          {
            cycleCountId: 1,
            status: "open",
            uncountedBins: [
              {
                binId: 1a,
                status: "open",
                uncountedBin: {cycleCountId: 1, inventoryId: a}
              },
              {
                binId: 2b,
                status: "active",
                uncountedBin: {cycleCountId: 1, inventoryId: b}
              }
            ]
          }
        ]
      */

      const cycleCountsUncountedBins = responsesWithUncountedBins
        .flat()
        .filter((el) => el);

      const shapedCycleCountInfosWithUncountedBins: ShapedCycleCountInfo[] =
        responsesWithUncountedBins
          .flat()
          .reduce(
            (acc: ShapedCycleCountInfo[], uncountedBin: UncountedBinDto) => {
              const matchingCycleCount: ShapedCycleCountInfo | undefined =
                acc.find(
                  (accUncountedBin) =>
                    accUncountedBin.cycleCountId === uncountedBin.cycleCountId
                );

              const newBinInfo = {
                binId:
                  uncountedBin.bin.autostoreBin?.autostoreBinId ||
                  "not autostore",
                status: "open",
                uncountedBin
              };
              return matchingCycleCount
                ? // cycle count id already exists, add the newBin to it
                  acc.map((shapedCycleCountInfo) => ({
                    ...shapedCycleCountInfo,
                    uncountedBinsInfo:
                      matchingCycleCount.cycleCountId ===
                      shapedCycleCountInfo.cycleCountId
                        ? [...matchingCycleCount.uncountedBinsInfo, newBinInfo]
                        : shapedCycleCountInfo.uncountedBinsInfo
                  }))
                : // no matching cycle count id, create a new one
                  [
                    ...acc,
                    {
                      cycleCountId: uncountedBin.cycleCountId,
                      status: "open",
                      uncountedBinsInfo: [newBinInfo]
                    }
                  ];
            },
            []
          );

      const shapedCycleCountInfos: ShapedCycleCountInfo[] = [
        ...shapedCycleCountInfosWithUncountedBins,
        ...cycleCountIdsWithoutUncountedBins.map((cycleCountId) => ({
          cycleCountId,
          status: "ready to complete",
          uncountedBinsInfo: []
        }))
      ];

      dispatch<FetchCycleCountsUncountedBinsSuccessAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS_SUCCESS",
        payload: {
          cycleCountsUncountedBins,
          shapedCycleCountInfos
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<FetchCycleCountsUncountedBinsFailureAction>({
          type: "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS_FAILURE"
          }
        });
      });
    }
  };

export const modifyUncountedBin =
  (args: {
    inventoryId: Guid;
    cycleCountId: Guid;
    newQuantity?: number;
    newDate?: {
      expirationDate?: Date;
      manufactureDate?: Date;
    };
    newStatus?: string;
  }): AppThunk =>
  (dispatch) => {
    dispatch<ModifyUncountedBinAction>({
      type: "cycleCounts/MODIFY_UNCOUNTED_BIN",
      payload: args
    });
  };

export const modifyShapedCycleCountInfo =
  (args: {
    cycleCountId: Guid;
    inventoryId?: Guid;
    newUncountedBinStatus?: string;
    newCycleCountStatus?: string;
  }): AppThunk =>
  (dispatch, getState) => {
    let argsToSend = args;

    const state = getState();

    // check to see if the cycle count is completed
    // if so, set the status to "ready to complete"
    // to let the component know to complete it
    if (args.newUncountedBinStatus && args.inventoryId) {
      const cycleCountInfoToChange: ShapedCycleCountInfo | undefined =
        state.cycleCounts.shapedCycleCountInfos.find(
          (ccInfo) => ccInfo.cycleCountId === args.cycleCountId
        );

      if (!cycleCountInfoToChange) return; // typescript does not know it won't be undefined

      // check to see if all uncounted bins are counted or about to be counted
      const cycleCountIsComplete =
        cycleCountInfoToChange.uncountedBinsInfo.reduce(
          (acc, uncountedBinInfo) =>
            (uncountedBinInfo.status === "counted" ||
              (uncountedBinInfo.uncountedBin.inventoryId === args.inventoryId &&
                args.newUncountedBinStatus === "counted")) &&
            acc,
          true
        );

      if (cycleCountIsComplete) {
        argsToSend = {
          ...argsToSend,
          newCycleCountStatus: "ready to complete"
        };
      }
    }

    dispatch<ModifyShapedCycleCountInfoAction>({
      type: "cycleCounts/MODIFY_SHAPED_CYCLE_COUNT_INFO",
      payload: argsToSend
    });
  };

// used by non-autostore cycle counts and needs repaired
export const fetchCycleCountBins =
  (cycleCountVariantIds: Guid[]): AsyncAppThunk =>
  async (dispatch) => {
    try {
      dispatch<FetchCycleCountInventoryRecordsAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS"
      });

      const response = await warehouseService.post<InventoryDto[]>(
        `/api/inventory/find`,
        { variantIds: cycleCountVariantIds }
      );

      dispatch<FetchCycleCountInventoryRecordsSuccessAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<FetchCycleCountInventoryRecordsFailureAction>({
          type: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_FAILURE"
          }
        });
      });
    }
  };

export const getCycleCountFrequencyByVid =
  (variantId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetCycleCountFrequencyAction>({
      type: "cycleCounts/GET_CYCLE_COUNT_FREQUENCY"
    });
    try {
      const response = await warehouseService.get<{
        variantId: Guid;
        frequency: number | null;
      }>(`/api/cycle-counts-overhaul/${variantId}/frequency`);

      dispatch<GetCycleCountFrequencySuccessAction>({
        type: "cycleCounts/GET_CYCLE_COUNT_FREQUENCY_SUCCESS",
        payload: { variantId, frequency: response.data?.frequency ?? null }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetCycleCountFrequencyFailureAction>({
          type: "cycleCounts/GET_CYCLE_COUNT_FREQUENCY_FAILURE",
          payload: { variantId }
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "cycleCounts/GET_CYCLE_COUNT_FREQUENCY_FAILURE"
          }
        });
      });
    }
  };

export const setSelectedCountingTasks =
  (selectedCycleCounts: CycleCountTaskDto[]): AppThunk =>
  (dispatch) => {
    dispatch<SetSelectedCountingTaskAction>({
      type: "cycleCounts/SET_SELECTED_COUNTING_TASKS",
      payload: selectedCycleCounts
    });
  };

export const clearSelectedCountingTasks = (): AppThunk => (dispatch) => {
  dispatch<ClearSelectedCountingTasksAction>({
    type: "cycleCounts/CLEAR_SELECTED_COUNTED_TASKS"
  });
};

export const postBinCount =
  (args: {
    verifiedCount: MeasuredValueDto;
    binId: Guid;
    inventoryId: Guid;
    cycleCountId: Guid;
  }): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<PostBinCountAction>({
      type: "cycleCounts/POST_BIN_COUNT"
    });

    let progressRecord = "";

    try {
      const { verifiedCount, binId, inventoryId, cycleCountId } = args;

      await warehouseService.post(
        `/api/cycle-counts/${cycleCountId}/bin-counts`,
        { binId, inventoryId, count: verifiedCount }
      );

      progressRecord = "posted bin count";

      // remove this after non-autostore cycle count is revised
      // refresh the cycle count
      dispatch<FetchCycleCountAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT",
        doNotNull: true
      });

      const response = await warehouseService.get<CycleCountV1Dto>(
        `/api/cycle-counts/${cycleCountId}`
      );

      const { data } = response;

      dispatch<FetchCycleCountSuccessAction>({
        type: "cycleCounts/FETCH_CYCLE_COUNT_SUCCESS",
        payload: data
      });
    } catch (err) {
      if (progressRecord === "posted bin count") {
        handleWarehouseError(err, (message) => {
          dispatch<FetchCycleCountFailureAction>({
            type: "cycleCounts/FETCH_CYCLE_COUNT_FAILURE"
          });
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error",
              origin: "cycleCounts/FETCH_CYCLE_COUNT_FAILURE"
            }
          });
        });
      } else {
        handleWarehouseError(err, (message) => {
          dispatch<PostBinCountFailureAction>({
            type: "cycleCounts/POST_BIN_COUNT_FAILURE"
          });
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error",
              origin: "cycleCounts/POST_BIN_COUNT_FAILURE"
            }
          });
        });
      }
    }
  };

export const completeCycleCounts =
  (cycleCountIds: Guid[], autostoreGridId?: Guid): AsyncAppThunk =>
  async (dispatch) => {
    const params: CompleteCycleCountRequest = {
      ...(autostoreGridId && { autostoreGridId })
    };
    try {
      await Promise.all(
        cycleCountIds.map((cycleCountId) =>
          warehouseService.post(
            `/api/cycle-counts/${cycleCountId}/completion`,
            params
          )
        )
      );

      dispatch<CompleteCycleCountSuccessAction>({
        type: "cycleCounts/COMPLETE_CYCLE_COUNT_SUCCESS",
        payload: { cycleCountIds }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<CompleteCycleCountFailureAction>({
          type: "cycleCounts/COMPLETE_CYCLE_COUNT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "cycleCounts/COMPLETE_CYCLE_COUNT_FAILURE"
          }
        });
      });
    }
  };

export const removeCycleCount =
  (cycleCountId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    try {
      await warehouseService.post(`/api/cycle-counts/${cycleCountId}/removal`);

      dispatch<RemoveCycleCountSuccessAction>({
        type: "cycleCounts/REMOVE_CYCLE_COUNT_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<RemoveCycleCountFailureAction>({
          type: "cycleCounts/REMOVE_CYCLE_COUNT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "cycleCounts/REMOVE_CYCLE_COUNT_FAILURE"
          }
        });
      });
    }
  };

export const clearCycleCountsState = (): AppThunk => (dispatch) => {
  dispatch<ClearCycleCountsStateAction>({
    type: "cycleCounts/CLEAR_CYCLE_COUNTS_STATE"
  });
};

export const clearCycleCountsMessage =
  (): AppThunk =>
  (dispatch): void => {
    dispatch<ClearMessageAction>({
      type: "cycleCounts/CLEAR_MESSAGE"
    });
  };
