import { useCallback } from "react";

import { useAppDispatch } from "~/app/store";

import {
  setPrinterError,
  setReadyToPrint,
  setStatusLoading
} from "~/redux/reducers/printer";
export const useGetPrinterStatus = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (printer: Device) => {
      dispatch(setStatusLoading(true));

      printer.sendThenReadAllAvailable(
        "~HQES",
        (text: string) => {
          let error;
          const isError = text.charAt(70) !== "0";
          const media = text.charAt(88);
          const head = text.charAt(87);
          const pause = text.charAt(84);
          if (!isError) {
            dispatch(setReadyToPrint(true));
          }
          if (media === "1") error = "Paper out";
          if (media === "2") error = "Ribbon Out";
          if (media === "4") error = "Media Door Open";
          if (media === "8") error = "Cutter Fault";
          if (head === "1") error = "Printhead Overheating";
          if (head === "2") error = "Motor Overheating";
          if (head === "4") error = "Printhead Fault";
          if (head === "8") error = "Incorrect Printhead";
          if (pause === "1") error = "Printer Paused";
          if (isError) {
            dispatch(setPrinterError(error || "Error: Unknown Error"));
          }
        },
        () => {
          dispatch(setPrinterError("Failed To Connect To Printer"));
        }
      );
    },
    [dispatch]
  );
};
