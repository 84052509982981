import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";

import { maybePluralize } from "~/lib/helpers";
import { RecurringScheduleDto } from "~/types/api";

export default function OrderCount(props: {
  recurringSchedule: RecurringScheduleDto;
}) {
  const { recurringSchedule } = props;
  const { orderTypes, interval, minMaxOrdersPerWave } = recurringSchedule;

  const minToMax = `${minMaxOrdersPerWave[0]} to ${minMaxOrdersPerWave[1]}`;
  const joinedOrderTypes = orderTypes.join(" & ");
  const batchedEvery = t("orders batched every");

  const getNumMinutes = () => {
    const parsedTime = dayjs(interval, "HH:mm:ss");
    const numMinutes = dayjs
      .duration({
        hours: parsedTime.hour(),
        minutes: parsedTime.minute(),
        seconds: parsedTime.second()
      })
      .asMinutes();
    return numMinutes;
  };

  const getMinutesForDisplay = () => {
    const numMinutes = getNumMinutes();
    return (
      <span>
        {getNumMinutes()} {t(maybePluralize("minute", numMinutes))}
      </span>
    );
  };

  return (
    <Box paddingX={3} paddingY={1.5}>
      <Typography>
        {minToMax}{" "}
        <Typography component="span" fontWeight="bold" display="inline">
          {joinedOrderTypes}
        </Typography>{" "}
        {batchedEvery} {getMinutesForDisplay()}
      </Typography>
    </Box>
  );
}
