import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { ProgressButton, RoundIconButton, useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { handleWarehouseError } from "~/api/warehouse";
import { useAppDispatch, useAppSelector } from "~/app/store";

import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import {
  completePick,
  outOfStockPickAutostore,
  splitPickAutostore,
  updatePickingState
} from "~/redux/actions";
import { addToteToBatch } from "~/redux/actions/batch";
import { selectAbleQtyToPick } from "~/redux/selectors/outOfStockSelectors";
import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";
import {
  useGetFocusedPickQuery,
  useGetTotesForBatchQuery
} from "~/redux/warehouse/autostorePicking.hooks";

import { closeAddToteModal } from "./addToteModal.slice";
import { setAbleToPickQty } from "./outOfStockDialog/outOfStockDialog.slice";

type AddToteModalBodyProps = {
  maxValue: number;
  pickQty: number;
  handleConfirm: (value: number | undefined) => Promise<void>;
};

export const AddToteModalBody = (props: AddToteModalBodyProps) => {
  const { maxValue, pickQty, handleConfirm } = props;
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [amountInNewTote, setAmountInNewTote] = useState<number>(maxValue);

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setAmountInNewTote((event.target.value && Number(event.target.value)) || 0);
  };

  const handleArrowClick = (increment: number): void => {
    // ensure the incremented field value change remains within the bounds of [1,maxMoveQty]
    setAmountInNewTote(
      Math.max(1, Math.min((amountInNewTote || 0) + increment, maxValue))
    );
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      <Typography
        sx={{ marginBottom: "15%", fontSize: "1.75em" }}
        data-testid="autostore-add-tote-dialog-content-text"
      >
        {`${t("how many units should be")} `}
        <span style={{ color: palette.info.main }}>{`${t("moved")}`}</span>
        {` ${t("into the new tote")}?`}
      </Typography>
      <Grid container sx={{ paddingLeft: "60px" }} style={{ marginTop: "-5%" }}>
        <Grid item xs={3}>
          <TextField
            id="quantity"
            style={{ fontSize: "2em" }}
            variant="outlined"
            value={amountInNewTote}
            onChange={handleQuantityChange}
            inputProps={{
              style: {
                width: "100px",
                height: "115px",
                fontSize: "6em",
                textAlign: "center"
              },
              "aria-label": "quantity-textbox"
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography
            sx={{
              fontSize: "13em",
              fontWeight: 200,
              textAlign: "center",
              marginTop: "-110%",
              paddingLeft: "40px"
            }}
          >
            /
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography
            sx={{
              fontSize: "6em",
              textAlign: "center",
              paddingTop: "10%",
              paddingLeft: "40px"
            }}
            id="pickAmount"
            data-testid="pickAmountTestId"
            aria-label="pick amount"
          >
            {pickQty}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ position: "absolute", right: 0 }}>
          <RoundIconButton
            sx={{
              color: "primary.main",
              background: "white",
              border: `2px solid`,
              borderColor: "primary.main",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: "secondary.main"
              }
            }}
            aria-label="increment"
            onClick={() => handleArrowClick(1)}
            size="large"
          >
            <ExpandLessIcon sx={{ fontSize: "27px" }} data-testid="up-arrow" />
          </RoundIconButton>
          <RoundIconButton
            onClick={() => handleArrowClick(-1)}
            sx={{
              color: "primary.main",
              background: "white",
              border: `2px solid`,
              borderColor: "primary.main",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: "secondary.main"
              },
              transform: "rotate(180deg)",
              fontSize: "4.5em",
              marginTop: "25%"
            }}
            aria-label="decrement"
            size="large"
          >
            <ExpandLessIcon
              sx={{ fontSize: "27px" }}
              data-testid="down-arrow"
            />
          </RoundIconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={6} />
        <Grid
          container
          justifyContent="flex-end"
          item
          xs={6}
          style={{ height: 65, marginTop: "-5%", marginBottom: "0.625em" }}
        >
          <ProgressButton
            data-testid="add-tote-confirm-button"
            aria-label="confirm-button"
            onClick={() => handleConfirm(amountInNewTote)}
            buttonSize="medium"
            color="primary"
          >
            {t("confirm")}
          </ProgressButton>
        </Grid>
      </Grid>
    </Box>
  );
};

type Props = {
  confirmPickCallback: (args: {
    toteId: string;
    pickId: string;
    forbidHandleMoveNextPickCall: boolean;
  }) => Promise<void>;
};

function AddToteModal(props: Props) {
  const { confirmPickCallback } = props;
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const dispatch = useAppDispatch();

  const selectedAutostoreGridId = useAppSelector(
    (state) => state.workstations.siteWorkstation?.autostoreGridId
  );
  const selectedPortId = useAppSelector(
    (state) => state.workstations.sitePortId
  );
  const workstationId = useAppSelector(selectWorkstationId);
  const ableToPickQty = useAppSelector(selectAbleQtyToPick);

  const isOpen = useAppSelector((state) => state.addToteModal.isOpen);

  const { data: focusedPick, isFetching: isFetchingFocusedPick } =
    useGetFocusedPickQuery(
      workstationId ? { workstationId: workstationId } : skipToken,
      {
        refetchOnMountOrArgChange: true
      }
    );

  const { toteContents, isFetchingToteContents } = useGetTotesForBatchQuery(
    focusedPick ? { batchId: focusedPick.batchId } : skipToken,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching }) => ({
        toteContents: data
          ?.flatMap((totesAtPosition) => totesAtPosition.totes)
          ?.find((tote) => tote.toteId === focusedPick?.assignedToteId)
          ?.contents,
        isFetchingToteContents: isFetching
      })
    }
  );

  const pickQty = ableToPickQty || focusedPick?.quantity.value || 0;

  // if the current tote has contents, then we can move the full amount.
  // else, we need to reserve at least one for the current tote.
  const maxMoveQty = focusedPick
    ? toteContents?.some((ct) => ct.quantity.value > 0)
      ? pickQty
      : pickQty - 1
    : 0;

  const [outOfStockCalled, setOutOfStockCalled] = useState(false);

  const isPartialOOSOccurredFromAddToteDialog = useRef(false);
  const isPartialOOSOccurredTimer = useRef(0);

  useEffect(() => {
    void (async () => {
      if (focusedPick?.status.toLowerCase() === "canceled") {
        isPartialOOSOccurredFromAddToteDialog.current = false;
        isPartialOOSOccurredTimer.current = 0;
        setOutOfStockCalled(false);
        dispatch(closeAddToteModal());
        await dispatch(updatePickingState({}));
        dispatch(setAbleToPickQty(null));
      }
    })();
  }, [dispatch, focusedPick]);

  // If 'out of stock' event isn't received longer than 4 seconds
  // We should close the modal, update picking state
  usePromiseInterval(
    async () => {
      if (isPartialOOSOccurredTimer.current > 4) {
        setOutOfStockCalled(false);
        dispatch(closeAddToteModal());
        await dispatch(updatePickingState({}));
        dispatch(setAbleToPickQty(null));
        isPartialOOSOccurredTimer.current = 0;
      } else {
        isPartialOOSOccurredTimer.current += 1;
      }
    },
    1000,
    outOfStockCalled
  );

  // TODO this needs to be refactored with more test coverage
  const handleConfirm = useCallback(
    async (value: number | undefined) => {
      // validation
      if (!value || value <= 0) {
        errorToast(`Some quantity must be moved to the new ${t("tote")}`);
        return;
      }

      if (value > maxMoveQty) {
        errorToast(
          `You may not move more than ${maxMoveQty} to the new ${t("tote")}`
        );
        return;
      }

      const addToteCallback = async (toteId: Guid | undefined) => {
        try {
          if (toteId && workstationId) {
            await dispatch(addToteToBatch(toteId, workstationId));
            await dispatch(updatePickingState({}));
          }
        } catch (err) {
          handleWarehouseError(err, (message) => errorToast(message));
        }
      };

      if (focusedPick && focusedPick.assignedToteId) {
        // This part should cover scenario when the picker wants first to do a partial oos and then
        // In the middle of the partial oos process wants to add new tote
        if (ableToPickQty) {
          const splitPickResponse = await dispatch(
            splitPickAutostore(focusedPick.pickId, {
              Value: ableToPickQty,
              Units: focusedPick.quantity.units
            })
          );
          if (
            ableToPickQty === value &&
            splitPickResponse &&
            selectedAutostoreGridId &&
            selectedPortId
          ) {
            await addToteCallback(splitPickResponse.splitPick.assignedToteId);
            await dispatch(
              outOfStockPickAutostore(
                splitPickResponse.remainingPick.pickId,
                focusedPick.binId || "",
                selectedAutostoreGridId,
                selectedPortId,
                "out of stock"
              )
            );
            isPartialOOSOccurredFromAddToteDialog.current = true;
            setOutOfStockCalled(true);
          } else if (
            splitPickResponse &&
            splitPickResponse.remainingPick.assignedToteId &&
            selectedAutostoreGridId &&
            selectedPortId
          ) {
            const splitActivePick = await dispatch(
              splitPickAutostore(splitPickResponse.splitPick.pickId, {
                Value: value,
                Units: focusedPick.quantity.units
              })
            );

            if (
              splitActivePick &&
              splitActivePick.remainingPick.assignedToteId
            ) {
              await dispatch(
                completePick(
                  splitActivePick.remainingPick.pickId,
                  selectedPortId,
                  selectedAutostoreGridId
                )
              );
              await addToteCallback(splitActivePick.splitPick.assignedToteId);
              await dispatch(
                outOfStockPickAutostore(
                  splitPickResponse.remainingPick.pickId,
                  focusedPick.binId || "",
                  selectedAutostoreGridId,
                  selectedPortId,
                  "out of stock"
                )
              );
              isPartialOOSOccurredFromAddToteDialog.current = true;
              setOutOfStockCalled(true);
            }
          }
          dispatch(closeAddToteModal());
        } else if (value < pickQty) {
          dispatch(closeAddToteModal());
          const splitPickResponse = await dispatch(
            splitPickAutostore(focusedPick.pickId, {
              Value: value,
              Units: focusedPick.quantity.units
            })
          );
          if (
            splitPickResponse &&
            splitPickResponse.remainingPick.assignedToteId
          ) {
            await confirmPickCallback({
              toteId: splitPickResponse.remainingPick.assignedToteId,
              pickId: splitPickResponse.remainingPick.pickId,
              forbidHandleMoveNextPickCall: true
            });
            await addToteCallback(splitPickResponse.splitPick.assignedToteId);
          }
        } else {
          dispatch(closeAddToteModal());
          await addToteCallback(focusedPick.assignedToteId);
        }
      }
    },
    [
      pickQty,
      ableToPickQty,
      confirmPickCallback,
      dispatch,
      errorToast,
      focusedPick,
      maxMoveQty,
      selectedAutostoreGridId,
      selectedPortId,
      t,
      workstationId
    ]
  );

  return (
    <Dialog
      key="autostore-add-tote-dialog"
      open={isOpen}
      onClose={() => dispatch(closeAddToteModal())}
      sx={{ padding: 0, margin: 0, fontFamily: "Roboto" }}
    >
      <DialogContent>
        <DialogContentText
          variant="h2"
          sx={{ color: "#000", fontWeight: "bold", fontSize: "2.3em" }}
          data-testid="autostore-add-tote-dialog-title-text"
        >
          {t("add tote")}
        </DialogContentText>
        {isFetchingToteContents || isFetchingFocusedPick ? (
          <CircularProgress />
        ) : (
          <AddToteModalBody
            pickQty={pickQty}
            maxValue={maxMoveQty}
            handleConfirm={handleConfirm}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AddToteModal;
