import { useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";

import { useEffect } from "react";

import { useAppSelector } from "~/app/store";
import { useDebounce } from "~/hooks/useDebounce";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetPutawayTasksWithPagingQuery } from "~/redux/warehouse/putAwayTasks.hooks";
import { PutAwayTaskSummaryDto } from "~/types/api";

export type SearchData = {
  searchInputValue?: string;
  scannedBarcodes: string[];
  offset: number;
};

const emptyArray: PutAwayTaskSummaryDto[] = [];

export const useGetPutawayTasks = (
  pageLimit: number,
  searchData: SearchData
) => {
  const { errorToast } = useToast();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const { data: selectedAutostoreGrid } = useGetAutostoreGridQuery(
    siteWorkstation?.autostoreGridId ?? skipToken
  );
  const debouncedSearchData = useDebounce(searchData, 200, searchData);

  const query = useGetPutawayTasksWithPagingQuery(
    {
      status: ["scheduled"],
      search: debouncedSearchData.searchInputValue,
      searches: debouncedSearchData.scannedBarcodes,
      binType: ["induction", "receiving"],
      limit: pageLimit,
      offset: (debouncedSearchData.offset - 1) * pageLimit,
      temperatureZone: selectedAutostoreGrid?.temperatureZone
        ? [selectedAutostoreGrid.temperatureZone.toLowerCase()]
        : [],
      searchSource: debouncedSearchData.scannedBarcodes.length
        ? "scanner"
        : undefined,
      workstationId: siteWorkstation?.id
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isLoading, error }) => ({
        putawayTasks: data?.putAwayTasks ?? emptyArray,
        putawayTasksLoading: isLoading,
        putawayTasksTotalCount: data?.putAwayTasksCount ?? 0,
        error
      })
    }
  );

  useEffect(() => {
    if (query.error) {
      errorToast(getMessageFromRtkError(query.error));
    }
  }, [query.error, errorToast]);

  return query;
};
