import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { warehouseService } from "~/api/warehouse";
import { useAppSelector } from "~/app/store";
import { isAutostoreView } from "~/lib/helpers";

import usePrevious from "./usePrevProps";

const autostoreGridPathNames = [
  "/autostore-pick",
  "/autostore-putaway",
  "/autostore-cyclecount",
  "/autostore-cyclecountv2",
  "/autostore-inventory-holds",
  "/bin-maintenance"
];

/** Checks to see if the user moved away from an autostore page, in which case close the workstation */
function useCloseWorkstationOnLocationChange() {
  const locationInfo = useLocation();
  const { pathname, search } = locationInfo;

  const prevPathname = usePrevious(pathname);
  const prevSearch = usePrevious(search);

  const currentWorkstation = useAppSelector(
    (state) => state.workstations.siteWorkstation
  );

  const isInterceptorReady = useAppSelector(
    (state) => state.login.isInterceptorReady
  );

  useEffect(() => {
    if (!isInterceptorReady) return;
    const prevLocationWasAutostore =
      (prevPathname && autostoreGridPathNames.includes(prevPathname)) ||
      (prevSearch && isAutostoreView(prevSearch));
    const locationHasChanged = pathname !== prevPathname;

    const isPickingToPreviousBinChange =
      pathname === "/picking-previous-bin" &&
      prevPathname === "/autostore-pick";

    const isPreviousBinChangeToPicking =
      pathname === "/autostore-pick" &&
      prevPathname === "/picking-previous-bin";

    const isPickingPreviousBinLocationChange =
      isPickingToPreviousBinChange || isPreviousBinChangeToPicking;

    const isInventoryToManageFlaggedBinsChange =
      pathname === "/inventoryv2/manage-flagged" &&
      prevPathname === "/inventoryv2";

    if (
      prevLocationWasAutostore &&
      locationHasChanged &&
      currentWorkstation &&
      !isPickingPreviousBinLocationChange &&
      !isInventoryToManageFlaggedBinsChange
    ) {
      // close workstation
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      warehouseService.post(
        `/api/workstations/grid/${currentWorkstation.autostoreGridId}/workstation/${currentWorkstation.id}/close-workstation`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search, isInterceptorReady]);
}

export default useCloseWorkstationOnLocationChange;
