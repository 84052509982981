import { Grid, Popover, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { ProgressButton } from "@qubit/autoparts";
import { MouseEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import {
  selectBinIsPresent,
  selectIsPickQuantityConfirmed,
  selectPickingState
} from "~/redux/selectors/autostoreSelectors";
import { AutostorePickingState } from "~/types/api";

type BarcodeInputProps = {
  matchEnteredUpcOrSku: (
    pickingState: AutostorePickingState | null,
    upc: string,
    callback: () => void
  ) => void;
};

export function BarcodeInput({ matchEnteredUpcOrSku }: BarcodeInputProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isUpcFieldEmptyErrorMessage, setIsUpcFieldEmptyErrorMessage] =
    useState<string | null>(null);
  const enteredUpc = useRef("");

  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);
  const binIsPresent = useAppSelector(selectBinIsPresent);
  const pickingState = useAppSelector(selectPickingState);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    enteredUpc.current = "";
    setAnchorEl(null);
    setOpen(false);
    setIsUpcFieldEmptyErrorMessage(null);
  };

  const handleUpcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enteredUpc.current = event.target.value;
    if (enteredUpc.current.length && isUpcFieldEmptyErrorMessage)
      setIsUpcFieldEmptyErrorMessage(null);
  };

  const handleConfirm = () => {
    if (!enteredUpc.current.length) {
      setIsUpcFieldEmptyErrorMessage("* Required");
      return;
    }
    const trimmedUpc = enteredUpc.current.trim();
    matchEnteredUpcOrSku(pickingState, trimmedUpc, handleClose);
  };

  return (
    <Box>
      <ProgressButton
        onClick={handleClick}
        disabled={isPickQuantityConfirmed || !binIsPresent}
        responsive
        variant="contained"
        color="secondary"
        size="large"
      >
        {t("enter product upc or sku")}
      </ProgressButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        disableScrollLock
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <TextField
              error={!!isUpcFieldEmptyErrorMessage}
              inputRef={(input: HTMLInputElement) => input && input.focus()}
              onChange={handleUpcChange}
              inputProps={{ style: { fontSize: "20px" } }}
              InputProps={{
                endAdornment: (
                  <ProgressButton
                    onClick={handleConfirm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {t("confirm")}
                  </ProgressButton>
                )
              }}
              helperText={isUpcFieldEmptyErrorMessage}
              FormHelperTextProps={{ style: { fontSize: "20px" } }}
            />
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
}
