import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PrinterState = {
  defaultPrinter: Device | null;
  statusLoading: boolean;
  readyToPrint: boolean;
  error: string | null;
};

const initialState: PrinterState = {
  defaultPrinter: null,
  statusLoading: false,
  readyToPrint: false,
  error: null
};

export const printerSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    setDefaultPrinter: (
      state: PrinterState,
      { payload }: PayloadAction<Device>
    ) => {
      state.defaultPrinter = payload;
      state.statusLoading = false;
    },
    setStatusLoading: (
      state: PrinterState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.statusLoading = payload;
      state.readyToPrint = false;
      state.error = null;
    },
    setReadyToPrint: (
      state: PrinterState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.readyToPrint = payload;
      state.statusLoading = false;
      state.error = null;
    },
    setPrinterError: (
      state: PrinterState,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
      state.statusLoading = false;
      state.readyToPrint = false;
    }
  }
});

export const {
  setPrinterError,
  setReadyToPrint,
  setDefaultPrinter,
  setStatusLoading
} = printerSlice.actions;
