import {
  TableHead,
  TableRow,
  Table,
  TableBody,
  Typography,
  Pagination,
  TableCell
} from "@mui/material";
import { formatUtcDate } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { configurationTypeToString } from "~/lib/helpers";
import {
  useConveyanceSubscription,
  useGridV2Subscription
} from "~/lib/signalr";
import {
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import { useGetFlaggedBinsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

export function CleaningBinTable() {
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );

  const workstationId = useAppSelector(selectWorkstationId);

  const { data: binCleaningData, refetch } = useGetFlaggedBinsQuery(
    workstationAutostoreGridId
      ? {
          autostoreGridId: workstationAutostoreGridId,
          workstationId
        }
      : skipToken
  );

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [waitingBinNumber, setWaitingBinNumber] = useState(-1);
  const pageSize = 12; // Number of items to show per page
  const binFilter = useAppSelector((state) => state.cleaningBin.binFilter);

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => setCurrentPage(newPage);

  useConveyanceSubscription((event) => {
    if (
      event.case === "BinScanned" &&
      event.binScanned.gridId === workstationAutostoreGridId
    ) {
      setWaitingBinNumber(+event.binScanned.binNumber);
    }
  });

  useGridV2Subscription((event) => {
    if (event.event.gridId !== workstationAutostoreGridId) {
      return;
    }
    switch (event.case) {
      case "BinModeChange":
        if (
          event.event.binId === waitingBinNumber &&
          event.event.previousBinMode === "X"
        ) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          refetch();
        }
    }
  });

  // function to filter the bins based on the bin ID
  const filteredBins = binCleaningData?.filter((binData) =>
    binData.binId.toString().startsWith(binFilter || "")
  );

  const startIndex = binFilter ? 0 : (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedBins = filteredBins?.slice(startIndex, endIndex);

  // if the current page has no bins after returning one to the grid, go back a page
  useEffect(() => {
    if (paginatedBins?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [paginatedBins, currentPage]);

  const isBool = (maybeBool?: boolean): maybeBool is boolean =>
    maybeBool !== undefined;

  const formatBoolOption = (maybeBool?: boolean) => {
    if (isBool(maybeBool)) return maybeBool ? "Yes" : "No";
    return "";
  };

  return (
    <>
      <Table sx={{ minWidth: 700, mr: 2 }} size="medium">
        <TableHead style={{ position: "sticky", top: 0 }}>
          <TableRow>
            <TableCell align="right">{t("bin id")}</TableCell>
            <TableCell align="right">{t("bin configuration")}</TableCell>
            <TableCell align="right">{t("flag")}</TableCell>
            <TableCell align="right">{t("is cleaned")}</TableCell>
            <TableCell align="right">{t("flagged date")}</TableCell>
            <TableCell align="right">{t("due date")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedBins && paginatedBins.length > 0 ? (
            paginatedBins.map((binData) => (
              <TableRow
                key={binData.binId}
                sx={{
                  "&>td": {
                    background: binData.isCleanedAndOutsideOfGrid
                      ? "lightGreen"
                      : undefined
                  }
                }}
              >
                <TableCell align="right">{binData.binId}</TableCell>
                <TableCell align="right">
                  {binData.binConfiguration?.configurationType
                    ? configurationTypeToString(
                        binData.binConfiguration?.configurationType
                      )
                    : ""}
                </TableCell>
                <TableCell align="right">
                  {binData.flags
                    ? binData.flags
                        .map(
                          (flag) => flag.charAt(0).toUpperCase() + flag.slice(1)
                        )
                        .join(" ")
                    : ""}
                </TableCell>
                <TableCell align="right">
                  {formatBoolOption(binData.isCleanedAndOutsideOfGrid)}
                </TableCell>
                <TableCell align="right">
                  {formatUtcDate(binData.lastFlaggedTime)}
                </TableCell>
                <TableCell align="right">
                  {formatUtcDate(binData.dueDate)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                <Typography>{t("bin not found in queue")}</Typography>
              </TableCell>
            </TableRow>
          )}
          {(!paginatedBins || paginatedBins.length === 0) && (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                <Typography>{t("no bins for cleaning")}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {filteredBins && filteredBins.length > pageSize && (
        <Pagination
          count={Math.ceil(filteredBins.length / pageSize)}
          page={currentPage}
          onChange={handleChangePage}
          shape="rounded"
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center"
          }}
        />
      )}
    </>
  );
}
