import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ProductInfoTypography } from "./styles";

type Props = {
  cycleCountFrequency: number | null;
};

export function CycleCountFrequency({ cycleCountFrequency }: Props) {
  const { t } = useTranslation();
  let frequencyDetails = t("n/a");
  if (cycleCountFrequency) {
    frequencyDetails = `${t("every")} ${String(cycleCountFrequency)} ${t(
      "days"
    )}`;
    if (cycleCountFrequency === 1) {
      frequencyDetails = t("every day");
    }
  }

  return (
    <div id="cycleCountFrequency">
      <ProductInfoTypography variant="overline">
        {t("cycle count frequency")}
      </ProductInfoTypography>
      <Typography
        gutterBottom
        variant="h6"
        component="h6"
        style={{ fontWeight: 400 }}
      >
        {frequencyDetails}
      </Typography>
    </div>
  );
}
