import { warehouseService } from "~/api/warehouse";
import { FulfillmentCenterDto } from "~/types/api";

export type BinSearchData = {
  autostore_bin_number: number;
  warehouse_bin_ids: Guid[];
  type?: "bin";
};

export async function getBinIds({
  searchText,
  usersClientId,
  usersFulfillmentCenter,
  usersAutostoreGridId
}: {
  searchText: string;
  usersClientId: string | null;
  usersFulfillmentCenter?: FulfillmentCenterDto | null;
  usersAutostoreGridId?: string;
}) {
  const binData = await warehouseService.get<BinSearchData[]>(
    `/api/bins/autostore/compartments/`,
    {
      params: {
        clientId: usersClientId,
        fulfillmentCenterId: usersFulfillmentCenter?.fulfillmentCenterId,
        gridId: usersAutostoreGridId,
        searchText
      }
    }
  );
  return binData.data;
}
