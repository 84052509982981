import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/system";

/**
 * Covers the product card with a yellow overlay as completionPercentage increases.
 *
 * This is a UniversalProductCard sub-component.
 */
export function CompletionOverlay({
  completionPercentage
}: {
  completionPercentage?: number;
}) {
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        position: "absolute",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box
        role="progress"
        aria-label="completion"
        sx={{
          position: "absolute",
          zIndex: 1000,
          opacity: 0.5,
          top: 0,
          left: 0,
          width: `${completionPercentage}%`,
          height: "100%",
          backgroundColor:
            completionPercentage === 100 ? "success.main" : "warning.main"
        }}
      />
      {completionPercentage === 100 && (
        <Box
          style={{
            height: 100,
            width: 100,
            position: "absolute",
            zIndex: 101
          }}
        >
          <CheckCircleIcon style={{ fill: "white", width: 100, height: 100 }} />
        </Box>
      )}
    </Box>
  );
}
