import * as Sentry from "@sentry/react";

import { isAxiosError } from "~/api/shared";

import { isFetchBaseQueryError } from "./isFetchBaseQueryError";

/**
 * Returns a status code for either Axios or fetch errors
 */
export const getStatusCodeFromError = (error: unknown): number => {
  const defaultError = 404;
  if (isFetchBaseQueryError(error) && typeof error.status === "number") {
    return error.status;
  } else if (isAxiosError(error)) {
    return error.status || defaultError;
  } else {
    Sentry.captureMessage(
      `Error was not known: ${JSON.stringify(error)}`,
      "warning"
    );
    return defaultError;
  }
};
