import { Box, useTheme } from "@mui/material";

type Props = {
  tempZone?: "ambient" | "chilled" | "frozen";
};

export function BulkBoxIcon({ tempZone }: Props) {
  const theme = useTheme();
  const tempColors = {
    ambient: theme.palette.ambient.main,
    chilled: theme.palette.chilled.main,
    frozen: theme.palette.frozen.main,
    none: "#000"
  };

  const color = tempColors[tempZone || "none"] || "primary.main";

  return (
    <Box
      role="img"
      aria-label="bulk box"
      width="2em"
      height="2em"
      border="2px solid"
      margin="0.1em"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderColor: color
      }}
    >
      B
    </Box>
  );
}
