import { Chip, Typography } from "@mui/material";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row
} from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { Inv_InventoryDateLabelSettings } from "~/config/clientConfig";
import { PutAwayTaskSummaryDto } from "~/types/api";

import NotesRow from "./NotesRow";
import { setSearchData } from "./autostorePutaway.slice";
import { useGetPutawayTasks } from "./useGetPutawayTasks";

type Props = {
  inv_inventoryDateLabel: Inv_InventoryDateLabelSettings;
  handleSelectRow: (row: PutAwayTaskSummaryDto) => void;
  pageLimit: 5 | 7 | 10;
  selectedRow?: PutAwayTaskSummaryDto | undefined;
};

export const PutawayTasksTable = (props: Props) => {
  const { pageLimit, selectedRow, handleSelectRow } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const searchData = useAppSelector(
    (state) => state.autostorePutaway.searchData
  );

  const {
    putawayTasks,
    putawayTasksLoading,
    putawayTasksTotalCount,
    error: putawayTasksError
  } = useGetPutawayTasks(pageLimit, searchData);

  const putaway_showScanFilters = useAppSelector(
    (state) => state.site.clientConfig.putaway_showScanFilters
  );

  const totalPageCount = putawayTasksTotalCount
    ? Math.ceil(putawayTasksTotalCount / pageLimit)
    : 0;

  const tableHeaderColNames = [
    t("product"),
    t("orderedQuantityAbbr"),
    t("remainingQuantityAbbr"),
    t("purchase order number abbr"),
    t("notes")
  ];

  const removeFilterCb = (barcode: string) => {
    dispatch(
      setSearchData({
        ...searchData,
        scannedBarcodes: searchData.scannedBarcodes.filter(
          (bc) => bc !== barcode
        )
      })
    );
  };

  return (
    <ASTableV2
      gridTemplateColumns={`1fr repeat(${tableHeaderColNames.length - 1}, auto)`}
      gridTemplateRows={`${putaway_showScanFilters && searchData.scannedBarcodes.length ? "50px 50px" : "50px"} repeat(${pageLimit}, 1fr)`}
      isEmpty={!putawayTasks.length}
      isLoading={putawayTasksLoading}
      isEmptyMessage="No Results"
      pagination={{
        page: searchData.offset,
        pageCount: putawayTasksError ? 1 : totalPageCount,
        setPage: (p) => dispatch(setSearchData({ ...searchData, offset: p }))
      }}
    >
      <ASTableV2Header>
        <ASTableV2Row>
          {tableHeaderColNames.map((h) => (
            <ASTableV2Cell key={h}>
              <Typography variant="tableBody">{h}</Typography>
            </ASTableV2Cell>
          ))}
        </ASTableV2Row>
        {putaway_showScanFilters && !!searchData.scannedBarcodes.length && (
          <ASTableV2Row>
            <ASTableV2Cell sx={{ gridColumn: "1 / -1" }}>
              {searchData.scannedBarcodes.map((filter) => (
                <Chip
                  key={filter}
                  label={filter}
                  color="primary"
                  onDelete={
                    removeFilterCb ? () => removeFilterCb(filter) : undefined
                  }
                  sx={{ mr: 2, fontSize: 14 }}
                />
              ))}
            </ASTableV2Cell>
          </ASTableV2Row>
        )}
      </ASTableV2Header>
      <ASTableV2Body>
        {!putawayTasksError &&
          !putawayTasksLoading &&
          putawayTasks.map((task) => (
            <ASTableV2Row
              key={task.putAwayTaskId}
              onClick={() => handleSelectRow(task)}
              selected={task.putAwayTaskId === selectedRow?.putAwayTaskId}
            >
              <ASTableV2Cell>
                <Typography variant="tableBody">{task.product.name}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {task.orderedQuantity.value.toString()}{" "}
                  {task.orderedQuantity.units}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {task.remaining.value.toString()} {task.remaining.units}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {task.purchaseOrderNumber || ""}
                </Typography>
              </ASTableV2Cell>

              <ASTableV2Cell>
                <NotesRow
                  receivingContainerCode={task.receivingContainerCode}
                />
              </ASTableV2Cell>
            </ASTableV2Row>
          ))}
      </ASTableV2Body>
    </ASTableV2>
  );
};
