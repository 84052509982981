import { useCallback } from "react";

import { useAppDispatch } from "~/app/store";
import {
  setDefaultPrinter,
  setPrinterError,
  setStatusLoading
} from "~/redux/reducers/printer";

export const useSetDefaultPrinter = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(setStatusLoading(true));
    BrowserPrint.getDefaultDevice(
      "printer",
      (printer) => {
        dispatch(setDefaultPrinter(printer));
      },
      () => {
        dispatch(setPrinterError("No default device found."));
      }
    );
  }, [dispatch]);
};
