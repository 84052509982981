import { InventoryMovementResponse } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const autostoreBinMaintenanceApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    startBinMaintenance: build.mutation<
      InventoryMovementResponse,
      { autostoreGridId: string; workstationId: string }
    >({
      query: ({ autostoreGridId, workstationId }) => ({
        url: `/autostore-bin-maintenance/grid/${autostoreGridId}/workstation/${workstationId}/start-bin-maintenance`,
        method: "POST"
      })
    })
  }),
  overrideExisting: false
});
