import { Box } from "@mui/material";
import { useToast, NavbarFilters } from "@qubit/autoparts";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectIsAdjustingBins,
  selectHoldTypeFilters,
  selectSelectedInventoryRows
} from "~/redux/selectors/inventorySelectors";

import { useGetAvailableHoldReasonCodesQuery } from "~/redux/warehouse/inventory.hooks";

import { ProductBinSearchInput } from "./ProductBinSearchInput";
import { setHoldTypeFilters } from "./inventory.slice";

/**
 * Inventory search components rendered in the navbar.
 *
 * Returns undefined if the user is adjusting bins.
 */
export function InventoryNavbarSearch() {
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const dispatch = useAppDispatch();
  const { data: holdReasonCodeOptions, error: holdReasonCodeError } =
    useGetAvailableHoldReasonCodesQuery();
  const isAdjustingBins = useAppSelector(selectIsAdjustingBins);
  const selectedRows = useAppSelector(selectSelectedInventoryRows);
  const selectedHoldTypeFilters = useAppSelector(selectHoldTypeFilters);

  useEffect(() => {
    if (holdReasonCodeError) {
      errorToast(getMessageFromRtkError(holdReasonCodeError));
    }
  }, [holdReasonCodeError, errorToast]);

  if (isAdjustingBins && selectedRows.length) {
    return undefined;
  }

  return (
    <Box
      id="inventoryLookup-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <ProductBinSearchInput />
      {holdReasonCodeOptions && (
        <NavbarFilters
          menuItemList={holdReasonCodeOptions.map((option) => ({
            translation: t(option),
            filter: option
          }))}
          selectedMenuItems={selectedHoldTypeFilters}
          menuItemsTitle={t("hold type")}
          onFilterData={(filters) => {
            dispatch(setHoldTypeFilters(filters));
          }}
          onResetFilters={() => {
            dispatch(setHoldTypeFilters([]));
          }}
        />
      )}
    </Box>
  );
}
