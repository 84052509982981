import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  useMediaQuery,
  Box,
  Chip,
  Typography,
  Grid,
  Divider,
  Paper
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import {
  mobileWidth,
  CircularProgressWithLabel,
  AutostoreTable
} from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import PickProgressBar from "~/features/pickBatches/PickProgressBar";
import { formatTime } from "~/lib/helpers";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetBatchesQuery } from "~/redux/warehouse/batches.hooks";
import { BatchSummaryDto } from "~/types/api";

import { TemperatureZoneHint } from "./TemperatureZoneHint";
import { selectBatch } from "./carts.slice";

export function CartTable() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(mobileWidth);
  const { t } = useTranslation();

  const [openBatches, setOpenBatches] = useState<string[]>([]);
  const selectedDate = useAppSelector((state) => state.carts.selectedDate);
  const selectedBatch = useAppSelector((state) => state.carts.selectedBatch);
  const scannedBarcode = useAppSelector((state) => state.carts.scannedBarcode);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  let startTimeStamp: Date | undefined;
  let endTimeStamp: Date | undefined;
  if (selectedDate) {
    startTimeStamp = new Date(selectedDate);
    endTimeStamp = new Date(selectedDate);
    startTimeStamp.setHours(0, 0, 0, 0);
    endTimeStamp.setHours(24, 0, 0, 0);
  }

  const { data, isFetching } = useGetBatchesQuery(
    {
      limit: 40,
      offset: 0,
      status: ["Picked", "Dropped"],
      startTimeStamp,
      endTimeStamp,
      ...(scannedBarcode && { toteId: scannedBarcode })
    },
    { refetchOnMountOrArgChange: true }
  );

  const batches = data ? data.batches : [];

  return (
    <AutostoreTable<BatchSummaryDto>
      widthOfCols={
        !isMobile
          ? ["5%", "23.75%", "23.75%", "23.75%", "23.75%"]
          : ["10%", "30%", "30%", "30%"]
      }
      headerColNames={[
        " ",
        t("zone"),
        t("batch"),
        !isMobile ? "status" : "",
        t("picks")
      ].filter((el) => el)}
      rowId={(row: BatchSummaryDto) => row.batchId}
      renderColumns={[
        (row: BatchSummaryDto) => {
          const { batchId } = row;

          const open = openBatches?.includes(batchId);

          return (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setOpenBatches(
                  open
                    ? openBatches?.filter((id) => id !== batchId)
                    : [...openBatches, batchId]
                );
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          );
        },
        (row: BatchSummaryDto) => {
          const { temperatureZones } = row;

          return <TemperatureZoneHint temperatureZones={temperatureZones} />;
        },
        (row: BatchSummaryDto) => {
          const { batchType, batchName } = row;

          return (
            <Grid>
              <Typography>{batchType}</Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ whiteSpace: "nowrap" }}
              >
                {batchName}
              </Typography>
            </Grid>
          );
        },
        !isMobile
          ? (row: BatchSummaryDto) => {
              const { status } = row;
              return <Chip color="primary" label={status} />;
            }
          : null,
        (row: BatchSummaryDto) => {
          return (
            <CircularProgressWithLabel
              completeCount={row.completedPicks}
              cancelledCount={row.canceledPicks}
              showBatchPicks
              totalCount={row.totalPicks}
              style={{ marginTop: 6 }}
            />
          );
        }
      ].filter((el) => el)}
      rowData={batches}
      selectedRows={selectedBatch ? [selectedBatch.batchId] : []}
      selectRowCallback={(row) => {
        if (selectedBatch?.batchId === row.batchId) {
          dispatch(selectBatch(null));
        } else {
          dispatch(selectBatch(row));
        }
      }}
      expandedRows={openBatches}
      renderExpandedRowsExpand={(row: BatchSummaryDto) => {
        return (
          <Collapse in timeout="auto" unmountOnExit>
            <Paper>
              <Box>
                <Divider orientation="horizontal" />
                {row.orderSummaries.map((orderSummary) => (
                  <Box
                    key={orderSummary.orderId}
                    sx={{
                      display: "flex",
                      padding: "20px",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography>{`${orderSummary.firstName} ${orderSummary.lastName}`}</Typography>
                    </Box>
                    <Box sx={{ width: "20%" }}>
                      <Typography>
                        {formatTime(
                          orderSummary.pickingEndTime,
                          fulfillmentCenter?.timeZone
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "100%" : "60%"
                        }}
                      >
                        <PickProgressBar
                          totalPicks={orderSummary.orderPicks}
                          completedPicks={orderSummary.orderCompletedPicks}
                          labelOutside
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Paper>
          </Collapse>
        );
      }}
      loading={isFetching}
      noResults={!isFetching && batches.length === 0}
    />
  );
}
