import { Box, Typography, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  mobileWidth,
  formatDate,
  ErrorPage,
  AutostoreTable
} from "@qubit/autoparts";
import moment from "moment-timezone";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { warehouseService } from "~/api/warehouse";
import { ToteStatus } from "~/api/warehouseTypes/cycleCount";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { dedupeBrand } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGetIncompleteCycleCountsQuery } from "~/redux/warehouse/cycleCount.hooks";
import { IncompleteCycleCountDto, CycleCountV1Dto } from "~/types/api";

export type CycleCountProps = { viewTitle?: ViewNameTranslation };

export const CycleCounts = (props: CycleCountProps) => {
  const { viewTitle } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);

  useNavbar({ viewTitle });

  const [page, setPage] = useState<number>(1);

  const limit = 10;
  const offset = (page - 1) * limit;

  const {
    incompleteCycleCounts,
    incompleteCycleCountsCount,
    loadingIncompleteCycleCounts,
    incompleteCycleCountsError
  } = useGetIncompleteCycleCountsQuery(
    { offset, limit },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching, error }) => ({
        incompleteCycleCounts: data?.items ?? [],
        incompleteCycleCountsCount: data?.totalCount,
        loadingIncompleteCycleCounts: isFetching,
        incompleteCycleCountsError: error
      })
    }
  );

  const totalPageCount = incompleteCycleCountsCount
    ? Math.ceil(incompleteCycleCountsCount / limit)
    : 0;

  const redirectToCycleCount = (cycleCountId: Guid) => {
    navigate(`/cycle-count/${cycleCountId}`);
  };

  const createCycleCount = async (productCycleId: Guid) => {
    const response = await warehouseService.post<CycleCountV1Dto>(
      "/api/cycle-counts",
      {
        ProductCycleId: productCycleId
      }
    );

    if (response.status === 200) {
      const { cycleCountId } = response.data;
      redirectToCycleCount(cycleCountId);
    } else {
      // handle error
    }
  };

  const overDueCycleCountIds = incompleteCycleCounts
    .filter((iCycleCount) => {
      if (!iCycleCount.cycleCountId || !iCycleCount.cycleEndsOn) return false;

      return !!moment(iCycleCount.cycleEndsOn).isBefore(moment());
    })
    .map((iCycleCount) => iCycleCount.cycleCountId || "");

  return incompleteCycleCountsError ? (
    <ErrorPage
      errorMessage={getMessageFromRtkError(incompleteCycleCountsError)}
    />
  ) : (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifiyContent: "center",
          alignItems: "center"
        }}
      >
        <Box sx={{ marginTop: "20px", width: "80%" }}>
          <AutostoreTable<IncompleteCycleCountDto>
            widthOfCols={
              !isMobile
                ? ["15%", "45%", "10%", "10%", "10%"]
                : ["50%", "20%", "30%"]
            }
            headerColNames={[
              !isMobile ? t("brand") : null,
              t("product"),
              !isMobile ? t("sku") : null,
              t("status"),
              t("due by")
            ].filter((el) => el)}
            rowId={(row: IncompleteCycleCountDto) => `${row.cycleCountId}`}
            renderColumns={[
              !isMobile
                ? (row: IncompleteCycleCountDto) => {
                    const { variant } = row;

                    const { brandName } = variant;

                    return brandName;
                  }
                : null,
              (row: IncompleteCycleCountDto) => {
                const { variant } = row;

                const { brandName, productName } = variant;

                // for mobile, combine brand and product into one cell
                return isMobile ? (
                  <div>
                    <div style={{ fontWeight: 600 }}>{brandName}</div>
                    <Box
                      sx={{
                        color: "text.secondary",
                        fontWeight: 200
                      }}
                    >
                      <Typography>
                        {dedupeBrand(productName, brandName)}
                      </Typography>
                    </Box>
                  </div>
                ) : (
                  <Box>
                    <Typography>{`${productName}`}</Typography>
                  </Box>
                );
              },
              !isMobile
                ? (row: IncompleteCycleCountDto) => {
                    const { variant } = row;

                    const { sku } = variant;

                    return (
                      <Box>
                        <Typography>{sku}</Typography>
                      </Box>
                    );
                  }
                : null,
              (row: IncompleteCycleCountDto) => {
                const { status } = row;

                return (
                  <Box>
                    <Typography>{`${t(status.toLowerCase() as Lowercase<ToteStatus>)}`}</Typography>
                  </Box>
                );
              },
              (row: IncompleteCycleCountDto) => {
                const { cycleEndsOn } = row;

                const dueBy = formatDate(cycleEndsOn);

                return (
                  <Typography
                    sx={{ whiteSpace: "nowrap" }}
                  >{`${dueBy}`}</Typography>
                );
              }
            ]}
            rowData={incompleteCycleCounts}
            alertRows={overDueCycleCountIds}
            selectRowCallback={(row: IncompleteCycleCountDto) => {
              if (row.status === "new" && row.productCycleId) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                createCycleCount(row.productCycleId);
              } else if (row.status === "open" && row.cycleCountId) {
                redirectToCycleCount(row.cycleCountId);
              }
            }}
            sort={(a, b) => {
              if (a.cycleEndsOn > b.cycleEndsOn) return 1;
              if (a.cycleEndsOn < b.cycleEndsOn) return -1;

              const statusRanks = ["open", "new"];

              if (statusRanks.indexOf(a.status) > statusRanks.indexOf(b.status))
                return 1;
              if (statusRanks.indexOf(a.status) < statusRanks.indexOf(b.status))
                return -1;

              return 0;
            }}
            loading={loadingIncompleteCycleCounts}
            noResults={
              !loadingIncompleteCycleCounts &&
              incompleteCycleCounts &&
              incompleteCycleCounts.length === 0
            }
          />
          <Grid
            item
            xs={12}
            style={{
              padding: "16px 0 48px",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {totalPageCount > 1 && (
              <Pagination
                count={totalPageCount}
                page={page}
                onChange={(_e, p) => {
                  setPage(p);
                  if (window.scrollTo) window.scrollTo(0, 0);
                }}
                shape="rounded"
              />
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
