import { AppBar, Stack, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { ErrorBoundary } from "@qubit/autoparts";
import { Outlet } from "react-router";

import autostoreDots from "~/assets/img/autostore-dots.png";

export function LoginLayout() {
  return (
    <Stack height="100vh" flexDirection="column">
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "flex-end", paddingRight: 1 }}>
          <img
            src={autostoreDots}
            alt="small logo"
            style={{
              height: "24px",
              background: "transparent"
            }}
          />
        </Toolbar>
      </AppBar>
      <Box flexGrow={1}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Stack>
  );
}
