import { Add } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  CardProps,
  Alert
} from "@mui/material";
import { CardMediaWithFallback } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "~/app/store";
import { useFlag } from "~/config/flags";
import { getVariantDisplayNameByDtoFe, isAutostoreView } from "~/lib/helpers";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useVariantsByIdQuery } from "~/redux/warehouse/variant.hooks";
import {
  FulfillmentCenterDto,
  InventoryReport,
  InventorySummaryDto
} from "~/types/api";

type InventoryProductCardProps = Omit<CardProps, "variant"> & {
  variantId?: Guid;
  isInventorySelected?: boolean;
  addClickCb: () => void;
  getInventoryBin?: () => void;
  getEmptyBin?: () => void;
  hideAddInventory?: boolean;
  inventoryReports: InventoryReport[] | null;
  usersFulfillmentCenter: FulfillmentCenterDto | null;
  isFetchingBin?: boolean;
  selectedInventorySummary?: InventorySummaryDto | null;
  initialCycleCountFrequency: number | null;
  isCreateInventoryButtonDisabled?: boolean;
};

export const InventoryProductCard = (props: InventoryProductCardProps) => {
  const {
    variantId,
    isInventorySelected,
    addClickCb,
    getInventoryBin,
    getEmptyBin,
    inventoryReports,
    usersFulfillmentCenter,
    isFetchingBin,
    selectedInventorySummary,
    initialCycleCountFrequency,
    isCreateInventoryButtonDisabled,
    hideAddInventory,
    ...rest
  } = props;

  const { variant, isVariantLoading, variantError } = useVariantsByIdQuery(
    variantId ? { variantIds: [variantId] } : skipToken,
    {
      selectFromResult: ({ data: variants, isLoading, error }) => {
        return {
          variant: variants?.at(0) || null,
          isVariantLoading: isLoading,
          variantError: error
        };
      }
    }
  );

  const { t } = useTranslation();

  const loadingInventoryReport = useAppSelector(
    (state) => state.inventory.loadingInventoryReport
  );

  const ProductInfoTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary
  }));

  const totalQty = inventoryReports?.reduce(
    (acc, inv) => {
      acc.qty += inv.quantity.value; // eslint-disable-line no-param-reassign
      acc.committed += inv.quantityCommitted.value; // eslint-disable-line no-param-reassign
      return acc;
    },
    { qty: 0, committed: 0 }
  );

  const loadingAnimation = variant ? "pulse" : false;

  const isLoadingOrNoVariant = isVariantLoading || !variant;

  const { search } = useLocation();

  const showCycleCountFrequencyCardDetails = useFlag().cycleCountFrequency;

  let frequencyDetails = t("n/a");
  if (initialCycleCountFrequency) {
    frequencyDetails = `${t("every")} ${String(initialCycleCountFrequency)} ${t(
      "days"
    )}`;
    if (initialCycleCountFrequency === 1) frequencyDetails = t("every day");
  }

  return (
    <Card {...rest}>
      {isLoadingOrNoVariant ? (
        <>
          {variantError && (
            <Alert severity="error">
              Error getting product details:{" "}
              {getMessageFromRtkError(variantError)}
            </Alert>
          )}
          <Skeleton
            animation={loadingAnimation}
            height={220}
            variant="rectangular"
            style={{ margin: "18px 0 10px" }}
          />
        </>
      ) : (
        <CardMediaWithFallback
          image={variant.imageFilename}
          title={
            getVariantDisplayNameByDtoFe(variant, usersFulfillmentCenter) ?? ""
          }
          sx={{ height: 220, backgroundSize: "contain", margin: "18px 0 10px" }}
        />
      )}
      <CardContent style={{ padding: "16px 22px" }}>
        <ProductInfoTypography variant="overline">
          {t("name")}
        </ProductInfoTypography>
        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          style={{ paddingRight: 80, fontWeight: 400 }}
        >
          {isLoadingOrNoVariant ? (
            <>
              <Skeleton animation={loadingAnimation} />
              <Skeleton animation={loadingAnimation} />
              <Skeleton animation={loadingAnimation} />
            </>
          ) : (
            getVariantDisplayNameByDtoFe(variant, usersFulfillmentCenter)
          )}
        </Typography>
        <ProductInfoTypography variant="overline">
          {t("brand")}
        </ProductInfoTypography>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 400 }}
        >
          {isLoadingOrNoVariant ? (
            <Skeleton animation={loadingAnimation} />
          ) : (
            variant?.brandName
          )}
        </Typography>
        <ProductInfoTypography variant="overline">
          {t("uom")}
        </ProductInfoTypography>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 400 }}
        >
          {isLoadingOrNoVariant ? (
            <Skeleton animation={loadingAnimation} />
          ) : (
            variant?.pickingUnits
          )}
        </Typography>
        {isLoadingOrNoVariant ? (
          <>
            <ProductInfoTypography variant="overline">
              UPCS
            </ProductInfoTypography>
            <Typography gutterBottom variant="h6" component="h3">
              <Skeleton animation={loadingAnimation} />
            </Typography>
          </>
        ) : (
          <ProductInfoTypography variant="overline">UPCS</ProductInfoTypography>
        )}
        {!isLoadingOrNoVariant &&
          (variant?.allUpcs.length > 1 ? (
            <Accordion style={{ boxShadow: "none", marginBottom: "0.35em" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="upcs-content"
                id="upcs-content"
                style={{
                  padding: 0,
                  margin: "5px 0 0 0",
                  minHeight: "20px",
                  height: "20px"
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h3"
                  style={{ padding: 0, margin: 0, fontWeight: 400 }}
                >
                  {variant?.allUpcs[0]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0, margin: 0 }}>
                {variant?.allUpcs.map((upc: string, i: number) => {
                  if (i > 0) {
                    return (
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h3"
                        key={upc}
                        sx={{ fontWeight: 400 }}
                      >
                        {upc}
                      </Typography>
                    );
                  }
                  return false;
                })}
              </AccordionDetails>
            </Accordion>
          ) : (
            <>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                sx={{ fontWeight: 400 }}
              >
                {variant?.allUpcs[0] || ""}
              </Typography>
            </>
          ))}
        <ProductInfoTypography variant="overline">
          {t("sku")}
        </ProductInfoTypography>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 400 }}
        >
          {isLoadingOrNoVariant ? (
            <Skeleton animation={loadingAnimation} />
          ) : (
            variant?.sku
          )}
        </Typography>
        <ProductInfoTypography variant="overline">
          {t("total quantity")}
        </ProductInfoTypography>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 400 }}
        >
          {loadingInventoryReport || !variant ? (
            <Skeleton animation={loadingAnimation} />
          ) : (
            totalQty?.qty
          )}
        </Typography>
        <ProductInfoTypography variant="overline">
          {t("total committed")}
        </ProductInfoTypography>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 400 }}
        >
          {loadingInventoryReport || !variant ? (
            <Skeleton animation={loadingAnimation} />
          ) : (
            totalQty?.committed
          )}
        </Typography>

        {showCycleCountFrequencyCardDetails && (
          <>
            <ProductInfoTypography variant="overline">
              {t("cycle count frequency")}
            </ProductInfoTypography>
            <Typography
              gutterBottom
              variant="h6"
              component="h3"
              sx={{ fontWeight: 400 }}
            >
              {loadingInventoryReport || !variant ? (
                <Skeleton animation={loadingAnimation} />
              ) : (
                frequencyDetails
              )}
            </Typography>
          </>
        )}
      </CardContent>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        {!hideAddInventory && getEmptyBin && (
          <CardActions>
            <Button
              sx={{ color: "primary.main" }}
              variant="text"
              startIcon={<Add />}
              size="large"
              onClick={() => {
                addClickCb();
                if (isAutostoreView(search)) getEmptyBin();
              }}
              disabled={
                isVariantLoading ||
                isFetchingBin ||
                (!isAutostoreView(search) &&
                  selectedInventorySummary?.binType === "autostore") ||
                isCreateInventoryButtonDisabled
              }
            >
              {t("create inventory")}
            </Button>
          </CardActions>
        )}
        {!hideAddInventory && getInventoryBin && (
          <CardActions>
            <Button
              sx={{ color: "primary" }}
              variant="text"
              startIcon={<Add />}
              size="large"
              onClick={() => {
                getInventoryBin();
              }}
              disabled={
                !isInventorySelected ||
                (isFetchingBin && isAutostoreView(search)) ||
                (!isAutostoreView(search) &&
                  selectedInventorySummary?.binType === "autostore")
              }
            >
              {t("adjust bin")}
            </Button>
          </CardActions>
        )}
      </div>
    </Card>
  );
};
