import { warehouseService, handleWarehouseError } from "~/api/warehouse";
import { PreviousBinInfo } from "~/api/warehouseTypes/previousBin";

import { AppThunk, AsyncAppThunk } from "~/app/store";

import { SignalRState } from "~/redux/reducers/autostore";
import {
  PickDto,
  InventoryDto,
  SplitPickResponse,
  CompletePickRequest,
  AutostorePickingState,
  AutostoreBinConfigurationDto,
  GetBinResponse,
  PickingStatePickInfoByTote,
  NextEmptyBinResponse,
  NextBinResponse,
  AutostorePortDto,
  ToteDto,
  PortSide,
  ShowPickToLightRequest,
  CloseBinRequest,
  OpenByContentRequest,
  GetBinByIdRequest,
  FlagBinRequest,
  StartPickingRequest,
  StartPickingOnWorkstationRequest,
  ClosePortRequest,
  PickingStateV2Tote
} from "~/types/api";

import { SetBatchWithCartNumberAction } from "./batch";
import { SetUserMessageAction } from "./site";

export const noBatchesLeftMessage = "No batches are available to pick";

// a function to sort the totes in the picking response
export const shapePickingState = (
  pickingState: AutostorePickingState
): AutostorePickingState => {
  const sortedTotes = pickingState.totes.slice().sort((tote1, tote2) => {
    if (tote1.totePosition && tote2.totePosition) {
      return tote1.totePosition - tote2.totePosition;
    }
    return 0;
  });

  const allPicksSortedByTotesPosition = sortedTotes
    .map((tote) => {
      return pickingState.allPicks.find(
        (picks) => picks.toteId === tote.toteId
      );
    })
    .filter((ps): ps is PickingStatePickInfoByTote => !!ps);

  return {
    ...pickingState,
    allPicks: allPicksSortedByTotesPosition,
    totes: sortedTotes
  };
};

export interface ResetBinAndPortData {
  type: "autostore/RESET_BIN_AND_PORT_DATA";
}

// SET SELECTED BIN CONFIGURATIONS

export interface SetSelectedBinConfigurations {
  type: "autostore/SET_SELECTED_BIN_CONFIGURATIONS";
  payload: number[];
}

export const setSelectedBinConfigurations =
  (configurations: number[]): AppThunk =>
  (dispatch) => {
    dispatch<SetSelectedBinConfigurations>({
      type: "autostore/SET_SELECTED_BIN_CONFIGURATIONS",
      payload: configurations
    });
  };

// NEXT EMPTY BIN

export interface NextEmptyBinAction {
  type: "autostore/NEXT_EMPTY_BIN";
}

export interface NextEmptyBinSuccessAction {
  type: "autostore/NEXT_EMPTY_BIN_SUCCESS";
  payload: {
    nextEmptyBin: NextEmptyBinResponse;
    portId: number;
  };
}

export const nextEmptyBin =
  (args?: { autostoreGridId?: Guid; portId?: number }): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<NextEmptyBinAction>({
      type: "autostore/NEXT_EMPTY_BIN"
    });

    const { autostoreGridId, portId } = args ?? {};

    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    try {
      if ((!autostoreGridId && !thisGridId) || (!portId && !thisPortId)) {
        throw new Error("Autostore grid or port are not set");
      }
      const gridId = autostoreGridId ?? thisGridId ?? "";
      const reqPortId = portId ?? thisPortId ?? "";

      const response = await warehouseService.get<NextEmptyBinResponse>(
        `/api/autostore-grid/${gridId}/port/${reqPortId}/next-empty-bin`
      );

      dispatch<NextEmptyBinSuccessAction>({
        type: "autostore/NEXT_EMPTY_BIN_SUCCESS",
        payload: {
          nextEmptyBin: response.data,
          portId: portId ?? thisPortId ?? 0
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

export interface SetCurrentEmptyBinAction {
  type: "autostore/SET_CURRENT_EMPTY_BIN";
  payload: NextEmptyBinResponse | null;
}

export const setCurrentEmptyBin =
  (bin: NextEmptyBinResponse | null): AppThunk =>
  (dispatch) => {
    dispatch<SetCurrentEmptyBinAction>({
      type: "autostore/SET_CURRENT_EMPTY_BIN",
      payload: bin
    });
  };

// CREATE BIN REQUEST

export const createBinRequest =
  ({
    autostoreGridId,
    binIds,
    categoryId
  }: {
    autostoreGridId?: Guid;
    binIds: number[];
    categoryId: number;
  }): AsyncAppThunk =>
  async (dispatch, getState) => {
    const requestTime = new Date();

    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;

    try {
      if (!autostoreGridId && !thisGridId) {
        throw new Error("Autostore grid or port are not set");
      }

      const gridId = autostoreGridId ?? thisGridId ?? "";

      await warehouseService.post(
        `api/autostore-grid/${gridId}/start-bin-request`,
        {
          bins: binIds,
          category: categoryId,
          requestTime
        }
      );
    } catch (err: unknown) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// GET NEXT BIN

export interface GetNextBinAction {
  type: "autostore/GET_NEXT_BIN";
}

export interface GetNextBinSuccessAction {
  type: "autostore/GET_NEXT_BIN_SUCCESS";
  payload: {
    nextBinInventory: InventoryDto[];
    portId: number;
    nextBinConfiguration: AutostoreBinConfigurationDto | null;
    nextBinResponse: NextBinResponse;
  };
}

export interface GetNextBinFailureAction {
  type: "autostore/GET_NEXT_BIN_FAILURE";
  payload?: number;
}

export interface SetNoMoreBinsAction {
  type: "autostore/SET_NO_MORE_BINS";
  payload: boolean;
}

export const getNextBin =
  (args?: {
    autostoreGridId?: Guid;
    portId?: number;
    shouldSoftFail?: boolean;
  }): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<GetNextBinAction>({
      type: "autostore/GET_NEXT_BIN"
    });

    const { autostoreGridId, portId, shouldSoftFail } = args ?? {};

    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    try {
      if ((!autostoreGridId && !thisGridId) || (!portId && !thisPortId)) {
        throw new Error("Autostore grid or port are not set");
      }

      const gridId = autostoreGridId ?? thisGridId ?? "";
      const reqPortId = portId ?? thisPortId ?? "";

      const response = await warehouseService.get<NextBinResponse>(
        `/api/autostore-grid/${gridId}/port/${reqPortId}/next-bin-with-bin-configuration`
      );
      dispatch<GetNextBinSuccessAction>({
        type: "autostore/GET_NEXT_BIN_SUCCESS",
        payload: {
          nextBinInventory: response?.data?.inventory,
          portId: portId ?? thisPortId ?? 0,
          nextBinConfiguration: response.data.binConfiguration ?? null,
          nextBinResponse: response.data
        }
      });
    } catch (err: unknown) {
      dispatch<GetNextBinFailureAction>({
        type: "autostore/GET_NEXT_BIN_FAILURE",
        payload: portId
      });
      dispatch<SetNoMoreBinsAction>({
        type: "autostore/SET_NO_MORE_BINS",
        payload: true
      });
      if (!shouldSoftFail) {
        handleWarehouseError(err, (message) => {
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error"
            }
          });
        });
      }
    }
  };

export const getNextBinWithConfig =
  (args?: { autostoreGridId?: Guid; portId?: number }): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<GetNextBinAction>({
      type: "autostore/GET_NEXT_BIN"
    });

    const { autostoreGridId, portId } = args ?? {};

    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    try {
      if ((!autostoreGridId && !thisGridId) || (!portId && !thisPortId)) {
        throw new Error("Autostore grid or port are not set");
      }

      const gridId = autostoreGridId ?? thisGridId ?? "";
      const reqPortId = portId ?? thisPortId ?? "";

      const response = await warehouseService.get<NextBinResponse>(
        `/api/autostore-grid/${gridId}/port/${reqPortId}/next-bin-with-bin-configuration`
      );

      dispatch<GetNextBinSuccessAction>({
        type: "autostore/GET_NEXT_BIN_SUCCESS",
        payload: {
          nextBinInventory: response?.data?.inventory,
          portId: portId ?? thisPortId ?? 0,
          nextBinConfiguration: response.data.binConfiguration ?? null,
          nextBinResponse: response.data
        }
      });
    } catch (err: unknown) {
      handleWarehouseError(err, (message) => {
        dispatch<GetNextBinFailureAction>({
          type: "autostore/GET_NEXT_BIN_FAILURE",
          payload: portId
        });
        if (
          message?.includes(
            "There is nothing left to pick or no appropriate bin found."
          )
        ) {
          dispatch<SetNoMoreBinsAction>({
            type: "autostore/SET_NO_MORE_BINS",
            payload: true
          });
        } else {
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error"
            }
          });
        }
      });
    }
  };

export const setNoMoreBins =
  (status: boolean): AppThunk =>
  (dispatch): void => {
    dispatch<SetNoMoreBinsAction>({
      type: "autostore/SET_NO_MORE_BINS",
      payload: status
    });
  };

// FETCH PORT STATUS

export type GetPortResponse = {
  portType: string;
  portId: number;
  mode: string;
  isReady: boolean;
  selectedBin: number;
  selectedTask: number;
};

export interface GetPortAction {
  type: "autostore/GET_PORT";
}
export interface GetPortSuccessAction {
  type: "autostore/GET_PORT_SUCCESS";
  payload: { getPortResponse: GetPortResponse; timestamp: Date };
}
export interface GetPortFailureAction {
  type: "autostore/GET_PORT_FAILURE";
}

export const fetchPortStatus =
  (args?: {
    portId?: number;
    autostoreGridId?: Guid;
  }): AsyncAppThunk<GetPortResponse | void> =>
  async (dispatch, getState) => {
    dispatch<GetPortAction>({
      type: "autostore/GET_PORT"
    });

    const state = getState();

    const thisGridId: string | undefined =
      args?.autostoreGridId ??
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      args?.portId ??
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    try {
      if (!thisGridId || !thisPortId) {
        throw new Error("Autostore grid or port are not set");
      }

      const response = await warehouseService.get<GetPortResponse>(
        `/api/autostore-grid/${thisGridId ?? ""}/port/${thisPortId ?? ""}`
      );
      dispatch<GetPortSuccessAction>({
        type: "autostore/GET_PORT_SUCCESS",
        payload: { getPortResponse: response.data, timestamp: new Date() }
      });
      // eslint-disable-next-line consistent-return
      return response.data;
    } catch (err) {
      return handleWarehouseError(err, (message) => {
        dispatch<GetPortFailureAction>({
          type: "autostore/GET_PORT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// SET PORT STATUS

export const setPortStatus =
  (portStatus: GetPortResponse): AppThunk =>
  (dispatch) => {
    dispatch<GetPortSuccessAction>({
      type: "autostore/GET_PORT_SUCCESS",
      payload: { getPortResponse: portStatus, timestamp: new Date() }
    });
  };

// FETCH PICKS

export const fetchPicks =
  (orderId: Guid): AsyncAppThunk<PickDto[] | null> =>
  async (dispatch) => {
    try {
      const response = await warehouseService.get<PickDto[]>(
        `/api/picks?orderId=${orderId}`
      );
      return response.data;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });

      return null;
    }
  };

// CLOSE WORKSTATION
export interface CloseWorkstation {
  type: "autostore/CLOSE_WORKSTATION";
}

export interface CloseWorkstationSuccessAction {
  type: "autostore/CLOSE_WORKSTATION_SUCCESS";
}

export interface CloseWorkstationFailureAction {
  type: "autostore/CLOSE_WORKSTATION_FAILURE";
}

export const closeWorkstation =
  (): AsyncAppThunk => async (dispatch, getState) => {
    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;

    const thisWorkstationId: string | undefined =
      state.workstations.siteWorkstation?.id;

    try {
      if (!thisGridId || !thisWorkstationId) {
        throw new Error("Autostore grid or workstation are not set");
      }

      await warehouseService.post(
        `/api/workstations/grid/${thisGridId}/workstation/${thisWorkstationId}/close-workstation`
      );

      dispatch<CloseWorkstationSuccessAction>({
        type: "autostore/CLOSE_WORKSTATION_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// CLOSE PORT

export interface ClosePort {
  type: "autostore/CLOSE_PORT";
}

export interface ClosePortSuccessAction {
  type: "autostore/CLOSE_PORT_SUCCESS";
  payload: {
    portState: GetPortResponse;
    doNotClear?: boolean;
  };
}

export interface ClosePortFailureAction {
  type: "autostore/CLOSE_PORT_FAILURE";
}

export const closePort =
  (args?: {
    autostoreGridId?: Guid;
    portId?: number;
    isFlushPortBinEnabled?: boolean;
    doNotClear?: boolean;
  }): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<ClosePort>({
      type: "autostore/CLOSE_PORT"
    });

    const { autostoreGridId, portId, isFlushPortBinEnabled, doNotClear } =
      args ?? {};

    const state = getState();

    const thisGridId: string | undefined =
      autostoreGridId ?? state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      portId ??
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    try {
      if (!thisGridId || !thisPortId) {
        throw new Error("Autostore grid or port are not set");
      }

      const gridId = thisGridId ?? "";
      const reqPortId = thisPortId ?? "";

      const request: ClosePortRequest | null = isFlushPortBinEnabled
        ? {
            isFlushPortBinEnabled
          }
        : null;

      await warehouseService({
        method: "post",
        url: `/api/autostore-grid/${gridId}/port/${reqPortId}/close`,
        data: request
      });

      const response = await warehouseService.get<GetPortResponse>(
        `/api/autostore-grid/${thisGridId ?? ""}/port/${thisPortId ?? ""}`
      );

      dispatch<ClosePortSuccessAction>({
        type: "autostore/CLOSE_PORT_SUCCESS",
        payload: {
          portState: response.data,
          doNotClear
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// OPEN PORT

export interface OpenPort {
  type: "autostore/OPEN_PORT";
}

export interface OpenPortSuccessAction {
  type: "autostore/OPEN_PORT_SUCCESS";
  payload: Pick<AutostorePortDto, "portId">;
}

export interface OpenPortFailureAction {
  type: "autostore/OPEN_PORT_FAILURE";
}

export const openPort =
  ({
    autostoreGridId,
    portId,
    categoryId
  }: {
    autostoreGridId?: Guid;
    portId?: number;
    categoryId: number;
  }): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<OpenPort>({
      type: "autostore/OPEN_PORT"
    });

    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    try {
      if ((!autostoreGridId && !thisGridId) || (!portId && !thisPortId)) {
        throw new Error("Autostore grid or port are not set");
      }

      const gridId = autostoreGridId ?? thisGridId ?? "";
      const reqPortId = portId ?? thisPortId ?? "";

      await warehouseService.post(
        `/api/autostore-grid/${gridId}/port/${reqPortId}/category/${categoryId}/open`
      );

      dispatch<OpenPortSuccessAction>({
        type: "autostore/OPEN_PORT_SUCCESS",
        payload: {
          portId: portId ?? thisPortId ?? 0
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<OpenPortFailureAction>({
          type: "autostore/OPEN_PORT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// OPEN AUTOSTORE BIN

export interface OpenBinSuccessAction {
  type: "autostore/OPEN_AUTOSTORE_BIN_SUCCESS";
  payload: AutostoreBinConfigurationDto | null;
}

export interface OpenBinFailureAction {
  type: "autostore/OPEN_AUTOSTORE_BIN_FAILURE";
}

export interface OpenAutostoreBinAction {
  type: "autostore/OPEN_AUTOSTORE_BIN";
}

export const openAutostoreBin =
  ({
    autostoreGridId,
    portId,
    binId,
    clobberPortStatus
  }: {
    autostoreGridId?: Guid;
    portId?: number;
    binId: number;
    clobberPortStatus?: boolean;
  }): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<OpenAutostoreBinAction>({
      type: "autostore/OPEN_AUTOSTORE_BIN"
    });

    const state = getState();
    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    try {
      if ((!autostoreGridId && !thisGridId) || (!portId && !thisPortId)) {
        throw new Error("Autostore grid or port are not set");
      }

      const request: GetBinByIdRequest = {
        clobberPortStatus: clobberPortStatus ?? true
      };

      const gridId = autostoreGridId ?? thisGridId ?? "";
      const reqPortId = portId ?? thisPortId ?? "";

      const response = await warehouseService.post<GetBinResponse>(
        `/api/autostore-grid/${gridId}/port/${reqPortId}/bin/${binId}/open`,
        request
      );

      dispatch<OpenBinSuccessAction>({
        type: "autostore/OPEN_AUTOSTORE_BIN_SUCCESS",
        payload: response.data?.binConfiguration ?? null
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// OPEN PORT BY CONTENT

export interface OpenPortByContentCode {
  type: "autostore/OPEN_PORT_BY_CONTENT_CODE";
}

export interface OpenPortByContentCodeSuccessAction {
  type: "autostore/OPEN_PORT_BY_CONTENT_CODE_SUCCESS";
  payload: Pick<AutostorePortDto, "portId">;
}

export interface OpenPortByContentCodeFailureAction {
  type: "autostore/OPEN_PORT_BY_CONTENT_CODE_FAILURE";
}

export const openPortByContentCode =
  ({
    autostoreGridId,
    portId,
    defaultBinConfigurations
  }: {
    autostoreGridId?: Guid;
    portId?: number;
    defaultBinConfigurations: AutostoreBinConfigurationDto[];
  }): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<OpenPortByContentCode>({
      type: "autostore/OPEN_PORT_BY_CONTENT_CODE"
    });

    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    // open port for default configurations if no one is selected from layout selection
    try {
      if ((!autostoreGridId && !thisGridId) || (!portId && !thisPortId)) {
        throw new Error("Autostore grid or port are not set");
      }

      const configurationTypes =
        state?.autostore?.selectedBinConfigurations?.length > 0
          ? state.autostore.selectedBinConfigurations
          : defaultBinConfigurations?.map(
              (binConfig) => binConfig.configurationType
            );

      const request: OpenByContentRequest = { configurationTypes };

      const gridId = autostoreGridId ?? thisGridId ?? "";
      const reqPortId = portId ?? thisPortId ?? "";

      await warehouseService.post<void>(
        `/api/autostore-grid/${gridId}/port/${reqPortId}/specific-content/open`,
        request
      );

      dispatch<OpenPortByContentCodeSuccessAction>({
        type: "autostore/OPEN_PORT_BY_CONTENT_CODE_SUCCESS",
        payload: { portId: portId ?? thisPortId ?? 0 }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<OpenPortByContentCodeFailureAction>({
          type: "autostore/OPEN_PORT_BY_CONTENT_CODE_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "autostore/OPEN_PORT_BY_CONTENT_CODE_FAILURE"
          }
        });
      });
    }
  };

// SET PREVIOUS BIN

export interface SetPreviousBinAction {
  type: "autostore/SET_PREVIOUS_BIN";
  payload: PreviousBinInfo | null;
}

export const setPreviousAutostoreBin =
  (args: {
    binId: number | undefined;
    binGuid: string | undefined;
    autostoreCompartmentNumber: number | undefined;
    inventoryId: string | undefined;
  }): AppThunk =>
  (dispatch) => {
    const { binId, binGuid, autostoreCompartmentNumber, inventoryId } = args;

    if (binId && binGuid) {
      dispatch<SetPreviousBinAction>({
        type: "autostore/SET_PREVIOUS_BIN",
        payload: {
          autostoreBinId: binId,
          autostoreBinGuid: binGuid,
          autostoreCompartmentNumber,
          inventoryId
        }
      });
    }
  };

// SUSPEND BATCH

export interface SuspendBatchAction {
  type: "batch/SUSPEND_BATCH";
}

export interface SuspendBatchSuccessAction {
  type: "batch/SUSPEND_BATCH_SUCCESS";
}

export interface SuspendBatchFailureAction {
  type: "batch/SUSPEND_BATCH_FAILURE";
}

export const suspendBatch =
  (batchId: Guid, autostorePortId: number): AsyncAppThunk =>
  async (dispatch) => {
    try {
      dispatch<SuspendBatchAction>({
        type: "batch/SUSPEND_BATCH"
      });

      await warehouseService.post<boolean>(`/api/batches/${batchId}/suspend`, {
        AutostorePortId: autostorePortId
      });

      dispatch<SuspendBatchSuccessAction>({
        type: "batch/SUSPEND_BATCH_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SuspendBatchFailureAction>({
          type: "batch/SUSPEND_BATCH_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// REQUEST NEXT PICKING BIN
// request next picking bin for the current task group
// returns picking state

export interface FetchNextPickingBin {
  type: "autostore/FETCH_NEXT_PICKING_BIN";
}

export interface FetchNextPickingBinSuccessAction {
  type: "autostore/FETCH_NEXT_PICKING_BIN_SUCCESS";
  payload: boolean;
}

export interface FetchNextPickingBinFailureAction {
  type: "autostore/FETCH_NEXT_PICKING_BIN_FAILURE";
  payload: string | null;
}

export interface NextPickingBinResponse {
  allPicksComplete: boolean;
}

type OldNextPickingBinResponse = {
  nextPickingBinResponse: {
    picksForSelectedTask: PickDto[];
    batchId: Guid;
    batchName: string;
    allPicksComplete: boolean;
    binConfiguration: AutostoreBinConfigurationDto;
  };
  pickingState: AutostorePickingState;
};

export const fetchNextPickBin =
  (args?: { portId?: number }): AsyncAppThunk<boolean | null> =>
  async (dispatch, getState) => {
    dispatch<FetchNextPickingBin>({
      type: "autostore/FETCH_NEXT_PICKING_BIN"
    });

    const state = getState();

    const { portId: overridePortId } = args ?? {};

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      overridePortId ??
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;
    const thisWorkstationId: string | undefined =
      state.workstations.siteWorkstation?.id;
    const thisDeviceId: string | undefined =
      state.workstations.siteWorkstation?.deviceId;

    try {
      if (!thisGridId || !thisPortId) {
        throw new Error("Autostore grid or port are not set");
      }

      const response = await warehouseService.get<
        OldNextPickingBinResponse | NextPickingBinResponse
      >(
        `/api/autostore-grid/${thisGridId}/port/${thisPortId}/next-picking-bin`,
        { params: { workstationId: thisWorkstationId, deviceId: thisDeviceId } }
      );

      dispatch<FetchNextPickingBinSuccessAction>({
        type: "autostore/FETCH_NEXT_PICKING_BIN_SUCCESS",
        payload:
          "nextPickingBinResponse" in response.data
            ? response.data.nextPickingBinResponse.allPicksComplete
            : response.data.allPicksComplete
      });

      if ("nextPickingBinResponse" in response.data) {
        return response.data.nextPickingBinResponse.allPicksComplete;
      }
      return response.data.allPicksComplete;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        const messageIncludeSpecificText = message?.includes(
          "There is nothing left to pick or no appropriate bin found."
        );
        dispatch<FetchNextPickingBinFailureAction>({
          type: "autostore/FETCH_NEXT_PICKING_BIN_FAILURE",
          payload: messageIncludeSpecificText ? noBatchesLeftMessage : message
        });
        if (messageIncludeSpecificText) {
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: noBatchesLeftMessage,
              severity: "warning"
            }
          });
        } else {
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error"
            }
          });
        }
      });
      return null;
    }
  };

// ERASE NEXT PICKING BIN FAILURE ERROR MESSAGE
export interface RestartNextPickingBinFailureMessageAction {
  type: "autostore/RESTART_NEXT_PICKING_BIN_MESSAGE_FAILURE";
}

export const restartNextPickingBinFailureMessage =
  (): AppThunk => (dispatch) => {
    dispatch<RestartNextPickingBinFailureMessageAction>({
      type: "autostore/RESTART_NEXT_PICKING_BIN_MESSAGE_FAILURE"
    });
  };

// SET IS CART NUMBER CONFIRMED

export interface SetIsPreppedCartConfirmed {
  type: "autostore/SET_IS_PREPPED_CART_CONFIRMED";
  payload: boolean;
}

export const setCartNumberConfirmed =
  (cartNumberConfirmed: boolean): AppThunk =>
  (dispatch) => {
    dispatch<SetIsPreppedCartConfirmed>({
      type: "autostore/SET_IS_PREPPED_CART_CONFIRMED",
      payload: cartNumberConfirmed
    });
    if (!cartNumberConfirmed) {
      dispatch<SetBatchWithCartNumberAction>({
        type: "batch/SET_BATCH_WITH_CART_NUMBER",
        payload: null
      });
    }
  };

// COMPLETE PICK

export interface CompletePickSuccessAction {
  type: "autostore/COMPLETE_PICK_SUCCESS";
}

export interface CompletePickFailureAction {
  type: "autostore/COMPLETE_PICK_FAILURE";
}

export const completePick =
  (
    pickId: Guid,
    portId: number | null,
    gridId: Guid | null | undefined,
    fulfilled?: boolean
  ): AsyncAppThunk =>
  async (dispatch) => {
    const request: CompletePickRequest = {
      fulfilled: fulfilled !== undefined ? fulfilled : true,
      scannedPick: {
        pickId,
        scannedUpcs: []
      },
      portId: portId ?? undefined,
      gridId: gridId ?? undefined
    };
    try {
      await warehouseService.post(`/api/picks/complete`, request);

      dispatch<CompletePickSuccessAction>({
        type: "autostore/COMPLETE_PICK_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<CompletePickFailureAction>({
          type: "autostore/COMPLETE_PICK_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// CLOSE BIN

export const closeBin =
  ({
    autostoreGridId,
    portId,
    binId,
    taskId
  }: {
    autostoreGridId?: Guid;
    portId?: number;
    binId: string | number;
    taskId?: number;
  }): AsyncAppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const thisGridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;
    const thisPortId: number | undefined =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;
    try {
      if ((!autostoreGridId && !thisGridId) || (!portId && !thisPortId)) {
        throw new Error("Autostore grid or port are not set");
      }

      const request: CloseBinRequest = {
        taskId: taskId !== 0 ? taskId : undefined
      };

      const gridId = autostoreGridId ?? thisGridId ?? "";
      const reqPortId = portId ?? thisPortId ?? "";

      await warehouseService.post(
        `/api/autostore-grid/${gridId}/port/${reqPortId}/bin/${binId}/close`,
        request
      );
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// SET SELECTED AUTOSTORE BIN ID

export interface SetSelectedAutostoreBinIdAction {
  type: "autostore/SET_SELECTED_AUTOSTORE_BIN_ID";
  payload: number | null;
}

export const setSelectedAutostoreBinId =
  (id: number | null): AppThunk =>
  (dispatch) => {
    dispatch<SetSelectedAutostoreBinIdAction>({
      type: "autostore/SET_SELECTED_AUTOSTORE_BIN_ID",
      payload: id
    });
  };

// SPLIT PICK

export interface SplitAutostoreAction {
  type: "autostore/SPLIT_AUTOSTORE";
}

export interface SplitAutostoreSuccessAction {
  type: "autostore/SPLIT_AUTOSTORE_SUCCESS";
  payload: SplitPickResponse;
}

export interface SplitAutostoreFailureAction {
  type: "autostore/SPLIT_AUTOSTORE_FAILURE";
}

export const splitPickAutostore =
  (
    pickId: string,
    splitQuantity: { Value: number; Units: string }
  ): AsyncAppThunk<{ remainingPick: PickDto; splitPick: PickDto } | null> =>
  async (dispatch) => {
    try {
      dispatch<SplitAutostoreAction>({
        type: "autostore/SPLIT_AUTOSTORE"
      });

      const splitPickResponse = await warehouseService.post<SplitPickResponse>(
        "/api/picks/split",
        {
          pickId,
          splitQuantity
        }
      );

      const payload = {
        remainingPick: splitPickResponse.data.remainingPick,
        splitPick: splitPickResponse.data.splitPick
      };

      dispatch<SplitAutostoreSuccessAction>({
        type: "autostore/SPLIT_AUTOSTORE_SUCCESS",
        payload
      });
      return payload;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SplitAutostoreFailureAction>({
          type: "autostore/SPLIT_AUTOSTORE_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
      return null;
    }
  };

// RESTART SPLIT PICK RESPONSE

export interface RestartSplitAutostoreAction {
  type: "autostore/RESTART_SPLIT_AUTOSTORE";
}

export const restartSplitAutostore =
  (): AppThunk =>
  (dispatch): void => {
    dispatch<RestartSplitAutostoreAction>({
      type: "autostore/RESTART_SPLIT_AUTOSTORE"
    });
  };

// OUT OF STOCK PICK AUTOSTORE

export interface AutostoreOutOfStockAction {
  type: "autostore/AUTOSTORE_OUT_OF_STOCK";
}

export interface AutostoreOutOfStockSuccessAction {
  type: "autostore/AUTOSTORE_OUT_OF_STOCK_SUCCESS";
}

export interface AutostoreOutOfStockFailureAction {
  type: "autostore/AUTOSTORE_OUT_OF_STOCK_FAILURE";
}

export const outOfStockPickAutostore =
  (
    pickId: string,
    pickBinId: string,
    autostoreGridId: string,
    autostorePortId: number,
    hold?: string
  ): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<AutostoreOutOfStockAction>({
      type: "autostore/AUTOSTORE_OUT_OF_STOCK"
    });
    try {
      const request: {
        pickId: string;
        pickBinId: string;
        autostoreGridId: string;
        autostorePortId: number;
        hold?: string;
      } = {
        pickId,
        pickBinId,
        autostoreGridId,
        autostorePortId,
        hold
      };

      await warehouseService.post<null>("/api/picks/out-of-stock", request);

      dispatch<AutostoreOutOfStockSuccessAction>({
        type: "autostore/AUTOSTORE_OUT_OF_STOCK_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<AutostoreOutOfStockFailureAction>({
          type: "autostore/AUTOSTORE_OUT_OF_STOCK_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// UPDATE PICKING STATE FROM EVENT
export interface UpdatePickingStateFromEventAction {
  type: "autostore/UPDATE_PICKING_STATE_FROM_EVENT";
  payload: {
    portId: number;
    pickingState: AutostorePickingState;
    timestamp: Date;
  };
}

export const updatePickingStateFromEvent =
  (portId: number, pickingState: AutostorePickingState): AppThunk =>
  (dispatch) => {
    dispatch<UpdatePickingStateFromEventAction>({
      type: "autostore/UPDATE_PICKING_STATE_FROM_EVENT",
      payload: {
        portId,
        pickingState: shapePickingState(pickingState),
        timestamp: new Date()
      }
    });
  };

// CLEAR PICKING STATE

export interface ClearPickingStateAction {
  type: "autostore/CLEAR_PICKING_STATE";
}

export const clearPickingState = (): AppThunk => (dispatch) => {
  dispatch<ClearPickingStateAction>({
    type: "autostore/CLEAR_PICKING_STATE"
  });
};

// SET SIGNAL R

export interface SetSignalrAction {
  type: "autostore/SET_SIGNALR";
  payload: { state: SignalRState };
}

export const setSignalRState =
  (value: { state: SignalRState }): AppThunk =>
  (dispatch) => {
    dispatch<SetSignalrAction>({
      type: "autostore/SET_SIGNALR",
      payload: value
    });
  };

// UPDATE PICKING STATE

export interface UpdatePickingStateAction {
  type: "autostore/UPDATE_PICKING_STATE";
}

export interface UpdatePickingStateSuccessAction {
  type: "autostore/UPDATE_PICKING_STATE_SUCCESS";
  payload: {
    portId: number;
    pickingState: AutostorePickingState;
    timestamp: Date;
  };
}

export interface UpdatePickingStateFailureAction {
  type: "autostore/UPDATE_PICKING_STATE_FAILURE";
}

// when a taskId is provided (from the port state call) the backend will validate
// that the picking state is being fetched is associated with the task id
export const updatePickingState =
  (args: {
    portId?: number;
    gridId?: Guid;
    validationTaskId?: number;
    ignoreErrorMessage?: boolean;
  }): AsyncAppThunk<AutostorePickingState | null> =>
  async (dispatch, getState) => {
    dispatch<UpdatePickingStateAction>({
      type: "autostore/UPDATE_PICKING_STATE"
    });

    const {
      gridId: overrideGridId,
      portId: overridePortId,
      validationTaskId,
      ignoreErrorMessage
    } = args;

    const state = getState();

    const workstationGridId =
      state.workstations.siteWorkstation?.autostoreGridId;
    const workstationPortId =
      state.workstations.sitePortId ??
      state.workstations.siteWorkstation?.ports[0].portId;

    const gridId = overrideGridId ?? workstationGridId;
    const portId = overridePortId ?? workstationPortId;

    try {
      if (!gridId || !portId) {
        throw new Error("Autostore grid or port are not set for start picking");
      }

      let pickingStateResponse;

      if (validationTaskId) {
        pickingStateResponse =
          await warehouseService.get<AutostorePickingState>(
            `/api/autostore-grid/${gridId}/port/${portId}/task/${validationTaskId}/picking-state`
          );
      } else {
        pickingStateResponse =
          await warehouseService.get<AutostorePickingState>(
            `/api/autostore-grid/${gridId}/port/${portId}/picking-state`
          );
      }

      dispatch<UpdatePickingStateSuccessAction>({
        type: "autostore/UPDATE_PICKING_STATE_SUCCESS",
        payload: {
          portId,
          pickingState: shapePickingState(pickingStateResponse.data),
          timestamp: new Date()
        }
      });
      return shapePickingState(pickingStateResponse.data);
    } catch (err) {
      if (!ignoreErrorMessage) {
        handleWarehouseError(err, (message) => {
          dispatch<UpdatePickingStateFailureAction>({
            type: "autostore/UPDATE_PICKING_STATE_FAILURE"
          });
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error"
            }
          });
        });
      }
      return null;
    }
  };

// START PICKING ON WORKSTATION
export interface StartPickingOnWorkstationAction {
  type: "autostore/START_PICKING_ON_WORKSTATION";
}

export interface StartPickingOnWorkstationSuccessAction {
  type: "autostore/START_PICKING_ON_WORKSTATION_SUCCESS";
  payload: {
    portId: number;
    pickingState: AutostorePickingState;
    timestamp: Date;
  };
}

export interface StartPickingOnWorkstationFailureAction {
  type: "autostore/START_PICKING_ON_WORKSTATION_FAILURE";
}

type PickingRequestUrlAndPayload = {
  url: string;
  request: StartPickingRequest | StartPickingOnWorkstationRequest;
};

export const startPickingOnWorkstation =
  (args?: {
    gridId?: Guid;
    workstationId?: Guid;
    preventClosePort?: boolean;
    enableMultiPort?: boolean;
    isStartPickingOnWorkstationEndpointEnabledInfo?: boolean;
  }): AsyncAppThunk<AutostorePickingState | null> =>
  async (dispatch, getState) => {
    dispatch<StartPickingOnWorkstationAction>({
      type: "autostore/START_PICKING_ON_WORKSTATION"
    });

    const {
      gridId: overrideGridId,
      workstationId: overrideWorkstationId,
      preventClosePort,
      enableMultiPort,
      isStartPickingOnWorkstationEndpointEnabledInfo
    } = args ?? {};

    const state = getState();

    const workstationGridId =
      state.workstations.siteWorkstation?.autostoreGridId;

    const workstationIdLocal = state.workstations.siteWorkstation?.id;

    const workstationPortId = enableMultiPort
      ? state.workstations.sitePortId
      : state.workstations.siteWorkstation?.ports[0].portId;

    const gridId = overrideGridId ?? workstationGridId;
    const workstationId = overrideWorkstationId ?? workstationIdLocal;
    const deviceId = state.workstations.siteWorkstation?.deviceId ?? "";

    try {
      if (!gridId || !workstationId) {
        throw new Error(
          "Autostore grid or workstation are not set for start picking"
        );
      }

      const reqPortId = workstationPortId ?? 0;

      /** Get the Start Picking URL and request payload appropriate for that endpoint. */
      const getPickRequestInfo = (): PickingRequestUrlAndPayload => {
        const forceClosePort = !preventClosePort; // true unless preventClosePort is true

        if (isStartPickingOnWorkstationEndpointEnabledInfo) {
          const url = `/api/autostore-grid/${gridId}/workstation/${workstationId}/start-picking-on-workstation`;
          const request: StartPickingOnWorkstationRequest = {
            forceClosePort,
            enableMultiPort,
            deviceId,
            temperatureZones: []
          };

          return { url, request };
        } else {
          const url = `/api/autostore-grid/${gridId}/port/${reqPortId}/start-picking`;
          const request: StartPickingRequest = {
            forceClosePort,
            deviceId,
            workstationId,
            temperatureZones: []
          };
          return { url, request };
        }
      };

      const { url, request } = getPickRequestInfo();

      const startPickingOnWorkstationResponse = await warehouseService.post<
        {
          portId: number;
          pickingState: AutostorePickingState;
        }[]
      >(url, request);

      // todo: cleanup when using only start-picking-on-workstation
      const pickingStateToUse = Array.isArray(
        startPickingOnWorkstationResponse.data
      )
        ? (startPickingOnWorkstationResponse.data.find(
            (pickingStateLocal) =>
              pickingStateLocal.portId === workstationPortId
          ) ?? startPickingOnWorkstationResponse.data[0])
        : {
            portId: workstationPortId ?? 0,
            pickingState:
              startPickingOnWorkstationResponse.data as AutostorePickingState
          };

      dispatch<StartPickingOnWorkstationSuccessAction>({
        type: "autostore/START_PICKING_ON_WORKSTATION_SUCCESS",
        payload: {
          portId: pickingStateToUse.portId,
          pickingState: shapePickingState(pickingStateToUse.pickingState),
          timestamp: new Date()
        }
      });
      return pickingStateToUse.pickingState;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<StartPickingOnWorkstationFailureAction>({
          type: "autostore/START_PICKING_ON_WORKSTATION_FAILURE"
        });
        if (
          message?.includes(
            "There is nothing left to pick or no appropriate bin found."
          )
        ) {
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: noBatchesLeftMessage,
              severity: "warning"
            }
          });
        } else {
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error"
            }
          });
        }
      });
      return null;
    }
  };

// SET EMPTY BIN BTN STATE

export interface EmptyBinBtnState {
  type: "autostore/GET_EMPTY_BIN_BTN_STATE";
  payload: boolean;
}

export const setEmptyBinBtnState =
  (emptyBinBtnState: boolean): AppThunk =>
  (dispatch) => {
    dispatch<EmptyBinBtnState>({
      type: "autostore/GET_EMPTY_BIN_BTN_STATE",
      payload: emptyBinBtnState
    });
  };

// BIN AT / NOT AT PORT EVENT

export interface BinAtPortEventAction {
  type: "autostore/BIN_AT_PORT_EVENT";
  payload: boolean;
}

export interface BinNotAtPortAction {
  type: "autostore/BIN_NOT_AT_PORT";
  payload: boolean;
}

export const binAtPortEvent = (): AppThunk => (dispatch) => {
  dispatch<BinAtPortEventAction>({
    type: "autostore/BIN_AT_PORT_EVENT",
    payload: true
  });
};

export const binNotAtPort = (): AppThunk => (dispatch) => {
  dispatch<BinNotAtPortAction>({
    type: "autostore/BIN_NOT_AT_PORT",
    payload: false
  });
};

// GET BIN STATE

export interface FetchBinLogPublisherStateAction {
  type: "autostore/FETCH_BIN_LOG_PUBLISHER_STATE";
}

export interface FetchBinLogPublisherStateSuccessAction {
  type: "autostore/FETCH_BIN_LOG_PUBLISHER_STATE_SUCCESS";
  payload: GetBinResponse;
}

export interface FetchBinLogPublisherStateFailureAction {
  type: "autostore/FETCH_BIN_LOG_PUBLISHER_STATE_FAILURE";
}

export const fetchBinLogPublisherState =
  (
    autostoreGridId: Guid,
    autostoreBinId: number
  ): AsyncAppThunk<GetBinResponse | null> =>
  async (dispatch) => {
    // prevent request if autostoreBinId is 0
    if (!autostoreBinId) return null;
    try {
      const response = await warehouseService.get<GetBinResponse>(
        `/api/autostore-grid/${autostoreGridId}/bin/${autostoreBinId}/get-log-publisher-state`
      );

      return response.data;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
      return null;
    }
  };

// SHOW PICK TO LIGHT

export interface ShowPickToLightAction {
  type: "autostore/SHOW_PICK_TO_LIGHT";
}

export interface ShowPickToLightSuccessAction {
  type: "autostore/SHOW_PICK_TO_LIGHT_SUCCESS";
}

export interface ShowPickToLighFailureAction {
  type: "autostore/SHOW_PICK_TO_LIGHT_FAILURE";
}

export const showPickToLight =
  (
    gridId: Guid,
    sourcePortId: number,
    sourceBinId: number,
    sourceCompartment: number,
    quantity: number
  ): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<ShowPickToLightAction>({
      type: "autostore/SHOW_PICK_TO_LIGHT"
    });

    try {
      const request: ShowPickToLightRequest = {
        sourcePortId,
        sourceBinId,
        sourceCompartment,
        quantity
      };

      await warehouseService.post<null>(
        `api/autostore-grid/${gridId}/port/show-pick-to-light`,
        request
      );

      dispatch<ShowPickToLightSuccessAction>({
        type: "autostore/SHOW_PICK_TO_LIGHT_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        if (message.trim().toLowerCase() !== "unknown method.") {
          dispatch<ShowPickToLighFailureAction>({
            type: "autostore/SHOW_PICK_TO_LIGHT_FAILURE"
          });
          dispatch<SetUserMessageAction>({
            type: "site/SET_USER_MESSAGE",
            payload: {
              title: message,
              severity: "error"
            }
          });
        }
      });
    }
  };

// SET CONFIRMATION MODAL VISIBILITY STATUS

export interface SetConfirmationModalVisibilityStatusAction {
  type: "autostore/SET_CONFIRMATION_MODAL_VISIBILITY_STATUS";
  payload: boolean;
}

export const setConfirmationModalVisibilityStatus =
  (isOpen: boolean): AppThunk =>
  (dispatch) => {
    dispatch<SetConfirmationModalVisibilityStatusAction>({
      type: "autostore/SET_CONFIRMATION_MODAL_VISIBILITY_STATUS",
      payload: isOpen
    });
  };

// PICK QUANTITY CONFIRMED

export interface PickQuantityConfirmed {
  type: "autostore/PICK_QUANTITY_CONFIRMED";
  payload: boolean;
}

export const setIsPickQuantityConfirmed =
  (isConfirmed: boolean): AppThunk =>
  (dispatch) => {
    dispatch<PickQuantityConfirmed>({
      type: "autostore/PICK_QUANTITY_CONFIRMED",
      payload: isConfirmed
    });
  };

// SET NEXT PICKING BIN LOADING

export interface SetNextPickingBinLoadingAction {
  type: "autostore/SET_NEXT_PICKING_BIN_LOADING";
  payload: boolean;
}

export const setNextPickingBinLoading =
  (loading: boolean): AppThunk =>
  (dispatch): void => {
    dispatch<SetNextPickingBinLoadingAction>({
      type: "autostore/SET_NEXT_PICKING_BIN_LOADING",
      payload: loading
    });
  };

// SET BIN IS PRESENT

export interface SetBinIsPresentAction {
  type: "autostore/SET_BIN_IS_PRESENT";
  payload: {
    status: boolean;
    reporter: string;
    timestamp: Date;
    binId?: number;
  };
}

export const setBinIsPresent =
  (args: { status: boolean; reporter: string; binId?: number }): AppThunk =>
  (dispatch): void => {
    dispatch<SetBinIsPresentAction>({
      type: "autostore/SET_BIN_IS_PRESENT",
      payload: {
        ...args,
        timestamp: new Date()
      }
    });
  };

// LEFT OR RIGHT PORT
export interface LeftOrRightPort {
  type: "autostore/LEFT_OR_RIGHT_PORT";
  payload: PortSide;
}

export const setLeftOrRightPortActive =
  (leftOrRightPort: PortSide): AppThunk =>
  (dispatch) => {
    dispatch<LeftOrRightPort>({
      type: "autostore/LEFT_OR_RIGHT_PORT",
      payload: leftOrRightPort
    });
  };
// SYNCHRONIZE TASK GROUPS (DEV RECOVER)

export const synchronizeTaskGroups =
  (): AsyncAppThunk => async (dispatch, getState) => {
    const state = getState();

    const gridId: string | undefined =
      state.workstations.siteWorkstation?.autostoreGridId;

    try {
      if (!gridId) {
        throw new Error("Autostore grid is not set.");
      }

      await warehouseService.post(
        `/api/autostore-grid/${gridId}/task-group/synchronize`
      );
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// TOTE PICKED
export interface TotePickedAction {
  type: "autostore/TOTE_PICKED";
  payload: ToteDto | null;
}

export const setPickedTote =
  (pickedTote: ToteDto | null): AppThunk =>
  (dispatch) => {
    dispatch<TotePickedAction>({
      type: "autostore/TOTE_PICKED",
      payload: pickedTote
    });
  };

// TOTE PICKING STARTED
export interface TotePickingStartedAction {
  type: "autostore/TOTE_PICKING_STARTED";
  payload: Guid | null;
}

export const setTotePickingStarted =
  (pickedTote: Guid | null): AppThunk =>
  (dispatch) => {
    dispatch<TotePickingStartedAction>({
      type: "autostore/TOTE_PICKING_STARTED",
      payload: pickedTote
    });
  };

// REPRINT LABEL
export interface ReprintLabelAction {
  type: "autostore/REPRINT_LABEL";
}

export interface ReprintLabelSuccessAction {
  type: "autostore/REPRINT_LABEL_SUCCESS";
}

export interface ReprintLabelFailureAction {
  type: "autostore/REPRINT_LABEL_FAILURE";
}

export const reprintLabel =
  (toteId: string | null): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<ReprintLabelAction>({
      type: "autostore/REPRINT_LABEL"
    });

    const state = getState();
    const workstationId = state.workstations.siteWorkstation?.id;
    const gridId = state.workstations.siteWorkstation?.autostoreGridId;

    try {
      await warehouseService.post(`/api/labels/print-label`, {
        toteId,
        workstationId,
        gridId
      });

      dispatch<ReprintLabelSuccessAction>({
        type: "autostore/REPRINT_LABEL_SUCCESS"
      });
    } catch (err) {
      dispatch<ReprintLabelFailureAction>({
        type: "autostore/REPRINT_LABEL_FAILURE"
      });

      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

export const resetPortBinData = (): AppThunk => (dispatch) => {
  dispatch({
    type: "autostore/RESET_BIN_AND_PORT_DATA"
  });
};

// SHOW TWO BIN COMPONENTS

export interface ShowTwoWbinComponentsAction {
  type: "autostore/SHOW_TWO_BIN_COMPONENTS";
  payload: boolean;
}

export const setShowTwoBinComponents =
  (show: boolean): AppThunk =>
  (dispatch) => {
    dispatch<ShowTwoWbinComponentsAction>({
      type: "autostore/SHOW_TWO_BIN_COMPONENTS",
      payload: show
    });
  };

export interface FlagBinAction {
  type: "autostore/FLAG_BIN";
}

export interface FlagBinSuccessAction {
  type: "autostore/FLAG_BIN_SUCCESS";
  payload: boolean;
}

export interface FlagBinFailureAction {
  type: "autostore/FLAG_BIN_FAILURE";
}

export const flagBin =
  (
    autostoreGridId: string,
    portId: number,
    binId: number,
    reason: string
  ): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<FlagBinAction>({
      type: "autostore/FLAG_BIN"
    });

    const request: FlagBinRequest = { reason };

    try {
      await warehouseService.post(
        `/api/autostore-grid/${autostoreGridId}/port/${portId}/bin/${binId}/flag`,
        request
      );
      dispatch<FlagBinSuccessAction>({
        type: "autostore/FLAG_BIN_SUCCESS",
        payload: true
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<FlagBinFailureAction>({
          type: "autostore/FLAG_BIN_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

export interface DisplayToteConfirmationModalForToteAction {
  type: "autostore/DISPLAY_TOTE_CONFIRMATION_MODAL_FOR_TOTE";
  payload: PickingStateV2Tote | null;
}

export const setToteForToteConfirmationModal =
  (tote: PickingStateV2Tote | null): AppThunk =>
  (dispatch) => {
    dispatch<DisplayToteConfirmationModalForToteAction>({
      type: "autostore/DISPLAY_TOTE_CONFIRMATION_MODAL_FOR_TOTE",
      payload: tote
    });
  };
