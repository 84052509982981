import { useAppSelector } from "~/app/store";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { useGridSelector } from "./useGridSelector";

export function useGridSelectorWrapper() {
  const workstation = useAppSelector(selectThisWorkstation);
  const { gridSelectorButton, autostoreGridOverride } = useGridSelector();
  return {
    gridSelectorButton: workstation ? undefined : gridSelectorButton,
    autostoreGridOverride
  };
}
