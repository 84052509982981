import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import { AlertBanner } from "@qubit/autoparts";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";

import PTL from "~/features/autostorePicking/tote/PTL";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useToteSubscription } from "~/lib/signalr";

import {
  closePort,
  setIsPickQuantityConfirmed,
  setToteForToteConfirmationModal,
  updatePickingState
} from "~/redux/actions";
import { selectPtlSimulationEnabled } from "~/redux/selectors/PTLselectors";
import { selectPickingState } from "~/redux/selectors/autostoreSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  selectSelectedPortId,
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import {
  useConfirmAllTotePositionsMutation,
  useConfirmTotePositionMutation
} from "~/redux/warehouse/totes.hooks";
import { PickingStateV2Tote, ToteEventDto } from "~/types/api";

import { CancelButton } from "./CancelButton";
import { ConfirmButton } from "./ConfirmButton";

import ToteBox from "./ToteBox";
import TotePrepModalInstruction from "./TotePrepModalInstruction";

type TotePrepModalProps = {
  originateTotes: PickingStateV2Tote[];
  restartInactivityTimer: () => void;
};

export function TotesPrepModal(props: TotePrepModalProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { originateTotes, restartInactivityTimer } = props;

  const selectedPortId = useAppSelector(selectSelectedPortId);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const ptlSimulationEnabled = useAppSelector(selectPtlSimulationEnabled);
  const clientConfig = useAppSelector(selectClientConfig);
  const activeWorkstation = useAppSelector(selectThisWorkstation);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const displayToteConfirmationModalForTote = useAppSelector(
    (state) => state.autostore.displayToteConfirmationModalForTote
  );
  const pickingState = useAppSelector(selectPickingState);

  const dispatch = useAppDispatch();

  const [
    confirmTotePosition,
    {
      isError: confirmToteError,
      error: confirmToteErrorMessage,
      isLoading: confirmToteLoading
    }
  ] = useConfirmTotePositionMutation();
  const [
    confirmAllTotePositions,
    {
      isError: confirmAllTotesError,
      error: confirmAllTotesErrorMessage,
      isLoading: confirmAllLoading
    }
  ] = useConfirmAllTotePositionsMutation();

  const hasError = confirmToteError || confirmAllTotesError;
  const errorMessage = confirmToteError
    ? getMessageFromRtkError(confirmToteErrorMessage)
    : confirmAllTotesError
      ? getMessageFromRtkError(confirmAllTotesErrorMessage)
      : "";

  const {
    ap_prepTotesModalShowTitle,
    ap_prepTotesModalShowInstructionText,
    ap_prepTotesModalColumnPlacement,
    ap_prepTotesModalShowCustomerName,
    ap_prepTotesModalShowToteId,
    ap_prepTotesModalShowToteSize
  } = clientConfig;

  const isConfirmAllTotePositionsAtOnceEnabled =
    !!fulfillmentCenter &&
    fulfillmentCenter.confirmAllTotePositionsAtOnceEnabled;

  // fires when the correct tote id is received as a confirmation event from ptl
  const toteSubscription = async (event: ToteEventDto) => {
    if (
      event.portId === selectedPortId &&
      event.gridId === selectedAutostoreGridId &&
      ["On Workstation", "Position Confirmed"].includes(event.eventType)
    ) {
      await dispatch(updatePickingState({}));
      restartInactivityTimer();
    }
    if (
      event.portId === selectedPortId &&
      event.gridId === selectedAutostoreGridId &&
      displayToteConfirmationModalForTote &&
      event.eventType.toLowerCase() === "position confirmed"
    ) {
      dispatch(setIsPickQuantityConfirmed(true));
      dispatch(setToteForToteConfirmationModal(null));
    }
  };

  useToteSubscription(toteSubscription);

  const toteOnWorkstation =
    pickingState &&
    (pickingState.totes.find((tote) => tote.status === "On Workstation") ||
      pickingState.totes.find((tote) => tote.status !== "Position Confirmed"));

  const handleConfirmClick = async (): Promise<void> => {
    // if the modal was opened for single tote position confirmation, confirm that tote position, otherwise confirm the onWorkstation tote
    const toteForConfirmation = displayToteConfirmationModalForTote
      ? displayToteConfirmationModalForTote
      : toteOnWorkstation;
    if (
      toteForConfirmation &&
      selectedAutostoreGridId &&
      selectedPortId &&
      activeWorkstation
    ) {
      await confirmTotePosition({
        toteId: toteForConfirmation.toteId,
        gridId: selectedAutostoreGridId,
        portId: selectedPortId,
        workstationId: activeWorkstation.id
      }).unwrap();
    }
    await dispatch(updatePickingState({}));
  };

  const handleConfirmAllClick = async (): Promise<void> => {
    if (pickingState && selectedAutostoreGridId && activeWorkstation) {
      await confirmAllTotePositions({
        gridId: selectedAutostoreGridId,
        batchId: pickingState.batchId,
        workstationId: activeWorkstation.id
      })
        .unwrap()
        .then(() => dispatch(updatePickingState({})));
    }
  };

  if (!pickingState || !originateTotes.length) return <></>;

  return (
    <Dialog fullWidth maxWidth="md" key="assign-totes-dialog" open>
      {ap_prepTotesModalShowTitle && (
        <DialogTitle variant="h5">{t("tote setup")}</DialogTitle>
      )}
      {ap_prepTotesModalShowInstructionText && (
        <DialogTitle>{t("assign box to picking position")}</DialogTitle>
      )}
      <DialogContent>
        <Stack gap={3} alignItems="center">
          {ap_prepTotesModalShowInstructionText && <TotePrepModalInstruction />}
          <Grid
            container
            sx={{ marginTop: "15px" }}
            data-testid="totesContainer"
            justifyContent={
              ap_prepTotesModalColumnPlacement ? "center" : "flex-start"
            }
            spacing={ap_prepTotesModalColumnPlacement ? 4 : 2}
          >
            {originateTotes.map((tote, i) => {
              const matchingPickInfo = pickingState.allPicks.find(
                (toteIdAndPicks) => toteIdAndPicks.toteId === tote.toteId
              );
              const matchingPickFirstName =
                matchingPickInfo?.picks[0]?.firstName || "";
              const matchingPickLastName =
                matchingPickInfo?.picks[0]?.firstName || "";

              const parentStyle: React.CSSProperties = {
                height: "155px",
                border: `2px solid ${palette.gray.main}`,
                backgroundColor: "#fff",
                padding: "10px"
              };
              if (tote.toteId === toteOnWorkstation?.toteId) {
                parentStyle.backgroundColor = palette.primary.main;
                parentStyle.color = "white";
              }
              if (tote.status === "Position Confirmed") {
                parentStyle.backgroundColor = palette.info.light;
                parentStyle.color = palette.info.dark;
              }
              if (displayToteConfirmationModalForTote) {
                if (
                  tote.totePosition ===
                  displayToteConfirmationModalForTote.totePosition
                ) {
                  parentStyle.backgroundColor = palette.primary.main;
                  parentStyle.color = "white";
                } else {
                  parentStyle.backgroundColor = palette.darkGray.light;
                  parentStyle.color = "black";
                }
              }

              return (
                <Fragment key={tote.toteId}>
                  <Grid
                    item
                    container={ap_prepTotesModalColumnPlacement}
                    xs={ap_prepTotesModalColumnPlacement ? 12 : 3}
                    sm={ap_prepTotesModalColumnPlacement ? 5 : 3}
                    gap={10}
                    justifyContent={
                      ap_prepTotesModalColumnPlacement ? "center" : "flex-start"
                    }
                  >
                    <Grid
                      item
                      sx={parentStyle}
                      style={
                        ap_prepTotesModalColumnPlacement
                          ? { width: "90%" }
                          : undefined
                      }
                    >
                      <Typography sx={{ fontSize: "2em" }}>
                        {`P${tote.totePosition || ""}`}
                      </Typography>
                      {(tote.toteId === toteOnWorkstation?.toteId ||
                        tote.status === "Position Confirmed") && (
                        <ToteBox
                          matchingPickFirstName={matchingPickFirstName}
                          matchingPickLastName={matchingPickLastName}
                          showCustomerName={ap_prepTotesModalShowCustomerName}
                          externalToteId={
                            ap_prepTotesModalShowToteId
                              ? tote.externalToteId || t("box id")
                              : undefined
                          }
                          toteSize={
                            ap_prepTotesModalShowToteSize
                              ? tote.size || t("box size")
                              : undefined
                          }
                        />
                      )}
                    </Grid>
                    {envConstants.ENABLE_PTL_SIMULATION === "true" &&
                      ptlSimulationEnabled && (
                        <PTL position={tote.totePosition || 0} />
                      )}
                  </Grid>
                  {ap_prepTotesModalColumnPlacement &&
                    i === originateTotes.length - 1 &&
                    originateTotes.length % 2 !== 0 && <Grid item xs={5} />}
                </Fragment>
              );
            })}
          </Grid>
          <Box
            mb={2}
            width="calc(100% - 64px)"
            display="grid"
            height="80px"
            sx={{ gridTemplateColumns: "1fr 1fr 1fr" }}
          >
            <CancelButton
              onClick={async () => {
                await dispatch(closePort());
                navigate("/autostore-main");
              }}
              color={palette.secondary.contrastText}
              backgroundColor={palette.secondary.dark}
              aria-label="cancel"
            />
            {(!isConfirmAllTotePositionsAtOnceEnabled ||
              displayToteConfirmationModalForTote) && (
              <ConfirmButton
                size="md"
                onClick={() => {
                  if (displayToteConfirmationModalForTote) {
                    dispatch(setIsPickQuantityConfirmed(true));
                    dispatch(setToteForToteConfirmationModal(null));
                  }
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  handleConfirmClick();
                }}
                greenBackground={false}
                color="#fff"
                backgroundColor={palette.primary.main}
                text={
                  hasError
                    ? t("retry")
                    : displayToteConfirmationModalForTote
                      ? t("confirm")
                      : t("next")
                }
                isNext
                data-testid="totes-prep-modal-confirm-button"
                disabled={confirmToteLoading}
              />
            )}
            {isConfirmAllTotePositionsAtOnceEnabled &&
              !displayToteConfirmationModalForTote && (
                <ConfirmButton
                  size="md"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                    handleConfirmAllClick();
                  }}
                  greenBackground={false}
                  color="#fff"
                  backgroundColor={palette.primary.main}
                  text={hasError ? t("retry") : t("confirm all")}
                  isNext={false}
                  data-testid="totes-prep-modal-confirm-all-button"
                  disabled={confirmAllLoading}
                />
              )}
            <AlertBanner
              message={errorMessage}
              sx={{ height: "80px", boxSizing: "border-box", width: "100%" }}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
