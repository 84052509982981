import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { OrderTypeAutostoreCategoryDto } from "~/types/api";

type State = {
  isDialogOpened: boolean;
  orderTypeToDelete: OrderTypeAutostoreCategoryDto | null;
};

const initialState: State = {
  isDialogOpened: false,
  orderTypeToDelete: null
};

export const orderTypesDropdownSlice = createSlice({
  name: "orderTypesDropdown",
  initialState,
  reducers: {
    openAddOrderTypeDialog(state) {
      state.isDialogOpened = true;
    },
    closeAddOrderTypeDialog(state) {
      state.isDialogOpened = false;
    },
    setOrderTypeToDelete(
      state,
      { payload }: PayloadAction<OrderTypeAutostoreCategoryDto>
    ) {
      state.orderTypeToDelete = payload;
    },
    clearOrderTypeToDelete(state) {
      state.orderTypeToDelete = null;
    }
  },
  selectors: {
    selectIsAddOrderTypeDialogOpen: (state: State) => state.isDialogOpened,
    selectOrderTypeToDelete: (state: State) => state.orderTypeToDelete
  }
});

export const {
  openAddOrderTypeDialog,
  closeAddOrderTypeDialog,
  setOrderTypeToDelete,
  clearOrderTypeToDelete
} = orderTypesDropdownSlice.actions;

export const { selectIsAddOrderTypeDialogOpen, selectOrderTypeToDelete } =
  orderTypesDropdownSlice.selectors;
