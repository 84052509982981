import Button from "@locaisolutions/button";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import MultiPort from "~/features/autostoreBin/MultiPort";
import { getFirstDefinedValue } from "~/lib/helpers";

import { GetPortResponse } from "~/redux/actions";
import { NextEmptyBinResponse, WorkstationDto } from "~/types/api";

type Props = {
  isOpen: boolean;
  setIsBinNotEmptyCompartmentPanelOpen: Dispatch<SetStateAction<boolean>>;
  setIsBinNotEmptyPanelOpen: Dispatch<SetStateAction<boolean>>;
  workstation: WorkstationDto | null;
  nextEmptyBinByPort: {
    [portId: number]: NextEmptyBinResponse | null;
  };
  portStateByPort: {
    [portId: number]: {
      getPortResponse: GetPortResponse;
      timestamp: Date;
    };
  };
  newSelectedBin: NextEmptyBinResponse | null;
  setNewSelectedBin: Dispatch<SetStateAction<NextEmptyBinResponse | null>>;
  newSelectedCompartment: number | null;
  setNewSelectedCompartment: (compartment: number | null) => void;
  showInitialLoading?: boolean;
};

function BinNotEmptyCompartment(props: Props) {
  const {
    isOpen,
    nextEmptyBinByPort,
    portStateByPort,
    workstation,
    showInitialLoading = false,
    newSelectedBin,
    setNewSelectedBin,
    newSelectedCompartment,
    setNewSelectedCompartment,
    setIsBinNotEmptyCompartmentPanelOpen
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsBinNotEmptyCompartmentPanelOpen(false)}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>{t("select incorrect compartment")}</DialogTitle>
      <DialogContent sx={{ margin: "30px 30px 0" }}>
        <Grid container direction="column" alignItems="center">
          <MultiPort
            nextEmptyBinByPort={nextEmptyBinByPort}
            portStateByPort={portStateByPort}
            workstation={workstation}
            onBinClick={(compartment: number | undefined) => {
              if (compartment || compartment === 0)
                setNewSelectedCompartment(compartment);
            }}
            setSelectedBinCallback={(bin: NextEmptyBinResponse) =>
              setNewSelectedBin(bin)
            }
            selectedCompartment={getFirstDefinedValue(
              [newSelectedCompartment],
              undefined
            )}
            currentSelectedBinId={newSelectedBin?.openBinResponse.binId}
            allowSelectingCompartmentsWithoutInventoryOnly
            showLoading={showInitialLoading}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "30px" }}>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            props.setIsBinNotEmptyCompartmentPanelOpen(false);
            props.setIsBinNotEmptyPanelOpen(true);
          }}
          data-testid="confirm-selected-compartment"
          disabled={!newSelectedCompartment && newSelectedCompartment !== 0}
          sx={{
            fontWeight: "normal"
          }}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BinNotEmptyCompartment;
