import { BatchStatus } from "~/features/batch/batch.type";
import { WorkstationSummaryDto } from "~/types/api";

export function shouldShowPickBatchButton(
  batchType: string,
  batchStatus: BatchStatus,
  workstation: WorkstationSummaryDto | null,
  deviceId: string,
  cartPrepEnabled: boolean
) {
  const isAutostoreBatch = batchType.toLowerCase() === "autostore";
  const batchReadyToPick =
    (cartPrepEnabled && batchStatus === "Cart Ready") ||
    (!cartPrepEnabled && batchStatus === "Scheduled");
  const workstationHasPickingRole =
    workstation && workstation.roles.some((r) => r.toLowerCase() === "picking");
  const batchIsSuspendedAtThisWorkstation =
    batchStatus === "Suspended" && deviceId === workstation?.deviceId;

  return (
    isAutostoreBatch &&
    workstationHasPickingRole &&
    (batchReadyToPick || batchIsSuspendedAtThisWorkstation)
  );
}
