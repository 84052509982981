import { Button, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup } from "~/features/navbar/Navbar";

import {
  selectCommonHoldsForSelectedSummary,
  selectSelectedRow,
  setIsInventoryAdjustDialogOpen,
  setIsBinHoldModalOpen
} from "./inventoryHolds.slice";

export function InventoryHoldsToolbar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedRow = useAppSelector(selectSelectedRow);
  const commonHolds = useAppSelector(selectCommonHoldsForSelectedSummary);

  return (
    <Toolbar>
      {selectedRow && (
        <NavBarButtonGroup>
          <Button
            id="adjust-button"
            color="secondary"
            onClick={() => dispatch(setIsInventoryAdjustDialogOpen(true))}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {`${t("adjust")}`}
            </Typography>
          </Button>
          {!!commonHolds.length && (
            <Button
              id="remove-hold-button"
              color="secondary"
              onClick={() => {
                dispatch(setIsBinHoldModalOpen(true));
              }}
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {`${t("remove hold")}`}
              </Typography>
            </Button>
          )}
        </NavBarButtonGroup>
      )}
    </Toolbar>
  );
}
