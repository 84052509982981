import { Tune } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type FilterOptionType = { filter: string; translation: string };

type Props = {
  menuItemsTitle?: string;
  menuItemList?: FilterOptionType[];
  selectedMenuItems?: string[];
  onFilterData?: (status: string[]) => void;
  onResetFilters?: () => void;
};

/**
 * Renders a button with a filter icon that opens a menu of the provided filters.
 */
export function NavbarFilters(props: Props) {
  const {
    menuItemsTitle,
    menuItemList,
    selectedMenuItems,
    onFilterData,
    onResetFilters
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-label="filter"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ minWidth: 40, paddingRight: 0, textTransform: "capitalize" }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Tune
              data-testid="batch-filterIcon"
              sx={{ color: "secondary.contrastText" }}
            />
          </Box>
        </Box>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <div>
          {menuItemsTitle && (
            <MenuItem value="" disabled>
              {menuItemsTitle}
            </MenuItem>
          )}
          {menuItemList?.map((status) => (
            <MenuItem
              key={status.filter}
              onClick={() => {
                if (selectedMenuItems && onFilterData) {
                  if (selectedMenuItems?.indexOf(status.filter) === -1) {
                    onFilterData([...selectedMenuItems, status.filter]);
                  } else {
                    onFilterData(
                      [...selectedMenuItems].filter(
                        (element) => element !== status.filter
                      )
                    );
                  }
                }
              }}
              style={{
                minWidth: 150,
                backgroundColor: selectedMenuItems?.includes(status.filter)
                  ? "rgb(219, 219, 219)"
                  : undefined
              }}
            >
              {status.translation}
            </MenuItem>
          ))}
        </div>
        <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
        <MenuItem
          key="reset filters"
          onClick={() => {
            if (onResetFilters) onResetFilters();
            handleClose();
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>{t("reset filters")}</Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
}
