import rfdc from "rfdc";

export function groupBy<TElement>(
  arr: Array<TElement>,
  criteria: (element: TElement) => string
): { [key: string]: TElement[] } {
  return arr.reduce((last: { [key: string]: TElement[] }, item) => {
    const key = criteria(item);
    const next = { ...last };

    // If the key doesn't exist yet, create it
    if (!Object.prototype.hasOwnProperty.call(next, key)) {
      next[key] = [];
    }

    // Push the value to the object
    next[key].push(item);

    // Return the object to the next item in the loop
    return next;
  }, {});
}

/** Use rfdc library for deep cloning */
export const deepClone = rfdc();

export const downloadBlob = (data: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};

/** Creates a sequence of numbers from 1...upperLimit incrementing by 1. */
export function createNumberSequence(upperLimit: number): number[] {
  return Array.from(new Array(upperLimit), (_, index) => index + 1);
}

export const isAbortedRequest = (err: unknown) =>
  (err as { name: string }).name === "AbortError";
