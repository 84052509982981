import { Button, Toolbar } from "@mui/material";

import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";

import { combineTotesAndLoosePicks } from "~/lib/toteOrLoosePick";
import {
  emptyLoosePickArray,
  emptyToteArray,
  singleSelectedToteOrLoosePick
} from "~/redux/selectors/stageTotesSelectors";

import { useGetBatchQuery } from "~/redux/warehouse/batches.hooks";

import { useGetLoosePicksQuery } from "~/redux/warehouse/loosePicks.openApi";
import { useGetTotesQuery } from "~/redux/warehouse/totes.hooks";

import { clearSelectedToteIds } from "./stageTotes.slice";

type Props = {
  handleStageTotes: () => Promise<void>;
};

export function StageTotesToolbar({ handleStageTotes }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { batchName = "" } = useParams<{ batchName: string }>();

  const { data: batchResponse } = useGetBatchQuery({ batchId: batchName });

  const batch = batchResponse?.batch;
  const { data: loosePicks } = useGetLoosePicksQuery(
    batch ? { batchId: batch.batchId } : skipToken
  );
  const { data: totes } = useGetTotesQuery(
    batch ? { batchId: batch.batchId } : skipToken
  );

  const totesAndLoosePicks = useMemo(
    () =>
      combineTotesAndLoosePicks(
        totes || emptyToteArray,
        loosePicks || emptyLoosePickArray
      ),
    [totes, loosePicks]
  );

  const selectedToteIds = useAppSelector(
    (state) => state.stageTotes.selectedToteIds
  );
  const singleSelectedTote = useAppSelector((state) =>
    singleSelectedToteOrLoosePick(state, totesAndLoosePicks)
  );

  const isBulk = batchResponse?.batch?.batchType === "Bulk";

  const viewButtonText = isBulk ? t("view bulk") : t("view totes");

  return (
    <Toolbar>
      <UnselectButton onClick={() => dispatch(clearSelectedToteIds())} />
      <NavBarButtonGroup>
        {singleSelectedTote && (
          <Button
            style={{ color: "white" }}
            onClick={(): void => {
              navigate(`/ship/order/${singleSelectedTote.orderId}`);
            }}
          >
            {viewButtonText}
          </Button>
        )}
        {!!totesAndLoosePicks.length && (
          <Button
            style={{ color: "white" }}
            onClick={async () => {
              await handleStageTotes();
            }}
          >
            {`${t("stage_verb")} ${selectedToteIds.length}`}
          </Button>
        )}
      </NavBarButtonGroup>
    </Toolbar>
  );
}
