import { Typography } from "@mui/material";

import { useAppSelector } from "~/app/store";
import { useSummarizedVariantInventorySummaries } from "~/hooks/useSummarizedVariantInventorySummaries";
import { selectOrderCreationSelectedAutostoreGridId } from "~/redux/selectors/orderCreationSelectors";

export const InventorySummary = () => {
  const selectedAutostoreGridId = useAppSelector(
    selectOrderCreationSelectedAutostoreGridId
  );

  const { totalAvailableInventory, totalAvailableSkus } =
    useSummarizedVariantInventorySummaries(selectedAutostoreGridId);

  return (
    <>
      <Typography variant="h5">
        Total Available Inventory Quantity: {totalAvailableInventory}
      </Typography>
      <Typography variant="h5">
        Total Number of SKUs: {totalAvailableSkus}
      </Typography>
    </>
  );
};
