import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  styled
} from "@mui/material";
import { ProgressButton, useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { AddedTote } from "~/api/addedToteCustomType/addedTote";
import { warehouseService } from "~/api/warehouse";
import { useAppDispatch, useAppSelector } from "~/app/store";

import envConstants from "~/config/envConstants";
import {
  resetScannedBarcode,
  setScannedBarcode
} from "~/features/autostorePicking/slices/pickingScannedBarcode.slice";
import PTL from "~/features/autostorePicking/tote/PTL";

import { ternaryIff } from "~/lib/helpers";
import { mixpanelTrackPickWrapper } from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useToteSubscription } from "~/lib/signalr";
import {
  reprintLabel,
  setPickedTote,
  setToteForToteConfirmationModal
} from "~/redux/actions";
import { selectPtlSimulationEnabled } from "~/redux/selectors/PTLselectors";
import {
  selectIsPickQuantityConfirmed,
  selectPickedTote
} from "~/redux/selectors/autostoreSelectors";
import { selectScanAndApplyLabelModalActiveStep } from "~/redux/selectors/pickingSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  selectSelectedPortId,
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import {
  useGetFocusedPickQuery,
  useGetTotesForBatchQuery
} from "~/redux/warehouse/autostorePicking.hooks";
import {
  usePlaceLabelMutation,
  useScanLabelMutation
} from "~/redux/warehouse/totes.hooks";
import { PickingStateV2Tote, ToteEventDto } from "~/types/api";

import {
  incrementScanAndApplyModalActiveStep,
  resetScanAndApplyModalActiveStep
} from "./scanAndApplyLabel.slice";

const ScanLabelModalButton = styled(ProgressButton)(() => ({
  width: "150px",
  height: "50px"
}));

const ToteBox = styled(Grid)(() => ({
  height: "100px",
  border: "1px solid black",
  borderRadius: "5px"
}));

type ScanAndApplyLabelConfirmModalProps = {
  originalTotes: PickingStateV2Tote[];
  addedTotes: AddedTote[];
  restartInactivityTimer: () => void;
};

function ScanAndApplyLabelConfirmModal(
  props: ScanAndApplyLabelConfirmModalProps
) {
  const { originalTotes, addedTotes, restartInactivityTimer } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isReprintLabelButtonDisabled, setIsReprintLabelButtonDisabled] =
    useState(true);
  const isModalClosed = useRef(false);
  const { errorToast, warningToast } = useToast();
  const pickedTote = useAppSelector(selectPickedTote);
  const activeStep = useAppSelector(selectScanAndApplyLabelModalActiveStep);
  const workstationId = useAppSelector(selectWorkstationId);
  const ptlSimulationEnabled = useAppSelector(selectPtlSimulationEnabled);
  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const sitePortId = useAppSelector(selectSelectedPortId);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const [scanLabel] = useScanLabelMutation();
  const [placeLabel] = usePlaceLabelMutation();
  const [searchParams] = useSearchParams();
  const { data: focusedPick } = useGetFocusedPickQuery(
    workstationId ? { workstationId: workstationId } : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );
  const batchId = searchParams.get("batchId") || focusedPick?.batchId;

  const { totes, refetch: refetchGetTotesForBatch } = useGetTotesForBatchQuery(
    batchId ? { batchId } : skipToken,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => ({
        totes: data?.map((toteByPosition) => toteByPosition.totes).flat()
      })
    }
  );

  const getLastPickedTote = () => {
    if (totes) {
      const allTotes = totes
        .filter((tote) => !!tote.pickedTimestamp)
        .sort((tote1, tote2) =>
          !!tote1.pickedTimestamp &&
          !!tote2.pickedTimestamp &&
          tote1.pickedTimestamp > tote2.pickedTimestamp
            ? 1
            : -1
        );
      if (allTotes.length) return allTotes[allTotes.length - 1];
      return null;
    }
    return null;
  };

  const pickedToteInfo = pickedTote || getLastPickedTote();
  const isActivePickScanned =
    !!fulfillmentCenter &&
    fulfillmentCenter.pickQuantityConfirmationEnabled &&
    isPickQuantityConfirmed;

  const getOriginateToteForPickedTote = () => {
    const pickedToteOriginateTote = originalTotes.find(
      (originateTote) => originateTote.toteId === pickedToteInfo?.toteId
    );
    if (pickedToteOriginateTote) return pickedToteOriginateTote;
    const pickedToteAddedTote = addedTotes.find(
      (addedTote) => addedTote.toteId === pickedToteInfo?.toteId
    );
    if (pickedToteAddedTote) {
      const finalOriginateTote = originalTotes.find(
        (originateTote) =>
          originateTote.toteId === pickedToteAddedTote.oldToteId
      );
      return finalOriginateTote;
    }
    return undefined;
  };

  const handleToteConfirmationFlow = async () => {
    if (totes && focusedPick && pickedToteInfo) {
      const stackedTote = totes.find(
        (tote) =>
          tote.totePosition === pickedToteInfo.totePosition &&
          tote.toteNumber > pickedToteInfo.toteNumber &&
          (!tote.pickedTimestamp || !tote.canceledTimestamp)
      );
      // After the previous tote has been sealed, we want to display the tote confirmation modal for the added tote
      if (stackedTote) dispatch(setToteForToteConfirmationModal(stackedTote));

      // After doing the reprint flow for one of the completed totes
      // We need to display the active pick quantity on PTL if the product barcode has already been scanned
      if (!stackedTote && isActivePickScanned) {
        const currentPickId = focusedPick.pickId;

        if (selectedAutostoreGridId && sitePortId) {
          await warehouseService.post("api/ptl/display-pick-data", {
            portId: sitePortId,
            pickId: currentPickId,
            batchId: batchId || focusedPick.batchId,
            gridId: selectedAutostoreGridId
          });
        }
      }
    }
  };

  const close = useCallback(() => {
    dispatch(setPickedTote(null));
    dispatch(resetScanAndApplyModalActiveStep());
  }, [dispatch]);

  const handleOnToteClick = async () => {
    try {
      isModalClosed.current = true;
      if (pickedToteInfo && workstationId) {
        mixpanelTrackPickWrapper(
          "Scan and Apply Label Modal",
          "Button Click",
          "Click Tote to Confirm Label Placement",
          originalTotes.find((tot) => tot.toteId === pickedToteInfo.toteId)
            ?.totePosition
        );
        await placeLabel({
          toteId: pickedToteInfo.toteId,
          workstationId: workstationId
        }).unwrap();
      }
      close();
      await handleToteConfirmationFlow();
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const skipScan = async () => {
    if (pickedTote?.externalToteId)
      dispatch(setScannedBarcode(pickedTote?.externalToteId));
    else dispatch(resetScannedBarcode());
    if (pickedTote?.toteId) {
      await scanLabel({
        toteId: pickedTote.toteId,
        autostorePortId: sitePortId || undefined,
        autostoreGridId: selectedAutostoreGridId,
        workstationId
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsReprintLabelButtonDisabled(false);
    }, 5000);
  }, []);

  // If the saved picked tote doesn't belong to the active batch, the modal should be closed
  useEffect(() => {
    if (pickedToteInfo && totes) {
      const pickedToteBelongsToCurrentBatch = totes.find(
        (tote) => tote.toteId === pickedToteInfo.toteId
      );
      if (!pickedToteBelongsToCurrentBatch) close();
    }
  }, [pickedToteInfo, totes, close]);

  const toteSubscription = async (event: ToteEventDto) => {
    // If the place label api is called from the PTL
    // Qubit should receive label placed event
    if (
      event.eventType.toLowerCase() === "labelplaced" &&
      event.tote.toteId === pickedToteInfo?.toteId &&
      !isModalClosed.current
    ) {
      isModalClosed.current = false;
      restartInactivityTimer();
      close();
      await handleToteConfirmationFlow();
    }
  };
  useToteSubscription(toteSubscription);

  const handleToteCancelled = () => {
    close();
    warningToast(
      `${t("batch cancelled exit scan 1")} ${t("tote").toLowerCase()} ${t(
        "batch cancelled exit scan 2",
        {
          bagNumber: pickedToteInfo?.totePosition
        }
      )}`
    );
  };

  const handleClickReprintLabel = async () => {
    if (!pickedToteInfo?.toteId || !batchId) {
      return;
    }
    setIsReprintLabelButtonDisabled(true);
    try {
      await refetchGetTotesForBatch().unwrap();
      const tote = totes?.find((tote) => tote.toteId === pickedToteInfo.toteId);
      if (tote?.canceledTimestamp) {
        handleToteCancelled();
      } else {
        await dispatch(reprintLabel(pickedToteInfo.toteId));
      }
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    } finally {
      setIsReprintLabelButtonDisabled(false);
    }
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      slotProps={{
        backdrop: { style: { backgroundColor: "#000000cc" } }
      }}
    >
      <DialogTitle>{t("scan and apply label")}</DialogTitle>
      <DialogContent style={{ marginTop: "20px" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key={1} active>
            <StepLabel
              StepIconProps={{
                sx: {
                  "& .MuiStepIcon-root": {
                    height: "30px",
                    width: "30px"
                  }
                }
              }}
            >
              <Typography>{t("scan label to confirm")}</Typography>
            </StepLabel>
            <StepContent>
              <Grid container spacing={1} justifyContent="space-between">
                {envConstants.SHOW_SKIP_SCAN_BUTTON_ON_SLAM_MODAL === "true" &&
                  activeStep === 0 && (
                    <Grid item>
                      <ScanLabelModalButton
                        onClick={async () => {
                          await skipScan();
                          dispatch(incrementScanAndApplyModalActiveStep());
                        }}
                        color="secondary"
                      >
                        {t("skip scan")}
                      </ScanLabelModalButton>
                    </Grid>
                  )}
                <Grid item>
                  <ScanLabelModalButton
                    onClick={handleClickReprintLabel}
                    color="secondary"
                    disabled={isReprintLabelButtonDisabled}
                  >
                    {t("reprint label")}
                  </ScanLabelModalButton>
                </Grid>
              </Grid>
            </StepContent>
          </Step>
          <Step key={2}>
            <StepLabel
              StepIconProps={{
                sx: {
                  "& .MuiStepIcon-root": {
                    height: "30px",
                    width: "30px"
                  }
                }
              }}
            >
              <Typography>{t("apply the label on the marked tote")}</Typography>
            </StepLabel>
            <StepContent>
              <Grid
                container
                rowGap={8}
                justifyContent="space-between"
                position="relative"
                sx={{ marginLeft: "-10px", marginTop: "10px" }}
              >
                {originalTotes.map((originateTote) => {
                  const findAllAddedTotesFromThisTote = addedTotes.filter(
                    (addedTote) => addedTote.oldToteId === originateTote.toteId
                  );

                  return findAllAddedTotesFromThisTote.length === 0 ? (
                    <Grid item xs={5} key={originateTote.toteId}>
                      <ToteBox
                        role="button"
                        item
                        sx={{
                          backgroundColor:
                            originateTote.toteId === pickedToteInfo?.toteId
                              ? "primary.main"
                              : "gray.main",
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          "@keyframes pulse": {
                            from: {
                              boxShadow: "0 0 0 0px rgba(0, 0, 0, 0.4)"
                            },
                            to: {
                              boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)"
                            }
                          },
                          ...(originateTote.toteId ===
                            pickedToteInfo?.toteId && {
                            animation: "pulse 2s infinite ease"
                          })
                        }}
                        data-testid={
                          originateTote.toteId === pickedToteInfo?.toteId
                            ? "active-tote-box"
                            : undefined
                        }
                        onClick={async () => {
                          if (originateTote.toteId === pickedToteInfo?.toteId) {
                            await handleOnToteClick();
                          }
                        }}
                      >
                        <Typography
                          fontWeight={700}
                          data-testid={
                            originateTote.toteId === pickedToteInfo?.toteId
                              ? "active-tote-box-text"
                              : undefined
                          }
                        >{`P${originateTote.totePosition || 1}`}</Typography>
                      </ToteBox>
                      {envConstants.ENABLE_PTL_SIMULATION === "true" &&
                        ptlSimulationEnabled &&
                        originateTote.toteId === pickedToteInfo?.toteId && (
                          <Box>
                            <PTL position={originateTote.totePosition || 0} />
                          </Box>
                        )}
                    </Grid>
                  ) : (
                    <Grid
                      key={originateTote.toteId}
                      item
                      xs={5}
                      position="relative"
                      sx={{
                        height: "100px",
                        marginBottom:
                          envConstants.ENABLE_PTL_SIMULATION === "true" &&
                          ptlSimulationEnabled
                            ? "105px"
                            : "50px"
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <ToteBox
                            item
                            sx={{
                              backgroundColor:
                                originateTote.toteId === pickedToteInfo?.toteId
                                  ? "info.main"
                                  : "gray.main",
                              paddingLeft: "5px",
                              paddingTop: "5px",
                              position: "absolute",
                              width: "100%",
                              "@keyframes pulse": {
                                from: {
                                  boxShadow: "0 0 0 0px rgba(0, 0, 0, 0.4)"
                                },
                                to: {
                                  boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)"
                                }
                              },
                              ...(originateTote.toteId ===
                                pickedToteInfo?.toteId && {
                                animation: "pulse 2s infinite ease"
                              })
                            }}
                            data-testid={
                              originateTote.toteId === pickedToteInfo?.toteId
                                ? "active-tote-box"
                                : undefined
                            }
                            onClick={async () => {
                              if (
                                originateTote.toteId === pickedToteInfo?.toteId
                              ) {
                                await handleOnToteClick();
                              }
                            }}
                          />

                          {envConstants.ENABLE_PTL_SIMULATION === "true" &&
                            ptlSimulationEnabled &&
                            originateTote.toteId === pickedToteInfo?.toteId && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  width: "100%",
                                  bottom: -120
                                }}
                              >
                                <PTL
                                  position={originateTote.totePosition || 0}
                                />
                              </Box>
                            )}
                        </Grid>
                        {findAllAddedTotesFromThisTote.map(
                          (addedTote, index) => {
                            const marginLeft = index * 3 + 5;
                            const marginTop = index * 3 + 10;
                            const originateToteForPickedTote =
                              getOriginateToteForPickedTote();

                            const isClikableAreaForMarkedTote =
                              originateToteForPickedTote &&
                              findAllAddedTotesFromThisTote[index].oldToteId ===
                                originateToteForPickedTote.toteId &&
                              findAllAddedTotesFromThisTote[index].toteNumber >=
                                originateToteForPickedTote.toteNumber;

                            return (
                              <Grid key={addedTote.toteId} item>
                                <ToteBox
                                  role="button"
                                  item
                                  key={addedTote.toteId}
                                  sx={{
                                    backgroundColor:
                                      addedTote.toteId ===
                                      pickedToteInfo?.toteId
                                        ? "info.main"
                                        : "gray.main",
                                    paddingLeft: "5px",
                                    paddingTop: "5px",
                                    marginTop: `${marginTop}%`,
                                    marginLeft: `${marginLeft}%`,
                                    position: "absolute",
                                    width: "100%",
                                    "@keyframes pulse": {
                                      from: {
                                        boxShadow:
                                          "0 0 0 0px rgba(0, 0, 0, 0.4)"
                                      },
                                      to: {
                                        boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)"
                                      }
                                    },
                                    ...(addedTote.toteId ===
                                      pickedToteInfo?.toteId && {
                                      animation: "pulse 2s infinite ease"
                                    })
                                  }}
                                  data-testid={
                                    addedTote.toteId === pickedToteInfo?.toteId
                                      ? "active-tote-box"
                                      : ternaryIff(
                                          index ===
                                            findAllAddedTotesFromThisTote.length -
                                              1 &&
                                            pickedTote?.totePosition ===
                                              originateTote.totePosition,
                                          "last-tote-box",
                                          undefined
                                        )
                                  }
                                  aria-label={
                                    addedTote.toteId === pickedToteInfo?.toteId
                                      ? "active-tote"
                                      : undefined
                                  }
                                  onClick={async () => {
                                    if (isClikableAreaForMarkedTote)
                                      await handleOnToteClick();
                                  }}
                                >
                                  {index ===
                                    findAllAddedTotesFromThisTote.length -
                                      1 && (
                                    <Typography
                                      fontWeight={700}
                                      data-testid={
                                        addedTote.toteId ===
                                        pickedToteInfo?.toteId
                                          ? "active-tote-box-text"
                                          : ternaryIff(
                                              index ===
                                                findAllAddedTotesFromThisTote.length -
                                                  1 &&
                                                pickedTote?.totePosition ===
                                                  originateTote.totePosition,
                                              "last-tote-box-text",
                                              undefined
                                            )
                                      }
                                    >{`P${
                                      originateTote.totePosition || 1
                                    }`}</Typography>
                                  )}
                                </ToteBox>
                                {envConstants.ENABLE_PTL_SIMULATION ===
                                  "true" &&
                                  ptlSimulationEnabled &&
                                  addedTote.toteId ===
                                    pickedToteInfo?.toteId && (
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        width: "100%",
                                        bottom: -120
                                      }}
                                    >
                                      <PTL
                                        position={addedTote.totePosition || 0}
                                      />
                                    </Box>
                                  )}
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
    </Dialog>
  );
}

export default ScanAndApplyLabelConfirmModal;
