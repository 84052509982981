import CheckIcon from "@mui/icons-material/Check";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography
} from "@mui/material";
import { ProgressButton, useToast, DialogRoot } from "@qubit/autoparts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { AutostoreBin } from "~/features/autostoreBin";

import useWindowDimensions from "~/lib/browserDimensions";
import { ternaryIff } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

import { closeBinConfigModal } from "./autostoreBinConfigModal.slice";

const BinComponent = styled(Box)(({ theme }) => ({
  margin: "1vH 0vW",
  border: `0.625em solid ${"#fff"}`,
  "&:hover": {
    padding: 0,
    borderRadius: "0.5em",
    border: `0.625em solid ${theme.palette.info.main}`
  }
}));

type AutostoreBinConfigModalProps = {
  dimensionsCallback?: (dimensions: {
    numOfRows: number;
    numOfCols: number;
  }) => void;
  saveBinConfigurations: (selectedConfig: number[]) => void;
  currentBinConfigurations: number[];
  isOneSelection?: boolean;
};

export function AutostoreBinConfigModal(props: AutostoreBinConfigModalProps) {
  const {
    dimensionsCallback,
    currentBinConfigurations,
    saveBinConfigurations,
    isOneSelection
  } = props;
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.autostoreBinConfigModal.isOpen
  );
  const {
    data: availableBinConfigurations,
    error: availableBinConfigurationsError
  } = useGetBinConfigurationsQuery();

  const { width } = useWindowDimensions();
  const browserWidth =
    width < 1280
      ? ternaryIff<"xs" | "sm" | "md">(
          width > 960,
          "md",
          ternaryIff<"xs" | "sm">(width > 600, "sm", "xs")
        )
      : "lg";

  const { t } = useTranslation();
  const { errorToast } = useToast();

  const [selectedConfigurations, setSelectedConfigurations] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (availableBinConfigurationsError) {
      const errorMessage = getMessageFromRtkError(
        availableBinConfigurationsError
      );
      errorToast(
        `Error occurred when getting available bin configurations: ${errorMessage}`
      );
    }
  }, [availableBinConfigurationsError, errorToast]);

  useEffect(() => {
    if (currentBinConfigurations)
      setSelectedConfigurations(currentBinConfigurations);
  }, [currentBinConfigurations]);

  const selectAllConfigurations = () => {
    if (availableBinConfigurations)
      setSelectedConfigurations(
        availableBinConfigurations.map((b) => b.configurationType)
      );
  };

  return (
    <DialogRoot
      fullWidth
      maxWidth={browserWidth}
      onClose={() => dispatch(closeBinConfigModal())}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle data-testid="simple-dialog-title">
        <Typography sx={{ fontSize: "24px" }} data-testid="title-text">
          {t("select bin configuration")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="space-evenly">
          {availableBinConfigurations?.map((conf) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <Grid
              item
              key={conf.numberOfCompartments}
              data-testid={`binconfiguration-${conf.configurationType}`}
              xs={12}
              sm={12}
              md={4}
              onClick={() => {
                if (isOneSelection) {
                  setSelectedConfigurations([conf.configurationType]);
                } else if (
                  selectedConfigurations.includes(conf.configurationType)
                ) {
                  setSelectedConfigurations(
                    selectedConfigurations.filter(
                      (selectedConf) => selectedConf !== conf.configurationType
                    )
                  );
                } else {
                  setSelectedConfigurations([
                    ...selectedConfigurations,
                    conf.configurationType
                  ]);
                }
                if (dimensionsCallback) {
                  dimensionsCallback({
                    numOfRows: conf.horizontalCompartmentCount,
                    numOfCols: conf.verticalCompartmentCount
                  });
                }
              }}
            >
              <BinComponent
                style={{ width: "93%" }}
                aria-label={
                  selectedConfigurations.includes(conf.configurationType)
                    ? "selected-bin"
                    : "unselected-bin"
                }
                sx={
                  selectedConfigurations.includes(conf.configurationType)
                    ? {
                        padding: 0,
                        borderRadius: "0.5em",
                        border: `0.625em solid`,
                        borderColor: "info.main"
                      }
                    : {}
                }
              >
                <AutostoreBin
                  state={"Port Closed"}
                  pickQuantity={0}
                  pickCompartment={null}
                  numberOfRows={conf.horizontalCompartmentCount}
                  numberOfColumns={conf.verticalCompartmentCount}
                  hideBinId
                />
              </BinComponent>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          top: "inherit",
          bottom: "0",
          position: "sticky",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "24px",
          paddingRight: "24px"
        }}
      >
        {!isOneSelection && (
          <div style={{ float: "left" }}>
            <ProgressButton
              id="get-empty-bin-button"
              data-testid="get-empty-bin-button"
              buttonSize="footer"
              emphasis="high"
              responsive
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<SelectAllIcon style={{ fontSize: 22 }} />}
              onClick={() => selectAllConfigurations()}
            >
              {t("select all")}
            </ProgressButton>
          </div>
        )}
        <div style={{ float: "right" }}>
          <ProgressButton
            id="get-empty-bin-button"
            data-testid="get-empty-bin-button"
            buttonSize="footer"
            emphasis="high"
            responsive
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<CheckIcon style={{ fontSize: 22 }} />}
            onClick={() => {
              saveBinConfigurations(selectedConfigurations);
              dispatch(closeBinConfigModal());
            }}
            disabled={!selectedConfigurations.length}
          >
            {t("confirm")}
          </ProgressButton>
        </div>
      </Grid>
    </DialogRoot>
  );
}
