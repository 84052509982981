import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import moment from "moment-timezone";

dayjs.extend(utc);

/** ISO 8601 standard date format. */
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const globalDateFormat: string = "YYYY-MM-DD";

/** ISO 8601 standard date and time format. */
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const globalDateTimeFormat: string = `${globalDateFormat} HH:mm:ss`;

/** Default time zone to use when Fulfillment Center time zone is not provided. */
const defaultTimezone = "America/New_York";

/**
 * Format the given date as an ISO 8601 date without time.
 *
 * WARNING: JS treats dates as being in local browser time, so the
 * input date may not match the original FC time.
 * E.g. if the FC is in Mountain Time and the browser is in European Time,
 *      an off-by-one error may occur.
 *
 * @date The date to format.
 * */
export const formatDate = (date: Date | moment.Moment): string =>
  moment(date).format(globalDateFormat);

/**
 * Format the given date as an ISO 8601 date without time, defaulting to null if it's null or undefined.
 *
 * WARNING: JS treats dates as being in local browser time, so the
 * input date may not match the original FC time.
 * E.g. if the FC is in Mountain Time and the browser is in European Time,
 *      an off-by-one error may occur.
 *
 * @date The date to format.
 * */
export const formatDateMaybe = (
  date: Date | null | undefined
): string | null => (date ? formatDate(date) : null);

/** Get the current date, formatted as ISO 8601. */
export const formatCurrentDate = (): string => formatDate(new Date());

/**
 * Format the given date and time as ISO 8601.
 *
 * @date The date and time to format.
 * @timeZone The time zone to use. Uses a default if not specified.
 * */
export const formatDateTime = (
  date: Date | null | undefined,
  timeZone?: string | null
): string =>
  date
    ? moment.tz(date, timeZone || defaultTimezone).format(globalDateTimeFormat)
    : "";

/**
 * Take the given date, converts to UTC, and format just the date portion of it.
 * E.g. given 2022-04-05 13:43:21-05:00, will return "2022-04-05"
 * Or,  given 2022-04-05 00:00:00-00:00, will return "2022-04-05"
 *
 * NOTE: If the date is not already in UTC, this function may switch to a different date.
 * E.g. 2022-04-05 22:00:00-05:00 will return "2022-04-06" since that is the UTC date.
 *
 * @date The date.
 */
export const formatUtcDate = (
  date: Date | moment.Moment | string | null | undefined
): string => (date ? moment.utc(date).format(globalDateFormat) : "");

/** Cloned from the moment version of this function */
export const formatUtcDateDayjs = (
  date: Date | Dayjs | string | null | undefined
): string => (date ? dayjs.utc(date).format(globalDateFormat) : "");

/**
 * Take the given Moment, converts to UTC, and format just the date portion of it.
 * Defaults to null.
 *
 * E.g. given 2022-04-05 13:43:21-05:00, will return "2022-04-05"
 * Or,  given 2022-04-05 00:00:00-00:00, will return "2022-04-05"
 *
 * NOTE: If the date is not already in UTC, this function may switch to a different date.
 * E.g. 2022-04-05 22:00:00-05:00 will return "2022-04-06" since that is the UTC date.
 *
 * @momentDate The Moment date.
 */
export const formatUtcMomentMaybe = (
  momentDate: moment.Moment | null | undefined
): string | null => momentDate?.utc()?.format(globalDateFormat) ?? null;

/** Cloned from the moment version of this function */
export const formatUtcDayjsMaybe = (
  dayjsDate: Dayjs | null | undefined
): string | null => dayjsDate?.utc()?.format(globalDateFormat) ?? null;

/**
 * Gets the given date at the given time zone, ensuring we just get the date portion.
 * E.g. if given "12/22/2021 8:30 AM", will get "2021-12-22 00:00:00 -XX:XX" regardless of
 * what the current browser time zone and given time zone are. The trailing offset will
 * be for the given FC time zone, or for the browser time zone if not specified.
 *
 * @date The date and time to extract date from. Defaults to current browser date and time if null.
 * @fcTimeZone The Fulfillment Center's time zone, if specified. Will default to using browser time zone if not specified.
 */
export const getDateForTimeZone = (
  date: Date | null,
  fcTimeZone: string | undefined
): string => {
  // Format for getting midnight of the given date in ISO 8601 format. E.g. "2022-12-21 00:00:00"
  const isoDateFormat = "YYYY-MM-DD 00:00:00";
  const dateToUse = moment(date || new Date());

  const dateWithTimeZone = fcTimeZone
    ? moment.tz(dateToUse.format(isoDateFormat), fcTimeZone)
    : dateToUse;

  const formattedDate = dateWithTimeZone.format(`${isoDateFormat} Z`);

  return formattedDate;
};

// Relevant SO: https://stackoverflow.com/a/41125840
// ISO 8601 formatted dates are treated as UTC, which leads to off-by-one errors when the
// date is converted back to a date + time.
/**
 * Gets the given UTC date in browser time.
 *
 * @date The date.
 */
export const getUtcDateInBrowserTime = (
  date: Date | string | null
): Date | null => {
  if (!date) return null;

  const utcDate = formatUtcDate(date);
  const withSlashes = utcDate.replace("-", "/");
  const newDate = new Date(withSlashes);
  return newDate;
};

/** Cloned from the moment version of this function */
export const getUtcDateInBrowserTimeDayjs = (
  date: Dayjs | null
): Date | null => {
  if (!date) return null;

  const utcDate = formatUtcDateDayjs(date);
  const withSlashes = utcDate.replace("-", "/");
  const newDate = new Date(withSlashes);
  return newDate;
};

/** Calculates the difference in days between two dates */
export const calculateDateDifference = (
  startDate: Date,
  endDate: Date
): number => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const diffInDays = endMoment.diff(startMoment, "days");
  return diffInDays;
};

/** Check if the given date is before today's date. */
export const isPastDate = (date: Date | null) => {
  const today = moment().startOf("day");
  return moment(date).isBefore(today);
};

/** Checks if End Date is before Start Date */
export const isEndDateBeforeStartDate = (
  startDate: Date | null,
  endDate: Date | null
) => {
  if (startDate && endDate) {
    return moment(endDate).isBefore(startDate);
  }
  return false;
};

/** Is the given date before or equal to the expiration date? */
export const isExpired = (
  date: moment.Moment | Date,
  expirationDate: moment.Moment | null
) => {
  if (!expirationDate) return false;

  const endOfDate = moment(date).endOf("day");

  const isExpired = expirationDate.isSameOrBefore(endOfDate);

  return isExpired;
};
