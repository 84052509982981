import { Box, Card, Typography, Stack, TextField, Alert } from "@mui/material";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  useFormContext
} from "react-hook-form";

import { Range } from "~/redux/warehouse/tools.openApi";

type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];
type PropertiesOfType<T, U> = {
  [K in KeysOfType<T, U>]: K;
};

export const RangeFields = <T,>({
  propertyName,
  label
}: {
  propertyName: KeysOfType<T, Range>;
  label: string;
}) => {
  const formContext = useFormContext<PropertiesOfType<T, Range>>();
  const minErrorMessage = (
    formContext.formState.errors[propertyName] as Merge<
      FieldError,
      FieldErrorsImpl<Range>
    >
  )?.min?.message;
  const maxErrorMessage = (
    formContext.formState.errors[propertyName] as Merge<
      FieldError,
      FieldErrorsImpl<Range>
    >
  )?.max?.message;
  return (
    <Box sx={{ flexBasis: "200px" }}>
      <Card sx={{ p: 1 }}>
        <Typography variant="body1">{label}</Typography>
        <Stack direction="row" flexWrap="wrap" gap={2}>
          <TextField
            {...formContext.register(
              `${String(propertyName)}.min` as Path<PropertiesOfType<T, Range>>,
              {
                required: { value: true, message: "Min is required" },
                min: { value: 1, message: "Min must be greater than zero" }
              }
            )}
            sx={{ flexBasis: "75px" }}
            variant="standard"
            label="Min"
            type="number"
            error={!!minErrorMessage}
          />
          <TextField
            {...formContext.register(
              `${String(propertyName)}.max` as Path<PropertiesOfType<T, Range>>,
              {
                required: { value: true, message: "Max is required" },
                validate: (value, formValues) => {
                  if (
                    (value as number) <
                    (formValues[propertyName] as unknown as Range).min
                  )
                    return "Max must be greater than Min";
                  return true;
                }
              }
            )}
            sx={{ flexBasis: "75px" }}
            variant="standard"
            label="Max"
            type="number"
            error={!!maxErrorMessage}
          />
        </Stack>
      </Card>
      {(!!minErrorMessage || !!maxErrorMessage) && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {!!minErrorMessage && <Typography>{minErrorMessage}</Typography>}
          {!!maxErrorMessage && <Typography>{maxErrorMessage}</Typography>}
        </Alert>
      )}
    </Box>
  );
};
