import * as Sentry from "@sentry/react";
import { ErrorBoundaryProps } from "@sentry/react";
import { AxiosError } from "axios";

import { ErrorPage } from "../errorPage/ErrorPage";

/**
 * An error boundary that catches errors and renders an <ErrorPage> component instead.
 *
 * Errors caught by this error boundary are unexpected and are logged to sentry.
 *
 * See more at https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
export function ErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        const axiosError = error as AxiosError;
        const code = axiosError?.response?.status;
        const message = axiosError?.message;

        return (
          <ErrorPage
            errorMessage={message}
            errorCode={code}
            goBackLocation="/"
          />
        );
      }}
      {...props}
    />
  );
}
