import dayjs from "dayjs";

// Languages
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/ko";

// Plugins
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
