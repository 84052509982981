import {
  Box,
  Typography,
  styled,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from "@mui/material";
import { ProgressButton, ModalWrapper } from "@qubit/autoparts";
import { useState } from "react";
import { Layout } from "react-grid-layout";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "~/app/store";

import { copyToClipboard, copyFromClipboard } from "~/lib/helpers";

import {
  setBoardLayout,
  setBoardDimensions,
  selectAndonBoardLayout,
  selectAndonBoardDimensions
} from "./andon.slice";

type LayoutCodeModalProps = {
  isOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
};

type LayoutCode = {
  layout: Layout[];
  dimensions: {
    rows: number;
    columns: number;
  };
};

function validateLayout(layoutCode: LayoutCode) {
  try {
    const layoutToVerify = layoutCode.layout;
    const dimensionsToVerify = layoutCode.dimensions;

    if (
      typeof dimensionsToVerify.rows !== "number" ||
      typeof dimensionsToVerify.columns !== "number"
    )
      return false;

    if (!Array.isArray(layoutCode.layout)) {
      return false;
    }

    return layoutToVerify.every((item) => {
      return (
        typeof item.w === "number" &&
        typeof item.h === "number" &&
        typeof item.x === "number" &&
        typeof item.y === "number" &&
        typeof item.i === "string" &&
        typeof item.maxH === "number" &&
        typeof item.moved === "boolean" &&
        typeof item.static === "boolean"
      );
    });
  } catch {
    return false;
  }
}

// Styled textarea component with Material UI
const StyledTextArea = styled("textarea")(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflowY: "scroll",
  padding: theme.spacing(1),
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  fontSize: "20px",
  boxSizing: "border-box",
  "&:focus": {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: "2px"
  }
}));

function LayoutCodeModalModal(props: LayoutCodeModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [inputCode, setInputCode] = useState("");
  const [selectedCode, setSelectedCode] = useState("");

  const { setModalOpen, isOpen } = props;
  const boardLayoutCode = JSON.stringify({
    layout: useAppSelector(selectAndonBoardLayout),
    dimensions: useAppSelector(selectAndonBoardDimensions)
  });

  const configBoardLayouts = useAppSelector(
    (state) => state.site.clientConfig.an_boardLayouts
  );

  const save = () => {
    try {
      const newLayoutCode: LayoutCode = JSON.parse(inputCode) as LayoutCode;

      const isValid = validateLayout(newLayoutCode);

      if (isValid) {
        dispatch(setBoardLayout(newLayoutCode.layout));
        dispatch(setBoardDimensions(newLayoutCode.dimensions));
      }
    } catch {
      // do not fail
    }
  };

  const isValidJSON = (jsonString: string) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t("layout code")}
      setModalOpen={(isOpen) => {
        setModalOpen(isOpen);
      }}
    >
      <Box
        sx={{
          width: "70vw",
          height: "70vh",
          overflowY: "hidden"
        }}
      >
        {/* top part */}
        <Box
          sx={{
            width: "100%",
            height: "20%",
            padding: "10px",
            boxSizing: "border-box",
            display: configBoardLayouts.length ? "flex" : "none"
          }}
        >
          <Box sx={{ width: "20%" }}>
            <Typography>{t("default codes")}</Typography>
          </Box>
          <Box sx={{ width: "55%", padding: "10px", boxSizing: "border-box" }}>
            <FormControl fullWidth>
              <InputLabel data-testid="change-board-layout-dropdown-label">
                {t("layouts")}
              </InputLabel>
              <Select
                labelId="change-default-andon-config-modal-dropdown-label"
                data-testid="change-default-andon-config-modal-dropdown"
                value={selectedCode}
                inputProps={{
                  "aria-label": "change-default-andon-config-modal-dropdown"
                }}
              >
                {configBoardLayouts.map((layout, index) => (
                  <MenuItem
                    value={layout.name}
                    key={layout.name}
                    data-testid={`layout-${index}`}
                    onClick={() => {
                      const andonBoardConfigName = layout.name;

                      setSelectedCode(andonBoardConfigName);
                      const selectedDefaultLayoutCode = configBoardLayouts.find(
                        (defaultLayout) =>
                          defaultLayout.name === andonBoardConfigName
                      );

                      if (selectedDefaultLayoutCode) {
                        setInputCode(
                          JSON.stringify({
                            layout: selectedDefaultLayoutCode.layout,
                            dimensions: selectedDefaultLayoutCode.dimensions
                          })
                        );
                      }
                    }}
                  >
                    {layout.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* middle part */}
        <Box
          sx={{
            width: "100%",
            height: "40%",
            display: "flex",
            padding: "10px",
            boxSizing: "border-box"
          }}
        >
          <Box sx={{ width: "20%" }}>
            <Typography>{t("new code")}</Typography>
          </Box>
          <Box
            sx={{
              width: "55%"
            }}
          >
            <StyledTextArea
              value={inputCode}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setInputCode(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ width: "25%" }}>
            <ProgressButton
              emphasis="high"
              responsive
              variant="contained"
              color="primary"
              onClick={async () => {
                const clipboardText = await copyFromClipboard();
                if (clipboardText) setInputCode(clipboardText);
                return Promise.resolve();
              }}
              sx={{ marginLeft: "20px", marginTop: "20px" }}
            >
              {t("paste")}
            </ProgressButton>
            <ProgressButton
              emphasis="high"
              responsive
              variant="contained"
              color="primary"
              onClick={() => {
                setInputCode("");
                return Promise.resolve();
              }}
              sx={{ marginLeft: "20px", marginTop: "20px" }}
            >
              {t("clear")}
            </ProgressButton>
            <ProgressButton
              emphasis="high"
              responsive
              variant="contained"
              color="primary"
              disabled={
                !isValidJSON(inputCode) ||
                (isValidJSON(inputCode) &&
                  !validateLayout(JSON.parse(inputCode) as LayoutCode))
              }
              onClick={() => {
                save();
                return Promise.resolve();
              }}
              sx={{ marginLeft: "20px", marginTop: "20px" }}
            >
              {t("use")}
            </ProgressButton>
          </Box>
        </Box>

        {/* bottom part */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: "40%",
            padding: "10px",
            boxSizing: "border-box"
          }}
        >
          <Box sx={{ width: "20%" }}>
            <Typography>{t("current code")}</Typography>
          </Box>
          <Box
            sx={{
              width: "55%"
            }}
          >
            <StyledTextArea disabled value={boardLayoutCode} />
          </Box>
          <Box sx={{ width: "25%", height: "100%" }}>
            <ProgressButton
              emphasis="high"
              responsive
              variant="contained"
              color="primary"
              onClick={async () => {
                await copyToClipboard(boardLayoutCode);
                return Promise.resolve();
              }}
              sx={{ marginLeft: "20px" }}
            >
              {t("copy")}
            </ProgressButton>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
}

export default LayoutCodeModalModal;
