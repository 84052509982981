import { Paper, Stack, Typography } from "@mui/material";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowCollapse
} from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { buildBatchStatusFilterAdmin } from "~/lib/helpers";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetBatchesQuery } from "~/redux/warehouse/batches.hooks";
import { BatchSummaryDto } from "~/types/api";

export const TaskGroupTableByBatch = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<Guid[]>([]);

  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );

  const { data: batchesResponse, isLoading } = useGetBatchesQuery(
    (selectedFulfillmentCenter?.pickingConfigurations || []).includes(
      "Autostore"
    )
      ? {
          limit: 1000,
          status: buildBatchStatusFilterAdmin(selectedFulfillmentCenter)
        }
      : skipToken
  );

  return (
    <ASTableV2
      gridTemplateColumns="repeat(3, auto)"
      isLoading={isLoading}
      isEmpty={!batchesResponse?.totalBatchCount}
      isEmptyMessage={t("no batches found")}
    >
      <ASTableV2Header>
        <ASTableV2Row>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Batch Name")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("status")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("TaskGroupId")}</Typography>
          </ASTableV2Cell>
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {(
          batchesResponse?.batches.filter(
            (batch) => batch.batchType === "Autostore"
          ) || []
        )
          .toSorted((a: BatchSummaryDto, b: BatchSummaryDto) => {
            if (!b.taskGroupId) {
              return 1;
            }
            return (a.taskGroupId || 0) - (b.taskGroupId || 0);
          })
          .map((batch) => (
            <Fragment key={batch.batchId}>
              <ASTableV2Row
                selected={selectedRows.includes(batch.batchId)}
                onClick={() => {
                  if (
                    selectedRows.length &&
                    selectedRows.includes(batch.batchId)
                  ) {
                    setSelectedRows([]);
                  } else {
                    setSelectedRows([`${batch.batchId}`]);
                  }
                }}
              >
                <ASTableV2Cell>
                  <Typography variant="tableBody">{batch.batchName}</Typography>
                </ASTableV2Cell>
                <ASTableV2Cell>
                  <Typography variant="tableBody">{batch.status}</Typography>
                </ASTableV2Cell>
                <ASTableV2Cell>
                  <Typography variant="tableBody">
                    {batch.taskGroupId || ""}
                  </Typography>
                </ASTableV2Cell>
              </ASTableV2Row>
              <ASTableV2RowCollapse
                expanded={selectedRows.includes(batch.batchId)}
                selected={selectedRows.includes(batch.batchId)}
              >
                <Stack
                  flexDirection="row"
                  gap={2}
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  {batch.orderSummaries.map((order) => (
                    <Paper
                      key={order.orderId}
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1
                      }}
                    >
                      <Typography variant="tableBody">
                        Order Id: {order.orderId}
                      </Typography>
                      <Typography variant="tableBody">
                        Customer: {order.firstName} {order.lastName}
                      </Typography>
                    </Paper>
                  ))}
                </Stack>
              </ASTableV2RowCollapse>
            </Fragment>
          ))}
        <ASTableV2Row></ASTableV2Row>
      </ASTableV2Body>
    </ASTableV2>
  );
};
