import { totesApi } from "./totes";

export const {
  useGetTotesQuery,
  useStageTotesMutation,
  useLoadTotesMutation,
  useSplitMutation,
  useTransferMutation,
  useVerifyTotesMutation,
  useScanLabelMutation,
  usePlaceLabelMutation,
  useConfirmTotePositionMutation,
  useConfirmAllTotePositionsMutation
} = totesApi;
