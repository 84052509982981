import { useToast } from "@qubit/autoparts";
import { useCallback } from "react";

import { useAppSelector } from "~/app/store";

export const usePrinter = () => {
  const { errorToast } = useToast();
  const defaultPrinter = useAppSelector(
    (state) => state.printer.defaultPrinter
  );

  return useCallback(
    (zpl: string) => {
      const printWith = (device: Device) => {
        device.send(
          zpl,
          () => {},
          () => {
            errorToast("Failed to print.");
          }
        );
      };

      if (defaultPrinter) {
        printWith(defaultPrinter);
      } else {
        BrowserPrint.getDefaultDevice("printer", printWith, () => {
          errorToast("No default device found.");
        });
      }
    },
    [errorToast, defaultPrinter]
  );
};
