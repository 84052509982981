import { Button, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";

import { selectBatch } from "./carts.slice";

export function CartsToolbar() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectedBatch = useAppSelector((state) => state.carts.selectedBatch);

  const stageButtonText =
    (selectedBatch && selectedBatch.batchType) !== "Bulk"
      ? t("stage totes")
      : t("stage bulk");

  return (
    <Toolbar>
      <UnselectButton onClick={() => dispatch(selectBatch(null))} />
      <NavBarButtonGroup>
        {selectedBatch && (
          <Button
            color="secondary"
            onClick={(): void =>
              navigate(`/batches/${selectedBatch.batchName}/stage`)
            }
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {stageButtonText}
            </Typography>
          </Button>
        )}
      </NavBarButtonGroup>
    </Toolbar>
  );
}
