import { Box, ListItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { createProductSearchOptions } from "~/config/searchProductConfig";
import NavSearchInput from "~/features/navbar/NavSearchInput";

import { searchProduct } from "~/lib/helpers";
import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import {
  selectAutostoreGridIdOverride,
  selectIsAdjustingBins
} from "~/redux/selectors/inventorySelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { BinOrProductResult } from "./InventoryMainV2";
import { BinSearchData, getBinIds } from "./getBinIds";
import { useAutocompleteSelect } from "./useAutocompleteSelect";
import { useViewType } from "./useViewType";

export function ProductBinSearchInput() {
  const { t } = useTranslation();
  const trackedPageName = useMixpanelPageName();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [autocompleteOptions, updateAutocompleteOptions] = useState<
    BinOrProductResult[]
  >([] as BinOrProductResult[]);
  const { viewType } = useViewType();

  const handleAutocompleteSelect = useAutocompleteSelect();

  const isAdjustingBins = useAppSelector(selectIsAdjustingBins);
  const usersClientId = useAppSelector(selectUsersClientId);
  const workstation = useAppSelector(selectThisWorkstation);
  const gridIdOverride = useAppSelector(selectAutostoreGridIdOverride);
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  // clear autocomplete options if view changes from bin to product or vice versa
  useEffect(() => updateAutocompleteOptions([]), [viewType]);

  const searchBinsByText = useCallback(
    (searchText: string) =>
      getBinIds({
        searchText,
        usersClientId,
        usersFulfillmentCenter,
        usersAutostoreGridId: workstation?.autostoreGridId ?? gridIdOverride
      }),
    [
      gridIdOverride,
      usersClientId,
      usersFulfillmentCenter,
      workstation?.autostoreGridId
    ]
  );

  const createBinSearchOptions = (binRecord: BinSearchData[]) =>
    binRecord.map(
      (b: {
        autostore_bin_number: number;
      }): { displayText: string; type: "bin" } => ({
        displayText: String(b.autostore_bin_number),
        type: "bin"
      })
    );

  const autocompleteSearchBinsAndProducts = async (
    input: string | null
  ): Promise<void> => {
    // search bins
    if (input === null || input === "" || !usersClientId) {
      updateAutocompleteOptions([]);
    } else {
      const [binSearch, hits] = await Promise.all([
        searchBinsByText(input),
        searchProduct(input)
      ]);

      const hitsAsAutocompleteRecords = createProductSearchOptions({ hits });
      const binsAsAutocompleteRecords = createBinSearchOptions(binSearch);
      updateAutocompleteOptions([
        ...binsAsAutocompleteRecords,
        ...hitsAsAutocompleteRecords
      ]);
    }
  };

  const searchPlaceholder = t("search products or bin");

  return (
    <NavSearchInput<BinOrProductResult>
      textInput={searchText || ""}
      setTextInput={(text) => setSearchText(text)}
      isAutocomplete
      searchPlaceholder={searchPlaceholder}
      autocompleteSearchCb={(input) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        autocompleteSearchBinsAndProducts(input);
      }}
      autocompleteOptions={autocompleteOptions}
      autocompleteLabelConstructor={(option: BinOrProductResult): string =>
        option.displayText
      }
      groupBy={(option) => t(option.type)}
      selectCb={(result: BinOrProductResult) => {
        handleAutocompleteSelect(result);
        mixpanelTrack({
          trackedPageName,
          type: "Select Search Result",
          label: "Product/Bin Search Result",
          eventProperties: result
        });
      }}
      clearTextOnSelect
      renderOption={(params, option) => (
        <ListItem {...params} key={option.displayText}>
          <Box mr={1}>{option.displayText}</Box>
        </ListItem>
      )}
      autofocus={!isAdjustingBins}
    />
  );
}
