import { createSelector } from "@reduxjs/toolkit";

import { AppSelector } from "~/app/store";
import { AS_HomepageType } from "~/config/clientConfig";

import { LanguageCode } from "~/localization_i18n";
import { StoreState } from "~/redux/reducers";

export const selectLanguageCode = (state: StoreState) =>
  state.site.languageCode;

export const selectLocale: AppSelector<LanguageCode> = createSelector(
  [selectLanguageCode],
  (languageCode) => (languageCode === "bs" ? "en" : languageCode)
);

export const selectClientConfig = (state: StoreState) =>
  state.site.clientConfig;

export const selectUserMessages = (state: StoreState) =>
  state.site.userMessages;

export const selectCartAssignmentEnabled: AppSelector<boolean> = createSelector(
  [selectClientConfig],
  (clientConfig) => clientConfig.cartAssignmentEnabled
);

export const selectFusionPortEnabled: AppSelector<boolean> = createSelector(
  [selectClientConfig],
  (clientConfig) => clientConfig.ap_fusionPortScreenEnabled
);

export const selectPickToLightEnabled: AppSelector<boolean> = createSelector(
  [selectClientConfig],
  (clientConfig) => clientConfig.ap_showPickToLight
);

export const selectHomepages: AppSelector<AS_HomepageType[]> = createSelector(
  [selectClientConfig],
  (clientConfig) => clientConfig.fc_autostore_homepages
);

export const selectInventoryDateLabelEnabled: AppSelector<string> =
  createSelector(
    [selectClientConfig],
    (clientConfig) => clientConfig.inv_inventoryDateLabel
  );

export const selectAutomatedOperationsEnabled = (state: StoreState) =>
  state.site.automatedOperationsEnabled;

export const selectManualOpsEnabled: AppSelector<boolean | undefined> =
  createSelector(
    [selectClientConfig],
    (clientConfig) => clientConfig.manualOpsEnabled
  );
