import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Cell, Pie, PieChart } from "recharts";

export type CircularProgressWithLabelProps = CircularProgressProps & {
  completeCount: number;
  cancelledCount: number;
  totalCount: number;
  showBatchPicks?: boolean;
};

export function CircularProgressWithLabel(
  props: CircularProgressWithLabelProps
) {
  const { palette } = useTheme();
  const { completeCount, cancelledCount, totalCount, showBatchPicks } = props;

  // Qubit receives info about batch related picks differently in comparation with order related picks
  // For batches, complete count is sum of completed and cancelled picks
  // So before displaying the number of completed picks fot batches, we need to subtract "canclledCount" from "completeCount"
  const circleProgressData = [
    {
      name: "Completed picks",
      value: completeCount,
      color: palette.pickProgressBarGreen.main
    },
    {
      name: "Cancelled picks",
      value: cancelledCount,
      color: palette.autostoreRed.main
    },
    {
      name: "Uncompleted picks",
      value: totalCount - completeCount - cancelledCount,
      color: palette.darkGray.light
    }
  ].filter((c) => c.value);

  return (
    <Box
      position="relative"
      display="inline-flex"
      aria-label={props["aria-label"] ?? "circularProgressWithLabel"}
    >
      <PieChart width={50} height={50} data-testid="progressbar">
        <Pie
          dataKey="value"
          data={circleProgressData}
          cx={20}
          cy={20}
          innerRadius={showBatchPicks ? 18 : 13}
          outerRadius={showBatchPicks ? 24 : 18}
          isAnimationActive={false}
        >
          {circleProgressData.map((entry) => (
            <Cell key={`cell-${entry.name}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div">
          {/*
            Qubit receives info about batch related picks differently in comparation with order related picks
            For batches, complete count is sum of completed and cancelled picks
            So before displaying the number of completed picks fot batches, we need to subtract "canclledCount" from "completeCount"
          */}
          {`${completeCount}/${totalCount}`}
        </Typography>
      </Box>
    </Box>
  );
}
