import { useToast } from "@qubit/autoparts";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { NextEmptyBinSuccessAction } from "~/redux/actions";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";

import { useNextEmptyBinMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import {
  useLazyGetWorkstationsQuery,
  useStartInductionMutation
} from "~/redux/warehouse/workstation.hooks";

import {
  setPortPollingActive,
  setClosePortBtnDisabled
} from "./autostorePutaway.slice";

const configToCompartmentCountMap: { [config: string]: number } = {
  "Whole Bin": 1,
  "Half Bin": 2,
  "Quarter Bin": 4
};

export function useStartInduction() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { errorToast, warningToast } = useToast();
  const [startInduction] = useStartInductionMutation();
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const shouldListenToGridEvents = useShouldListenToGridEvents();

  const [nextEmptyBin] = useNextEmptyBinMutation();
  const [getWorkstations, { error: getWorkstationsError }] =
    useLazyGetWorkstationsQuery();

  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const nextEmptyBinFunc = async (portId: number) => {
    if (!selectedAutostoreGridId || !siteWorkstation) return;

    try {
      const result = await nextEmptyBin({
        autostoreGridId: selectedAutostoreGridId,
        portId
      }).unwrap();

      if (!result) return;

      // for compatibility until all calls to legacy redux are moved
      dispatch<NextEmptyBinSuccessAction>({
        type: "autostore/NEXT_EMPTY_BIN_SUCCESS",
        payload: {
          nextEmptyBin: result,
          portId
        }
      });

      dispatch(setClosePortBtnDisabled(false));
    } catch (err) {
      const errorMessage = getMessageFromRtkError(err);
      const inductionBinConfigurations = siteWorkstation.ports.find(
        (p) => p.portId === portId
      )?.inductionBinConfiguration;

      if (
        inductionBinConfigurations?.length &&
        errorMessage.includes(
          "There is nothing left to pick or no appropriate bin found."
        )
      ) {
        const message = inductionBinConfigurations
          .map((ibc) =>
            t(
              `autostoreBin.no available ${configToCompartmentCountMap[ibc] as 1 | 2 | 4} compartments`
            )
          )
          .join("; ");

        warningToast(message);
      } else {
        errorToast(errorMessage);
      }
    }

    if (!shouldListenToGridEvents) {
      dispatch(setPortPollingActive(true));
    }
  };

  // Call start-induction and next-empty-bin
  useEffect(() => {
    if (!siteWorkstation) {
      return;
    }
    void (async () => {
      try {
        await startInduction({
          autostoreGridId: siteWorkstation.autostoreGridId,
          workstationId: siteWorkstation.id
        });
        await getWorkstations().unwrap();

        await Promise.all(
          siteWorkstation.ports.map((port) => nextEmptyBinFunc(port.portId))
        );
      } catch (error) {
        errorToast("Start induction failed", {
          description: getMessageFromRtkError(error)
        });
      }
    })();
    // we want to only call this on mount, or if somehow the workstation chages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteWorkstation?.id]);
}
