import { FitnessCenterRounded, Redo } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  TextField,
  Button,
  IconButton,
  useMediaQuery,
  Card,
  InputAdornment,
  Alert,
  Autocomplete,
  Typography,
  DialogTitle,
  Dialog
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { mobileWidth, ProgressButton } from "@qubit/autoparts";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { warehouseService } from "~/api/warehouse";
import { useAppDispatch, useAppSelector } from "~/app/store";

import { useDebounceSearch } from "~/hooks/useDebounce";
import { useBarcodeScanner } from "~/lib/barCodeScan";
import { moveInventory } from "~/redux/actions/inventory";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { InventorySummaryDto, SearchBinRecord } from "~/types/api";

import { setIsMovePanelOpen } from "./inventory.slice";

const QtyButton = styled(Button)(() => ({
  margin: "0 5px",
  fontSize: 20
}));

const IncrementButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  background: "white",
  border: `2px solid ${theme.palette.primary.main}`,
  marginRight: 10,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main
  }
}));

const ButtonRow = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "flex-end"
}));

export type Props = {
  invToModify: InventorySummaryDto;
  refreshCb: ({
    inventoryWasEmptied
  }: {
    inventoryWasEmptied: boolean;
  }) => Promise<void>;
};

export function MoveInventoryButton(props: Props) {
  const { invToModify, refreshCb } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(mobileWidth);

  const workstationId = useAppSelector(selectThisWorkstation)?.id;
  const isOpen = useAppSelector((state) => state.inventoryNew.isMovePanelOpen);

  const [quantity, updateQuantity] = useState<number | null>(null);
  const [weight, updateWeight] = useState<number | null>(null);
  const [newLocation, setNewLocation] = useState<SearchBinRecord | null>(null);
  const [options, updateOptions] = useState<SearchBinRecord[]>([]);
  const [binFieldValue, setBinFieldValue] = useState<string | null>(null);
  const debouncedInput = useDebounceSearch(binFieldValue);
  const maxQty =
    (invToModify?.count?.value || 0) -
    (invToModify?.committedCount?.value || 0);
  const overCommitted = quantity && quantity > maxQty;
  const disabled = overCommitted || !newLocation || !quantity;

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    updateQuantity(event.target.value ? Number(event.target.value) : null);
  };

  const handleWeightChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    updateWeight(event.target.value ? Number(event.target.value) : null);
  };

  const handleMoveInventory = (): Promise<void> | null => {
    if (newLocation && quantity && invToModify) {
      return dispatch(
        moveInventory(
          {
            count: {
              units: invToModify.count?.units || "",
              value: quantity
            },
            targetBinId: newLocation.bin_id
          },
          invToModify.inventoryId,
          newLocation.bin_type,
          workstationId
        )
      );
    }
    return null;
  };

  const handleClose = useCallback(() => {
    setNewLocation(null);
    setBinFieldValue(null);
    dispatch(setIsMovePanelOpen(false));
  }, [dispatch]);

  const searchBins = async (input: string): Promise<void> => {
    let binRecords: SearchBinRecord[] = [];
    if (input.length) {
      const binSearch = await warehouseService.get<SearchBinRecord[]>(
        `/api/bins/search/${input}`
      );
      binRecords = binSearch.data;
    }
    if (binRecords.length === 1) {
      setNewLocation(binRecords[0]);
    }
    // don't give user the option to move from autostore bin to autostore bin
    const filteredBinRecords =
      invToModify?.binType === "autostore"
        ? binRecords.filter((bin) => bin.bin_type !== "autostore")
        : binRecords;
    updateOptions(filteredBinRecords);
  };

  useEffect(() => {
    if (isOpen && debouncedInput) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      searchBins(debouncedInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput, open]);
  useBarcodeScanner<boolean>({
    disabled: !isOpen,
    findScanMatch: (buffer: string) => {
      setBinFieldValue(buffer);
      return true;
    },
    processScanMatch: () => null
  });

  const quantityToDisplay = quantity || (invToModify?.count ? "" : t("n/a"));

  const quantityTextField = (
    <TextField
      id="quantity"
      label={t("quantity")}
      data-testid="quantity"
      type="number"
      variant="outlined"
      value={quantityToDisplay}
      onChange={handleQuantityChange}
      inputProps={{
        style: { fontSize: 50, padding: "35px 10px 35px 18px" }
      }}
      InputLabelProps={{
        shrink: true
      }}
      disabled={!invToModify || !invToModify.count}
    />
  );

  const quantityButtons = (
    <Grid
      container
      item
      direction="column"
      xs={6}
      spacing={1}
      wrap="nowrap"
      sx={{ position: "absolute", top: 28, right: 8 }}
    >
      <ButtonRow item xs={12}>
        <IncrementButton
          onClick={(): void => updateQuantity(quantity ? quantity + 1 : 1)}
          size="large"
          aria-label="increase quantity"
        >
          <ExpandLessIcon
            style={{
              fontSize: 27
            }}
          />
        </IncrementButton>
        <QtyButton
          variant="contained"
          color="primary"
          onClick={(): void => updateQuantity(6)}
        >
          6
        </QtyButton>
        <QtyButton
          variant="contained"
          color="primary"
          onClick={(): void => updateQuantity(8)}
        >
          8
        </QtyButton>
      </ButtonRow>
      <ButtonRow item xs={12}>
        <IncrementButton
          onClick={(): void => updateQuantity(quantity ? quantity - 1 : 0)}
          size="large"
          aria-label="decrease quantity"
        >
          <ExpandMoreIcon
            style={{
              fontSize: 27
            }}
          />
        </IncrementButton>
        <QtyButton
          id="qty24"
          variant="contained"
          color="primary"
          onClick={(): void => updateQuantity(24)}
        >
          24
        </QtyButton>
        <QtyButton
          variant="contained"
          color="primary"
          onClick={(): void => updateQuantity(36)}
        >
          36
        </QtyButton>
      </ButtonRow>
    </Grid>
  );

  const weightTextField = (
    <TextField
      id="weight"
      label={t("weight")}
      type="number"
      fullWidth
      variant="outlined"
      value={weight || ""}
      onChange={handleWeightChange}
      disabled={disabled}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FitnessCenterRounded
              color="disabled"
              style={{
                fontSize: 25
              }}
            />
          </InputAdornment>
        )
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        style: { fontSize: 50, padding: "35px 10px 35px 18px" }
      }}
    />
  );

  const MoveInventoryButton = (
    <ProgressButton
      id="move-button"
      data-testid="move-butotn"
      buttonSize="xLarge"
      emphasis="high"
      responsive
      variant="contained"
      color="primary"
      fullWidth
      startIcon={<Redo style={{ fontSize: 26 }} />}
      disabled={disabled}
      onClick={async () => {
        await handleMoveInventory();
        await refreshCb({ inventoryWasEmptied: quantity === maxQty });
        handleClose();
      }}
    >
      {t("move")}
    </ProgressButton>
  );

  const warningText = `${maxQty} is the maximum number that can be moved.  ${
    invToModify?.committedCount?.value || 0
  } units are committed.`;

  return (
    <>
      <Button
        color="secondary"
        onClick={(): void => {
          dispatch(setIsMovePanelOpen(true));
        }}
        style={{
          flexBasis: "auto",
          width: "175px"
        }}
      >
        {t("move")}
      </Button>
      <Dialog onClose={handleClose} open={isOpen}>
        <Grid container item xs={12} direction="column" wrap="nowrap">
          <Grid item>
            <Card>
              <DialogTitle sx={{ position: "relative" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                  width="100%"
                >
                  <Box flexGrow={1}>
                    <Typography variant="h6">{t("move inventory")}</Typography>
                  </Box>
                  <Box position="absolute" right="5px">
                    <IconButton onClick={handleClose}>
                      <CloseIcon sx={{ color: "primary.main" }} />
                    </IconButton>
                  </Box>
                </Box>
              </DialogTitle>
              <Box padding={4}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Autocomplete<SearchBinRecord>
                      options={options}
                      filterOptions={(ops) => ops}
                      getOptionLabel={(option: SearchBinRecord): string =>
                        option.location
                      }
                      style={{
                        background: "white"
                      }}
                      onChange={(
                        e: ChangeEvent<unknown>,
                        option: SearchBinRecord | null
                      ): void => {
                        if (e) {
                          setNewLocation(option || null);
                          setBinFieldValue(null);
                        }
                      }}
                      popupIcon={null}
                      inputValue={binFieldValue || newLocation?.location || ""}
                      blurOnSelect
                      isOptionEqualToValue={(option, value): boolean =>
                        [
                          option.location.replace(" ", ""),
                          option.location
                        ].includes(value.location)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="subInput"
                          onChange={(e): void => {
                            setBinFieldValue(e.target.value);
                            setNewLocation(null);
                          }}
                          label={` ${t("search")} ${t("locations")}`}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      position: "relative"
                    }}
                  >
                    {quantityTextField}
                    {!isMobile &&
                      invToModify &&
                      invToModify.count &&
                      quantityButtons}
                  </Grid>
                  <Grid item xs={12}>
                    {weightTextField}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{ marginTop: isMobile ? 20 : 0 }}
                >
                  <Grid item xs={12}>
                    {!!overCommitted && (
                      <Alert
                        id="warning"
                        severity="warning"
                        style={{ marginBottom: 10 }}
                      >
                        {warningText}
                      </Alert>
                    )}
                    {MoveInventoryButton}
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
