import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SearchData } from "~/features/autostorePutaway/useGetPutawayTasks";

type AutostorePutawayOldState = {
  changeQuantity: boolean;
  isPutawayTaskTableRefreshed: boolean;
  onLeaveInputTextShown: boolean;
  selectedRowId: Guid | null;
  searchData: SearchData;
};

const initialState: AutostorePutawayOldState = {
  changeQuantity: false,
  isPutawayTaskTableRefreshed: false,
  onLeaveInputTextShown: false,
  selectedRowId: null,
  searchData: {
    scannedBarcodes: [],
    offset: 1
  }
};

export const autostorePutawayOldSlice = createSlice({
  name: "autostorePutawayOld",
  initialState,
  reducers: {
    setChangeQuantity(state, { payload }: PayloadAction<boolean>) {
      state.changeQuantity = payload;
    },
    setIsPutawayTaskTableRefreshed(state, { payload }: PayloadAction<boolean>) {
      state.isPutawayTaskTableRefreshed = payload;
    },
    setOnLeaveInputTextShown(state, { payload }: PayloadAction<boolean>) {
      state.onLeaveInputTextShown = payload;
    },
    selectRow(state, { payload }: PayloadAction<Guid | null>) {
      state.selectedRowId = payload;
    },
    setSearchData(state, { payload }: PayloadAction<SearchData>) {
      state.searchData = payload;
    }
  }
});

export const {
  selectRow,
  setChangeQuantity,
  setIsPutawayTaskTableRefreshed,
  setOnLeaveInputTextShown,
  setSearchData
} = autostorePutawayOldSlice.actions;
