import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  FetchCycleCountAction,
  FetchCycleCountSuccessAction,
  FetchCycleCountFailureAction,
  FetchCycleCountsUncountedBinsAction,
  FetchCycleCountsUncountedBinsSuccessAction,
  FetchCycleCountsUncountedBinsFailureAction,
  FetchCycleCountInventoryRecordsAction,
  FetchCycleCountInventoryRecordsSuccessAction,
  FetchCycleCountInventoryRecordsFailureAction,
  GetCycleCountFrequencyAction,
  GetCycleCountFrequencySuccessAction,
  GetCycleCountFrequencyFailureAction,
  PostBinCountAction,
  PostBinCountFailureAction,
  ModifyUncountedBinAction,
  ModifyShapedCycleCountInfoAction,
  CompleteCycleCountSuccessAction,
  CompleteCycleCountFailureAction,
  RemoveCycleCountSuccessAction,
  RemoveCycleCountFailureAction,
  ClearCycleCountsStateAction,
  ClearMessageAction,
  ShapedCycleCountInfo,
  FetchIncompleteCycleCountCountingTasksActionV2,
  FetchIncompleteCycleCountCountingTasksSuccessActionV2,
  FetchIncompleteCycleCountCountingTasksFailureActionV2,
  ClearActiveCountingTask,
  SetSelectedCountingTaskAction,
  CycleCountTaskDto,
  ClearSelectedCountingTasksAction
} from "~/redux/actions/cycleCounts";
import { GetVariantByVariantIdFailureAction } from "~/redux/actions/inventory";
import {
  CycleCountV1Dto,
  InventoryDto,
  UncountedBinDto,
  IncompleteCycleCountsResponse
} from "~/types/api";

export type CycleCountsState = {
  loadingCycleCount: boolean; // likely delete
  cycleCount: CycleCountV1Dto | null; // likely delete
  loadingCycleCountBins: boolean;
  loadingCycleCountVariantFrequency: boolean;
  cycleCountsUncountedBins: UncountedBinDto[];
  shapedCycleCountInfos: ShapedCycleCountInfo[];
  cycleCountInventoryRecords: InventoryDto[];
  cycleCountFrequency: { variantId: Guid | null; frequency: number | null };
  incompleteCycleCountsWithTasks: IncompleteCycleCountsResponse | null;
  loadingIncompleteCountingTasks: boolean;
  selectedCountingTasks: CycleCountTaskDto[] | null;
  activeCountingTask: {
    cycleCountId: Guid | null;
    inventoryId: Guid | null;
    autostoreBinNumber: number | null;
  };
  verifiedCountingTasks:
    | {
        cycleCountId: Guid | null;
        inventoryId: Guid | null;
        autostoreBinNumber: number | null;
      }[]
    | [];
};

const initialState = {
  loadingCycleCount: true, // likely delete
  cycleCount: null, // likely delete
  loadingCycleCountBins: true,
  loadingCycleCountVariantFrequency: false,
  cycleCountsUncountedBins: [],
  shapedCycleCountInfos: [],
  cycleCountInventoryRecords: [],
  cycleCountFrequency: { variantId: null, frequency: null },
  incompleteCycleCountsWithTasks: null,
  prev_url: null,
  next_url: null,
  loadingIncompleteCountingTasks: false,
  activeCountingTask: {
    cycleCountId: null,
    inventoryId: null,
    autostoreBinNumber: null
  },
  verifiedCountingTasks: [],
  selectedCountingTasks: null
};

type CycleCountActions =
  | FetchCycleCountAction // likely delete
  | FetchCycleCountSuccessAction // likely delete
  | FetchCycleCountFailureAction // likely delete
  | FetchCycleCountsUncountedBinsAction
  | FetchCycleCountsUncountedBinsSuccessAction
  | FetchCycleCountsUncountedBinsFailureAction
  | FetchCycleCountInventoryRecordsAction
  | FetchCycleCountInventoryRecordsSuccessAction
  | FetchCycleCountInventoryRecordsFailureAction
  | GetCycleCountFrequencyAction
  | GetCycleCountFrequencySuccessAction
  | GetCycleCountFrequencyFailureAction
  | PostBinCountAction
  | PostBinCountFailureAction
  | ModifyUncountedBinAction
  | ModifyShapedCycleCountInfoAction
  | CompleteCycleCountSuccessAction
  | CompleteCycleCountFailureAction
  | RemoveCycleCountSuccessAction
  | RemoveCycleCountFailureAction
  | ClearCycleCountsStateAction
  | ClearMessageAction
  | GetVariantByVariantIdFailureAction
  | FetchIncompleteCycleCountCountingTasksActionV2
  | FetchIncompleteCycleCountCountingTasksSuccessActionV2
  | FetchIncompleteCycleCountCountingTasksFailureActionV2
  | ClearActiveCountingTask
  | SetSelectedCountingTaskAction
  | ClearSelectedCountingTasksAction;

const reducer = (
  state: CycleCountsState | undefined,
  action: CycleCountActions
): CycleCountsState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    // likely delete
    case "cycleCounts/FETCH_CYCLE_COUNT":
      return {
        ...state,
        loadingCycleCount: true,
        cycleCount: action.doNotNull ? state.cycleCount : null
      };
    // likely delete
    case "cycleCounts/FETCH_CYCLE_COUNT_SUCCESS":
      return {
        ...state,
        loadingCycleCount: false,
        cycleCount: action.payload
      };
    // likely delete
    case "cycleCounts/FETCH_CYCLE_COUNT_FAILURE":
      return {
        ...state,
        loadingCycleCount: false,
        cycleCount: null
      };

    case "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS":
      return {
        ...state,
        loadingCycleCountBins: true,
        cycleCountsUncountedBins: [],
        shapedCycleCountInfos: []
      };
    case "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS_SUCCESS":
      return {
        ...state,
        loadingCycleCountBins: false,
        cycleCountsUncountedBins: action.payload.cycleCountsUncountedBins,
        shapedCycleCountInfos: action.payload.shapedCycleCountInfos
      };
    case "cycleCounts/FETCH_CYCLE_COUNTS_UNCOUNTED_BINS_FAILURE":
      return {
        ...state,
        loadingCycleCountBins: false,
        cycleCountsUncountedBins: [],
        shapedCycleCountInfos: []
      };

    case "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS":
      return {
        ...state,
        loadingCycleCountBins: true,
        cycleCountInventoryRecords: []
      };
    case "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_SUCCESS":
      return {
        ...state,
        loadingCycleCountBins: false,
        cycleCountInventoryRecords: action.payload
      };
    case "cycleCounts/FETCH_CYCLE_COUNT_INVENTORY_RECORDS_FAILURE":
      return {
        ...state,
        loadingCycleCountBins: false,
        cycleCountInventoryRecords: []
      };
    case "cycleCounts/GET_CYCLE_COUNT_FREQUENCY":
      return {
        ...state,
        loadingCycleCountVariantFrequency: true
      };
    case "cycleCounts/GET_CYCLE_COUNT_FREQUENCY_SUCCESS":
      return {
        ...state,
        loadingCycleCountVariantFrequency: false,
        cycleCountFrequency: {
          ...action.payload
        }
      };
    case "cycleCounts/GET_CYCLE_COUNT_FREQUENCY_FAILURE":
      return {
        ...state,
        cycleCountFrequency: {
          variantId: action.payload.variantId,
          frequency: null
        },
        loadingCycleCountVariantFrequency: false
      };
    case "cycleCounts/POST_BIN_COUNT_FAILURE":
      return {
        ...state
      };
    case "cycleCounts/MODIFY_UNCOUNTED_BIN": {
      const { inventoryId, newQuantity, newDate } = action.payload;

      return {
        ...state,
        cycleCountsUncountedBins:
          newQuantity || newQuantity === 0 || newDate
            ? state.cycleCountsUncountedBins.map((uncountedBin) =>
                uncountedBin.inventoryId === inventoryId
                  ? {
                      ...uncountedBin,
                      expectedCount: {
                        units: uncountedBin.expectedCount?.units ?? "ea",
                        value:
                          newQuantity || newQuantity === 0
                            ? newQuantity
                            : (uncountedBin.expectedCount?.value ?? 0)
                      },
                      expiration:
                        newDate?.expirationDate ?? uncountedBin.expiration,
                      manufactureDate: newDate?.manufactureDate
                        ? // due to cycle counts v1
                          // we are concerned about possible off by one date formatting errors
                          // casting this to preserve prior behavior
                          (newDate.manufactureDate as unknown as string)
                        : uncountedBin.manufactureDate
                    }
                  : uncountedBin
              )
            : state.cycleCountsUncountedBins
      };
    }

    case "cycleCounts/MODIFY_SHAPED_CYCLE_COUNT_INFO": {
      const {
        cycleCountId,
        inventoryId,
        newUncountedBinStatus,
        newCycleCountStatus
      } = action.payload;

      return {
        ...state,
        shapedCycleCountInfos: state.shapedCycleCountInfos.map(
          (cycleCountInfo) =>
            cycleCountInfo.cycleCountId === cycleCountId
              ? {
                  ...cycleCountInfo,
                  status: newCycleCountStatus || cycleCountInfo.status,
                  uncountedBinsInfo: cycleCountInfo.uncountedBinsInfo.map(
                    (uncountedBinInfo) => ({
                      ...uncountedBinInfo,
                      status:
                        inventoryId &&
                        newUncountedBinStatus &&
                        uncountedBinInfo.uncountedBin.inventoryId ===
                          action.payload.inventoryId
                          ? newUncountedBinStatus
                          : uncountedBinInfo.status
                    })
                  )
                }
              : cycleCountInfo
        )
      };
    }

    case "cycleCounts/COMPLETE_CYCLE_COUNT_SUCCESS": {
      const { cycleCountIds } = action.payload;

      return {
        ...state,
        shapedCycleCountInfos: state.shapedCycleCountInfos.map(
          (cycleCountInfo) =>
            cycleCountIds.includes(cycleCountInfo.cycleCountId)
              ? { ...cycleCountInfo, status: "complete" }
              : cycleCountInfo
        ),

        cycleCount: null, // likely delete
        cycleCountInventoryRecords: [] // likely delete after non-autostore cycle count rewrite
      };
    }

    case "cycleCounts/REMOVE_CYCLE_COUNT_SUCCESS":
      return {
        ...state,
        cycleCount: null, // likely delete
        cycleCountInventoryRecords: [] // likely delete after non-autostore cycle count rewrite
      };

    case "cycleCounts/COMPLETE_CYCLE_COUNT_FAILURE":
    case "cycleCounts/REMOVE_CYCLE_COUNT_FAILURE":
      return {
        ...state
      };

    case "cycleCounts/CLEAR_CYCLE_COUNTS_STATE":
      return initialState;

    case "cycleCounts/CLEAR_MESSAGE":
      return {
        ...state
      };

    case "cycleCounts/FETCH_INCOMPLETE_CYCLE_COUNT_COUNTING_TASKS_V2":
      return {
        ...state,
        loadingIncompleteCountingTasks: true,
        incompleteCycleCountsWithTasks: null
      };
    case "cycleCounts/FETCH_INCOMPLETE_CYCLE_COUNT_COUNTING_TASKS_SUCCESS_V2":
      return {
        ...state,
        loadingIncompleteCountingTasks: false,
        incompleteCycleCountsWithTasks: action.payload
      };
    case "cycleCounts/FETCH_INCOMPLETE_CYCLE_COUNT_COUNTING_TASKS_FAILURE_V2":
      return {
        ...state,
        loadingIncompleteCountingTasks: false
      };

    case "cycleCounts/SET_SELECTED_COUNTING_TASKS":
      return {
        ...state,
        selectedCountingTasks: action.payload
      };
    case "cycleCounts/CLEAR_SELECTED_COUNTED_TASKS":
      return {
        ...state,
        selectedCountingTasks: null
      };
    case "cycleCounts/CLEAR_ACTIVE_COUNTING_TASK":
      return {
        ...state,
        activeCountingTask: {
          cycleCountId: null,
          inventoryId: null,
          autostoreBinNumber: null
        },
        ...(action.payload ? { verifiedCountingTasks: [] } : {})
      };
    default:
      return state;
  }
};

const cycleCountsPersistConfig = {
  key: "cycleCounts",
  storage,
  whitelist: ["nothing"]
};

export const cycleCounts = persistReducer(cycleCountsPersistConfig, reducer);
