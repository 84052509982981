import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

import { ModalDialogTransition } from "../animations/ModalDialogTransition";
import { ProgressButton } from "../progressButton/ProgressButton";

type ConfirmationModal = {
  isOpen: boolean;
  noCancelButton?: boolean;
  confirmCb: () => void;
  closeCb: () => void;
  modalTitle: string;
  modalText?: string;
  listToDisplay?: string[];
  loader?: boolean;
  disableConfirmButton?: boolean;
  confirmText?: string;
};

const DialogText = styled(DialogContentText)(({ theme }) => ({
  textAlign: "center",
  fontSize: "20px",
  fontWeight: 400,
  paddingTop: theme.spacing(2)
}));

/**
 * A modal that prompts for confirmation, and optionally an option to cancel.
 */
export function ConfirmationModal(
  props: ConfirmationModal
): React.ReactElement {
  const {
    isOpen,
    noCancelButton,
    closeCb,
    confirmCb,
    modalTitle,
    modalText,
    listToDisplay,
    loader,
    disableConfirmButton,
    confirmText
  } = props;

  const { t } = useTranslation();

  const handleClose = (): void => {
    closeCb();
  };

  const handleConfirm = (): void => {
    confirmCb();
  };

  return (
    <Dialog
      data-testid="confirmation-modal"
      open={isOpen}
      TransitionComponent={ModalDialogTransition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        data-testid="confirmation-modal-title"
        className="confirm-title"
        sx={{
          textAlign: "center"
        }}
      >
        {modalTitle}
      </DialogTitle>

      {loader && (
        <DialogContent
          data-testid="confirmation-modal-loader"
          sx={{ textAlign: "center", mt: 4 }}
        >
          <CircularProgress color="inherit" />
        </DialogContent>
      )}
      <DialogContent>
        {modalText && (
          <DialogText id="alert-dialog-slide-description">
            {modalText}
          </DialogText>
        )}
      </DialogContent>
      {listToDisplay && (
        <DialogContent>
          {listToDisplay.map((el, index) => (
            <DialogContentText key={`description-${index}`}>
              {el}
            </DialogContentText>
          ))}
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
        {!noCancelButton && (
          <ProgressButton
            onClick={handleClose}
            color="secondary"
            startIcon={<CloseIcon />}
            buttonSize="medium"
            emphasis="high"
            responsive
            fullWidth
          >
            {t("cancel")}
          </ProgressButton>
        )}

        <ProgressButton
          onClick={handleConfirm}
          color="primary"
          data-testid="confirm-modal-confirm-button"
          startIcon={<CheckIcon />}
          buttonSize="medium"
          emphasis="high"
          responsive
          fullWidth
          disabled={disableConfirmButton ? true : false}
        >
          {confirmText || t("confirm")}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
