import { PartiallyCompleteRequest } from "~/types/api";

import { publicApi } from "./publicApi";

const picksApi = publicApi.injectEndpoints({
  endpoints: (build) => ({
    partiallyComplete: build.mutation<
      void,
      {
        pickId: string;
        requestBody: PartiallyCompleteRequest;
        clientId: string;
        fulfillmentCenterId: string;
      }
    >({
      query: ({ pickId, clientId, fulfillmentCenterId, requestBody }) => {
        return {
          url: `/picks/${pickId}/partially-complete`,
          method: "POST",
          body: requestBody,
          params: { clientId, fulfillmentCenterId }
        };
      }
    })
  }),
  overrideExisting: false
});

export default picksApi;
