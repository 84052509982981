import Button from "@locaisolutions/button";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  TextField,
  MenuItem
} from "@mui/material";
import { ModalDialogTransition, useToast } from "@qubit/autoparts";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";

import { OrderTypesDropdown } from "~/components/orderTypes/OrderTypesDropdown";

import { dayOfWeekAsString, DayOfWeekValue } from "~/lib/daysOfTheWeek";
import { hoursMinutesSecondsRegex, timeOfDayRegex } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { usePostRecurringScheduleMutation } from "~/redux/warehouse/recurringSchedule.hooks";
import { useGetStagingAreasQuery } from "~/redux/warehouse/stagingArea.hooks";
import { CreateRecurringScheduleRequest } from "~/types/api";

type Props = {
  dayOfWeekToCreate: DayOfWeekValue;
  onClose: () => void;
};

export default function CreateRecurringScheduleModal(props: Props) {
  const { onClose, dayOfWeekToCreate } = props;
  const { errorToast, successToast } = useToast();
  const isOpen = dayOfWeekToCreate !== null;

  const { data: stagingAreas } = useGetStagingAreasQuery();
  const [postRecurringSchedule] = usePostRecurringScheduleMutation();

  const {
    register,
    getValues,
    control,
    formState: { isValid }
  } = useForm<CreateRecurringScheduleRequest>({
    defaultValues: {
      dayOfWeek: dayOfWeekToCreate,
      scheduleWindow: ["00:00:00", "23:59:00"],
      interval: "00:01:00",
      minMaxOrdersPerWave: [1, 10],
      shipmentConfig: "UseShipments",
      orderTypes: [],
      batchDependency: "No Dependency",
      stagingAreaId: ""
    }
  });

  const handleCreate = async () => {
    const newSchedule = getValues();
    try {
      await postRecurringSchedule(newSchedule).unwrap();
      successToast(t("batch setting created"));
      onClose();
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={ModalDialogTransition}
      onClose={onClose}
    >
      <DialogTitle>
        {t("add to")} {dayOfWeekAsString(dayOfWeekToCreate)}
      </DialogTitle>

      <DialogContent>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          pt="20px"
          gap="20px 40px"
        >
          <Box>
            <InputLabel htmlFor="startTime" shrink>
              {t("start time")}
            </InputLabel>
            <TextField
              id="startTime"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">HH:mm:ss</InputAdornment>
                )
              }}
              {...register("scheduleWindow.0", {
                required: true,
                pattern: timeOfDayRegex
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="minimum" shrink>
              {t("batch minimum label")}
            </InputLabel>
            <TextField
              id="minimum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.0", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="endTime" shrink>
              {t("end time")}
            </InputLabel>
            <TextField
              id="endTime"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">HH:mm:ss</InputAdornment>
                )
              }}
              {...register("scheduleWindow.1", {
                required: true,
                pattern: timeOfDayRegex
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="maximum" shrink>
              {t("batch maximum label")}
            </InputLabel>
            <TextField
              id="maximum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.1", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="interval" shrink>
              {t("batch interval label")}
            </InputLabel>
            <TextField
              id="interval"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">HH:mm:ss</InputAdornment>
                )
              }}
              {...register("interval", {
                required: true,
                pattern: hoursMinutesSecondsRegex
              })}
            />
          </Box>

          <OrderTypesDropdown
            control={control}
            fieldName="orderTypes"
            tooltipText={t("batch order types tooltip")}
          />

          <Controller
            name="stagingAreaId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                select
                label={t("staging area")}
                error={!!error}
                {...field}
              >
                <MenuItem value="">
                  <em>{t("none")}</em>
                </MenuItem>
                {stagingAreas?.map((sa) => (
                  <MenuItem key={sa.stagingAreaId} value={sa.stagingAreaId}>
                    {sa.stagingAreaName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>

        <Controller
          name="shipmentConfig"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("use shipments checkbox")}
              sx={{ m: "32px 0 0" }}
              control={
                <Checkbox
                  checked={value?.toLowerCase() === "useshipments"}
                  onChange={(_, checked) => {
                    onChange(checked ? "UseShipments" : "NoSpecifiedShipment");
                  }}
                />
              }
            />
          )}
        />

        <Controller
          name="batchDependency"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("batch dependency checkbox")}
              sx={{ m: "4px 0 0" }}
              control={
                <Checkbox
                  checked={value?.toLowerCase() === "warehouse on autostore"}
                  onChange={(_, checked) => {
                    onChange(
                      checked ? "Warehouse on Autostore" : "No Dependency"
                    );
                  }}
                />
              }
            />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ gap: 1 }}>
        <Button
          onClick={onClose}
          kind="subtle"
          color="secondary"
          size="large"
          sx={{ fontWeight: "normal" }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={handleCreate}
          disabled={!isValid}
          kind="accent"
          size="large"
          sx={{ minWidth: 88, fontWeight: "normal" }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
