import { Dayjs } from "dayjs";
import { useCallback, useState } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostorePutawayModal as AutostorePutawayModalComponent } from "~/features/autostorePutawayModal/AutostorePutawayModal";
import useWindowDimensions from "~/lib/browserDimensions";
import { getEmptyCompartmentNumbers } from "~/lib/getEmptyBinCompartmentNumbers";
import { getInventoryDateObjDayjs } from "~/lib/helpers";
import { binNotAtPort, closeWorkstation } from "~/redux/actions";
import { putAway } from "~/redux/actions/inventory";
import { selectPortStateByPort } from "~/redux/selectors/autostoreSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { NextBinResponse, NextEmptyBinResponse } from "~/types/api";

import {
  setIsAutostorePutawayDialogOpen,
  setIsAdjustingBins,
  setSelectedCompartmentNumber,
  resetBinAtPortSeconds
} from "./inventory.slice";
import { useGridSelector } from "./useGridSelector";

type Props = {
  handleRefetchInventory: () => void;
  resetView: (resetInv: boolean) => void;
};

export function AutostorePutawayModal({
  handleRefetchInventory,
  resetView
}: Props) {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const { autostoreGridOverride } = useGridSelector();

  const { inv_inventoryDateLabel, inv_inventoryDateRequired } =
    useAppSelector(selectClientConfig);
  const isAutostorePutawayDialogOpen = useAppSelector(
    (state) => state.inventoryNew.isAutostorePutawayDialogOpen
  );
  const isAdjustingBins = useAppSelector(
    (state) => state.inventoryNew.isAdjustingBins
  );
  const isChangeBinConfigModalOpen = useAppSelector(
    (state) => state.inventoryNew.isChangeBinConfigModalOpen
  );
  const isInventoryAdjustDialogOpen = useAppSelector(
    (state) => state.inventoryNew.isInventoryAdjustDialogOpen
  );
  const portStateByPort = useAppSelector(selectPortStateByPort);
  const selectedCompartmentInfo = useAppSelector(
    (state) => state.inventoryNew.selectedCompartment
  );
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const siteWorkstation = useAppSelector(selectThisWorkstation);

  const [inventoryDate, setInventoryDate] = useState<Dayjs | null>(null);
  const [changedQuantity, setChangedQuantity] = useState<number | null>(null);

  const emptyCompartmentNumbers = getEmptyCompartmentNumbers(
    selectedCompartmentInfo.bin
  );

  const selectedAutostoreGridId = siteWorkstation
    ? siteWorkstation.autostoreGridId
    : autostoreGridOverride;

  /** selected compartment's bin data */
  const selectedCompartmentBin =
    selectedCompartmentInfo.bin?.autostoreBinCompartments.find(
      (binCompartment) =>
        binCompartment.autostoreCompartmentNumber ===
        (selectedCompartmentInfo.compartmentNumber || 0) + 1
    );

  const adjustingPortId = selectedCompartmentInfo.bin?.openBinResponse.portId;
  const isAdjustingPortReady =
    adjustingPortId &&
    portStateByPort[adjustingPortId]?.getPortResponse.isReady;

  const handleClose = useCallback(async () => {
    dispatch(setIsAutostorePutawayDialogOpen(false));
    await dispatch(closeWorkstation());
    handleRefetchInventory();
    dispatch(setIsAdjustingBins(false));
    setInventoryDate(null);
    if (!isInventoryAdjustDialogOpen && !isAdjustingBins) {
      resetView(false);
      dispatch(resetBinAtPortSeconds());
      dispatch(binNotAtPort());
    }
  }, [
    dispatch,
    handleRefetchInventory,
    isAdjustingBins,
    isInventoryAdjustDialogOpen,
    resetView
  ]);

  return (
    selectedCompartmentInfo.bin && (
      <AutostorePutawayModalComponent
        open={isAutostorePutawayDialogOpen && !isChangeBinConfigModalOpen}
        binLoading={!isAdjustingPortReady}
        onClose={handleClose}
        showCancelButton
        binId={selectedCompartmentInfo.bin?.openBinResponse.binId || null}
        onPutawayButtonClick={async () => {
          if (
            !selectedVariant ||
            !selectedCompartmentInfo ||
            !selectedCompartmentBin
          )
            return;
          await dispatch(
            putAway(
              {
                autostoreGridId: selectedAutostoreGridId,
                autostorePortId: adjustingPortId,
                binId: selectedCompartmentBin.binId,
                variantId: selectedVariant.variantId,
                count: {
                  value: changedQuantity || 1,
                  units: selectedVariant.pickingUnits
                },
                ...getInventoryDateObjDayjs(
                  inv_inventoryDateLabel,
                  inventoryDate
                ),
                lotNumber: null as unknown as string
              },
              siteWorkstation?.id
            )
          );
          setInventoryDate(null);
          dispatch(setIsAutostorePutawayDialogOpen(false));
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          dispatch(closeWorkstation());
          setChangedQuantity(null);
          handleRefetchInventory();
          dispatch(setIsAdjustingBins(false));
        }}
        changeQuantityFunc={setChangedQuantity}
        numberOfRows={
          (selectedCompartmentInfo.bin as NextEmptyBinResponse)
            .autostoreBinConfiguration?.horizontalCompartmentCount ||
          (selectedCompartmentInfo.bin as NextBinResponse).binConfiguration
            ?.horizontalCompartmentCount
        }
        numberOfColumns={
          (selectedCompartmentInfo.bin as NextEmptyBinResponse)
            .autostoreBinConfiguration?.verticalCompartmentCount ||
          (selectedCompartmentInfo.bin as NextBinResponse).binConfiguration
            ?.verticalCompartmentCount
        }
        selectedCompartment={
          selectedCompartmentInfo?.compartmentNumber ?? undefined
        }
        setSelectedCompartment={(compartmentNumber: number) => {
          dispatch(setSelectedCompartmentNumber(compartmentNumber));
        }}
        emptyCompartments={emptyCompartmentNumbers}
        inv_inventoryDateLabel={inv_inventoryDateLabel}
        inventoryDate={inventoryDate}
        onDateChange={setInventoryDate}
        inv_inventoryDateRequired={inv_inventoryDateRequired}
        maxWidth="lg"
        browserWidth={width}
        showProductSearch
        isExpirationRequired={selectedVariant?.isExpirationRequired ?? false}
      />
    )
  );
}
