import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { SearchData } from "./useGetPutawayTasks";

type AutostorePutawayState = {
  selectedRowId: Guid | null;
  selectedCompartment: number | undefined;
  newSelectedCompartment: number | null;
  changedQuantity: number | undefined;
  inventoryDate: Date | string | null;
  searchData: SearchData;
  isPutawayTaskTableRefreshed: boolean;
  portPollingActive: boolean;
  closePortBtnDisabled: boolean;
  suggestBinConfigurationError: boolean;
  suggestBinConfigurationIsLoading: boolean;
  onLeaveInputTextShown: boolean;
};

const initialState: AutostorePutawayState = {
  selectedRowId: null,
  selectedCompartment: undefined,
  newSelectedCompartment: null,
  changedQuantity: undefined,
  inventoryDate: moment().utc().add(1, "year").toDate(),
  searchData: {
    scannedBarcodes: [],
    offset: 1
  },
  isPutawayTaskTableRefreshed: false,
  portPollingActive: false,
  closePortBtnDisabled: true,
  suggestBinConfigurationError: false,
  suggestBinConfigurationIsLoading: false,
  onLeaveInputTextShown: false
};

export const autostorePutawaySlice = createSlice({
  name: "autostorePutaway",
  initialState,
  reducers: {
    selectRow(state, { payload }: PayloadAction<Guid | null>) {
      state.selectedRowId = payload;
    },

    // Compartment number 0-3
    selectCompartment(state, { payload }: PayloadAction<number | undefined>) {
      state.selectedCompartment = payload;
    },

    // Compartment number used for selecting a new compartment
    selectNewCompartment(state, { payload }: PayloadAction<number | null>) {
      state.newSelectedCompartment = payload;
    },

    setChangedQuantity(state, { payload }: PayloadAction<number | undefined>) {
      state.changedQuantity = payload;
    },

    setInventoryDate(state, { payload }: PayloadAction<Date | string | null>) {
      state.inventoryDate = payload;
    },

    setSearchData(state, { payload }: PayloadAction<SearchData>) {
      state.searchData = payload;
    },

    setIsPutawayTaskTableRefreshed(state, { payload }: PayloadAction<boolean>) {
      state.isPutawayTaskTableRefreshed = payload;
    },

    setPortPollingActive(state, { payload }: PayloadAction<boolean>) {
      state.portPollingActive = payload;
    },

    setClosePortBtnDisabled(state, { payload }: PayloadAction<boolean>) {
      state.closePortBtnDisabled = payload;
    },

    setOnLeaveInputTextShown(state, { payload }: PayloadAction<boolean>) {
      state.onLeaveInputTextShown = payload;
    },

    setSuggestBinConfigurationError(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.suggestBinConfigurationError = payload;
    },

    setSuggestBinConfigurationIsLoading(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.suggestBinConfigurationIsLoading = payload;
    },

    reset(state) {
      state.selectedRowId = null;
      state.selectedCompartment = undefined;
      state.newSelectedCompartment = null;
      state.inventoryDate = new Date(Date.now() + 3600 * 1000 * 24 * 365); // +1 year
    }
  }
});

export const {
  selectRow,
  selectCompartment,
  setChangedQuantity,
  setInventoryDate,
  setSearchData,
  setIsPutawayTaskTableRefreshed,
  setPortPollingActive,
  setClosePortBtnDisabled,
  setOnLeaveInputTextShown,
  setSuggestBinConfigurationError,
  setSuggestBinConfigurationIsLoading,
  selectNewCompartment,
  reset
} = autostorePutawaySlice.actions;
