import { Button, Toolbar, Typography } from "@mui/material";

import { useToast } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";

import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";

import { nonNullable } from "~/lib/typeHelpers";
import { closeBin, createBinRequest, openPort } from "~/redux/actions";
import {
  selectCommonHolds,
  selectSelectedInventoryAtPort
} from "~/redux/selectors/autostoreInventoryHoldsSelectors";
import {
  selectSelectedPortId,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";

import {
  resetSelectedSummaries,
  setIsAdjustPanelOpen,
  setIsBinHoldModalOpen,
  setPortOpened
} from "./autostoreInventoryHolds.slice";

type Props = {
  handleGetNextBin: () => Promise<void>;
  resetPage: () => void;
};

export function AutostoreInventoryHoldsToolbar({
  handleGetNextBin,
  resetPage
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast } = useToast();

  const closeWorkstation = useCloseWorkstationWithErrorToast();

  const commonHolds = useAppSelector(selectCommonHolds);
  const portState = useAppSelector((state) => state.autostore.portState);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const selectedPortId = useAppSelector(selectSelectedPortId);

  const selectedInventoryAtPort = useAppSelector(selectSelectedInventoryAtPort);
  const selectedSummaries = useAppSelector(
    (state) => state.autostoreInventoryHolds.selectedSummaries
  );

  const binIds = selectedSummaries
    .map((summary) => summary.autostoreBinNumber)
    .filter(nonNullable);

  const handleExitGetBins = async () => {
    if (!selectedAutostoreGridId || !portState) return;

    const { selectedBin, selectedTask, isReady } = portState;
    if (isReady) {
      await dispatch(closeBin({ binId: selectedBin, taskId: selectedTask }));
    }
    const error = await closeWorkstation();
    if (!error) successToast(t("workstation closed"));
    resetPage();
  };

  const handleGetBins = async () => {
    if (!!selectedAutostoreGridId && !!selectedPortId) {
      // close workstation - reset task-groups
      await closeWorkstation();

      // create task group with inventory-holds category
      const categoryId = 6000 + selectedPortId;
      await dispatch(createBinRequest({ binIds, categoryId }));

      // open port with category used above to create bin request (8)
      await dispatch(openPort({ categoryId }));
      dispatch(setPortOpened(true));

      await handleGetNextBin();
    }
  };

  return (
    <Toolbar>
      {!!binIds.length && !selectedInventoryAtPort && (
        <>
          <UnselectButton
            onClick={() => {
              dispatch(resetSelectedSummaries());
            }}
          />
          <NavBarButtonGroup>
            <Button
              id="get-bins-button"
              color="secondary"
              onClick={async () => {
                await handleGetBins();
              }}
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {`${t("get bins")} (${binIds.length})`}
              </Typography>
            </Button>
            {!!commonHolds.length && (
              <Button
                id="remove-holds-button"
                color="secondary"
                onClick={() => {
                  dispatch(setIsBinHoldModalOpen(true));
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {`${t("remove hold")}`}
                </Typography>
              </Button>
            )}
          </NavBarButtonGroup>
        </>
      )}
      {selectedInventoryAtPort && (
        <NavBarButtonGroup>
          <Button
            id="adjust-button"
            color="secondary"
            onClick={() => dispatch(setIsAdjustPanelOpen(true))}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {`${t("adjust")}`}
            </Typography>
          </Button>
          {!!commonHolds.length && (
            <Button
              id="remove-holds-button"
              color="secondary"
              onClick={() => {
                dispatch(setIsBinHoldModalOpen(true));
              }}
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {`${t("remove hold")}`}
              </Typography>
            </Button>
          )}
          <Button
            id="close-port-button"
            color="secondary"
            onClick={async () => {
              await handleExitGetBins();
            }}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {`${t("close workstation")}`}
            </Typography>
          </Button>
          <Button
            id="next-bin-button"
            color="secondary"
            onClick={async () => {
              await handleGetNextBin();
            }}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {t("next bin")}
            </Typography>
          </Button>
        </NavBarButtonGroup>
      )}
    </Toolbar>
  );
}
