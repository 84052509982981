import { Stack, Typography } from "@mui/material";
import { ProgressButton } from "@qubit/autoparts";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { useSetThisWorkstation } from "~/hooks/useSetThisWorkstation";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectDropdownWorkstation } from "~/redux/selectors/workstationConfigSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { UpdateWorkstation } from "~/types/api";

export function SetAsWorkstationButton() {
  const { t } = useTranslation();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const setThisWorkstation = useSetThisWorkstation();
  const workstation = useAppSelector(selectDropdownWorkstation);
  const isAdmin = useAppSelector(selectUserIsAdmin);

  const {
    formState: { isDirty }
  } = useFormContext<UpdateWorkstation>();

  return (
    <Stack width="100%" alignItems="flex-end" pt={2}>
      {siteWorkstation?.id === workstation?.id ? (
        <ProgressButton
          emphasis="high"
          responsive
          disabled={isDirty && !!isAdmin}
          style={{ width: 200 }}
          color="secondary"
          onClick={async () => {
            await setThisWorkstation(null, true);
          }}
        >
          <Typography>{t("unselect current workstation")}</Typography>
        </ProgressButton>
      ) : (
        <ProgressButton
          emphasis="high"
          responsive
          disabled={isDirty && !!isAdmin}
          style={{
            width: 200
          }}
          color="primary"
          onClick={async () =>
            workstation && (await setThisWorkstation(workstation, true))
          }
        >
          <Typography>{t("set this machine as workstation")}</Typography>
        </ProgressButton>
      )}
    </Stack>
  );
}
