import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useCreateOrderTypeMutation } from "~/redux/warehouse/orders.hooks";

import {
  clearOrderTypeToDelete,
  closeAddOrderTypeDialog
} from "./orderTypesDropdown.slice";

export function AddOrderTypeDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();
  const [newOrderType, setNewOrderType] = useState("");
  const [createOrderType] = useCreateOrderTypeMutation();

  const handleClose = () => {
    dispatch(clearOrderTypeToDelete());
    dispatch(closeAddOrderTypeDialog());
  };

  const handleConfirm = async () => {
    try {
      await createOrderType({ orderType: newOrderType }).unwrap();
      successToast(t("order type created"));
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle textAlign="center" variant="h6">
        {t("add new order type")}
      </DialogTitle>
      <DialogContent sx={{ mt: 2, display: "flex", gap: 1 }}>
        <TextField
          onChange={(event) => setNewOrderType(event.target.value)}
          inputProps={{
            "aria-label": "new-order-type-textfield"
          }}
        />
        <Button
          onClick={handleConfirm}
          disabled={!newOrderType.length}
          variant="contained"
        >
          {t("confirm")}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
