import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

import { MenuItem } from "~/features/navbar/Navbar";

type DatePicker = {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
};

export type ViewNameTranslation =
  | "nav.viewname.admin batches"
  | "nav.viewname.andon"
  | "nav.viewname.analytics"
  | "nav.viewname.autostore"
  | "nav.viewname.autostore cyclecounts"
  | "nav.viewname.autostore grid"
  | "nav.viewname.autostore induction"
  | "nav.viewname.autostore inventory"
  | "nav.viewname.autostore inventory holds"
  | "nav.viewname.autostore pick"
  | "nav.viewname.batch - pick tasks"
  | "nav.viewname.batches"
  | "nav.viewname.batches - staging areas"
  | "nav.viewname.bin cleaning schedule"
  | "nav.viewname.bin maintenance"
  | "nav.viewname.bin reconfiguration"
  | "nav.viewname.cart prep"
  | "nav.viewname.carts"
  | "nav.viewname.consolidation"
  | "nav.viewname.create batches"
  | "nav.viewname.cycle count"
  | "nav.viewname.cycle counts"
  | "nav.viewname.dev"
  | "nav.viewname.home"
  | "nav.viewname.inventory"
  | "nav.viewname.inventory control - bin"
  | "nav.viewname.inventory control - product"
  | "nav.viewname.inventory holds"
  | "nav.viewname.low inventory"
  | "nav.viewname.manage flagged bins"
  | "nav.viewname.manual ops"
  | "nav.viewname.order"
  | "nav.viewname.order creation"
  | "nav.viewname.orders"
  | "nav.viewname.pick batches"
  | "nav.viewname.pick path"
  | "nav.viewname.picking - previous bin"
  | "nav.viewname.prepare"
  | "nav.viewname.printer"
  | "nav.viewname.putaway"
  | "nav.viewname.putaway task"
  | "nav.viewname.putaway task_plural"
  | "nav.viewname.replenishment"
  | "nav.viewname.settings"
  | "nav.viewname.service and support"
  | "nav.viewname.ship"
  | "nav.viewname.ship - order"
  | "nav.viewname.start bin reconfiguration"
  | "nav.viewname.status"
  | "nav.viewname.store"
  | "nav.viewname.user"
  | "nav.viewname.user settings"
  | "nav.viewname.users"
  | "nav.viewname.version"
  | "nav.viewname.workstation";

export const NavbarContext = createContext<{
  centerComponent: ReactNode | undefined;
  datePicker?: DatePicker;
  informationalMenuItems: {
    textContent: string;
  }[];
  isHidden: boolean;
  menuItems: MenuItem[];

  /**
   * Resets the context to the initial state.  Should be called when a route changes.
   */
  reset: () => void;
  rightComponent: ReactNode | undefined;
  /**
   * If set, displays a date picker on desktop and a menu item on mobile.
   */
  setDatePicker: (datePicker: DatePicker | undefined) => void;
  /**
   * Menu items displayed on the right hand side of the nav bar after a divider
   * @param menuItems
   */
  setInformationalMenuItems: (
    menuItems: {
      textContent: string;
    }[]
  ) => void;
  /**
   * Menu items displayed on the right hand side of the nav bar
   * @param menuItems
   */
  setMenuItems: (menuItems: MenuItem[]) => void;
  /**
   * Define react components to show in the center of the toolbar.
   * @default undefined
   */
  setCenterComponent: (toolbar: ReactNode | undefined) => void;
  /**
   * Hides the navbar if true
   * @default false
   * */
  setIsHidden: (isHidden: boolean) => void;
  /**
   * Define react components to show in the right of the toolbar.
   * @default undefined
   */
  setRightComponent: (toolbar: ReactNode | undefined) => void;
  /**
   * Shows a toolbar over the navbar if set.  Hide toolbar by calling with undefined.
   * @default undefined
   */
  setToolbar: (toolbar: ReactNode | undefined) => void;
  /**
   * Title text displayed in the nav bar
   * @default Qubit
   * */
  setViewTitle: (viewTitle: ViewNameTranslation | undefined) => void;
  /**
   * Warnings displayed under the nav bar
   * @default Qubit
   * */
  setWarnings: (warnings: string[]) => void;
  toolbar: ReactNode | undefined;
  viewTitle: ViewNameTranslation | undefined;
  warnings?: string[];
}>(null as never);

export const NavbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [centerComponent, setCenterComponent] = useState<
    ReactNode | undefined
  >();
  const [datePicker, setDatePicker] = useState<DatePicker>();
  const [informationalMenuItems, setInformationalMenuItems] = useState<
    {
      textContent: string;
    }[]
  >([]);
  const [isHidden, setIsHidden] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [rightComponent, setRightComponent] = useState<ReactNode | undefined>();
  const [toolbar, setToolbar] = useState<ReactNode | undefined>();
  const [viewTitle, setViewTitle] = useState<ViewNameTranslation | undefined>();
  const [warnings, setWarnings] = useState<string[]>([]);

  const reset = useCallback(() => {
    setCenterComponent(undefined);
    setDatePicker(undefined);
    setInformationalMenuItems([]);
    setMenuItems([]);
    setRightComponent(undefined);
    setToolbar(undefined);
    setViewTitle(undefined);
    setWarnings([]);
  }, []);

  return (
    <NavbarContext.Provider
      value={{
        centerComponent,
        datePicker,
        informationalMenuItems,
        isHidden,
        menuItems,
        reset,
        rightComponent,
        setCenterComponent,
        setDatePicker,
        setIsHidden,
        setInformationalMenuItems,
        setMenuItems,
        setRightComponent,
        setToolbar,
        setViewTitle,
        setWarnings,
        toolbar,
        viewTitle,
        warnings
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

type Options = {
  /**
   * Define react components to show in the center of the navbar.
   * @default undefined
   */
  centerComponent?: ReactNode;
  /**
   * If set, displays a date picker on desktop and a menu item on mobile.
   * @default undefined
   */
  datePicker?: DatePicker;
  /**
   * Triggers some dialogs when navigating away
   * @deprecated
   * @default false
   * */
  isAutostorePicking?: boolean;
  /**
   * True if the navbar is hidden
   * @default false
   * */
  isHidden?: boolean;
  /**
   * Define react components to show in the right of the navbar.
   * @default undefined
   */
  rightComponent?: ReactNode;
  /**
   * Title text displayed in the nav bar
   * @default undefined
   * */
  viewTitle?: ViewNameTranslation;
};

/**
 * Configures the layout's nav bar.
 */
export function useNavbar({
  centerComponent = undefined,
  datePicker = undefined,
  isHidden = false,
  rightComponent = undefined,
  viewTitle
}: Options = {}) {
  const context = useContext(NavbarContext);
  const {
    setCenterComponent,
    setDatePicker,
    setIsHidden,
    setRightComponent,
    setViewTitle
  } = context;

  useEffect(() => {
    setCenterComponent(centerComponent);
  }, [setCenterComponent, centerComponent]);

  useEffect(() => {
    setDatePicker(datePicker);
  }, [setDatePicker, datePicker]);

  useEffect(() => {
    setIsHidden(isHidden);
  }, [setIsHidden, isHidden]);

  useEffect(() => {
    setRightComponent(rightComponent);
  }, [setRightComponent, rightComponent]);

  useEffect(() => {
    setViewTitle(viewTitle);
  }, [setViewTitle, viewTitle]);

  return context;
}
