import { ScanBinRequest } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const hardwareConductorApi = warehouseApi.injectEndpoints({
  endpoints: (builder) => ({
    simulateBinScan: builder.mutation<void, ScanBinRequest>({
      query: ({ portId, binNumber, gridId }) => {
        return {
          url: `/hwc-device/scan-bin`,
          method: "POST",
          body: { portId, binNumber, gridId }
        };
      }
    })
  }),
  overrideExisting: false
});
