import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { stringify } from "qs";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { getWarehouseServiceUrl } from "~/api/warehouse";
import { accessTokenFactory } from "~/features/login/login.slice";
import { StoreState } from "~/redux/reducers";

import { selectUsersFulfillmentCenterId } from "~/redux/selectors/storeSelectors";

export const publicApiBase = fetchBaseQuery({
  baseUrl: `${getWarehouseServiceUrl()}/public-api/v1`,
  prepareHeaders: async (headers, api) => {
    const state = api.getState() as StoreState;
    const accessToken = await accessTokenFactory(
      state.login.accessToken,
      state.login.authMethod
    );
    headers.set("authorization", `Bearer ${accessToken}`);
  },
  paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
});

export const publicApiBaseWithRedirectOn401: typeof publicApiBase = async (
  args,
  api,
  extra
) => {
  const state = api.getState() as StoreState;

  const argsWithDefaults =
    typeof args === "string"
      ? args
      : {
          ...args,
          params: {
            fulfillmentCenterId: selectUsersFulfillmentCenterId(state),
            clientId: state.login.profile
              ? getUserClientId(state.login.profile)
              : null,
            // we want any specified params to override the defaults above
            ...args.params
          }
        };

  const result = await publicApiBase(argsWithDefaults, api, extra);
  // if the server responds with a 401 response, redirect to the sign-in page
  if (result.error && result.error.status === 401) {
    window.location.href = "/logout";
  }
  return result;
};

export const publicApi = createApi({
  baseQuery: publicApiBaseWithRedirectOn401,
  endpoints: () => ({}),
  reducerPath: "publicApi"
});
