import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import bookstoreTranslation from "./translations/bookstoreenglish.json";
import englishTranslation from "./translations/english.json";
import frenchTranslation from "./translations/french.json";
import japaneseTranslation from "./translations/japanese.json";
import koreanTranslation from "./translations/korean.json";
import spanishTranslation from "./translations/spanish.json";

const resources = {
  en: { translation: englishTranslation },
  ko: { translation: koreanTranslation },
  es: { translation: spanishTranslation },
  fr: { translation: frenchTranslation },
  bs: { translation: bookstoreTranslation },
  ja: { translation: japaneseTranslation }
};

export type LanguageCode = keyof typeof resources;
export const languageCodes = Object.keys(resources) as LanguageCode[];

export const initializeI18N = async () => {
  await i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: "en",
      fallbackLng: "en",
      debug: false,
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      resources
    });
};
