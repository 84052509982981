import { LanguageCode } from "~/localization_i18n";

import andonBoardLayouts, { BoardLayout } from "./andonBoardLayouts";

export type Inv_addExpirationFieldLabel = "code date" | "expiration";
export type Inv_InventoryDateLabelSettings = "manufacture" | "expiration";
export type Ap_totesPlacement = "right" | "split";

type as_homepageTypes = [
  "picking",
  "induction",
  "induction v2",
  "inventory holds",
  "cycle counts",
  "cycle counts v2",
  "inventory",
  "inventory v2"
];

export type AS_HomepageType = as_homepageTypes[number];

const defaultHomepageTypes: AS_HomepageType[] = [
  "picking",
  "induction",
  "inventory holds",
  "cycle counts",
  "inventory"
];

export type ClientConfig = {
  // andon
  an_andonFeaturesEnabled: boolean;
  an_displayEventFeatures: boolean;
  an_boardLayouts: BoardLayout[];

  // autostore picking
  ap_totesPlacement: Ap_totesPlacement;
  ap_prepTotesModalEnabled: boolean;
  ap_confirmCartNumberEnabled: boolean;
  ap_fusionPortScreenEnabled: boolean;
  ap_prepTotesModalShowTitle: boolean;
  ap_prepTotesModalShowInstructionText: boolean;
  ap_prepTotesModalColumnPlacement: boolean;
  ap_prepTotesModalShowToteId: boolean;
  ap_prepTotesModalShowCustomerName: boolean;
  ap_prepTotesModalShowToteSize: boolean;
  ap_scanningIndicatorShown: boolean;
  ap_addToteFunctionalityEnabled: boolean;
  ap_repickToteFunctionalityEnabled: boolean;
  ap_excludeRecalledHold: boolean;
  ap_includeDirtyBinHold: boolean;
  ap_includeDamagedBinHold: boolean;
  ap_includeMisconfiguredBinHold: boolean;
  ap_applyingLabelProcessEnabled: boolean;
  ap_showPickQtyWhileBinLoaderIsActive: boolean;
  ap_showPickToLight: boolean;
  ap_isTopAreaOfToteClickable: boolean;
  ap_showOrderOrProductCancellationModal: boolean;
  ap_binRemovedFlow: boolean;

  // batches
  bat_printEnabled: boolean;
  bat_pickFromBatchesEnabled: boolean;
  bat_stageFromBatchesEnabled: boolean;

  // cleaningBin
  binCleaningEnabled: boolean;

  // dev
  dev_orderCreation: boolean;
  dev_useV2OrderCreation: boolean;
  dev_ptlSimulation: boolean;
  dev_conveyanceSimulation: boolean;
  dev_automatedOperations: boolean;

  // inventory
  inv_uomOptions: { value: string; label: string }[];
  inv_productCardAddInventoryEnabled: boolean;
  inv_addSetExpirationEnabled: boolean;
  inv_addUomSelectionForASBinsDisabled: boolean;
  inv_addExpirationFieldLabel: Inv_addExpirationFieldLabel;
  inv_addUseUomFromFC: boolean;
  inv_inventoryDateLabel: Inv_InventoryDateLabelSettings;
  inv_inventoryDateRequired: boolean;

  // operations (switch to op_variableName)
  manualOpsEnabled?: boolean;
  cartAssignmentEnabled: boolean;
  cartPrepEnabled: boolean;
  pickPathEnabled: boolean;

  // orders
  ord_printEnabled: boolean;
  ord_closeOrdersDisabled: boolean;
  ord_includeSpokes: boolean;

  // putaway
  putaway_multipleSearchTerms: boolean;
  putaway_showScanFilters: boolean;
  putaway_showDecantRate: boolean;
  putaway_showExceptionsButton: boolean;

  // workstations
  ws_addRemovePortsEnabled: boolean;
  ws_modifyPortSetupEnabled: boolean;
  ws_deleteWorkstationEnabled: boolean;

  site_defaultLanguageCode: LanguageCode;

  // fc client homepage settings
  fc_autostore_homepages: AS_HomepageType[];
};

// on load and used by tests
export const defaultClientConfig: ClientConfig = {
  inv_uomOptions: [{ value: "ea", label: "ea" }],
  an_andonFeaturesEnabled: true,
  an_displayEventFeatures: true,
  an_boardLayouts: [],

  ap_totesPlacement: "split" as Ap_totesPlacement,
  ap_prepTotesModalEnabled: false,
  ap_confirmCartNumberEnabled: false,
  ap_excludeRecalledHold: false,
  ap_includeDamagedBinHold: false,
  ap_includeDirtyBinHold: false,
  ap_includeMisconfiguredBinHold: false,
  ap_fusionPortScreenEnabled: false,
  ap_prepTotesModalShowTitle: false,
  ap_prepTotesModalShowInstructionText: true,
  ap_prepTotesModalColumnPlacement: false,
  ap_prepTotesModalShowToteId: true,
  ap_prepTotesModalShowCustomerName: true,
  ap_prepTotesModalShowToteSize: true,
  ap_scanningIndicatorShown: false,
  ap_addToteFunctionalityEnabled: false,
  ap_repickToteFunctionalityEnabled: false,
  ap_applyingLabelProcessEnabled: false,
  ap_showPickQtyWhileBinLoaderIsActive: true,
  ap_showPickToLight: false,
  ap_isTopAreaOfToteClickable: true,
  ap_showOrderOrProductCancellationModal: false,
  ap_binRemovedFlow: false,

  bat_printEnabled: true,
  bat_pickFromBatchesEnabled: true,
  bat_stageFromBatchesEnabled: true,

  dev_orderCreation: false,
  dev_useV2OrderCreation: false,
  dev_ptlSimulation: false,
  dev_conveyanceSimulation: false,
  dev_automatedOperations: false,

  inv_productCardAddInventoryEnabled: true,
  inv_addSetExpirationEnabled: true,
  inv_addUomSelectionForASBinsDisabled: true,
  inv_addExpirationFieldLabel: "expiration" as Inv_addExpirationFieldLabel,
  inv_addUseUomFromFC: false,
  inv_inventoryDateLabel: "expiration" as Inv_InventoryDateLabelSettings,
  inv_inventoryDateRequired: false,

  manualOpsEnabled: undefined,
  cartAssignmentEnabled: true,
  cartPrepEnabled: true,
  pickPathEnabled: true,
  binCleaningEnabled: false,

  ord_printEnabled: true,
  ord_closeOrdersDisabled: false,
  ord_includeSpokes: true,

  putaway_multipleSearchTerms: false,
  putaway_showScanFilters: true,
  putaway_showDecantRate: false,
  putaway_showExceptionsButton: false,

  ws_addRemovePortsEnabled: true,
  ws_modifyPortSetupEnabled: false,
  ws_deleteWorkstationEnabled: true,

  site_defaultLanguageCode: "en",

  fc_autostore_homepages: defaultHomepageTypes
};

const bevClientConfig: ClientConfig = {
  ...defaultClientConfig,
  inv_uomOptions: [
    { value: "ea", label: "ea" },
    { value: "cd", label: "cd" }
  ],
  ap_prepTotesModalEnabled: true,
  bat_printEnabled: false,
  bat_pickFromBatchesEnabled: false,
  bat_stageFromBatchesEnabled: false,
  dev_useV2OrderCreation: true,
  inv_productCardAddInventoryEnabled: false,
  inv_addSetExpirationEnabled: false,
  inv_addUomSelectionForASBinsDisabled: false,
  inv_addExpirationFieldLabel: "code date",
  inv_inventoryDateLabel: "manufacture",
  inv_inventoryDateRequired: true,
  inv_addUseUomFromFC: true,
  cartAssignmentEnabled: false,
  cartPrepEnabled: false,
  manualOpsEnabled: false,
  pickPathEnabled: false,
  ord_printEnabled: false,
  ord_closeOrdersDisabled: true,
  ws_deleteWorkstationEnabled: true
};
const bevClientDevConfig: ClientConfig = {
  ...bevClientConfig,
  ap_scanningIndicatorShown: true,
  ap_addToteFunctionalityEnabled: true
};

const mochiConfig: ClientConfig = {
  ...defaultClientConfig,
  ap_totesPlacement: "right",
  ap_confirmCartNumberEnabled: true,
  putaway_showScanFilters: false,
  manualOpsEnabled: true
};

const trainingGridConfig: ClientConfig = {
  ...defaultClientConfig,
  dev_orderCreation: true,
  dev_ptlSimulation: true,
  dev_conveyanceSimulation: true,
  dev_automatedOperations: true,
  ap_fusionPortScreenEnabled: true,
  cartAssignmentEnabled: false,
  cartPrepEnabled: false,
  bat_printEnabled: false,
  manualOpsEnabled: false,
  fc_autostore_homepages: [
    "picking",
    "induction v2",
    "inventory holds",
    "cycle counts v2",
    "inventory v2"
  ]
};

const asWarehouseConfig: ClientConfig = {
  ...trainingGridConfig,
  manualOpsEnabled: true,
  inv_addExpirationFieldLabel: "code date",
  inv_inventoryDateLabel: "manufacture",
  inv_inventoryDateRequired: true,
  inv_addSetExpirationEnabled: false
};

const aslabConfig: ClientConfig = {
  ...trainingGridConfig,
  ap_binRemovedFlow: true,
  binCleaningEnabled: true
};

const bookstoreClientConfig: ClientConfig = {
  ...defaultClientConfig,
  ap_fusionPortScreenEnabled: true,
  ap_prepTotesModalEnabled: true,
  ap_prepTotesModalShowTitle: true,
  ap_prepTotesModalShowInstructionText: false,
  ap_prepTotesModalColumnPlacement: true,
  ap_prepTotesModalShowToteId: false,
  ap_prepTotesModalShowCustomerName: false,
  ap_prepTotesModalShowToteSize: false,
  ap_scanningIndicatorShown: true,
  ap_addToteFunctionalityEnabled: true,
  ap_repickToteFunctionalityEnabled: true,
  ap_showPickToLight: true,
  ap_excludeRecalledHold: true,
  ap_includeDamagedBinHold: true,
  ap_includeDirtyBinHold: true,
  ap_includeMisconfiguredBinHold: true,
  ap_applyingLabelProcessEnabled: true,
  ap_isTopAreaOfToteClickable: false,
  ap_showOrderOrProductCancellationModal: true,
  ap_binRemovedFlow: true,

  bat_stageFromBatchesEnabled: false,
  bat_printEnabled: false,
  manualOpsEnabled: false,
  binCleaningEnabled: true,
  cartAssignmentEnabled: false,
  cartPrepEnabled: false,

  putaway_multipleSearchTerms: true,
  putaway_showDecantRate: true,
  putaway_showExceptionsButton: true,

  ws_addRemovePortsEnabled: false,
  ws_modifyPortSetupEnabled: true,
  ws_deleteWorkstationEnabled: true,
  site_defaultLanguageCode: "bs",
  fc_autostore_homepages: [
    "picking",
    "induction v2",
    "inventory holds",
    "cycle counts v2",
    "inventory v2"
  ]
};

const bookstoreDevConfig: ClientConfig = {
  ...bookstoreClientConfig,
  dev_orderCreation: true,
  dev_ptlSimulation: true,
  dev_conveyanceSimulation: true,
  dev_automatedOperations: true,
  ap_binRemovedFlow: true
};

const parisClientConfig: ClientConfig = {
  ...defaultClientConfig,
  dev_orderCreation: true,
  manualOpsEnabled: true
};

const chicagoGrocerConfig: ClientConfig = {
  ...defaultClientConfig,
  ord_includeSpokes: false,
  manualOpsEnabled: true
};

const configList: Record<Guid, ClientConfig> = {
  // Bastian bev
  "ba571a99-4fdc-403e-a327-4be7786cf513": bevClientDevConfig,
  // Service & Support bev
  "5e971ce5-11d6-4375-ba04-9f48c1d9dec9": bevClientDevConfig,
  // bev client
  "9e951c04-edad-42a7-af77-accaf41701a1": bevClientConfig,

  // nj mart
  "ea57a51a-5b12-4501-5ace-c04eaaadea15": mochiConfig,
  // Bastian nj
  "ba571a99-c89e-40c4-b571-583b35d2c2e1": mochiConfig,
  // Service & Support nj
  "5e971ce5-0dee-44e6-a618-f38372cff1e0": mochiConfig,
  // Autostore training and demo grids
  "a470570e-0000-0000-0000-000000000000": trainingGridConfig,
  //Aslab
  "45748000-d0c6-4d67-b96f-c0afe4b3916b": aslabConfig,
  //AS Warehouse
  "3443219a-cd93-4b98-a7db-498ab060313d": asWarehouseConfig,
  /// bookstore gts1-3
  "1a8a502f-3540-4e50-a046-6c2aff6573ab": bookstoreDevConfig,
  "2a8a502f-3540-4e50-a046-6c2aff6573ab": bookstoreDevConfig,
  "3a8a502f-3540-4e50-a046-6c2aff6573ab": bookstoreDevConfig,
  // bookstore client
  "a8a502f4-3540-4e50-a046-6c2aff6573ab": bookstoreClientConfig,

  // Bastian bookstore
  "ba571a99-b859-418d-a75b-97f10b747876": bookstoreClientConfig,
  // Service & Support bookstore Client
  "5e971ce5-67c3-40ef-a0e1-55a76b55bbc7": bookstoreClientConfig,
  // A Paris Demo
  "7507d0c6-dd2b-4c97-9cb6-efa0eac82bd7": parisClientConfig,
  // chicago grocer
  "bdaba286-2072-44e2-bf00-e2c13d6c68f7": chicagoGrocerConfig
};

export const getClientConfig = (clientId: Guid | null): ClientConfig => {
  const boardLayouts = andonBoardLayouts[clientId ?? ""] ?? [];

  return {
    ...(configList[clientId ?? ""] ?? defaultClientConfig),
    an_boardLayouts: boardLayouts
  };
};
