import { WarningRounded } from "@mui/icons-material";
import { useMediaQuery, Typography, Container } from "@mui/material";
import {
  mobileWidth,
  formatUtcDate,
  BulkBoxIcon,
  ScanningIndicator,
  useScanIndicator,
  useToast,
  ErrorPanel,
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowSkeleton
} from "@qubit/autoparts";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";

import { searchProduct, getBarcodeValue } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGetPutawayTasksWithPagingQuery } from "~/redux/warehouse/putAwayTasks.hooks";
import { PutAwayTaskSummaryDto } from "~/types/api";

import { PutawaySearch } from "./PutawaySearch";
import { PutawayToolbar } from "./PutawayToolbar";
import { selectPutawayTasks, setPage, setSearch } from "./putaway.slice";

export type PutawayProps = { viewTitle?: ViewNameTranslation };

export const Putaway = ({ viewTitle }: PutawayProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const navigate = useNavigate();

  const { setMenuItems, setToolbar } = useNavbar({
    centerComponent: useMemo(() => <PutawaySearch />, []),
    viewTitle
  });

  const page = useAppSelector((state) => state.putaway.page);
  const searchText = useAppSelector((state) => state.putaway.search);
  const selectedPutawayTasks = useAppSelector(
    (state) => state.putaway.selectedPutawayTasks
  );

  const isMobile = useMediaQuery(mobileWidth);

  const limit = isMobile ? 6 : 11;

  const { data, isFetching, error, refetch } =
    useGetPutawayTasksWithPagingQuery(
      {
        status: ["scheduled"],
        limit,
        offset: (page - 1) * limit,
        ...(searchText && { search: searchText }),
        binType: [],
        temperatureZone: []
      },
      { refetchOnMountOrArgChange: true }
    );

  const putawayInventory = data?.putAwayTasks;
  const putawayInventoryCount = data?.putAwayTasksCount;

  const totalPageCount = putawayInventoryCount
    ? Math.ceil(putawayInventoryCount / limit)
    : 0;

  const [scanState, setScanState] = useScanIndicator();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  useKeyDownHandler();
  useBarcodeScanner({
    findScanMatch: (buffer: string) => {
      // product scan
      setScanState("success");
      setScannedBarcode(buffer);
      const barcodeValue = getBarcodeValue(buffer);
      dispatch(setPage(1));
      dispatch(setSearch(barcodeValue));
      return barcodeValue;
    },
    processScanMatch: () => null
  });

  // if there are no putaway tasks returned, check backend to see if the product exists at all
  useEffect(() => {
    if (putawayInventory?.length === 0 && scannedBarcode) {
      const searchProductFunc = async () => {
        const hits = await searchProduct(scannedBarcode);
        if (!hits.length) {
          errorToast(t("scanned product does not exist"));
        }
      };
      void searchProductFunc();
    }
  }, [errorToast, putawayInventory, scannedBarcode, t]);

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Refresh",
        actionCb: async () => {
          await refetch();
        }
      }
    ]);
  }, [navigate, refetch, setMenuItems]);

  useEffect(() => {
    if (selectedPutawayTasks.length > 0) {
      setToolbar(<PutawayToolbar limit={limit} />);
    } else {
      setToolbar(null);
    }
  }, [limit, selectedPutawayTasks.length, setToolbar]);

  const sortedPutawayInventory = putawayInventory?.slice().sort((a, b) => {
    // prioritize non-backstock bins if isHighPriority is the same
    if (
      a.target &&
      b.target &&
      a.target.isHighPriority === b.target.isHighPriority
    ) {
      return a.target.bin.binType !== "back stock" ? -1 : 0;
    }
    // otherwise, prioritize high priority
    return a.target && a.target.isHighPriority ? -1 : 0;
  });

  const tasksItemCount = sortedPutawayInventory?.reduce(
    (acc: number, i: PutAwayTaskSummaryDto) => acc + i.remaining.value,
    0
  );

  const handlePutAwayRowClick = (putAwayTaskId: string) => {
    dispatch(
      selectPutawayTasks(
        selectedPutawayTasks.includes(putAwayTaskId)
          ? [...selectedPutawayTasks.filter((id) => id !== putAwayTaskId)]
          : [...selectedPutawayTasks, putAwayTaskId]
      )
    );
  };

  return (
    <>
      <Container sx={{ my: 2 }}>
        {!!error && <ErrorPanel message={getMessageFromRtkError(error)} />}
        <ASTableV2
          gridTemplateColumns={isMobile ? "repeat(3, auto)" : "repeat(5, auto)"}
          isLoading={false}
          isEmpty={!isFetching && !sortedPutawayInventory?.length}
          isEmptyMessage={t("no results")}
          pagination={{
            pageCount: totalPageCount,
            page,
            setPage: (p) => {
              dispatch(setPage(p));
              if (window.scrollTo) window.scrollTo(0, 0);
            }
          }}
        >
          <ASTableV2Header>
            <ASTableV2Row>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("product")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("target bin")}</Typography>
              </ASTableV2Cell>
              {!isMobile && (
                <ASTableV2Cell>
                  <Typography variant="tableBody">
                    {t("received date")}
                  </Typography>
                </ASTableV2Cell>
              )}
              {!isMobile && (
                <ASTableV2Cell>
                  <Typography variant="tableBody">
                    {t("purchase order")}
                  </Typography>
                </ASTableV2Cell>
              )}
              <ASTableV2Cell>
                <Typography variant="tableBody">{`(${tasksItemCount}) ${t("items")}`}</Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
          </ASTableV2Header>
          <ASTableV2Body>
            {isFetching && (
              <ASTableV2RowSkeleton columns={isMobile ? 3 : 5} rows={4} />
            )}
            {sortedPutawayInventory?.map((row) => {
              const { isHighPriority } = row.target || {};
              const { name } = row.product || {};

              const {
                aisle = "",
                shelfBay = "",
                shelf = "",
                position = "",
                temperatureZone,
                binType
              } = row.target?.bin || {};

              const isBulkTarget = binType === "Bulk";

              return (
                <ASTableV2Row
                  key={row.putAwayTaskId}
                  selected={selectedPutawayTasks.includes(row.putAwayTaskId)}
                  onClick={() => handlePutAwayRowClick(row.putAwayTaskId)}
                >
                  <ASTableV2Cell
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <Typography variant="tableBody">{name}</Typography>
                    {isMobile && (
                      <>
                        <Typography variant="tableBody" color="textSecondary">
                          {formatUtcDate(row.createdTimestamp)}
                        </Typography>
                        {row.purchaseOrderLineItem?.purchaseOrderNumber && (
                          <Typography variant="tableBody" color="textSecondary">
                            {row.purchaseOrderLineItem.purchaseOrderNumber}
                          </Typography>
                        )}
                      </>
                    )}
                    {isHighPriority && (
                      <WarningRounded
                        sx={{
                          color: "warning.main",
                          verticalAlign: "bottom"
                        }}
                      />
                    )}
                  </ASTableV2Cell>
                  <ASTableV2Cell
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    <Typography variant="tableBody">
                      {[aisle, shelfBay, shelf, position]
                        .filter(Boolean)
                        .join("-")}
                    </Typography>
                    {isBulkTarget && (
                      <Typography component="div" variant="tableBody">
                        <BulkBoxIcon
                          tempZone={
                            temperatureZone?.toLowerCase() as
                              | "chilled"
                              | "ambient"
                              | "frozen"
                          }
                        />
                      </Typography>
                    )}
                  </ASTableV2Cell>
                  {!isMobile && (
                    <ASTableV2Cell>
                      <Typography>
                        {formatUtcDate(row.createdTimestamp) ?? ""}
                      </Typography>
                    </ASTableV2Cell>
                  )}
                  {!isMobile && (
                    <ASTableV2Cell>
                      <Typography>
                        {row.purchaseOrderLineItem?.purchaseOrderNumber ?? ""}
                      </Typography>
                    </ASTableV2Cell>
                  )}
                  <ASTableV2Cell>
                    <Typography variant="tableBody">
                      {row.remaining.value}
                    </Typography>
                  </ASTableV2Cell>
                </ASTableV2Row>
              );
            })}
          </ASTableV2Body>
        </ASTableV2>
      </Container>

      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText="Scan Product"
      />
    </>
  );
};
