import { Dialog, styled } from "@mui/material";

/**
 * A dialog with no padding or margins, and a default font weight of 400.
 *
 * This could one day become part of the mui theme to simply use Mui's Dialog.
 */
export const DialogRoot = styled(Dialog)(() => ({
  padding: 0,
  margin: 0,
  fontWeight: 400
}));
