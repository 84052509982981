import { t } from "i18next";

import { warehouseService, handleWarehouseError } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import {
  InventoryDto,
  InventoryReport,
  AdjustInventoryRequest,
  MoveInventoryRequest,
  VariantFrontendDto,
  SlotDto,
  BinDto,
  RequestChangeOfBin,
  PutAwayRequest,
  GetInventorySummariesResponse,
  LowInventoryPage,
  VariantPutawayTaskIdMapping,
  LowInventoryQuery,
  InventorySummaryDto,
  RequestBinsRequestDto
} from "~/types/api";

import { CloseWorkstationSuccessAction } from "./autostore";
import { SetUserMessageAction } from "./site";

/**
 * Generate the list of Inventory Adjustment Reason Codes to display,
 * either with a client-specific list or a generic list.
 *
 * @clientFcReasonCodes Client-specific list of reason codes.
 */
export const generateInventoryReasonCodeOptions = (
  clientFcReasonCodes: string[] | undefined
) => {
  const defaultReasonCodes = [
    { value: "Counted", label: t("counted") },
    { value: "Customer Return", label: t("customer return") },
    { value: "Damaged", label: t("damaged") },
    { value: "Expired", label: t("expired") },
    { value: "Found", label: t("found") },
    { value: "Missing", label: t("missing") },
    { value: "Transfer", label: t("transfer out") }
  ] as const;
  type ReasonCodes = (typeof defaultReasonCodes)[number]["value"];
  if (clientFcReasonCodes?.length) {
    return [
      ...clientFcReasonCodes.map((reasonCode) => ({
        value: reasonCode,
        label: t(reasonCode.toLowerCase() as Lowercase<ReasonCodes>)
      })),
      ...[
        { value: "Counted", label: t("counted") },
        { value: "Transfer", label: t("transfer out") }
      ]
    ];
  }
  return defaultReasonCodes;
};

export interface GetInventoryReportAction {
  type: "inventory/GET_INVENTORY_REPORT";
}

export interface GetInventoryReportSuccessAction {
  type: "inventory/GET_INVENTORY_REPORT_SUCCESS";
  payload: InventoryReport[];
}

export interface GetInventoryReportFailureAction {
  type: "inventory/GET_INVENTORY_REPORT_FAILURE";
}

export interface PutAwayAction {
  type: "inventory/PUT_AWAY";
}

export interface PutAwayFailureAction {
  type: "inventory/PUT_AWAY_FAILURE";
}

export interface ClearSelectedVariantAction {
  type: "inventory/CLEAR_SELECTED_VARIANT";
}

export interface FindInventoryToModifyByVariantAction {
  type: "inventory/GET_INVENTORY_TO_MODIFY_BY_VID";
}

export interface FindInventoryToModifyByVariantSuccessAction {
  type: "inventory/GET_INVENTORY_TO_MODIFY_BY_VID_SUCCESS";
  payload: InventorySummaryDto;
}

export interface ClearInventoryByVariantsAction {
  type: "inventory/CLEAR_INVENTORY_TO_MODIFY_BY_VID";
}
export interface ClearInventoryAction {
  type: "inventory/CLEAR_INVENTORY";
}

export interface FindInventoryByAutostoreBinNumberAction {
  type: "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER";
}

export interface FindInventoryByAutostoreBinNumberSuccessAction {
  type: "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER_SUCCESS";
  payload: InventoryDto[];
}

export interface FindInventoryToModifyByVariantFailureAction {
  type: "inventory/GET_INVENTORY_TO_MODIFY_BY_VID_FAILURE";
}

export interface FindInventoryByAutostoreBinNumberFailureAction {
  type: "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER_FAILURE";
}

export interface AdjustInventoryAction {
  type: "inventory/ADJUST_INVENTORY";
}

export interface AdjustInventorySuccessAction {
  type: "inventory/ADJUST_INVENTORY_SUCCESS";
}

export interface AdjustInventoryFailureAction {
  type: "inventory/ADJUST_INVENTORY_FAILURE";
}

export interface MoveInventoryAction {
  type: "inventory/MOVE_INVENTORY";
}

export interface MoveInventorySuccessAction {
  type: "inventory/MOVE_INVENTORY_SUCCESS";
  payload: string;
}

export interface MoveInventoryFailureAction {
  type: "inventory/MOVE_INVENTORY_FAILURE";
}

export interface GetSlottingInfoAction {
  type: "inventory/GET_SLOTTING_INFO";
}

export interface GetSlottingInfoSuccessAction {
  type: "inventory/GET_SLOTTING_INFO_SUCCESS";
  payload: SlotDto[];
}

export interface GetSlottingInfoFailureAction {
  type: "inventory/GET_SLOTTING_INFO_FAILURE";
}

export interface UpdateSlottingInfoFailureAction {
  type: "inventory/UPDATE_SLOTTING_INFO_FAILURE";
}

export interface GetBinByIdAction {
  type: "inventory/GET_BIN_BY_ID";
}

export interface GetBinByIdSuccessAction {
  type: "inventory/GET_BIN_BY_ID_SUCCESS";
  payload: BinDto;
}

export interface GetBinByIdFailureAction {
  type: "inventory/GET_BIN_BY_ID_FAILURE";
}

export interface GetSlotBinByIdAction {
  type: "inventory/GET_SLOT_BIN_BY_ID";
}

export interface GetSlotBinByIdSuccessAction {
  type: "inventory/GET_SLOT_BIN_BY_ID_SUCCESS";
  payload: BinDto;
}

export interface GetSlotBinByIdFailureAction {
  type: "inventory/GET_SLOT_BIN_BY_ID_FAILURE";
}

export interface CreateBinSlotFailureAction {
  type: "inventory/CREATE_BIN_SLOT_FAILURE";
}

export interface SetSelectedInventoryIdAction {
  type: "inventory/SET_SELECTED_INVENTORY_ID";
  payload: string | null;
}

export interface ClearSelectedInventoryIdAction {
  type: "inventory/CLEAR_SELECTED_INVENTORY_ID";
}

export interface GetInventorySummariesAction {
  type: "inventory/GET_INVENTORY_SUMMARIES";
}

export interface GetInventorySummariesSuccessAction {
  type: "inventory/GET_INVENTORY_SUMMARIES_SUCCESS";
  payload: GetInventorySummariesResponse;
}

export interface GetInventorySummariesFailureAction {
  type: "inventory/GET_INVENTORY_SUMMARIES_FAILURE";
}

export interface GetLowInventoryAction {
  type: "inventory/GET_LOW_INVENTORY";
}

export interface GetLowInventorySuccessAction {
  type: "inventory/GET_LOW_INVENTORY_SUCCESS";
  payload: LowInventoryPage;
}

export interface GetLowInventoryFailureAction {
  type: "inventory/GET_LOW_INVENTORY_FAILURE";
}

export interface FindPutawaysByVariantAction {
  type: "inventory/FIND_PUTAWAYS_BY_VARIANT";
}

export interface FindPutawaysByVariantSuccessAction {
  type: "inventory/FIND_PUTAWAYS_BY_VARIANT_SUCCESS";
  payload: VariantPutawayTaskIdMapping[];
}

export interface FindPutawaysByVariantFailureAction {
  type: "inventory/FIND_PUTAWAYS_BY_VARIANT_FAILURE";
}

export interface PlaceInventoryHoldAction {
  type: "inventory/PLACE_INVENTORY_HOLD";
}

export interface PlaceInventoryHoldSuccessAction {
  type: "inventory/PLACE_INVENTORY_HOLD_SUCCESS";
  payload: { inventoryId: Guid; reasonCode: string };
}

export interface PlaceInventoryHoldFailureAction {
  type: "inventory/PLACE_INVENTORY_HOLD_FAILURE";
}

export interface DeleteInventoryHoldSuccessAction {
  type: "inventory/DELETE_INVENTORY_HOLD_SUCCESS";
}

export interface DeleteInventoryHoldFailureAction {
  type: "inventory/DELETE_INVENTORY_HOLD_FAILURE";
}

export interface ClearSlotBin {
  type: "inventory/CLEAR_SLOT_BIN";
}

export const getInventoryReport =
  (variantId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetInventoryReportAction>({
      type: "inventory/GET_INVENTORY_REPORT"
    });
    try {
      const response = await warehouseService.get<InventoryReport[]>(
        `/api/inventory/report/variant/${variantId}`
      );
      dispatch<GetInventoryReportSuccessAction>({
        type: "inventory/GET_INVENTORY_REPORT_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetInventoryReportFailureAction>({
          type: "inventory/GET_INVENTORY_REPORT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_INVENTORY_REPORT_FAILURE"
          }
        });
      });
    }
  };

// GET VARIANT BY ID

export interface GetVariantByVariantIdAction {
  type: "inventory/GET_VARIANT_BY_VARIANT_ID";
}
export interface GetVariantByVariantIdSuccessAction {
  type: "inventory/GET_VARIANT_BY_VARIANT_ID_SUCCESS";
  payload: VariantFrontendDto;
}
export interface GetVariantByVariantIdFailureAction {
  type: "inventory/GET_VARIANT_BY_VARIANT_ID_FAILURE";
}

export const getVariantByVariantId =
  (variantId: Guid): AsyncAppThunk<VariantFrontendDto | null | void> =>
  async (dispatch) => {
    dispatch<GetVariantByVariantIdAction>({
      type: "inventory/GET_VARIANT_BY_VARIANT_ID"
    });
    try {
      const response = await warehouseService.post<VariantFrontendDto[]>(
        `/api/variants`,
        [variantId]
      );
      if (!response.data[0]) {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: "variant not found",
            severity: "warning",
            origin: "inventory/VARIANT_NOT_FOUND"
          }
        });
        return null;
      }

      dispatch<GetVariantByVariantIdSuccessAction>({
        type: "inventory/GET_VARIANT_BY_VARIANT_ID_SUCCESS",
        payload: response.data[0]
      });

      return response.data[0];
    } catch (err) {
      return handleWarehouseError(err, (message) => {
        dispatch<GetVariantByVariantIdFailureAction>({
          type: "inventory/GET_VARIANT_BY_VARIANT_ID_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_VARIANT_BY_VARIANT_ID_FAILURE"
          }
        });
      });
    }
  };

export interface GetInventorySummariesParams {
  autostoreGridId?: Guid;
  hold?: string[];
  hasHold?: boolean;
  variantId?: Guid;
  inventoryId?: Guid;
  binId?: Guid | Guid[];
  limit?: number;
  offset?: number;
  binTypesIncluded?: "autostore";
  binTypesExcluded?: "autostore";
}

export const getVariantData =
  (params: GetInventorySummariesParams): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<FindInventoryToModifyByVariantAction>({
      type: "inventory/GET_INVENTORY_TO_MODIFY_BY_VID"
    });
    try {
      // get most recent inventory data scoped to the variantId
      const response =
        await warehouseService.get<GetInventorySummariesResponse>(
          "/api/inventory",
          { params }
        );
      const filteredInventoryItem = response.data.inventory.filter(
        (inv) => inv.inventoryId === params.inventoryId
      );
      dispatch<FindInventoryToModifyByVariantSuccessAction>({
        type: "inventory/GET_INVENTORY_TO_MODIFY_BY_VID_SUCCESS",
        // return element out of array, since we're limiting to 1 value
        payload: filteredInventoryItem[0]
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<FindInventoryToModifyByVariantFailureAction>({
          type: "inventory/GET_INVENTORY_TO_MODIFY_BY_VID_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_INVENTORY_TO_MODIFY_BY_VID_FAILURE"
          }
        });
      });
    }
  };

export const getInventorySummaries =
  (params: GetInventorySummariesParams): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetInventorySummariesAction>({
      type: "inventory/GET_INVENTORY_SUMMARIES"
    });
    try {
      const response =
        await warehouseService.get<GetInventorySummariesResponse>(
          "/api/inventory",
          { params: { ...params, limit: params.limit || 100 } }
        );
      dispatch<GetInventorySummariesSuccessAction>({
        type: "inventory/GET_INVENTORY_SUMMARIES_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetInventorySummariesFailureAction>({
          type: "inventory/GET_INVENTORY_SUMMARIES_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_INVENTORY_SUMMARIES_FAILURE"
          }
        });
      });
    }
  };

/**
 * Remove an inventory hold.
 *
 * @inventoryId The inventory to remove the hold for.
 * @thisWorkstationId The workstation the user is at.
 * @reasonCode The hold to remove.
 * @autostoreGridId The grid the user is at.
 * @autostorePortId The port the user is at.
 */
export const deleteInventoryHold =
  (
    inventoryId: Guid,
    thisWorkstationId: Guid | null,
    reasonCode: string,
    autostoreGridId?: Guid,
    autostorePortId?: number
  ): AsyncAppThunk =>
  async (dispatch) => {
    try {
      await warehouseService.delete(`/api/inventory/${inventoryId}/hold`, {
        data: {
          workstationId: thisWorkstationId,
          autostoreGridId: autostoreGridId || null,
          autostorePortId: autostorePortId || null,
          reasonCode
        }
      });
      dispatch<DeleteInventoryHoldSuccessAction>({
        type: "inventory/DELETE_INVENTORY_HOLD_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<DeleteInventoryHoldFailureAction>({
          type: "inventory/DELETE_INVENTORY_HOLD_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/DELETE_INVENTORY_HOLD_FAILURE"
          }
        });
      });
    }
  };

export const findInventoryByAutostoreBinNumber =
  (
    autostoreGridId: Guid,
    binNumber: number
  ): AsyncAppThunk<InventoryDto[] | null> =>
  async (dispatch) => {
    dispatch<FindInventoryByAutostoreBinNumberAction>({
      type: "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER"
    });
    try {
      const response = await warehouseService.get<InventoryDto[]>(
        `/api/inventory/grid/${autostoreGridId}/by-autostore-bin-number/${binNumber}`
      );
      dispatch<FindInventoryByAutostoreBinNumberSuccessAction>({
        type: "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER_SUCCESS",
        payload: response.data
      });
      return response.data;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<FindInventoryByAutostoreBinNumberFailureAction>({
          type: "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/FIND_INVENTORY_BY_AUTOSTORE_BIN_NUMBER_FAILURE"
          }
        });
      });
      return null;
    }
  };

export const putAway =
  (putAwayRequest: PutAwayRequest, workstationId?: string): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<PutAwayAction>({
      type: "inventory/PUT_AWAY"
    });
    try {
      await warehouseService.post("/api/inventory/put-away", {
        ...putAwayRequest,
        workstationId
      });
      dispatch<SetUserMessageAction>({
        type: "site/SET_USER_MESSAGE",
        payload: {
          title: t("inventory added"),
          severity: "success",
          autohideDuration: 2000,
          origin: "putaway thunk"
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<PutAwayFailureAction>({
          type: "inventory/PUT_AWAY_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/PUT_AWAY_FAILURE"
          }
        });
      });
    }
  };

export const adjustInventory =
  (
    args: AdjustInventoryRequest & { inventoryId: Guid; workstationId?: string }
  ): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<AdjustInventoryAction>({
      type: "inventory/ADJUST_INVENTORY"
    });
    const { inventoryId, workstationId, ...rest } = args;

    try {
      await warehouseService.patch<void>(`/api/inventory/${inventoryId}`, {
        ...rest,
        workstationId
      });
      dispatch<AdjustInventorySuccessAction>({
        type: "inventory/ADJUST_INVENTORY_SUCCESS"
      });
      dispatch<SetUserMessageAction>({
        type: "site/SET_USER_MESSAGE",
        payload: {
          title: t("inventory adjusted"),
          severity: "success",
          autohideDuration: 2000,
          origin: "inventory/ADJUST_INVENTORY_SUCCESS"
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<AdjustInventoryFailureAction>({
          type: "inventory/ADJUST_INVENTORY_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/ADJUST_INVENTORY_FAILURE"
          }
        });
      });
    }
  };

export const moveInventory =
  (
    args: MoveInventoryRequest,
    inventoryId: Guid,
    location: string,
    workstationId?: string
  ): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<MoveInventoryAction>({
      type: "inventory/MOVE_INVENTORY"
    });
    const { targetBinId, count } = args;
    try {
      await warehouseService.post(`/api/inventory/${inventoryId}/move`, {
        targetBinId,
        count,
        workstationId
      });
      dispatch<MoveInventorySuccessAction>({
        type: "inventory/MOVE_INVENTORY_SUCCESS",
        payload: location
      });
      dispatch<SetUserMessageAction>({
        type: "site/SET_USER_MESSAGE",
        payload: {
          title: `${t("inventory moved to")} ${location}`,
          severity: "success",
          autohideDuration: 2000,
          origin: "inventory/MOVE_INVENTORY_SUCCESS"
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<MoveInventoryFailureAction>({
          type: "inventory/MOVE_INVENTORY_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/MOVE_INVENTORY_FAILURE"
          }
        });
      });
    }
  };

export const getSlottingInfo =
  (params: {
    binId?: Guid;
    search?: string;
    variantId?: Guid;
  }): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetSlottingInfoAction>({
      type: "inventory/GET_SLOTTING_INFO"
    });
    try {
      const response = await warehouseService.get<SlotDto[]>(`/api/slots`, {
        params
      });
      dispatch<GetSlottingInfoSuccessAction>({
        type: "inventory/GET_SLOTTING_INFO_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetSlottingInfoFailureAction>({
          type: "inventory/GET_SLOTTING_INFO_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_SLOTTING_INFO_FAILURE"
          }
        });
      });
    }
  };

export const createBinSlot =
  ({ variantId, binId }: { variantId: Guid; binId: Guid }): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const requestBody = [{ binId, variantId }];
      await warehouseService.post<void>(`/api/slots`, requestBody);
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<CreateBinSlotFailureAction>({
          type: "inventory/CREATE_BIN_SLOT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/CREATE_BIN_SLOT_FAILURE"
          }
        });
      });
    }
  };

export const updateSlottingInfo =
  ({ slotId, binId }: { slotId: Guid; binId: Guid }): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const requestBody: RequestChangeOfBin = { binId };
      await warehouseService.post<void>(
        `/api/slots/${slotId}/change-of-bin`,
        requestBody
      );
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<UpdateSlottingInfoFailureAction>({
          type: "inventory/UPDATE_SLOTTING_INFO_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/UPDATE_SLOTTING_INFO_FAILURE"
          }
        });
      });
    }
  };

export const getBinById =
  (binId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetBinByIdAction>({
      type: "inventory/GET_BIN_BY_ID"
    });
    try {
      const response = await warehouseService.get<BinDto>(`/api/bins/${binId}`);
      dispatch<GetBinByIdSuccessAction>({
        type: "inventory/GET_BIN_BY_ID_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetBinByIdFailureAction>({
          type: "inventory/GET_BIN_BY_ID_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_BIN_BY_ID_FAILURE"
          }
        });
      });
    }
  };

export const getSlotBinById =
  (binId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetSlotBinByIdAction>({
      type: "inventory/GET_SLOT_BIN_BY_ID"
    });
    try {
      const response = await warehouseService.get<BinDto>(`/api/bins/${binId}`);
      dispatch<GetSlotBinByIdSuccessAction>({
        type: "inventory/GET_SLOT_BIN_BY_ID_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetSlotBinByIdFailureAction>({
          type: "inventory/GET_SLOT_BIN_BY_ID_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_SLOT_BIN_BY_ID_FAILURE"
          }
        });
      });
    }
  };

export const getLowInventory =
  (limit: number, offset: number, hasPutawayTasks?: boolean): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetLowInventoryAction>({
      type: "inventory/GET_LOW_INVENTORY"
    });
    try {
      const queryParams: LowInventoryQuery = {
        prioritizeFullyCommitted: true,
        hasPutawayTasks,
        limit,
        offset
      };

      const response = await warehouseService.get<LowInventoryPage>(
        `/api/inventory/low-inventory`,
        {
          params: queryParams
        }
      );
      dispatch<GetLowInventorySuccessAction>({
        type: "inventory/GET_LOW_INVENTORY_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetLowInventoryFailureAction>({
          type: "inventory/GET_LOW_INVENTORY_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/GET_LOW_INVENTORY_FAILURE"
          }
        });
      });
    }
  };

export const findPutawayByVariant =
  (variantIds: Guid[]): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<FindPutawaysByVariantAction>({
      type: "inventory/FIND_PUTAWAYS_BY_VARIANT"
    });
    try {
      const response = await warehouseService.post<
        VariantPutawayTaskIdMapping[]
      >(`/api/inventory/put-away/find-by-variant`, variantIds);
      dispatch<FindPutawaysByVariantSuccessAction>({
        type: "inventory/FIND_PUTAWAYS_BY_VARIANT_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<FindPutawaysByVariantFailureAction>({
          type: "inventory/FIND_PUTAWAYS_BY_VARIANT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/FIND_PUTAWAYS_BY_VARIANT_FAILURE"
          }
        });
      });
    }
  };

/**
 * Place an inventory hold.
 *
 * @inventoryId The inventory to remove the hold for.
 * @reasonCode The hold to place.
 * @thisWorkstationId The workstation the user is at.
 */
export const placeInventoryHold =
  (
    inventoryId: Guid,
    reasonCode: string,
    thisWorkstationId: Guid | null
  ): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<PlaceInventoryHoldAction>({
      type: "inventory/PLACE_INVENTORY_HOLD"
    });
    try {
      await warehouseService.post(`/api/inventory/holds/${inventoryId}/place`, {
        reasonCode,
        workstationId: thisWorkstationId
      });
      dispatch<PlaceInventoryHoldSuccessAction>({
        type: "inventory/PLACE_INVENTORY_HOLD_SUCCESS",
        payload: { inventoryId, reasonCode }
      });
      dispatch<SetUserMessageAction>({
        type: "site/SET_USER_MESSAGE",
        payload: {
          title: t("hold placed successfully"),
          severity: "success",
          autohideDuration: 2000,
          origin: "inventory/PLACE_INVENTORY_HOLD_SUCCESS"
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<PlaceInventoryHoldFailureAction>({
          type: "inventory/PLACE_INVENTORY_HOLD_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/PLACE_INVENTORY_HOLD_FAILURE"
          }
        });
      });
    }
  };

export const clearSlotBin = (): AppThunk => (dispatch) => {
  dispatch<ClearSlotBin>({
    type: "inventory/CLEAR_SLOT_BIN"
  });
};

export const setSelectedInventoryId =
  (id: string | null): AppThunk =>
  (dispatch) => {
    dispatch<SetSelectedInventoryIdAction>({
      type: "inventory/SET_SELECTED_INVENTORY_ID",
      payload: id
    });
  };

export const clearSelectedInventoryId = (): AppThunk => (dispatch) => {
  dispatch<ClearSelectedInventoryIdAction>({
    type: "inventory/CLEAR_SELECTED_INVENTORY_ID"
  });
};

export const clearSelectedVariant =
  (): AppThunk =>
  (dispatch): void => {
    dispatch<ClearSelectedVariantAction>({
      type: "inventory/CLEAR_SELECTED_VARIANT"
    });
  };

export const clearInventoryToModify = (): AppThunk => (dispatch) => {
  dispatch<ClearInventoryByVariantsAction>({
    type: "inventory/CLEAR_INVENTORY_TO_MODIFY_BY_VID"
  });
};

export const clearInventory = (): AppThunk => (dispatch) => {
  dispatch<ClearInventoryAction>({
    type: "inventory/CLEAR_INVENTORY"
  });
};

export const requestBinsForMultiport =
  (
    binIds: number[],
    autostoreGridId: Guid,
    workstationId: Guid
  ): AsyncAppThunk =>
  async (dispatch) => {
    try {
      // Close workstation in order to close all ports
      await warehouseService.post(
        `/api/workstations/grid/${autostoreGridId}/workstation/${workstationId}/close-workstation`
      );
      dispatch<CloseWorkstationSuccessAction>({
        type: "autostore/CLOSE_WORKSTATION_SUCCESS"
      });

      const getBinsRequest: RequestBinsRequestDto = {
        binIds
      };

      await warehouseService.post(
        `/api/autostore-grid/${autostoreGridId}/workstation/${workstationId}/request-bins`,
        getBinsRequest
      );
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error",
            origin: "inventory/REQUEST_BINS_FOR_MULTIPORT_FAILURE"
          }
        });
      });
    }
  };
