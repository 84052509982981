import { Box, Card, CardContent, Paper, Skeleton, Stack } from "@mui/material";
import LazyLoad from "react-lazyload";

import { useAppSelector } from "~/app/store";
import { createNumberSequence } from "~/lib/shared";
import { BatchSummaryDto } from "~/types/api";

import { BatchCard } from "./BatchCard";
import { selectBatchIds } from "./batchCards.slice";

function LoadingBatchCard() {
  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Skeleton variant="rectangular" width="100%" height={56} />
        <Skeleton variant="text" width="100%" height={28} />
        <Skeleton variant="text" width="100%" height={28} />
      </CardContent>
    </Card>
  );
}

export function BatchList({
  batchesLoading,
  selectedBatch,
  onBatchSelected
}: {
  batchesLoading: boolean;
  selectedBatch: BatchSummaryDto | null;
  onBatchSelected: (batch: BatchSummaryDto) => void;
}) {
  const batchIds = useAppSelector(selectBatchIds);
  const rows = batchIds?.map((batchId) => (
    // flex-basis here was set to 100% by material ui class
    // however, this messes up the view on safari
    <Box key={batchId}>
      <LazyLoad height={200} offset={500} once>
        <BatchCard
          batchId={batchId}
          onBatchSelected={onBatchSelected}
          selectedBatchId={selectedBatch?.batchId || null}
        />
      </LazyLoad>
    </Box>
  ));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: 3,
        width: "100%"
      }}
    >
      {batchesLoading ? (
        createNumberSequence(10).map((num) => <LoadingBatchCard key={num} />)
      ) : (
        <Paper elevation={5} sx={{ padding: 1 }}>
          <Stack spacing={1}>{rows}</Stack>
        </Paper>
      )}
    </Box>
  );
}
