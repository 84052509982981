import { SxProps, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { formatDateTime, useToast } from "@qubit/autoparts";
import { useEffect } from "react";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  useGetAutostoreGridsQuery,
  useGetAutostoreGridStatusQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { AutostoreGridDto } from "~/types/api";

const GridStatusBox: SxProps = {
  width: "600px",
  p: 1,
  backgroundColor: "gray.dark",
  display: "grid",
  gridTemplateColumns: "3fr 7fr",
  "& > *": { display: "block" }
};

const GridStatus = (props: { autostoreGrid: AutostoreGridDto }) => {
  const {
    data: systemStatus,
    fulfilledTimeStamp,
    error
  } = useGetAutostoreGridStatusQuery(props.autostoreGrid.autostoreGridId, {
    pollingInterval: 5000
  });
  const { errorToast } = useToast();

  useEffect(() => {
    if (error) errorToast(getMessageFromRtkError(error));
  }, [error, errorToast]);

  if (!systemStatus || !fulfilledTimeStamp) return <></>;

  return (
    <>
      <Typography>Last Refreshed:</Typography>
      <Typography>{formatDateTime(new Date(fulfilledTimeStamp))}</Typography>
      {systemStatus.kind === "connected" && (
        <>
          <Typography>Mode:</Typography>
          <Typography>{systemStatus.mode}</Typography>
          <Typography>Charge:</Typography>
          <Typography>{systemStatus.charge}</Typography>
          {systemStatus.stopCode && (
            <>
              <Typography>Stop Code:</Typography>
              <Typography>{systemStatus.stopCode}</Typography>
            </>
          )}
        </>
      )}
      {systemStatus.kind === "error" && (
        <>
          <Typography>Grid Error:</Typography>
          <Typography>{systemStatus.message}</Typography>
        </>
      )}
    </>
  );
};

export const SystemStatus = () => {
  const { data: autostoreGrids, error } = useGetAutostoreGridsQuery();

  const { errorToast } = useToast();

  useEffect(() => {
    if (error) errorToast(getMessageFromRtkError(error));
  }, [error, errorToast]);

  const grids = autostoreGrids ? autostoreGrids : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2.5
      }}
    >
      {grids.map((g) => (
        <Box key={g.autostoreGridId} sx={GridStatusBox}>
          <Typography>Autostore Grid Name:</Typography>
          <Typography>{g.autostoreGridName}</Typography>
          <Typography>Autostore Grid Id:</Typography>
          <Typography>{g.autostoreGridId}</Typography>
          <GridStatus autostoreGrid={g} />
        </Box>
      ))}
    </Box>
  );
};
