import { Stack, Box } from "@mui/material";

import { useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { selectPortStateByPort } from "~/redux/selectors/autostoreSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import {
  BinMovementAssignment,
  BinMovementState,
  InventoryMovementResponse
} from "~/types/api";

type Props = {
  currentBins: InventoryMovementResponse | undefined;
  selectedRow: BinMovementAssignment | undefined;
  selectedBin?: BinMovementState | null;
  isFlagBinModalOpen?: boolean;
};

const MovementsBins = (props: Props) => {
  const { currentBins, selectedRow, isFlagBinModalOpen } = props;
  const portStateByPort = useAppSelector(selectPortStateByPort);
  const workstation = useAppSelector(selectThisWorkstation);

  return (
    <Stack direction="row" gap={2} pb={2} width="100%">
      {workstation?.ports.map((port, i) => {
        const isFirstPort = i === 0;
        const isSecondPort = i === 1;
        const isThirdPort = i === 2;
        const isLoading =
          !isThirdPort &&
          !portStateByPort[port.portId]?.getPortResponse.isReady;
        let thisBin: BinMovementState | undefined;
        if (isFirstPort) {
          thisBin = currentBins?.sourceBin;
        } else if (isSecondPort) {
          thisBin = currentBins?.destinationBin;
        }
        const pickCompartment = thisBin?.compartmentNumber
          ? thisBin.compartmentNumber - 1
          : null;

        return (
          <Box
            key={port.portId}
            flex="1"
            sx={{
              border:
                isSecondPort && isFlagBinModalOpen ? "0.625em solid" : "none",
              borderColor:
                isSecondPort && isFlagBinModalOpen ? "blue" : "transparent",
              borderRadius: isSecondPort && isFlagBinModalOpen ? "0.5em" : "0",
              pointerEvents:
                isFlagBinModalOpen && (isFirstPort || isThirdPort)
                  ? "none"
                  : "auto",
              opacity:
                isFlagBinModalOpen && (isFirstPort || isThirdPort) ? 0.5 : 1
            }}
            data-testid={`bin-container-${i}`}
          >
            <AutostoreBin
              state={
                isThirdPort
                  ? "Port Closed"
                  : isLoading
                    ? "Waiting for Bin"
                    : "Bin Opened"
              }
              binId={thisBin?.autostoreBinId}
              numberOfRows={thisBin?.horizontalCompartmentCount || 1}
              numberOfColumns={thisBin?.verticalCompartmentCount || 1}
              pickQuantity={
                !isFlagBinModalOpen ? selectedRow?.quantity.value || 0 : ""
              }
              pickCompartment={!isFlagBinModalOpen ? pickCompartment : null}
            />
          </Box>
        );
      })}
    </Stack>
  );
};

export default MovementsBins;
