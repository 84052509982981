import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, TextField, styled } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { PutAwayTaskSummaryDto } from "~/types/api";

const AdjustQuantityButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  backgroundColor: theme.palette.gray.dark,
  borderRadius: "10px",
  ":hover": {
    backgroundColor: theme.palette.darkGray.light
  }
}));

type Props = {
  changedQuantity: number | undefined;
  handleChangeQuantity: (row: PutAwayTaskSummaryDto, quantity: number) => void;
  selectedRow: PutAwayTaskSummaryDto;
  isMaxQuantityExceeded?: boolean;
};

export const QuantityField = (props: Props) => {
  const {
    changedQuantity,
    handleChangeQuantity,
    selectedRow,
    isMaxQuantityExceeded
  } = props;
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const quantityMaxValue = 10000;
  const remainingValue = selectedRow?.remaining.value || quantityMaxValue;

  const handleQuantityChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = parseInt(e.target.value || "0", 10);
    if (inputValue <= remainingValue && inputValue < quantityMaxValue) {
      handleChangeQuantity(selectedRow, inputValue);
    } else if (inputValue > remainingValue) {
      errorToast(t("max quantity for selected item is") + ` ${remainingValue}`);
    }
  };

  const onClickIncreaseQuantity = () => {
    if (!selectedRow) {
      return;
    }
    const previousQuantity = changedQuantity ?? selectedRow.remaining.value;
    const newQuantity =
      changedQuantity === undefined ? 1 : previousQuantity + 1;
    if (newQuantity <= remainingValue) {
      handleChangeQuantity(selectedRow, newQuantity);
    } else if (selectedRow?.remaining) {
      errorToast(t("max quantity for selected item is") + ` ${remainingValue}`);
    }
  };

  const onClickDecreaseQuantity = () => {
    if (!selectedRow) {
      return;
    }
    const previousQuantity = changedQuantity ?? selectedRow.remaining.value;
    const newQuantity =
      changedQuantity === undefined ? 0 : previousQuantity - 1;
    if (newQuantity > 0) {
      handleChangeQuantity(selectedRow, newQuantity);
    } else {
      errorToast("Quantity must be greater than zero");
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <TextField
        label={t("quantity")}
        variant="outlined"
        inputProps={{
          style: {
            fontSize: "48px",
            textAlign: "center"
          }
        }}
        InputLabelProps={{
          sx: {
            color: "gray.main.dark",
            fontSize: 22
          },
          shrink: true
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline > legend": {
            fontSize: "unset"
          }
        }}
        onChange={handleQuantityChange}
        value={changedQuantity || ""}
        error={!changedQuantity || isMaxQuantityExceeded}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
        <AdjustQuantityButton
          aria-label="quantity-increase"
          onClick={onClickIncreaseQuantity}
          size="large"
        >
          <KeyboardArrowUpIcon />
        </AdjustQuantityButton>
        <AdjustQuantityButton
          aria-label="quantity-decrease"
          onClick={onClickDecreaseQuantity}
          size="large"
          disabled={!changedQuantity}
        >
          <KeyboardArrowDownIcon />
        </AdjustQuantityButton>
      </Box>
    </Box>
  );
};
