import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { Box } from "@mui/material";

type Props = {
  status: "Completed" | "Scheduled" | "Canceled";
  exception?: "outofstock";
};

export function PickStatusIcon({ exception, status }: Props) {
  const isComplete = status.toLowerCase() === "completed";
  const isOOS =
    isComplete && !!exception && exception?.toLowerCase() === "outofstock";
  const isCanceled = status.toLowerCase() === "canceled" || isOOS;

  if (isCanceled) {
    return (
      <CancelOutlined
        sx={{ color: "autostoreRed.main" }}
        data-testid="oos-icon"
      />
    );
  }

  if (isComplete) {
    return (
      <CheckCircleOutline
        data-testid="complete-icon"
        sx={{ color: "success.main" }}
      />
    );
  }
  return <Box width="24px" height="24px" />;
}
