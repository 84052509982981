import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import { styled } from "@mui/material/styles";
import { mobileWidth } from "@qubit/autoparts";
import { t } from "i18next";
import { useRef, useState } from "react";

import { useAppSelector } from "~/app/store";

import LogoutButton from "~/features/login/LogoutButton";
import { extractUsername } from "~/lib/helpers";
import { selectAuth0Profile } from "~/redux/selectors/authSelectors";

const UserDetailsTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isMobile: boolean };
}>(({ extraProps }) => ({
  overflowWrap: "break-word",
  width: extraProps.isMobile ? "80%" : "90%"
}));

const UserDetailsMainContainer = styled(Stack)(() => ({
  padding: "5px",
  alignItems: "center",
  flexDirection: "column"
}));

const UserDetailsMenuPreviewContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "10px"
}));

const ArrowDownButton = styled(IconButton)(() => ({
  height: 35,
  width: 35,
  marginLeft: "3px"
}));

const ArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  height: 32,
  width: 32,
  color: theme.palette.darkGray.main
}));

const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isMobile: boolean };
}>(({ extraProps }) => ({
  "& .MuiPaper-root": {
    width: extraProps.isMobile ? 200 : 320,
    borderRadius: "5px",
    marginTop: "10px",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    zIndex: 10000
  }
}));
const UserProfileMenu = () => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const rippleRef = useRef<
    React.ComponentType<unknown> & {
      start: (
        event: unknown,
        options: { pulsate: boolean; center: boolean }
      ) => void;
      stop: () => void;
    }
  >(null);
  const isMobile = useMediaQuery(mobileWidth);
  const auth0Profile = useAppSelector(selectAuth0Profile);
  const username = auth0Profile ? extractUsername(auth0Profile) : "";
  const fullName = auth0Profile ? auth0Profile.name : "";

  const triggerRipple = () => {
    if (rippleRef.current) {
      // keep the ripple open when user menu is open
      rippleRef.current.start(
        {
          clientX: 0,
          clientY: 0
        },

        { pulsate: false, center: true }
      );
    }
  };
  const handleUserAvatarBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setUserMenuAnchorEl(event.currentTarget);
    triggerRipple();
  };

  const handleCloseMenu = () => {
    setUserMenuAnchorEl(null);
    if (rippleRef.current) {
      // manually stop the ripple
      rippleRef.current.stop();
    }
  };

  return (
    <Stack>
      <UserDetailsMenuPreviewContainer>
        <Typography
          sx={{
            color: "darkGray.dark"
          }}
        >
          {t("nav.viewname.user")}: {username}
        </Typography>
        <ArrowDownButton
          aria-haspopup="true"
          aria-expanded={userMenuAnchorEl ? "true" : undefined}
          onClick={handleUserAvatarBtnClick}
        >
          <TouchRipple ref={rippleRef} center={true} />
          <ArrowDownIcon />
        </ArrowDownButton>
      </UserDetailsMenuPreviewContainer>
      <StyledMenu
        extraProps={{ isMobile }}
        open={!!userMenuAnchorEl}
        onClose={handleCloseMenu}
        keepMounted
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box
          sx={{
            padding: "5px 5px 2px 10px",
            cursor: "default"
          }}
        >
          <Stack flexDirection="column">
            <Typography
              variant="h6"
              sx={{
                fontWeight: 400
              }}
            >
              {t("user details")}
            </Typography>
            <UserDetailsMainContainer>
              <UserDetailsTitle variant="subtitle1" extraProps={{ isMobile }}>
                {username}
              </UserDetailsTitle>
              <UserDetailsTitle
                variant="subtitle2"
                extraProps={{ isMobile }}
                sx={{
                  fontWeight: 400,
                  color: "text.secondary"
                }}
              >
                {fullName}
              </UserDetailsTitle>
            </UserDetailsMainContainer>
          </Stack>
        </Box>
        <Divider sx={{ marginTop: "5px" }} />
        <LogoutButton />
      </StyledMenu>
    </Stack>
  );
};

export default UserProfileMenu;
