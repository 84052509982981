import ASButton from "@locaisolutions/button";
import { Alert, Box, Stack, Typography } from "@mui/material";

import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";

export const ThisMachineInfo = () => {
  const { t } = useTranslation();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const siteFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const {
    data: selectedAutostoreGrid,
    error: selectedAutostoreGridError,
    refetch: refetchSelectedAutostoreGrid
  } = useGetAutostoreGridQuery(siteWorkstation?.autostoreGridId ?? skipToken);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gap: 0.5
      }}
    >
      {!!selectedAutostoreGridError && (
        <Alert
          sx={{ gridColumn: "1/-1" }}
          variant="outlined"
          severity="error"
          action={
            <ASButton onClick={refetchSelectedAutostoreGrid}>Retry</ASButton>
          }
        >
          {getMessageFromRtkError(selectedAutostoreGridError)}
        </Alert>
      )}
      <Typography>{t("fulfillment center")}:</Typography>
      <Typography>{siteFulfillmentCenter?.name}</Typography>
      <Typography>{t("fulfillment center")} ID:</Typography>
      <Typography>{siteFulfillmentCenter?.fulfillmentCenterId}</Typography>
      <Typography>{t("autostore grid")}:</Typography>
      <Typography>{selectedAutostoreGrid?.autostoreGridName}</Typography>
      <Typography>{t("temperature zone")}:</Typography>
      <Typography>{selectedAutostoreGrid?.temperatureZone}</Typography>
      <Typography>{t("workstation name")}:</Typography>
      <Typography>{siteWorkstation?.deviceId}</Typography>
      <Typography>{t("workstation mode")}:</Typography>
      <Typography>{siteWorkstation?.mode}</Typography>
      <Typography>{t("workstation orientation")}:</Typography>
      <Typography>{siteWorkstation?.orientation}</Typography>
      <Typography>{`${t("ports")}:`}</Typography>
      <Stack gap={1}>
        {siteWorkstation?.ports.map((port) => (
          <Box
            key={port.portId}
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              backgroundColor: "mediumGray.main",
              gap: 0.5
            }}
          >
            <Typography>{t("autostore port")}:</Typography>
            <Typography>{port.portId}</Typography>
            {port.parentPortId && (
              <>
                <Typography>{t("autostore parent port")}:</Typography>
                <Typography>{port.parentPortId}</Typography>
              </>
            )}
            {port.inductionBinConfiguration && (
              <>
                <Typography>{t("bin configuration")}:</Typography>
                <Typography>{port.inductionBinConfiguration}</Typography>
              </>
            )}
            {port.cleaningDirection &&
              port.cleaningDirection !== "No Direction" && (
                <>
                  <Typography>{t("port direction")}:</Typography>
                  <Typography>{port.cleaningDirection}</Typography>
                </>
              )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
