import { SxProps, Theme, styled } from "@mui/material";

const BouncingLoader = styled("div")({
  display: "flex",
  justifyContent: "center",
  "@keyframes bouncing-loader": {
    to: {
      transform: "translateY(-1em)"
    }
  },
  "> div:nth-of-type(2)": {
    animationDelay: "0.2s"
  },
  "> div:nth-of-type(3)": {
    animationDelay: "0.4s"
  }
});

const BouncingDot = styled("div")(({ theme }) => ({
  width: "1em",
  height: "1em",
  margin: "0.5em 1em",
  borderRadius: "50%",
  backgroundColor: theme.palette.autostoreRed.main,
  opacity: "1",
  animation: "bouncing-loader 0.6s infinite alternate"
}));

export const BouncingDotsLoader = ({ sx }: { sx?: SxProps<Theme> }) => {
  return (
    <BouncingLoader sx={sx} role="progressbar">
      <BouncingDot />
      <BouncingDot />
      <BouncingDot />
    </BouncingLoader>
  );
};
