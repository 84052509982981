import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ReactNode } from "react";

type ModalWrapperProps = {
  title?: string;
  setModalOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  children: ReactNode;
};

/**
 * A simple MUI Dialog wrapper that renders a title and content reducing the boilerplate to do so.
 */
export const ModalWrapper = (props: ModalWrapperProps) => {
  const { title, setModalOpen, isOpen, children } = props;

  return (
    <Dialog open={isOpen} onClose={() => setModalOpen(false)} maxWidth={false}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
