import BackHandIcon from "@mui/icons-material/BackHand";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { andonColors } from "@qubit/autoparts";

import { useAppSelector } from "~/app/store";
import { figureAndonColorFromStatus } from "~/lib/helpers";

import {
  selectAndonGrids,
  selectAndonWorkstations,
  selectAndonCompactViewsIds
} from "./andon.slice";

export function AndonFullscreen() {
  const andonGrids = useAppSelector(selectAndonGrids);
  const compactViewIds = useAppSelector(selectAndonCompactViewsIds);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        height: "100vh"
      }}
    >
      {/* each workstation has a column */}
      {compactViewIds.map((id) => {
        const aws = Object.values(andonWorkstations).find(
          (workstation) => workstation.id === id
        );

        if (aws && aws.workstation) {
          const mainWorkstationBackgroundColor = figureAndonColorFromStatus({
            gridSystemMode:
              andonGrids[aws.workstation.autostoreGridId].systemMode ||
              undefined,
            workstationStatus: aws.status,
            workstationActive: aws.workstation.status === "Active",
            workstationOpen: aws.isOpen
          });

          // workstation column
          return (
            <Box
              key={`workstation-${aws.id}`}
              id={aws.id}
              sx={{
                display: "flex",
                position: "relative",
                flexDirection: "column",
                justifyContent: "center",
                flexBasis: 0,
                height: "100%",
                backgroundColor: "red",
                flexGrow: 1,
                boxSizing: "border-box"
              }}
            >
              {/* hand raised overlay */}
              {aws.handRaised && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      zIndex: 10
                    }}
                  >
                    <BackHandIcon
                      role="img"
                      aria-hidden={false}
                      aria-label="hand raised"
                      sx={{
                        width: "80%",
                        height: "80%",
                        color: andonColors.handRaisedYellow
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      opacity: 0.5
                    }}
                  />
                </>
              )}

              {/* column start */}
              <Box
                sx={{
                  backgroundColor: mainWorkstationBackgroundColor,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "5px",
                  height: "100%",
                  boxSizing: "border-box",
                  border: "1px solid black",
                  flexGrow: 1
                }}
              >
                {/* device id */}
                <Box
                  component="figure"
                  aria-labelledby={`device-${id}`}
                  sx={{
                    margin: "20px",
                    height: "5%",
                    width: "100%",
                    backgroundColor: mainWorkstationBackgroundColor,
                    boxSizing: "border-box"
                  }}
                >
                  <Typography id={`device-${id}`} align="center" variant="h4">
                    {aws.workstation.deviceId}
                  </Typography>
                </Box>

                {/* port and bins at port columns */}
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1, // y growth
                    width: "100%",
                    justifyContent: "space-around"
                  }}
                >
                  {/* port id and bins at port */}
                  {aws.ports.map((aport) => {
                    let backgroundColor = "gray.light";

                    backgroundColor = figureAndonColorFromStatus({
                      portStatus: aport.status,
                      portOpen: aport.isOpen,
                      defaultColor: "gray.light"
                    });

                    return (
                      <Box
                        key={aport.portId}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1
                        }}
                      >
                        {/* port id */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Typography variant="h2">{aport.portId}</Typography>
                        </Box>

                        <Box
                          key={aport.portId}
                          sx={{
                            flexGrow: 1,
                            height: "80%",
                            backgroundColor,
                            border: "1px solid black",
                            padding: "20px",
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          {/* bins at the port */}
                          {aport.hasBins.map((binId) => {
                            return (
                              <Box
                                key={binId}
                                sx={{
                                  width: "75%",
                                  marginTop: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  backgroundColor: "gray.main",
                                  borderRadius: "25px"
                                }}
                              >
                                <Typography variant="h4">{binId}</Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
}

export default AndonFullscreen;
