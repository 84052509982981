import { ToggleHandRequest } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const andonApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    toggleHandRaise: build.mutation<
      void,
      { autostoreGridId: Guid; workstationId: Guid; raiseHand: boolean }
    >({
      query: ({ autostoreGridId, workstationId, raiseHand }) => ({
        url: `/autostore-grid/${autostoreGridId}/workstation/${workstationId}/toggle-hand-raise`,
        method: "POST",
        body: { raiseHand } as ToggleHandRequest
      })
    })
  })
});
