import { InductionOrderCreateRequest } from "~/types/api";

import { pepsiApi } from "./pepsiApi";

export const inductionOrderApi = pepsiApi.injectEndpoints({
  endpoints: (builder) => ({
    createPepsiInductionOrder: builder.mutation<
      void,
      {
        clientId: Guid;
        fulfillmentCenterId: Guid;
      } & InductionOrderCreateRequest
    >({
      query: ({ clientId, fulfillmentCenterId, ...order }) => ({
        url: `/v1/inductionorder`,
        method: "POST",
        params: {
          clientId,
          fulfillmentCenterId
        },
        body: order
      })
    })
  }),
  overrideExisting: false
});
