export const DayOfWeekMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
} as const;

export type DayOfWeek = keyof typeof DayOfWeekMap;
export type DayOfWeekValue = (typeof DayOfWeekMap)[DayOfWeek];

export const daysOfTheWeek = Object.keys(DayOfWeekMap) as DayOfWeek[];

/**
 * Converts a day number to a typed string
 */
export function dayOfWeekAsString(dayIndex: number): DayOfWeek {
  if (daysOfTheWeek.length < dayIndex || dayIndex < 0) {
    return "Unknown" as DayOfWeek;
  }
  return daysOfTheWeek[dayIndex];
}
