import { Button, Toolbar, Typography } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { NavBarButtonGroup } from "~/features/navbar/Navbar";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { updateSelectedSiteWorkstation } from "~/redux/actions/workstations";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { usePutWorkstationMutation } from "~/redux/warehouse/workstation.hooks";
import { UpdateWorkstation, WorkstationSummaryDto } from "~/types/api";

type WorkstationChangesToolbarProps = {
  viewTitle?: ViewNameTranslation;
};

export const WorkstationChangesToolbar = (
  props: WorkstationChangesToolbarProps
) => {
  const { viewTitle } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedSiteWorkstation = useAppSelector(selectThisWorkstation);
  const { errorToast, successToast } = useToast();
  const { setToolbar } = useNavbar({ viewTitle });
  const [putWorkstation] = usePutWorkstationMutation();

  const {
    formState: { errors },
    getValues,
    reset
  } = useFormContext<UpdateWorkstation>();

  const handlePutRequest = async (updatedWorkstation: UpdateWorkstation) => {
    try {
      await putWorkstation(updatedWorkstation).unwrap();
      successToast(t("workstation saved"));
      if (updatedWorkstation.workstationId === selectedSiteWorkstation?.id) {
        const {
          autostoreGridId,
          deviceId,
          mode,
          multiPortEnabled,
          orientation,
          pickableOrderTypes,
          ports,
          roles,
          totePlacements
        } = updatedWorkstation;

        dispatch(
          updateSelectedSiteWorkstation({
            ...selectedSiteWorkstation,
            autostoreGridId,
            deviceId,
            multiPortEnabled,
            orientation,
            pickableOrderTypes,
            ports,
            roles,
            totePlacements,
            mode: mode as WorkstationSummaryDto["mode"]
          })
        );
      }
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  const handleSaveChanges = async () => {
    // Manually checking isValid here instead of using formState.isValid
    // because formState.isValid doesn't work with setError
    const isValid = Object.keys(errors).length === 0;
    if (!isValid) {
      return errorToast("Please correct the errors on the form");
    }
    const updatedWorkstation = getValues();
    await handlePutRequest(updatedWorkstation);
    reset(updatedWorkstation);
    setToolbar(null);
  };

  return (
    <Toolbar>
      <NavBarButtonGroup>
        <Button color="secondary" onClick={handleSaveChanges}>
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("save changes")}
          </Typography>
        </Button>
        <Button color="secondary" onClick={() => reset()}>
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("cancel")}
          </Typography>
        </Button>
      </NavBarButtonGroup>
    </Toolbar>
  );
};
