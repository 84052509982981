import { getUtcDateInBrowserTime, isExpired } from "@qubit/autoparts";
import { createSelector } from "@reduxjs/toolkit";

import moment from "moment/moment";

import { AppSelector } from "~/app/store";

import { checkIsExpiration, isExpirationValid } from "~/lib/helpers";
import { StoreState } from "~/redux/reducers";
import { PutAwayTaskSummaryDto } from "~/types/api";

import { selectClientConfig } from "./siteSelectors";

const selectedCompartment = (state: StoreState) =>
  state.autostorePutaway.selectedCompartment;

const currentSelectedBin = (state: StoreState) =>
  state.autostore.currentEmptyBin;

const nextEmptyBinByPort = (state: StoreState) =>
  state.autostore.nextEmptyBinByPort;

const portStateByPort = (state: StoreState) => state.autostore.portStateByPort;

export const selectCurrentlySelectedBinId: AppSelector<Guid | null> =
  createSelector(
    [selectedCompartment, currentSelectedBin],
    (selectedCompartment, currentSelectedBin) =>
      (!!(selectedCompartment || selectedCompartment === 0) &&
        currentSelectedBin?.autostoreBinCompartments.find(
          (compartment) =>
            compartment.autostoreCompartmentNumber === selectedCompartment + 1
        )?.binId) ||
      null
  );

const selectSelectedRowId = (state: StoreState) =>
  state.autostorePutaway.selectedRowId;
const inventoryDate = (state: StoreState) =>
  state.autostorePutaway.inventoryDate;

export const selectSelectedRow = createSelector(
  [
    selectSelectedRowId,
    (_, putawayTasks: PutAwayTaskSummaryDto[]) => putawayTasks
  ],
  (selectedRowId: string | null, putawayTasks: PutAwayTaskSummaryDto[]) =>
    putawayTasks.find((task) => task.putAwayTaskId === selectedRowId)
);

export const selectIsInventoryDateValid = createSelector(
  [selectSelectedRow, selectClientConfig, inventoryDate],
  (selectedRow, clientConfig, inventoryDate) => {
    const { inv_inventoryDateLabel, inv_inventoryDateRequired } = clientConfig;

    const isExpirationRequired =
      selectedRow?.product?.isExpirationRequired ?? false;
    if (
      !checkIsExpiration(inv_inventoryDateLabel) ||
      (!isExpirationRequired && !inventoryDate)
    ) {
      return true;
    }

    const formattedExpDate = getUtcDateInBrowserTime(inventoryDate);
    const expirationDate = moment(formattedExpDate);
    const isInventoryExpired = isExpired(moment(), expirationDate);

    return (
      !isInventoryExpired &&
      !!isExpirationValid(
        inv_inventoryDateRequired || isExpirationRequired,
        expirationDate
      )
    );
  }
);

export const selectIsInventoryAtPort: AppSelector<boolean> = createSelector(
  [nextEmptyBinByPort, portStateByPort],
  (emptyBins, portState) => {
    const nextEmptyBins = Object.values(emptyBins);
    return nextEmptyBins.some((bin) => {
      // only return true if at least one of the bins has inventory
      if (!bin?.inventory.length) return false;
      // and if bin with inventory is ready at the port
      const binsPortId = bin?.openBinResponse?.portId;
      const binsPortState = !!binsPortId && portState[binsPortId];
      return binsPortState && binsPortState?.getPortResponse.isReady;
    });
  }
);
