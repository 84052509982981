import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import { t } from "i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { selectIsDeleteWorkstationShown } from "~/redux/selectors/workstationConfigSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { DeleteWorkstationButton } from "./DeleteWorkstationButton";
import {
  selectDropdownWorkstationId,
  setDropdownWorkstationId
} from "./workstationConfig.slice";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 200
    }
  }
};

export function WorkstationHeader() {
  const dispatch = useAppDispatch();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const dropdownWorkstationId = useAppSelector(selectDropdownWorkstationId);
  const isDeleteWorkstationButtonShown = useAppSelector(
    selectIsDeleteWorkstationShown
  );
  const shouldRenderDeleteWorkstationBtn =
    isDeleteWorkstationButtonShown && !!dropdownWorkstationId;

  const { data: sortedWorkstations } = useGetWorkstationsQuery();

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">{t("autostore workstations")}</Typography>
        {siteWorkstation?.deviceId && (
          <Typography
            sx={{
              float: "right",
              backgroundColor: "background.default"
            }}
            variant="h6"
          >
            {t("in use")}: {siteWorkstation?.deviceId}
          </Typography>
        )}
      </Stack>

      <Stack
        mt={4}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Stack>
          <InputLabel id="workstation-select" shrink>
            {t("workstation")}
          </InputLabel>
          <Select
            labelId="workstation-select"
            style={{ width: 300, marginBottom: 10 }}
            value={sortedWorkstations ? dropdownWorkstationId : ""}
            onChange={(e: SelectChangeEvent) =>
              dispatch(setDropdownWorkstationId(e.target.value))
            }
            MenuProps={MenuProps}
            displayEmpty
          >
            {sortedWorkstations?.map((workstation) => (
              <MenuItem key={workstation.deviceId} value={workstation.id}>
                {`${workstation.deviceId}`}
              </MenuItem>
            ))}
            <MenuItem value="">
              <em>{t("no workstation selected")}</em>
            </MenuItem>
          </Select>
        </Stack>

        {shouldRenderDeleteWorkstationBtn && <DeleteWorkstationButton />}
      </Stack>
    </>
  );
}
