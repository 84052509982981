import { ProgressButton, useToast } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { mixpanelTrack } from "~/lib/mixpanel-tracking";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { openAutostoreBin } from "~/redux/actions";
import {
  selectSitePortId,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";

import { useCloseBinMutation } from "~/redux/warehouse/autostoreGrid.hooks";

const trackedPageName = "Picking";

export const PreviousBinButton = () => {
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [closeBin] = useCloseBinMutation();

  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const sitePortId = useAppSelector(selectSitePortId);
  const previousBin = useAppSelector((state) => state.autostore.previousBin);
  const portState = useAppSelector((state) => state.autostore.portState);
  const pickingState = useAppSelector((state) => state.autostore.pickingState);
  const binIsPresent = useAppSelector(
    (state) => state.autostore.binIsPresent.status
  );

  // current pick comes from picking state and is assumed to be the first pick with the highest quantity in the current picks array
  const currentPick =
    pickingState?.currentPicks.find(
      (p) => p.pickId === pickingState.selectedPickId
    ) || null;

  const handlePreviousBinClick = async () => {
    if (!portState || !siteWorkstation || !previousBin?.autostoreBinId) return;

    const selectedBin = pickingState
      ? currentPick?.pickBin?.autostoreBin?.autostoreBinId
      : portState.selectedBin;

    if (selectedBin && sitePortId) {
      try {
        await closeBin({
          autostoreGridId: siteWorkstation.autostoreGridId,
          portId: sitePortId,
          binNumber: selectedBin
        }).unwrap();

        await dispatch(
          openAutostoreBin({
            binId: previousBin.autostoreBinId,
            clobberPortStatus: false,
            portId: sitePortId
          })
        );

        // nav point
        navigate("/picking-previous-bin", {
          state: {
            batchId: pickingState?.batchId,
            batchName: pickingState?.batchName
          }
        });
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    }
  };

  return (
    <ProgressButton
      id="previous-bin-button"
      buttonSize="large"
      emphasis="high"
      responsive
      variant="contained"
      color="secondary"
      sx={{ width: "208px" }}
      fullWidth
      onClick={async () => {
        mixpanelTrack({
          trackedPageName,
          type: "Button Click",
          label: "Previous Bin"
        });
        await handlePreviousBinClick();
      }}
      disabled={!binIsPresent || !previousBin}
    >
      {t("previous bin")}
    </ProgressButton>
  );
};
