import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { BinOrProductResult } from "~/features/inventory/InventoryMainV2";
import NavSearchInput from "~/features/navbar/NavSearchInput";

import { setBinFilter } from "./cleaningBin.slice";

export function BinFilter() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const binFilter = useAppSelector((state) => state.cleaningBin.binFilter);

  return (
    <Box
      id="cleaningbin-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <NavSearchInput<BinOrProductResult>
        textInput={binFilter || ""}
        setTextInput={(text) => dispatch(setBinFilter(text))}
        searchPlaceholder={t("search bins")}
      />
    </Box>
  );
}
