import * as Sentry from "@sentry/react";

import { isFetchBaseQueryError } from "./isFetchBaseQueryError";

const isStringArray = (xs: unknown): xs is string[] =>
  Array.isArray(xs) && xs.every((v) => typeof v === "string");

const isString = (v: unknown): v is string => typeof v === "string";

const deduplicateErrorMessages = (errors: string[]): string =>
  [...new Set(errors)].join(", ");

/**
 * Returns an error message from an RTK mutation.
 */
export const getMessageFromRtkError = (error: unknown): string => {
  const defaultErrorMessage =
    "Something went wrong. Please try again later or contact support.";
  if (isFetchBaseQueryError(error)) {
    if (isStringArray(error.data)) {
      return deduplicateErrorMessages(error.data);
    } else if (isString(error.data)) {
      return error.data;
    } else if (error.status === "FETCH_ERROR") {
      return defaultErrorMessage;
    } else {
      Sentry.captureMessage(
        `Error was not an array: ${JSON.stringify(error)}`,
        "error"
      );
      return defaultErrorMessage;
    }
  } else {
    Sentry.captureMessage(
      `Error was not known: ${JSON.stringify(error)}`,
      "warning"
    );
    return defaultErrorMessage;
  }
};
