import { styled } from "@mui/material/styles";

export function BarcodeIcon({
  height = 40,
  width = 52,
  barcodeColor = "black",
  disabled = false,
  hide = false
}) {
  // use styled components here due to TS typing issue with sx on svg elements
  const StyledRect = styled("rect")(() => ({
    animation: `scanLine 3s alternate ease-in-out .8s 1 forwards`,
    "@keyframes scanLine": {
      "0%": {
        transform: "translateY(0px)"
      },
      "33%": {
        transform: "translateY(10px)"
      },
      "66%": { transform: "translateY(-10px)" },
      "100%": { transform: "tranlateY(0)" }
    }
  }));

  const StyledSVG = styled("svg")(() => ({
    transition: "width 0.2s"
  }));

  return disabled ? (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="barcode icon"
    >
      <rect x="7" y="5" width="1" height="32" fill="#8F8F8F" />
      <rect x="43" y="5" width="2" height="32" fill="#8F8F8F" />
      <rect x="11" y="5" width="3" height="32" fill="#8F8F8F" />
      <rect x="16" y="5" width="1" height="32" fill="#8F8F8F" />
      <rect x="20" y="5" width="1" height="32" fill="#8F8F8F" />
      <rect x="24" y="5" width="1" height="32" fill="#8F8F8F" />
      <rect x="37" y="5" width="2" height="32" fill="#8F8F8F" />
      <rect x="28" y="5" width="6" height="32" fill="#8F8F8F" />
      <rect y="5" width="4" height="32" fill="#8F8F8F" />
      <line
        x1="38.558"
        y1="1.25402"
        x2="5.55802"
        y2="42.254"
        stroke="white"
        strokeWidth="4"
      />
      <line
        x1="38.3904"
        y1="1.31235"
        x2="6.39043"
        y2="41.3123"
        stroke="#7E7E7E"
      />
    </svg>
  ) : (
    <StyledSVG
      width={width}
      height={height}
      viewBox="0 0 57 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: hide ? 0 : width, position: "absolute" }}
      role="img"
      aria-label="barcode icon"
    >
      <rect x="13" y="4" width="1" height="32" fill={barcodeColor} />
      <rect x="49" y="4" width="2" height="32" fill={barcodeColor} />
      <rect x="17" y="4" width="3" height="32" fill={barcodeColor} />
      <rect x="22" y="4" width="1" height="32" fill={barcodeColor} />
      <rect x="26" y="4" width="1" height="32" fill={barcodeColor} />
      <rect x="30" y="4" width="1" height="32" fill={barcodeColor} />
      <rect x="43" y="4" width="2" height="32" fill={barcodeColor} />
      <rect x="34" y="4" width="6" height="32" fill={barcodeColor} />
      <rect x="6" y="4" width="4" height="32" fill={barcodeColor} />
      <StyledRect
        y="19"
        width="57"
        height="2"
        fill="#FF0000"
        fillOpacity="0.67"
      />
    </StyledSVG>
  );
}
