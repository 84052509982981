import CheckCircle from "@mui/icons-material/CheckCircleOutline";
import { CircularProgress, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ModalDialogTransition } from "@qubit/autoparts";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ToteConfiguration,
  WaveCompletionConfiguration
} from "~/api/warehouseTypes/fulfillmentCenter";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import {
  clearBatchViewModel,
  completeBatch,
  dropTotes
} from "~/redux/actions/batch";
import { StoreState } from "~/redux/reducers";
import { ToteSummaryDto } from "~/types/api";

import { BatchViewModel } from "./BatchViewModel";

const mapStateToProps = (
  state: StoreState
): {
  batchViewModel: BatchViewModel | null;
  batchComplete: boolean;
  toteConfiguration: ToteConfiguration | null;
  waveCompletionConfiguration: WaveCompletionConfiguration | null;
} => ({
  batchViewModel: state.batch.batchViewModel,
  batchComplete: state.batch.batchComplete,
  toteConfiguration:
    (state.store.usersFulfillmentCenter
      ?.toteConfiguration as ToteConfiguration) || null,
  waveCompletionConfiguration:
    (state.store.usersFulfillmentCenter
      ?.waveCompletionConfiguration as WaveCompletionConfiguration) || null
});

const connector = connect(mapStateToProps, {
  clearBatchViewModel,
  completeBatch,
  dropTotes
});
type PropsFromRedux = ConnectedProps<typeof connector>;
type PackModalProps = PropsFromRedux & {
  pickComplete: boolean;
  getTotesByBatch: (batchId: string) => Promise<ToteSummaryDto[] | null>;
};

type PackBatchState = "Error" | "Success" | "Waiting" | "Ready";

export function PackModal(props: PackModalProps): React.ReactElement {
  const {
    batchViewModel,
    pickComplete,
    batchComplete,
    toteConfiguration,
    waveCompletionConfiguration
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [packBatchState, setPackBatchState] = useState<PackBatchState>("Ready");
  const [nonPickedTotes, setNonPickedTotes] = useState<ToteSummaryDto[] | null>(
    null
  );
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
  const isConfirmButtonDisabledTimer = useRef(0);

  useEffect(() => {
    setOpen(pickComplete);
  }, [pickComplete]);

  // In the moment when all picks are completed, it can happen that not all totes
  // Are in 'Picked' status so first thing that should be checked after the modal is open
  // Is status of every tote in the batch
  useEffect(() => {
    if (open && batchViewModel) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getTotesByBatch(batchViewModel.batchId).then((totes) => {
        if (totes) {
          const nonPickedTotesLocal = totes.filter(
            (tote) => tote.status.toLowerCase() !== "picked"
          );
          if (nonPickedTotesLocal.length) {
            setNonPickedTotes(nonPickedTotesLocal);
          } else {
            isConfirmButtonDisabledTimer.current = 0;
            setIsConfirmButtonDisabled(false);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, batchViewModel]);

  // If batch contains some non picked totes, we should check updated status of each tote on every second
  usePromiseInterval(
    async () => {
      if (nonPickedTotes && batchViewModel) {
        const totes = await props.getTotesByBatch(batchViewModel.batchId);
        if (totes) {
          const everyToteIsPicked = totes.every(
            (tote) => tote.status.toLowerCase() === "picked"
          );
          if (everyToteIsPicked) {
            isConfirmButtonDisabledTimer.current = 0;
            setNonPickedTotes(null);
            setIsConfirmButtonDisabled(false);
          }
        }
      }
    },
    1000,
    !!nonPickedTotes && isConfirmButtonDisabled
  );

  // Fallback code in case that 'Confirm' button is disabled for more than 6 seconds
  usePromiseInterval(
    () => {
      isConfirmButtonDisabledTimer.current += 1;
      if (isConfirmButtonDisabledTimer.current > 6 && isConfirmButtonDisabled) {
        isConfirmButtonDisabledTimer.current = 0;
        setIsConfirmButtonDisabled(false);
      }
      return Promise.resolve();
    },
    1000,
    open && isConfirmButtonDisabled
  );

  useEffect(() => {
    if (batchComplete) {
      setPackBatchState("Success");
    }
    return () => {
      setPackBatchState("Ready");
    };
  }, [batchComplete]);

  const isBulk = batchViewModel?.batchType === "Bulk";

  const handleClose = (redirect: boolean): void => {
    setOpen(false);
    const toteIds =
      batchViewModel?.picks.map((pick) => pick.assignedToteId || "") || [];
    if (toteIds.length && toteConfiguration !== "NoTotes") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.dropTotes({ toteIds }).then(() => {
        if (redirect) {
          navigate("/pick-batches");
          props.clearBatchViewModel();
          setPackBatchState("Ready");
        }
      });
    } else if (redirect) {
      navigate("/pick-batches");
      props.clearBatchViewModel();
      setPackBatchState("Ready");
    }
  };

  const handleCancelClose = (): void => {
    setOpen(false);
  };

  const handleConfirm = (): void => {
    if (batchViewModel) {
      setPackBatchState("Waiting");
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.completeBatch(batchViewModel.batchId);
    }
  };

  const showStageButton =
    (!waveCompletionConfiguration ||
      waveCompletionConfiguration === "TotesVerified") &&
    toteConfiguration !== "NoTotes";

  const stageButtonText = isBulk ? t("stage bulk") : t("stage totes");

  const showDropCartButton =
    (!waveCompletionConfiguration ||
      waveCompletionConfiguration === "TotesVerified") &&
    toteConfiguration !== "NoTotes";

  const showOkButton =
    waveCompletionConfiguration === "BatchPicked" ||
    toteConfiguration === "NoTotes";

  return (
    <div className="PackModal">
      <Dialog
        open={open}
        TransitionComponent={ModalDialogTransition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        {packBatchState === "Ready" && (
          <DialogTitle>{t("is this batch complete")}</DialogTitle>
        )}
        {packBatchState === "Waiting" && (
          <DialogTitle className="packing-batch">
            {t("packing batch")}
          </DialogTitle>
        )}
        {packBatchState === "Success" && (
          <DialogTitle>{t("batch packed")}</DialogTitle>
        )}
        <DialogContent>
          {packBatchState === "Ready" && (
            <DialogContentText id="alert-dialog-slide-description">
              {t("make sure everything is ready to go before confirming")}
            </DialogContentText>
          )}
          {packBatchState === "Waiting" && (
            <Grid
              container
              alignItems="center"
              direction="column"
              style={{ overflow: "hidden" }}
            >
              <Grid item>
                <CircularProgress size={120} />
              </Grid>
            </Grid>
          )}
          {packBatchState === "Success" && (
            <Grid container alignItems="center" direction="column">
              <Grid item>
                <CheckCircle style={{ fontSize: 160 }} color="info" />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {packBatchState === "Ready" && (
          <DialogActions>
            <Button onClick={(): void => handleCancelClose()} color="primary">
              {t("cancel")}
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              disabled={isConfirmButtonDisabled}
            >
              {t("confirm")}
            </Button>
          </DialogActions>
        )}
        {packBatchState === "Success" && (
          <DialogActions style={{ justifyContent: "space-between" }}>
            {showStageButton && (
              <Button
                onClick={(): void =>
                  batchViewModel
                    ? navigate(`/batches/${batchViewModel.batchName}/stage`)
                    : handleClose(true)
                }
                color="primary"
              >
                {stageButtonText}
              </Button>
            )}
            {showDropCartButton && !isBulk && (
              <Button onClick={(): void => handleClose(true)} color="primary">
                {t("drop cart")}
              </Button>
            )}
            {showOkButton && (
              <Button onClick={(): void => handleClose(true)} color="primary">
                {t("ok")}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

export default connector(PackModal);
