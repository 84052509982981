import { ErrorBoundary } from "@qubit/autoparts";
import { Outlet } from "react-router";

import { Navbar } from "~/features/navbar/Navbar";
import { Shell } from "~/features/shell/Shell";
import { ShellContent } from "~/features/shell/ShellContent";

export function BasicLayout() {
  return (
    <Shell>
      <Navbar />
      <ShellContent>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </ShellContent>
    </Shell>
  );
}
