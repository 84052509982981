import { Box, BoxProps } from "@mui/material";

import { SideNav } from "./SideNav";

export type Props = BoxProps;

export function Shell({ children, ...rest }: Props) {
  return (
    <Box {...rest}>
      <SideNav />
      {children}
    </Box>
  );
}

export default Shell;
