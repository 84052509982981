import { IconButton, IconButtonProps, styled } from "@mui/material";

/**
 * A button that renders a circular border around an icon.
 */
export const RoundIconButton = styled(IconButton)<IconButtonProps>(
  ({ theme, disabled }) => ({
    background: "white",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    color: disabled ? theme.palette.gray.dark : theme.palette.primary.main,
    border: "2px solid",
    borderColor: disabled ? theme.palette.gray.dark : theme.palette.primary.main
  })
);
