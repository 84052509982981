import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useToast } from "@qubit/autoparts";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  useGetOrderSummariesQuery,
  usePostUnstallOrderMutation
} from "~/redux/warehouse/orders.hooks";

import { clearSelectedOrders } from "./orders.slice";

export function OrdersToolbar() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errorToast, successToast } = useToast();

  const { ord_includeSpokes } = useAppSelector(selectClientConfig);
  const selectedDate = useAppSelector(
    (state) => state.ordersSlice.selectedDate
  );
  const selectedOrderIds = useAppSelector(
    (state) => state.ordersSlice.selectedOrderIds
  );
  const orderStatusFilter = useAppSelector(
    (state) => state.ordersSlice.orderStatusFilter
  );
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const orderFilter = useAppSelector((state) => state.ordersSlice.orderFilter);

  const { data: orders } = useGetOrderSummariesQuery({
    date: selectedDate,
    fcTimeZone: fulfillmentCenter?.timeZone,
    includeSpokes: ord_includeSpokes,
    search: orderFilter,
    statuses: orderStatusFilter
  });

  const [postUnstallOrder] = usePostUnstallOrderMutation();

  const areSelectedOrdersStalled =
    selectedOrderIds.length > 0 &&
    orders
      ?.filter((order) => selectedOrderIds.includes(order.orderId))
      .every((order) => order.status.toLowerCase() === "stalled");

  const handleUnstallOrders = useCallback(async () => {
    try {
      if (!areSelectedOrdersStalled) {
        errorToast("Not all selected orders are stalled.  Please try again.");
        return;
      }
      await postUnstallOrder({ orderIds: selectedOrderIds }).unwrap();
      dispatch(clearSelectedOrders());
      const orderPluralized = selectedOrderIds.length > 1 ? "orders" : "order";
      successToast(`Unstalled ${orderPluralized}`, {
        description: `${selectedOrderIds.length} ${orderPluralized} are now ready to be batched again`
      });
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    }
  }, [
    areSelectedOrdersStalled,
    postUnstallOrder,
    selectedOrderIds,
    dispatch,
    successToast,
    errorToast
  ]);

  return (
    <Toolbar>
      <UnselectButton onClick={() => dispatch(clearSelectedOrders())} />
      <NavBarButtonGroup>
        {selectedOrderIds.length === 1 && (
          <Button
            color="secondary"
            onClick={() => navigate(`/ship/order/${selectedOrderIds[0]}`)}
            style={{ color: "white", borderColor: "white" }}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {t("view order")}
            </Typography>
          </Button>
        )}
        {areSelectedOrdersStalled && (
          <Button
            color="secondary"
            onClick={handleUnstallOrders}
            style={{ color: "white", borderColor: "white" }}
          >
            {t("unstall")}
          </Button>
        )}
      </NavBarButtonGroup>
    </Toolbar>
  );
}
