import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

import { AddedTote } from "~/api/addedToteCustomType/addedTote";
import { useAppSelector } from "~/app/store";
import {
  selectPickingState,
  selectSignalR
} from "~/redux/selectors/autostoreSelectors";
import { useGetTotesForBatchQuery } from "~/redux/warehouse/autostorePicking.hooks";
import { PickingStateV2Tote } from "~/types/api";

const emptyArray: never[] = [];

const getOriginalTotes = (allTotes?: PickingStateV2Tote[]) => {
  if (!allTotes) {
    return emptyArray;
  }

  const originalTotesArray: PickingStateV2Tote[] = [];

  // We want to sort all totes based on the tote position first
  // If the tote position is the same, we want to sort based on the tote number
  const sortedPickingState = allTotes.toSorted((toteA, toteB) => {
    if (toteA.totePosition && toteB.totePosition) {
      if (toteA.totePosition > toteB.totePosition) return 1;
      if (toteA.totePosition < toteB.totePosition) return -1;

      if (toteA.toteNumber > toteB.toteNumber) return 1;
      if (toteA.toteNumber < toteB.toteNumber) return -1;
    }
    return 1;
  });

  if (sortedPickingState.length) {
    // Since we have a sorted array, we can grab first tote for each tote position
    // Because that tote represents originate tote for specific position
    sortedPickingState.forEach((tote) => {
      const isToteInOriginalTotesArray = originalTotesArray.some(
        (originalTote) => originalTote.totePosition === tote.totePosition
      );
      if (!isToteInOriginalTotesArray) originalTotesArray.push(tote);
    });
  }
  return originalTotesArray;
};

const getAddedTotes = (
  originalTotes: PickingStateV2Tote[],
  allTotes?: PickingStateV2Tote[]
) => {
  const addedTotesArray: AddedTote[] = [];

  if (!allTotes || !originalTotes) {
    return emptyArray;
  }

  if (allTotes && originalTotes) {
    originalTotes.forEach((originateTote) => {
      const findAddedTotes = allTotes
        .filter(
          (toteLocal) =>
            toteLocal.totePosition === originateTote.totePosition &&
            toteLocal.toteNumber > originateTote.toteNumber
        )
        .sort((toteA, toteB) => toteA.toteNumber - toteB.toteNumber);
      if (findAddedTotes.length) {
        findAddedTotes.forEach((addedTote) => {
          addedTotesArray.push({
            toteId: addedTote.toteId,
            oldToteId: originateTote.toteId,
            totePosition: addedTote.totePosition,
            toteNumber: addedTote.toteNumber,
            externalToteId: addedTote.externalToteId
          });
        });
      }
    });
  }

  return addedTotesArray.length ? addedTotesArray : emptyArray;
};

export function useAddedTotes() {
  const pickingState = useAppSelector(selectPickingState);
  const signalR = useAppSelector(selectSignalR);
  const { batchId } = pickingState || {};

  const { data } = useGetTotesForBatchQuery(batchId ? { batchId } : skipToken, {
    pollingInterval: signalR?.state === "Disconnected" ? 2000 : undefined,
    refetchOnMountOrArgChange: 5
  });

  const allTotes = useMemo(() => {
    return data?.flatMap((pos) => pos.totes);
  }, [data]);

  const originalTotes = useMemo(() => getOriginalTotes(allTotes), [allTotes]);

  const addedTotes = useMemo(
    () => getAddedTotes(originalTotes, allTotes),
    [originalTotes, allTotes]
  );

  return {
    originalTotes,
    addedTotes
  };
}
