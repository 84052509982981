import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControl,
  InputLabel,
  ListItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";

import { ConfirmationModal, useToast } from "@qubit/autoparts";
import { useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  useDeleteOrderTypeMutation,
  useGetOrderTypesQuery
} from "~/redux/warehouse/orders.hooks";

import { AddOrderTypeDialog } from "./AddOrderTypeDialog";
import {
  openAddOrderTypeDialog,
  selectIsAddOrderTypeDialogOpen,
  selectOrderTypeToDelete,
  closeAddOrderTypeDialog,
  clearOrderTypeToDelete,
  setOrderTypeToDelete
} from "./orderTypesDropdown.slice";

type Props<T extends FieldValues> = {
  /* The form control object from react-hook-form */
  control: Control<T>;
  /* The name of the field in the form */
  fieldName: Path<T>;
  /* Text shown inside the Help icon tooltip */
  tooltipText: string;
};

export function OrderTypesDropdown<T extends FieldValues>(props: Props<T>) {
  const { control, fieldName, tooltipText } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const isAddOrderTypeDialogOpen = useAppSelector(
    selectIsAddOrderTypeDialogOpen
  );
  const orderTypeToDelete = useAppSelector(selectOrderTypeToDelete);
  const [deleteOrderType] = useDeleteOrderTypeMutation();

  const { orderTypesDto, orderTypes } = useGetOrderTypesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => ({
      orderTypes: data
        ? data.map((orderTypeDto) => orderTypeDto.orderType)
        : [],
      orderTypesDto: data ? data : []
    })
  });

  const handleAddNewOrderTypeClick = () => {
    dispatch(openAddOrderTypeDialog());
  };

  const handleCloseDeleteModal = () => {
    dispatch(clearOrderTypeToDelete());
    dispatch(closeAddOrderTypeDialog());
  };

  const handleConfirmDeleteOrderType = async () => {
    if (!orderTypeToDelete) {
      return;
    }
    try {
      await deleteOrderType({
        orderTypeId: orderTypeToDelete?.orderTypeId
      }).unwrap();
      successToast(t("order type deleted"));
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    } finally {
      handleCloseDeleteModal();
    }
  };

  return (
    <Box width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <InputLabel shrink>{t("order types")}</InputLabel>
        <ClickAwayListener onClickAway={() => setIsPopoverOpen(false)}>
          <Tooltip
            title={<Typography variant="body2">{tooltipText}</Typography>}
            open={isPopoverOpen}
            disableHoverListener
            placement="right"
          >
            <HelpOutlineIcon
              sx={{
                cursor: "pointer",
                color: "gray"
              }}
              onClick={() => setIsPopoverOpen(true)}
            />
          </Tooltip>
        </ClickAwayListener>
      </Stack>
      <FormControl fullWidth>
        <Controller
          name={fieldName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              disableClearable
              filterSelectedOptions
              aria-label="order-types-dropdown"
              getOptionLabel={(orderType) => orderType}
              options={orderTypes}
              renderOption={(props, option, state) => (
                <ListItem {...props} key={`${state.index}-${option}`}>
                  <Typography sx={{ width: "100%" }}>{option}</Typography>
                  <CloseIcon
                    aria-label={`${option}-closeIcon`}
                    onClick={(event) => {
                      event.stopPropagation();
                      const orderTypeDto = orderTypesDto.find(
                        (orderType) => orderType.orderType === option
                      );
                      if (orderTypeDto) {
                        dispatch(setOrderTypeToDelete(orderTypeDto));
                      }
                    }}
                    sx={{
                      "&: hover": {
                        backgroundColor: "rgba(0,0,0,0.1)",
                        cursor: "pointer"
                      }
                    }}
                  />
                </ListItem>
              )}
              renderInput={({ inputProps, ...params }) => (
                <TextField
                  {...params}
                  variant="standard"
                  onChange={onChange}
                  inputProps={{
                    ...inputProps,
                    "aria-label": "order types",
                    readOnly: true
                  }}
                  aria-label="order-types-dropdown-input"
                  sx={{
                    input: { cursor: "pointer" },
                    minWidth: "150px"
                  }}
                />
              )}
              value={value}
              onChange={(_, values) => onChange(values)}
              PaperComponent={({ children }) => {
                return (
                  <Paper onMouseDown={(event) => event.preventDefault()}>
                    {children}
                    <Divider variant="middle" />
                    <Button
                      fullWidth
                      sx={{ justifyContent: "flex-start", pl: 2, mt: 1 }}
                      onClick={handleAddNewOrderTypeClick}
                    >
                      {t("add new order type")}
                    </Button>
                  </Paper>
                );
              }}
            />
          )}
        />
      </FormControl>

      {isAddOrderTypeDialogOpen && <AddOrderTypeDialog />}

      {orderTypeToDelete && (
        <ConfirmationModal
          isOpen
          confirmCb={handleConfirmDeleteOrderType}
          closeCb={handleCloseDeleteModal}
          modalTitle={t("delete order type")}
          modalText={`${t("are you sure you want to delete the order type")}: "${
            orderTypeToDelete.orderType
          }"?`}
        />
      )}
    </Box>
  );
}
