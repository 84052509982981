import { createSelector } from "@reduxjs/toolkit";

import { AppSelector } from "~/app/store";
import {
  getCommonHolds,
  convertHolds,
  external,
  HoldType
} from "~/lib/helpers";
import { StoreState } from "~/redux/reducers";
import { InventoryDto } from "~/types/api";

export const selectInventoryAtPort = (state: StoreState) =>
  state.inventory.inventory;

export const selectSelectedSummaries = (state: StoreState) =>
  state.autostoreInventoryHolds.selectedSummaries;

export const selectSelectedInventoryAtPort: AppSelector<
  InventoryDto | undefined
> = createSelector(
  [selectInventoryAtPort, selectSelectedSummaries],
  (inventoryAtPort, selectedSummaries) => {
    // inventory in bin at port could be > 1, match against previously selected summaries
    return inventoryAtPort.find((inv) =>
      selectedSummaries.find((sum) => sum?.inventoryId === inv.inventoryId)
    );
  }
);

export const selectCommonHolds: AppSelector<HoldType[]> = createSelector(
  [selectSelectedSummaries, selectInventoryAtPort],
  (selectedSummaries, selectedInventoryAtPort) => {
    const selectedSummaryAtPort = selectedSummaries.find((sum) =>
      selectedInventoryAtPort.find(
        (inv) => sum?.inventoryId === inv.inventoryId
      )
    );
    return !selectedSummaryAtPort
      ? // if we have no selected Inventory at the port (then get a list of the common holds)
        getCommonHolds(
          selectedSummaries,
          convertHolds([
            ...selectedSummaries
              .flatMap((inv) => inv?.holds)
              // Ensuring uniqueness by reasonCode
              .filter(
                (hold, index, self) =>
                  self.findIndex((h) => h?.reasonCode === hold?.reasonCode) ===
                  index
              )
          ]),
          [external]
        )
      : // if we have inventory at the port, then use the hold(s) for that inventory record, instead of
        // all the selected summaries
        getCommonHolds(
          [selectedSummaryAtPort],
          convertHolds([
            ...selectedSummaryAtPort.holds
              // Ensuring uniqueness by reasonCode
              .filter(
                (hold, index, self) =>
                  self.findIndex((h) => h.reasonCode === hold.reasonCode) ===
                  index
              )
          ]),
          [external]
        );
  }
);
