import { Box } from "@mui/material";
import { NavbarFilters } from "@qubit/autoparts";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import NavSearchInput from "~/features/navbar/NavSearchInput";

import { useDebounce } from "~/hooks/useDebounce";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { setOrderFilter, setOrderStatusFilter } from "./orders.slice";

type BinOrProductResult = {
  type: string;
  variantId?: Guid;
  binId?: Guid;
  displayText: string;
};

const defaultOrderStatuses = [
  "batched",
  "received",
  "picked",
  "canceled",
  "verified",
  "staged",
  "loaded"
] as const;

export type OrderStatus = (typeof defaultOrderStatuses)[number];

function buildOrderStatusFilter(orderStatuses: string[] | null | undefined) {
  return (orderStatuses || defaultOrderStatuses) as OrderStatus[];
}

export function OrdersSearch() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const [textInput, setTextInput] = useState<string | null>(null);
  const orderStatusFilter = useAppSelector(
    (state) => state.ordersSlice.orderStatusFilter
  );

  const debouncedSearchText = useDebounce(textInput, 300);

  const onFilterOrders = (statuses: string[]): void => {
    dispatch(setOrderStatusFilter(statuses));
  };

  const translatedMenuFilterOptions = buildOrderStatusFilter(
    fulfillmentCenter?.clientConfiguration?.orderStatuses
  ).map((option) => ({
    filter: option,
    translation: t(option)
  }));

  const handleSearchOrdersSubmit = useCallback(
    (searchText: string) => {
      dispatch(setOrderFilter(searchText));
    },
    [dispatch]
  );

  const handleSearchClear = useCallback(() => {
    dispatch(setOrderFilter(""));
  }, [dispatch]);

  const onResetFilters = () => {
    dispatch(setOrderStatusFilter([]));
    handleSearchClear();
  };

  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText.length >= 3) {
      handleSearchOrdersSubmit(debouncedSearchText);
    } else if (!debouncedSearchText) {
      handleSearchClear();
    }
  }, [debouncedSearchText, handleSearchClear, handleSearchOrdersSubmit]);

  return (
    <Box
      id="adminbatches-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <NavSearchInput<BinOrProductResult>
        textInput={textInput || ""}
        setTextInput={setTextInput}
        searchPlaceholder={t("search orders")}
        onLeaveInputTextShown
        clearCb={handleSearchClear}
      />
      <NavbarFilters
        menuItemList={translatedMenuFilterOptions}
        selectedMenuItems={orderStatusFilter}
        onFilterData={onFilterOrders}
        onResetFilters={onResetFilters}
      />
    </Box>
  );
}
