import { Box, InputLabel, Switch, TextField } from "@mui/material/";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import {
  saveEventLimitData,
  selectAndonEventSaveLimit,
  selectAndonSaveAllEvents,
  setEventSavingEnabled,
  setFetchWorkstationsInterval,
  selectFetchWorkstationsInterval
} from "~/features/andon/andon.slice";

import { ViewNameTranslation } from "~/hooks/useNavbar";

type AndonSettingsInheritedProps = { viewTitle?: ViewNameTranslation };
type AndonSettingsProps = AndonSettingsInheritedProps;

function AndonSettings(_props: AndonSettingsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const saveAllEvents = useAppSelector(selectAndonSaveAllEvents);
  const eventSaveLimit = useAppSelector(selectAndonEventSaveLimit);
  const fetchWorkstationsInterval = useAppSelector(
    selectFetchWorkstationsInterval
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column"
        }}
      >
        <Box sx={{ marginTop: "10px", padding: "5px" }}>
          <InputLabel id="save-all-events-label" sx={{ fontSize: ".8rem" }}>
            {t("save all events")}
          </InputLabel>
          <Switch
            checked={saveAllEvents}
            onChange={() => {
              dispatch(setEventSavingEnabled(!saveAllEvents));
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        {/* save limit input */}
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            padding: "5px"
          }}
        >
          <InputLabel id="save-limit-label" sx={{ fontSize: ".8rem" }}>
            {t("save limit")}
          </InputLabel>
          <TextField
            sx={{ width: 100 }}
            variant="standard"
            type="number"
            value={eventSaveLimit}
            disabled={!saveAllEvents}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value) {
                dispatch(saveEventLimitData(parseInt(e.target.value, 10)));
              } else {
                dispatch(saveEventLimitData(0));
              }
            }}
          />
        </Box>
      </Box>
      {/* column: fetch workstations */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "20px"
        }}
      >
        {/* switch */}
        <Box sx={{ padding: "5px" }}>
          <InputLabel
            id="fetch-workstation-interval-switch-label"
            sx={{ fontSize: ".8rem" }}
          >
            {t("fetch workstations while on boards")}
          </InputLabel>
          <Switch
            checked={fetchWorkstationsInterval !== 0}
            onChange={() => {
              dispatch(
                setFetchWorkstationsInterval(
                  fetchWorkstationsInterval === 0 ? 5000 : 0
                )
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        {/* interval input */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "5px"
          }}
        >
          <InputLabel
            id="fetch-workstation-interval-label"
            sx={{ fontSize: ".8rem" }}
          >
            {t("every __ seconds")}
          </InputLabel>
          <TextField
            sx={{ width: 100 }}
            variant="standard"
            type="number"
            value={(fetchWorkstationsInterval / 1000).toString()}
            disabled={fetchWorkstationsInterval === 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value) {
                dispatch(
                  setFetchWorkstationsInterval(
                    parseInt(e.target.value, 10) * 1000
                  )
                );
              } else {
                dispatch(setFetchWorkstationsInterval(1000));
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AndonSettings;
