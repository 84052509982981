import Button from "@locaisolutions/button";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import InventoryAdjustDialog, {
  InventoryToModify
} from "~/features/inventoryAdjustDialog/InventoryAdjustDialog";

import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import { BinMovementAssignment, InventoryMovementResponse } from "~/types/api";

export function InventoryAdjustButton({
  currentBins,
  selectedRow,
  disabled,
  adjustInventory
}: {
  currentBins?: InventoryMovementResponse;
  selectedRow?: BinMovementAssignment;
  disabled?: boolean;
  adjustInventory: (quantity: number | undefined) => void;
}) {
  const { t } = useTranslation();

  const [adjustModalOpen, setAdjustModalOpen] = useState(false);
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const selectedBin = currentBins?.sourceBin;

  const dispatch = useAppDispatch();

  const inventoryToModify: InventoryToModify = {
    binType: "",
    binId: currentBins?.sourceBin?.warehouseBinId || "",
    inventoryId: selectedBin?.inventoryId || "",
    locationName: "",
    count: selectedRow?.quantity,
    committedCount: undefined,
    lotNumber: "",
    variantId: selectedRow?.variantId || "",
    hold: selectedRow?.flags.join(" ")
  };

  return (
    <>
      <Button
        size="large"
        onClick={() => setAdjustModalOpen(true)}
        disabled={disabled}
        sx={{
          fontWeight: "normal"
        }}
      >
        {t("adjust inventory")}
      </Button>

      <InventoryAdjustDialog
        open={adjustModalOpen}
        disabled={false}
        onClose={() => setAdjustModalOpen(false)}
        selectedVariant={selectedVariant}
        invToModify={inventoryToModify}
        refreshCb={({ newQuantity }) => {
          dispatch(warehouseApi.util.invalidateTags(["inventory movements"]));
          adjustInventory(newQuantity);
        }}
        canceledReason={""}
      />
    </>
  );
}
