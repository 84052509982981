import { createSelector } from "@reduxjs/toolkit";

import { useGetVariantInventorySummariesQuery } from "~/redux/warehouse/inventory.hooks";
import {
  VariantInventorySummaryRecord,
  VariantInventorySummaryResponse
} from "~/types/api";

const emptyArray: VariantInventorySummaryRecord[] = [];
const selectSummarizedVariantInventorySummaries = createSelector(
  (data?: VariantInventorySummaryResponse) =>
    data?.variantInventorySummaries || emptyArray,
  (_res: unknown, autostoreGridId: Guid | null) => autostoreGridId,
  (
    variantInventorySummaries: VariantInventorySummaryRecord[],
    autostoreGridId: Guid | null
  ) => {
    const filteredSummaries = variantInventorySummaries
      .slice()
      .filter(
        (ia) =>
          (!autostoreGridId || ia.autostoreGridIds.includes(autostoreGridId)) &&
          !!ia.totalAvailable
      );

    const totalAvailableInventory = filteredSummaries.reduce(
      (p, s) => p + s.totalAvailable,
      0
    );
    const totalAvailableSkus = filteredSummaries.length;

    return {
      variantInventorySummaries: filteredSummaries,
      totalAvailableInventory,
      totalAvailableSkus
    };
  }
);

export const useSummarizedVariantInventorySummaries = (
  autostoreGridId: Guid | null
) => {
  return useGetVariantInventorySummariesQuery(undefined, {
    selectFromResult: ({ data, isLoading, error }) => ({
      isLoading,
      error,
      ...selectSummarizedVariantInventorySummaries(data, autostoreGridId)
    })
  });
};
