import { getDateForTimeZone } from "@qubit/autoparts";

import {
  BatchOrdersResponse,
  GetConsolidationSummariesResponse,
  OrderDto,
  OrderSummaryDto,
  OrderTypeAutostoreCategoryDto
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const ordersApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrders: build.query<OrderDto[], { orderId: Guid }>({
      query: ({ orderId }) => ({
        url: `/orders`,
        method: "GET",
        params: {
          orderId
        }
      }),
      providesTags: ["order"]
    }),
    getConsolidationSummary: build.query<
      GetConsolidationSummariesResponse,
      {
        limit: number;
        offset: number;
        orderType?: string | null;
        putWallIdentifier?: string | null;
        search?: string | null;
        status?: string[];
      }
    >({
      query: ({
        limit,
        offset,
        orderType,
        putWallIdentifier,
        search,
        status
      }) => {
        return {
          url: `/orders/consolidation-summary`,
          params: {
            status,
            limit,
            offset,
            ...(putWallIdentifier && { putWallIdentifier }),
            ...(orderType && { orderType }),
            ...(search && { search })
          }
        };
      },

      providesTags: ["loose pick", "order", "tote"]
    }),
    getOrderSummaries: build.query<
      OrderSummaryDto[],
      {
        date: Date | null;
        fcTimeZone?: string;
        includeSpokes: boolean;
        search?: string;
        statuses?: string[];
      }
    >({
      query: ({ date, includeSpokes, fcTimeZone, search, statuses }) => {
        const deliveryDate = getDateForTimeZone(date, fcTimeZone);
        return {
          url: `/orders/summaries`,
          params: {
            deliveryDate,
            includeSpokes,
            statuses: statuses || [],
            search
          }
        };
      },

      providesTags: ["order"],
      keepUnusedDataFor: 0
    }),
    postUnstallOrder: build.mutation<
      void,
      {
        orderIds: Guid[];
      }
    >({
      query: ({ orderIds }) => {
        return {
          url: `/orders/unstall`,
          method: "POST",
          body: orderIds
        };
      },

      invalidatesTags: ["order"]
    }),
    postBatchesFromOrders: build.mutation<
      BatchOrdersResponse,
      {
        orderIds: Guid[];
      }
    >({
      query: ({ orderIds }) => {
        return {
          url: `/orders/batch/`,
          method: "POST",
          body: {
            orderIds
          }
        };
      },
      invalidatesTags: ["order", "batch"]
    }),
    getOrderTypes: build.query<OrderTypeAutostoreCategoryDto[], void>({
      query: () => {
        return {
          url: `/order-types`
        };
      },
      providesTags: ["order types"]
    }),
    createOrderType: build.mutation<
      void,
      {
        orderType: string;
      }
    >({
      query: ({ orderType }) => {
        return {
          url: `/order-types`,
          method: "POST",
          body: {
            orderType
          }
        };
      },
      invalidatesTags: ["order types"]
    }),
    deleteOrderType: build.mutation<
      void,
      {
        orderTypeId: Guid;
      }
    >({
      query: ({ orderTypeId }) => {
        return {
          url: `/order-types/${orderTypeId}`,
          method: "DELETE"
        };
      },
      invalidatesTags: ["order types"]
    })
  }),
  overrideExisting: false
});
