import { Stack, Typography } from "@mui/material";

import { Center } from "../center/Center";
import { RobotLoader } from "../robotLoader/RobotLoader";

type Props = {
  loadingMessage?: string;
};

/**
 * Displays a loading animation and message to a user as a placeholder for a
 * full sized page.
 *
 * @param loadingMessage
 * @constructor
 */
export function LoadingPage({ loadingMessage }: Props) {
  return (
    <Center>
      <Stack spacing={8} marginTop="25vh">
        <RobotLoader />
        {loadingMessage && (
          <Typography variant="h5" textAlign="center">
            {loadingMessage}
          </Typography>
        )}
      </Stack>
    </Center>
  );
}
