import Box from "@mui/material/Box";
import { useState, useEffect, useMemo } from "react";
import RGL, { WidthProvider, Layout } from "react-grid-layout";

import "./dragdrop.css";

import { AndonWorkstations } from "./andon.slice";

interface NoCollisionLayoutProps {
  className: string;
  cols: number;
  rows: number;
  width: number;
  rowHeight: number;
  onLayoutChange: (layout: Layout[]) => void;
  preventCollision?: boolean;
  layout: Layout[];
  andonWorkstations: AndonWorkstations;
  onExistingElementClick: (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => void;
  onGridClick: (event: MouseEvent) => void;
  renderElementWithIndex: (index: string) => React.ReactElement | null;
  sizeLocked: boolean;
}

const ReactGridLayout = WidthProvider(RGL);

const NoCollisionLayout: React.FC<NoCollisionLayoutProps> = (
  props: NoCollisionLayoutProps
) => {
  const {
    className,
    cols,
    rows,
    width,
    rowHeight,
    onLayoutChange,
    preventCollision,
    layout,
    onExistingElementClick,
    onGridClick,
    renderElementWithIndex,
    sizeLocked
  } = props;

  useEffect(() => {
    const handleGridClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      const grid = document.querySelector(`.${className}`);

      if (grid && target === grid) {
        onGridClick(event);
      }
    };
    // Attach the event listener to the document
    document.addEventListener("click", handleGridClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleGridClick);
    };
  }, [className, onGridClick]);

  const [isDragging, setIsDragging] = useState(false);

  const handleDrag = () => {
    if (!isDragging) setIsDragging(true);
  };

  const onDragStop = () => {
    setTimeout(() => setIsDragging(false), 0);
  };

  const handleLayoutChange = (layout: Layout[]) => {
    const newLayout = layout.map((el) => {
      // calculate the maxH based on new layout
      return {
        ...el,
        maxH: rows - el.y
      };
    });

    onLayoutChange(newLayout);
  };

  const children = useMemo(() => {
    return layout.map((item) => {
      const renderedElement = renderElementWithIndex(item.i);

      return (
        <Box
          key={item.i}
          role="presentation"
          data-grid={item}
          onClick={(e) => {
            if (isDragging) return;
            onExistingElementClick(e, item.i);
          }}
          sx={{ height: "100%", width: "100%" }}
        >
          {renderedElement}
        </Box>
      );
    });
  }, [layout, onExistingElementClick, renderElementWithIndex, isDragging]);

  return (
    <ReactGridLayout
      width={width}
      layout={layout}
      onLayoutChange={handleLayoutChange}
      className={className}
      cols={cols}
      rowHeight={rowHeight - 10}
      compactType={null}
      preventCollision={preventCollision}
      onDrag={handleDrag}
      onDragStop={onDragStop}
      onResizeStart={handleDrag}
      onResizeStop={onDragStop}
      isBounded
      autoSize={false}
      isResizable={!sizeLocked}
    >
      {children}
    </ReactGridLayout>
  );
};

export default NoCollisionLayout;
