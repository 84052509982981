import { Outlet } from "react-router";

import { DevCheatsProvider } from "~/hooks/useDevCheats";
import { NavbarProvider } from "~/hooks/useNavbar";
import { SideNavProvider } from "~/hooks/useSideNav";

/**
 * Combines custom providers that provide context to Qubit pages.
 * @constructor
 */
export function AppProviders() {
  return (
    <NavbarProvider>
      <SideNavProvider>
        <DevCheatsProvider>
          <Outlet />
        </DevCheatsProvider>
      </SideNavProvider>
    </NavbarProvider>
  );
}
