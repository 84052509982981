import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

import { globalDateFormat } from "../util/dateHelpers";

type DatePickerProps = {
  isOpen: boolean;
  setIsOpenCb: (isOpen: boolean) => void;
  selectedDate: Date | string | null;
  onDateChange: (date: Date | null) => void;
  minDate?: Date;
  onAcceptDate?: (date: Dayjs | null) => void;
};

/**
 * Renders a calendar to choose a date when isOpen is set to true.
 *
 * Applies dayjs formatting to the mui date picker.
 */
export function DatePicker(props: DatePickerProps) {
  const {
    isOpen,
    setIsOpenCb,
    selectedDate,
    onDateChange,
    minDate,
    onAcceptDate
  } = props;

  const transformSelectedDate = (date: Dayjs | null): void => {
    const transformedDate = date || null;
    onDateChange(transformedDate?.toDate() || null);
  };

  return (
    <MobileDatePicker
      slotProps={{
        textField: { style: { display: "none" }, label: "Order Date" },
        toolbar: {
          hidden: true
        }
      }}
      format={globalDateFormat}
      value={selectedDate ? dayjs(selectedDate) : null}
      onChange={transformSelectedDate}
      onAccept={onAcceptDate}
      open={isOpen}
      onOpen={(): void => setIsOpenCb(true)}
      onClose={(): void => setIsOpenCb(false)}
      minDate={minDate ? dayjs(minDate) : undefined}
    />
  );
}
