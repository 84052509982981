import { warehouseApi as api } from "./warehouseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postToolsOrdersGenerate: build.mutation<
      PostToolsOrdersGenerateApiResponse,
      PostToolsOrdersGenerateApiArg
    >({
      query: (queryArg) => ({
        url: `/tools/orders/generate`,
        method: "POST",
        body: queryArg.generateOrders
      })
    }),
    postToolsPutawayGenerate: build.mutation<
      PostToolsPutawayGenerateApiResponse,
      PostToolsPutawayGenerateApiArg
    >({
      query: (queryArg) => ({
        url: `/tools/putaway/generate`,
        method: "POST",
        body: queryArg.generatePutAwayTasks
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as toolsApi };
export type PostToolsOrdersGenerateApiResponse = /** status 200 OK */ {
  /** AutoStore's primary identifier for the order. If an identifier is sent, matches an existing order, and that order has not been batched, the order will be updated with the data supplied in the request. */
  id?: string | null;
  /** Client system's primary identifier for the order. Identifiers cannot be reused. If the identifier sent matches an existing order and that order has not been batched, the order will be updated with the data supplied in the request. */
  externalOrderId: string;
  /** AutoStore's primary identifier for the fulfillment center where the order should be fulfilled. */
  fulfillmentCenterId: string;
  /** Label to distinguish the order types. Used during batching to group orders or time-box batching. */
  orderType: "Fresh" | "Shop";
  /** Express orders will be prioritized above standard orders during batching and picking. Defaults to Standard. */
  priority?: ("Standard" | "Express") | null;
  /** The date/time with relative UTC offset representing when the order was created. */
  createdDate?: string | null;
  /** First and last name of the customer, for displaying to Ops users */
  customerName?: string | null;
  /** This property is obsolete and no longer in use. */
  deliveryChannel?: string | null;
  /** The date/time with relative UTC offset representing when all picking should be complete for the order. Used for prioritization during batching and picking. */
  pickingEndTime: string;
  /** The date/time with relative UTC offset representing when picking can begin. Must be before the Picking End Time. */
  earliestPickingStartTime?: string | null;
  /** When the customer should receive the order. Note that the time zone offsets (-04:00 in this example) must match the FC time zone offset, including across Daylight Saving Time shifts. This field is deprecated. Please use PickingEndTime. */
  orderWindow?: string[] | null;
  /** Indicates whether or not the customer has enabled or disabled substitutions on this order. Use 'ForbidSubstitutes' or leave empty. */
  substitutionPreference?: (null | "ForbidSubstitutes") | null;
  /** Text field the customer entered with any notes */
  specialRequest?: string | null;
  /** List of line items */
  lineItems: {
    /** SKU identifier for this product */
    sku: string;
    /** Identifier for the order line. This is client facing */
    externalLineItemId: string;
    /** Customer notes on the order line */
    instructions?: string | null;
    /** True if this product requires prep */
    madeToOrder?: boolean;
    quantity: {
      /** Units for the quantity ordered. Typically the same as the SaleUnit */
      unit: string;
      /** How many units the customer ordered */
      value: number;
    };
    tote?: {
      /** The Tote identifier */
      id: string;
      /** Layer in which to package the product. Determines priority of picking */
      priority?: number | null;
    };
    /** List of acceptable substitutions for this line item */
    acceptableSubstitutions?:
      | {
          sku: string;
          quantity: {
            unit: string;
            value: number;
          };
        }[]
      | null;
  }[];
}[];
export type PostToolsOrdersGenerateApiArg = {
  /** order generation spec */
  generateOrders: GenerateOrders;
};
export type PostToolsPutawayGenerateApiResponse = /** status 200 OK */ {
  /** Used if tracking a Batch Code, Pallet Code or Lot Code, etc. */
  code?: string | null;
  /** The Unit of Measure expected to be put away. */
  count: {
    unit: string;
    /** Amount of items to be added / subtracted (negative number) */
    value: number;
  };
  decantingRate?:
    | (object | null)
    | {
        unit?: string;
        /** The amount of the DecantingRate’s Units that are included in one count. */
        value?: number;
      };
  /** Expiration date for the inventory in ISO 8601 format. Note: A Date can be passed in instead if preferred. */
  expiration?: string | null;
  /** Manufacture date for the inventory in ISO 8601 format. */
  manufactureDate?: string | null;
  /** Lot number */
  lotNumber?: string | null;
  /** Line Item number within the Purchase Order. Does not have to be unique. */
  purchaseOrderLineItemNumber?: string | null;
  /** Purchase Order number. Does not have to be unique. */
  purchaseOrderNumber?: string | null;
  /** Stock Keeping Unit */
  sku: string;
  /** Primary UPC or PLU for scanning barcode of the product */
  upcOrPlu?: string | null;
}[];
export type PostToolsPutawayGenerateApiArg = {
  /** order generation spec */
  generatePutAwayTasks: GeneratePutAwayTasks;
};
export type Range = {
  min: number;
  max: number;
};
export type GenerateOrders = {
  gridIds: string[];
  numberOfOrders: number;
  variantsPerOrder: Range;
  totesPerOrder: Range;
  lineItemsPerTote: Range;
  quantityPerLineItem: Range;
};
export type GeneratePutAwayTasks = {
  gridIds: string[];
  numberOfPutAwayTasks: number;
  countPerTask: Range;
};
export const {
  usePostToolsOrdersGenerateMutation,
  usePostToolsPutawayGenerateMutation
} = injectedRtkApi;
