import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container } from "@mui/material";
import Tab from "@mui/material/Tab";
import { ErrorPage } from "@qubit/autoparts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { isClientAdmin } from "~/api/usersTypes/auth0Profile";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { getDashboard } from "~/redux/actions/analytics";
import { AnalyticsState } from "~/redux/reducers";
import { AnalyticsDashboardDto } from "~/types/api";

const mapStateToProps = (
  state: AnalyticsState
): {
  isAdmin: boolean;
  dashboard: AnalyticsDashboardDto | null;
  loading: boolean;
  error: string | null;
} => ({
  isAdmin: state.login.profile ? isClientAdmin(state.login.profile) : false,
  dashboard: state.analytics.dashboard,
  loading: state.analytics.loading,
  error: state.analytics.error
});

const connector = connect(mapStateToProps, {
  getDashboard
});
type AnalyticsInheritedProps = { viewTitle?: ViewNameTranslation };
type PropsFromRedux = ConnectedProps<typeof connector>;
type AnalyticsProps = PropsFromRedux & AnalyticsInheritedProps;

function Analytics(props: AnalyticsProps) {
  const { t } = useTranslation();
  const { isAdmin, dashboard, loading, error, viewTitle } = props;
  useNavbar({ viewTitle });

  const [currentView, setCurrentView] = useState("picker");

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.getDashboard(currentView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  const getDashboardUrl = (dash: AnalyticsDashboardDto | null) => {
    if (dash == null) return "";

    return `${dash.embedUrl}#titled=false`;
  };

  // This function returns an iframe.
  // We cannot inspect dimensions of it due to cross domain protections
  // so make it fill the height and width of the parent.
  // This requires that the parent container have a reasonable height set.
  const getContent = () =>
    loading ? (
      <div>Loading</div>
    ) : (
      <iframe
        title="Analytics Dashboard"
        src={getDashboardUrl(dashboard)}
        frameBorder={0}
        allowTransparency
        height="100%"
        width="100%"
      />
    );

  const getFrame = () =>
    isAdmin ? (
      <TabContext value={currentView}>
        <TabList
          onChange={(_event: React.SyntheticEvent<unknown>, newVal: string) =>
            setCurrentView(newVal)
          }
        >
          <Tab label={t("nav.link.picking")} value="picker" />
          <Tab label={t("shift management")} value="shift-manager" />
        </TabList>
        <TabPanel
          value="picker"
          style={{
            height: "660px"
          }}
        >
          {getContent()}
        </TabPanel>
        <TabPanel
          value="shift-manager"
          style={{
            height: "960px"
          }}
        >
          {getContent()}
        </TabPanel>
      </TabContext>
    ) : (
      <TabContext value="picker">
        <TabList
          onChange={(_event: React.SyntheticEvent<unknown>, newVal: string) =>
            setCurrentView(newVal)
          }
        >
          <Tab label="Picking" value="picker" />
        </TabList>
        <TabPanel
          value="picker"
          style={{
            height: "660px"
          }}
        >
          {getContent()}
        </TabPanel>
      </TabContext>
    );

  return (
    <Container>
      {error ? (
        <ErrorPage errorMessage={getMessageFromRtkError(error)} />
      ) : (
        getFrame()
      )}
    </Container>
  );
}

export default connector(Analytics);
