import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "~/app/store";
import NavSearchInput from "~/features/navbar/NavSearchInput";

import { useDebounceSearch } from "~/hooks/useDebounce";

import { setPage, setSearch } from "./putaway.slice";

export function PutawaySearch() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState<string>("");
  const debouncedSearchInput = useDebounceSearch(searchInput);

  useEffect(() => {
    dispatch(setSearch(debouncedSearchInput));
  }, [debouncedSearchInput, dispatch]);

  return (
    <Box
      id="autostorePutaway-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <NavSearchInput
        textInput={searchInput}
        setTextInput={(text: string) => setSearchInput(text)}
        isAutocomplete
        searchPlaceholder={t("search placeholder")}
        inputChangeCb={(input) => {
          dispatch(setPage(1));
          setSearchInput(input || "");
        }}
        autocompleteOptions={[]}
        hideOptions
        clearOnBlur={false}
        onLeaveInputTextShown
      />
    </Box>
  );
}
