import CheckCircle from "@mui/icons-material/CheckCircleOutline";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { ModalDialogTransition } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type CompletionModal = {
  isOpen: boolean;
};

export function CompletionModal(props: CompletionModal): React.ReactElement {
  const { isOpen } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePickAnotherBatch = (): void => {
    navigate("/pick-batches");
  };

  const handleStageCarts = (): void => {
    navigate("/carts");
  };

  return (
    <div className="CompletionModal">
      <Dialog
        open={isOpen}
        TransitionComponent={ModalDialogTransition}
        keepMounted
        fullWidth
        maxWidth="sm"
        aria-labelledby="completion"
        aria-describedby="redirect"
      >
        <DialogTitle>{t("totes for this batch are all staged")}</DialogTitle>
        <Grid container alignItems="center" direction="column">
          <Grid item>
            <CheckCircle style={{ fontSize: 160 }} color="info" />
          </Grid>
        </Grid>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={handlePickAnotherBatch} color="primary">
            {t("pick another batch")}
          </Button>
          <Button onClick={handleStageCarts} color="primary">
            {t("stage carts")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
