import { IncompleteCycleCountResponse } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const cycleCountApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getIncompleteCycleCounts: build.query<
      IncompleteCycleCountResponse,
      { limit?: number; offset?: number; autostoreGridId?: Guid }
    >({
      query: ({ limit, offset, autostoreGridId }) => ({
        url: "/cycle-counts/incomplete",
        params: {
          includeUnchangedInventory: false,
          limit,
          offset,
          ...(autostoreGridId && { autostoreGridId })
        }
      }),
      providesTags: ["cycle count data"]
    })
  }),
  overrideExisting: false
});
