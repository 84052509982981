import { Warning24Px } from "@locaisolutions/icons";
import {
  Container,
  Paper,
  Typography,
  Divider,
  Stack,
  Alert,
  SvgIcon
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { BatchSettingsHeader } from "./BatchSettingsHeader";
import { RecurringSchedules } from "./RecurringSchedules";

type Props = { viewTitle?: ViewNameTranslation };

export function BatchSettings({ viewTitle }: Props) {
  const { t } = useTranslation();

  useNavbar({ viewTitle });

  return (
    <Container sx={{ pb: 4 }}>
      <Stack spacing={4} mt={3}>
        <Alert
          severity="warning"
          variant="filled"
          icon={
            <SvgIcon
              component={Warning24Px}
              sx={{ fontSize: "50px", ml: "12px" }}
              inheritViewBox
            />
          }
        >
          <Typography fontSize="1.5rem">
            {t("batch settings warning 1")}
            <br />
            {t("batch settings warning 2")}
          </Typography>
        </Alert>
        <Paper>
          <Stack padding={2} spacing={2}>
            <Typography variant="h6">
              {t("batch")} {t("nav.viewname.settings")}
            </Typography>
            <Divider />

            <BatchSettingsHeader />
          </Stack>
        </Paper>
        <RecurringSchedules />
      </Stack>
    </Container>
  );
}
