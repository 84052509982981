import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  Typography,
  useMediaQuery,
  Skeleton,
  useTheme
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { ErrorPage, mobileWidth, useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { useLocation } from "react-router-dom";

import { PrinterConfiguration } from "~/api/warehouseTypes/fulfillmentCenter";
import { useAppDispatch, useAppSelector } from "~/app/store";
import { CartSearchInput } from "~/features/cartSearchInput/CartSearchInput";
import PrintableToteLabels, {
  LabelInfo
} from "~/features/printableToteLabels/PrintableToteLabels";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { temperatureZoneColors } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { createNumberSequence } from "~/lib/shared";

import {
  selectCanUnassignCart,
  selectToteLabels
} from "~/redux/selectors/batchSelectors";

import {
  useGetBatchQuery,
  usePostAssignCartMutation,
  usePostUnassignCartMutation
} from "~/redux/warehouse/batches.hooks";

import { useGetTotesQuery } from "~/redux/warehouse/totes.hooks";

import { BatchCartPrepToolbar } from "./BatchCartPrepToolbar";
import {
  closeAssignCartModal,
  closeUnassignCartModal,
  openUnassignCartModal
} from "./batch.slice";

type Props = { viewTitle?: ViewNameTranslation };

const CustomerName = styled(Typography)(() => ({
  display: "flex",
  paddingTop: 10,
  lineHeight: "1.3em",
  height: 40,
  letterSpacing: ".03em",
  justifyContent: "center",
  alignItems: "center"
}));

const ToteId = styled(Typography)(() => ({
  color: "white",
  fontSize: 20,
  bottom: 4,
  position: "absolute",
  right: 0,
  left: 0
}));

export function ToteCard({
  tote,
  totesPerRow
}: {
  tote: LabelInfo;
  totesPerRow: number;
}) {
  const { palette } = useTheme();
  const { toteId, zone } = tote;
  const backgroundColor =
    temperatureZoneColors[
      zone.toLowerCase() as "ambient" | "chilled" | "frozen"
    ] || "primary.main";

  return (
    <Grid item xs={totesPerRow === 4 ? 3 : 4} key={toteId} className="toteCard">
      <Button style={{ width: "100%", padding: 0 }}>
        <Card
          sx={{
            textAlign: "center",
            width: "100%",
            height: "120px",
            bgcolor: backgroundColor,
            border: `3px solid`,
            borderColor: backgroundColor
          }}
        >
          <>
            <Box sx={{ height: "100px", bgcolor: "background.default" }}>
              <CustomerName variant="overline">
                {`${tote.firstName} ${tote.lastName || ""}`}
              </CustomerName>
              <Typography
                sx={{ fontSize: "28px", color: "primary.main" }}
                display="block"
              >
                {tote.position}
              </Typography>
            </Box>
            <ToteId
              style={{ color: palette.primary.main }}
              display="block"
              gutterBottom
            />
          </>
        </Card>
      </Button>
    </Grid>
  );
}

export function BatchCartPrep({ viewTitle }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const { batchName = "" } = useParams<{ batchName: string }>();
  const location = useLocation();

  const printComponentRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(mobileWidth);

  const [postAssignCart] = usePostAssignCartMutation();
  const [postUnassignCart] = usePostUnassignCartMutation();

  const { setMenuItems } = useNavbar({
    centerComponent: useMemo(
      () => <BatchCartPrepToolbar printComponentRef={printComponentRef} />,
      [printComponentRef]
    ),
    viewTitle
  });

  const {
    data: batchResponse,
    isFetching: isFetchingBatch,
    error: batchError
  } = useGetBatchQuery(
    { batchId: batchName },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: totes,
    isFetching: isFetchingTotes,
    error: totesError
  } = useGetTotesQuery(
    batchResponse ? { batchId: batchResponse.batch.batchId } : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const batch = batchResponse?.batch;
  const cartNumberAssigned = batchResponse?.cartNumber;
  const printerConfiguration = useAppSelector(
    (state) =>
      (state.store.usersFulfillmentCenter
        ?.printerConfiguration as PrinterConfiguration) || null
  );

  const isAssignCartModalOpen = useAppSelector(
    (state) => state.batchSlice.isAssignCartModalOpen
  );
  const isUnassignCartModalOpen = useAppSelector(
    (state) => state.batchSlice.isUnassignCartModalOpen
  );
  const toteLabels = useAppSelector((state) =>
    selectToteLabels(state, batch, totes)
  );
  const canUnassignCart = useAppSelector((state) =>
    selectCanUnassignCart(state, batch, cartNumberAssigned)
  );

  const [assignedCartModalOpen, setAssignedCartModalOpen] =
    useState<boolean>(false);

  // new cart number
  const [cartNumber, setCartNumber] = useState<string | null>(null);

  useEffect(() => {
    if (isMobile && canUnassignCart) {
      setMenuItems([
        {
          textContent: t("unassign"),
          actionCb: () => {
            dispatch(openUnassignCartModal());
          }
        }
      ]);
    } else {
      setMenuItems([]);
    }
  }, [dispatch, isMobile, setMenuItems, t, canUnassignCart]);

  const toteCount: number = totes?.length || 0;
  const totesPerRow = toteCount > 6 ? 4 : 3;

  const closeModalReloadData = () => {
    // if the second modal is closes now (after successful assignment to the cart batch) remember the cart number
    setCartNumber(null);
    dispatch(closeAssignCartModal());
    setAssignedCartModalOpen(false);
    dispatch(closeUnassignCartModal());
  };

  const assignCart = async () => {
    // set cart for the batch
    if (isAssignCartModalOpen) {
      try {
        if (batch?.batchId != null && cartNumber) {
          await postAssignCart({
            batchId: batch.batchId,
            cartNumber
          }).unwrap();
          // if the cart is successfully assigned, close the first and open the second modal
          dispatch(closeAssignCartModal());
          setAssignedCartModalOpen(true);
        }
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    } else {
      // close second modal after cart has been assigned to the batch
      closeModalReloadData();
    }
  };

  const unassignCart = async () => {
    try {
      if (batch != null && cartNumberAssigned) {
        await postUnassignCart({
          batchId: batch.batchId
        }).unwrap();
        // close the modal after the cart is successfully unassigned
        dispatch(closeUnassignCartModal());
      }
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const assignOrUnassignCart = () => {
    if (isUnassignCartModalOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      unassignCart();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      assignCart();
    }
  };

  const error = batchError || totesError;

  return error ? (
    <ErrorPage
      errorMessage={getMessageFromRtkError(error)}
      goBackLocation={location.pathname}
    />
  ) : (
    <>
      <Grid
        container
        spacing={1}
        style={{ padding: isMobile ? 10 : 20, margin: 0, width: "100%" }}
      >
        {(isFetchingBatch || isFetchingTotes) &&
          createNumberSequence(6).map((num) => (
            <Grid
              item
              xs={4}
              key={num}
              className="toteCard"
              data-testid="tote-card"
            >
              <Card
                sx={{ textAlign: "center", width: "100%", height: "120px" }}
              >
                <Skeleton height={126} />
              </Card>
            </Grid>
          ))}
        {!isFetchingBatch &&
          !isFetchingTotes &&
          toteLabels &&
          toteLabels.map((tote) => (
            <ToteCard key={tote.toteId} tote={tote} totesPerRow={totesPerRow} />
          ))}
      </Grid>

      {/* Assign or unassign cart modal */}
      <Dialog
        key="assign-cart-dialog"
        open={
          !!(
            isAssignCartModalOpen ||
            assignedCartModalOpen ||
            isUnassignCartModalOpen
          )
        }
        onClose={() => {
          closeModalReloadData();
        }}
      >
        <DialogContent
          style={{
            textAlign: "center"
          }}
        >
          {isAssignCartModalOpen && (
            <DialogContentText style={{ color: "black" }}>
              {" "}
              {t("scan cart")}
            </DialogContentText>
          )}
          <FormControl style={{ margin: 10 }}>
            {isAssignCartModalOpen ? (
              <div style={{ width: "250px" }}>
                <CartSearchInput
                  setPreppedCartNumberCb={(cartNumber1) => {
                    setCartNumber(cartNumber1);
                  }}
                  placeholder={t("scan cart to assign")}
                />
              </div>
            ) : (
              <>
                {(assignedCartModalOpen || isUnassignCartModalOpen) && (
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="initial"
                    align="center"
                    style={{
                      paddingTop: "12%"
                    }}
                  >
                    {assignedCartModalOpen ? (
                      <>
                        {t("cart")} {cartNumber} {t("assigned to batch")}{" "}
                        {batchName}
                      </>
                    ) : (
                      <>
                        {t("cart")} {cartNumberAssigned} {t("unassigned cart")}{" "}
                        {batchName}
                      </>
                    )}
                  </Typography>
                )}
              </>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {(isAssignCartModalOpen || isUnassignCartModalOpen) && (
            <Button
              autoFocus
              onClick={() => {
                dispatch(closeAssignCartModal());
                dispatch(closeUnassignCartModal());
              }}
              color="primary"
            >
              {t("cancel")}
            </Button>
          )}
          <Button
            disabled={
              isAssignCartModalOpen &&
              (cartNumber === null || Number.isNaN(cartNumber))
            }
            onClick={() => assignOrUnassignCart()}
            color="primary"
            autoFocus
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {printerConfiguration === "PagePrint" && (
        <div style={{ display: "none" }}>
          {!!toteLabels?.length && (
            <PrintableToteLabels labels={toteLabels} ref={printComponentRef} />
          )}
        </div>
      )}
    </>
  );
}
