import { CompletePickRequest, PickDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const pickApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getPick: build.query<PickDto, Guid>({
      query: (pickId) => ({
        url: `/picks/${pickId}`,
        method: "GET"
      }),
      providesTags: ["pick"]
    }),
    getPicks: build.query<PickDto[], { orderId: Guid }>({
      query: ({ orderId }) => ({
        url: "/picks",
        method: "GET",
        params: {
          orderId
        }
      }),
      providesTags: ["pick"]
    }),
    verifyPick: build.mutation<
      void,
      {
        upcOrSku: string;
        pickId: string;
        verifyMethod: string;
        workstationId: string | undefined;
      }
    >({
      query: ({ upcOrSku, pickId, verifyMethod, workstationId }) => {
        const request = { upcOrSku, pickId, verifyMethod, workstationId };

        return {
          url: "/picks/verify",
          method: "POST",
          body: request
        };
      }
    }),
    completePick: build.mutation<void, CompletePickRequest>({
      query: (body) => {
        return {
          url: "/picks/complete",
          method: "POST",
          body
        };
      }
    })
  }),
  overrideExisting: false
});
