import Box from "@mui/material/Box";
import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { useAppDispatch } from "~/app/store";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { resetSiteWorkstationStatus } from "~/redux/actions/workstations";

import { useLazyGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import EventExplorer from "./EventExplorer";
import { clearAndonWorkstationsAndGrids, clearEventData } from "./andon.slice";

type EventsInheritedProps = { viewTitle?: ViewNameTranslation };
type PropsFromRedux = ConnectedProps<typeof connector>;
type EventsProps = EventsInheritedProps & PropsFromRedux;

const connector = connect(null, {
  resetSiteWorkstationStatus
});

function Events(props: EventsProps) {
  const { viewTitle, resetSiteWorkstationStatus } = props;
  const [triggerFetchWorkstations] = useLazyGetWorkstationsQuery();
  const dispatch = useAppDispatch();

  useDevCheats({ showEventSimulator: true });
  const { setMenuItems } = useNavbar({ viewTitle });

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Clear Andon Statuses and Fetch Workstations",
        actionCb: () => {
          dispatch(clearAndonWorkstationsAndGrids());
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          triggerFetchWorkstations();
          resetSiteWorkstationStatus();
        }
      },
      {
        textContent: "Clear Events",
        actionCb: () => {
          dispatch(clearEventData());
        }
      }
    ]);
  }, [
    setMenuItems,
    resetSiteWorkstationStatus,
    dispatch,
    triggerFetchWorkstations
  ]);

  return (
    <Box
      id="tab-content"
      sx={{
        width: "100%",
        overflowX: "hidden"
      }}
    >
      <EventExplorer />
    </Box>
  );
}

export default connector(Events);
