import Button from "@locaisolutions/button";
import { Refresh } from "@mui/icons-material";
import { Box, Container, Stack, styled } from "@mui/material";
import { ConfirmationModal, useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import UniversalProductCard, {
  PickInfoIsLoading
} from "~/components/productCard/UniversalProductCard";
import useFlag from "~/config/flags";
import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import usePortStatus from "~/hooks/usePortStatus";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import { fetchPortStatus } from "~/redux/actions/autostore";
import { selectPortStateByPort } from "~/redux/selectors/autostoreSelectors";
import {
  selectSelectedPortId,
  selectWorkstationAutostoreGridId,
  selectThisWorkstation,
  selectSitePortIds
} from "~/redux/selectors/workstationsSelectors";
import {
  useGetInventoryMovementsStateQuery,
  usePostSkipInventoryMovementMutation,
  usePostStartInventoryMovementsQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useLazyGetInventoryByAutostoreBinNumberQuery } from "~/redux/warehouse/inventory.hooks";

import { BinNotEmptyButton } from "./BinNotEmptyButton";
import ConfirmMovementButton from "./ConfirmMovementButton";
import { FlagBinButton } from "./FlagBinButton";
import { InventoryAdjustButton } from "./InventoryAdjustButton";
import MovementsBins from "./MovementsBins";
import MovementsHeader from "./MovementsHeader";
import MovementsTasksTable from "./MovementsTasksTable";
import { SkipMovementButton } from "./SkipMovementButton";
import { UnflagBinButton } from "./UnflagBinButton";
import { setCurrentBins } from "./manageFlaggedInventory.slice";

const PageContainer = styled(Container)(() => ({
  display: "grid",
  gridTemplateColumns: "minmax(0, 2fr) minmax(0, 1fr)",
  gap: "30px",
  padding: "20px"
}));

type Props = { viewTitle: ViewNameTranslation };

const ManageFlaggedInventory = (props: Props) => {
  const { viewTitle } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setWarnings } = useNavbar({ viewTitle });
  const { successToast, errorToast } = useToast();

  const closeWorkstation = useCloseWorkstationWithErrorToast();

  const isSkipMovementEnabled = useFlag().skipMovementButtonEnabled;
  const isBinNotEmptyEnabled =
    useFlag().binNotEmptyInventoryMovementButtonEnabled;
  const isUnflagBinEnabled = useFlag().unflagBinButton;

  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const portStateByPort = useAppSelector(selectPortStateByPort);
  const selectedPortId = useAppSelector(selectSelectedPortId);
  const workstation = useAppSelector(selectThisWorkstation);
  const siteAllPortIds = useAppSelector(selectSitePortIds);
  const currentBins = useAppSelector(
    (state) => state.manageFlaggedInventory.currentBins
  );

  const workstationId = workstation?.id;
  const showFlagBinButton = useFlag().flagBinAndInventoryAdjustButtons;

  const [isFetchingOver, setIsFetchingOver] = useState(false);

  const {
    data: initialBins,
    isLoading: isLoadingStartInventoryMovements,
    isSuccess: isStartMovementsSuccess,
    isFetching
  } = usePostStartInventoryMovementsQuery(
    autostoreGridId && workstationId
      ? { autostoreGridId, workstationId }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: tasks,
    isLoading: isLoadingGetMovementsState,
    isSuccess: isGetMovementsStateSuccess,
    refetch
  } = useGetInventoryMovementsStateQuery(
    autostoreGridId && workstationId
      ? { autostoreGridId, workstationId }
      : skipToken
  );

  useEffect(() => {
    if (isFetchingOver && !isFetching && isStartMovementsSuccess) {
      const getInventoryMovementsState = async () => {
        try {
          await refetch();
        } catch (error) {
          errorToast(getMessageFromRtkError(error));
        }
      };

      void getInventoryMovementsState();
    }
  }, [
    errorToast,
    isFetching,
    isFetchingOver,
    isStartMovementsSuccess,
    refetch
  ]);

  useEffect(() => {
    setIsFetchingOver(true);
  }, [isFetching]);

  const [getInventoryByAutostoreBinNumber] =
    useLazyGetInventoryByAutostoreBinNumberQuery();
  const [skipInventoryMovement] = usePostSkipInventoryMovementMutation();

  // Port polling (only first two ports until three ports is supported)
  const handleFetchPortStatus = async ({ shouldSkipIfReady = false }) => {
    for (const port of workstation?.ports.slice(0, 2) || []) {
      if (
        (shouldSkipIfReady &&
          !portStateByPort[port.portId]?.getPortResponse.isReady) ||
        !shouldSkipIfReady
      ) {
        await dispatch(fetchPortStatus({ portId: port.portId }));
      }
    }
  };
  const [isPortPollingActive, setIsPortPollingActive] = useState(true);
  const { areTwoOrMorePortsReady } = usePortStatus(
    portStateByPort,
    siteAllPortIds
  );
  const portPollingRequirements =
    isPortPollingActive &&
    isStartMovementsSuccess &&
    isGetMovementsStateSuccess;

  usePromiseInterval(
    async () => {
      await handleFetchPortStatus({ shouldSkipIfReady: true });
      if (areTwoOrMorePortsReady) {
        setIsPortPollingActive(false);
      }
    },
    1000,
    portPollingRequirements
  );

  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const selectedRow = tasks?.find((item) => item.id === selectedRowId);

  const inventoryActionsEnabled = !!tasks?.length && areTwoOrMorePortsReady;

  //selecting the row in the table related to the bin currently at the port and marked as the source bin
  useEffect(() => {
    if (isGetMovementsStateSuccess && tasks?.length) {
      const binAtPort = tasks.find(
        (task) =>
          task.binId === currentBins?.sourceBin?.autostoreBinId &&
          task.compartmentNumber === currentBins.sourceBin.compartmentNumber
      );

      //if there is no "source bin" in the table, do not select any row. (This is a corner case and should not be happening)
      if (binAtPort) setSelectedRowId(binAtPort.id);
      else setSelectedRowId(null);
    }
  }, [isGetMovementsStateSuccess, tasks, currentBins]);

  useEffect(() => {
    setWarnings([
      ...(!selectedPortId ? [t("no autostore port selected")] : [])
    ]);
  }, [selectedPortId, setWarnings, t]);

  useEffect(() => {
    if (initialBins) {
      dispatch(setCurrentBins(initialBins));
    }
  }, [initialBins, dispatch]);

  const handleCleanup = (useIndividualTasks = false) => {
    const uniqueTasksByBinId = new Set(tasks?.map((task) => task.binId)).size;
    const shouldGoToNextTask = useIndividualTasks
      ? tasks?.length && tasks.length > 1
      : uniqueTasksByBinId > 1;
    if (shouldGoToNextTask) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleFetchPortStatus({ shouldSkipIfReady: false });
      setIsPortPollingActive(true);
    } else {
      navigate("/inventoryv2?autostore=true");
    }
  };

  const skipMove = async () => {
    if (autostoreGridId && workstationId) {
      try {
        const nextBins = await skipInventoryMovement({
          autostoreGridId,
          workstationId
        }).unwrap();
        dispatch(setCurrentBins(nextBins));
        successToast(t("bin successfully skipped"));
      } catch (err) {
        const errorMessage = getMessageFromRtkError(err);
        // don't show error on last task if its 'there is nothing left to pick'
        if (
          !errorMessage.toLowerCase().includes("there is nothing left to pick")
        ) {
          errorToast(getMessageFromRtkError(err));
          return;
        } else {
          successToast(t("bin successfully skipped"));
        }
      }
    }
    handleCleanup();
  };

  const adjustInventory = async (quantity: number | undefined) => {
    if (quantity == 0 && tasks && currentBins) {
      // search for the same bin with different compartment
      const sameBin = tasks.find(
        (t) =>
          t.binId == currentBins.sourceBin.autostoreBinId &&
          t.compartmentNumber != currentBins.sourceBin.compartmentNumber
      );
      //if the same bin exists then find the inventory in proper compartment
      if (sameBin && autostoreGridId) {
        const inventoryForSourceBin = await getInventoryByAutostoreBinNumber({
          autostoreGridId: autostoreGridId,
          binNumber: sameBin.binId
        }).unwrap();

        const inventoryInCompartment = inventoryForSourceBin?.find(
          (i) => i.bin.autostoreCompartmentNumber == sameBin.compartmentNumber
        );

        // update sourceBin for currentBins (because we want to leave the current bin at the port)
        if (inventoryInCompartment) {
          const updatedQuantityBins = { ...currentBins };

          updatedQuantityBins.sourceBin = {
            ...updatedQuantityBins.sourceBin,
            warehouseBinId: inventoryInCompartment.bin.binId,
            compartmentNumber: sameBin.compartmentNumber,
            inventoryId: inventoryInCompartment.inventoryId
          };

          dispatch(setCurrentBins(updatedQuantityBins));
          setSelectedRowId(sameBin.id);
        } else await skipMove();
      } else await skipMove();
    }
  };

  return (
    <>
      <PageContainer maxWidth="xl">
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            mb={2}
          >
            <MovementsHeader />
          </Box>

          <MovementsTasksTable
            isLoading={
              isLoadingStartInventoryMovements || isLoadingGetMovementsState
            }
            selectedRow={selectedRow}
          />

          <MovementsBins currentBins={currentBins} selectedRow={selectedRow} />
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            justifyItems="center"
            alignItems="center"
            sx={{
              "& > button": {
                fontSize: "18px",
                width: "15em"
              }
            }}
          >
            <InventoryAdjustButton
              currentBins={currentBins}
              selectedRow={selectedRow}
              disabled={!inventoryActionsEnabled}
              adjustInventory={adjustInventory}
            />

            <ConfirmMovementButton
              isDisabled={!inventoryActionsEnabled}
              isLastTask={tasks?.length === 1}
              currentBins={currentBins}
              quantity={selectedRow?.quantity}
              handleCleanup={() => handleCleanup(true)}
            />
          </Box>
        </Box>

        <Box>
          <Box display="flex" justifyContent="flex-end" mb="10px">
            <Button
              onClick={() => setIsRefreshModalOpen(true)}
              startIcon={<Refresh />}
              variant="contained"
              color="primary"
              sx={{ fontWeight: "normal" }}
            >
              {t("return to all inventory")}
            </Button>
          </Box>

          {isGetMovementsStateSuccess && selectedRow ? (
            <UniversalProductCard
              allUpcs={[selectedRow.upc]}
              brandName={selectedRow.brandName}
              imageFileName={selectedRow.imageFilename}
              productName={selectedRow.variantName}
              sku={selectedRow.sku}
              upc={selectedRow.upc}
              quantityInline={`${selectedRow.quantity.value.toString()} ${
                selectedRow.quantity.units
              }`}
              disableGutters
              hideProductCount
            />
          ) : (
            <PickInfoIsLoading marginTop="0" height="579px" disableGutters />
          )}
          <Stack
            gap={2}
            margin={2}
            direction="row"
            flexWrap="wrap"
            alignItems={"left"}
            sx={{
              "& > button": {
                fontSize: "18px",
                width: "15em"
              }
            }}
          >
            {showFlagBinButton && (
              <FlagBinButton
                currentBins={currentBins}
                selectedRow={selectedRow}
                disabled={!inventoryActionsEnabled}
                handleCleanup={handleCleanup}
              />
            )}
            {isUnflagBinEnabled && (
              <UnflagBinButton
                inventoryActionsEnabled={inventoryActionsEnabled}
                tasks={tasks}
                selectedRow={selectedRow}
                handleCleanup={handleCleanup}
              />
            )}
            {isSkipMovementEnabled && (
              <SkipMovementButton
                inventoryActionsEnabled={inventoryActionsEnabled}
                skipMove={skipMove}
              />
            )}
            {isBinNotEmptyEnabled && (
              <BinNotEmptyButton
                inventoryActionsEnabled={inventoryActionsEnabled}
                currentBins={currentBins}
                handleCleanup={handleCleanup}
              />
            )}
          </Stack>
        </Box>
      </PageContainer>

      <ConfirmationModal
        isOpen={isRefreshModalOpen}
        confirmCb={async () => {
          setIsRefreshModalOpen(false);
          await closeWorkstation();
          navigate("/inventoryv2?autostore=true");
        }}
        closeCb={() => setIsRefreshModalOpen(false)}
        modalTitle={t("confirm")}
        modalText={t(
          "are you sure you want to close all current bins and return to the inventory main page?"
        )}
      />
    </>
  );
};

export default ManageFlaggedInventory;
