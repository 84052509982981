import { Paper, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useEmptyBinReportQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

import {
  setSourceBinConfigurationId,
  setTargetBinConfigurationId
} from "./binReconfiguration.slice";

export const BinConfiguration = ({
  binConfigurationId,
  variant
}: {
  binConfigurationId: Guid;
  variant: "source" | "target";
}) => {
  const dispatch = useAppDispatch();
  const sourceBinConfigurationId = useAppSelector(
    (store) => store.binReconfiguration.sourceBinConfigurationId
  );
  const targetBinConfigurationId = useAppSelector(
    (store) => store.binReconfiguration.targetBinConfigurationId
  );

  const variantBinConfigurationId =
    variant === "source" ? sourceBinConfigurationId : targetBinConfigurationId;

  const isSelected = variantBinConfigurationId === binConfigurationId;

  const siteAutostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const { binConfiguration } = useGetBinConfigurationsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      binConfiguration: data?.find(
        (bc) => bc.configurationId === binConfigurationId
      )
    })
  });

  const { emptyCompartmentReport } = useEmptyBinReportQuery(
    siteAutostoreGridId ?? skipToken,
    {
      selectFromResult: ({ data }) => {
        return {
          emptyCompartmentReport: data?.find(
            (r) => r.binConfigurationId === binConfigurationId
          )
        };
      }
    }
  );

  const handleSelectBinConfiguration = () => {
    if (variant === "source") {
      dispatch(setSourceBinConfigurationId(binConfigurationId));
    } else {
      dispatch(setTargetBinConfigurationId(binConfigurationId));
    }
  };

  return (
    <>
      {variant === "source" && emptyCompartmentReport && (
        <Typography variant="h4" gridRow={2} justifySelf="center">
          {emptyCompartmentReport?.binsWithNoInventory} Empty Bins
        </Typography>
      )}
      <Paper
        role="button"
        aria-label={`${variant}-${emptyCompartmentReport?.numberOfCompartments}-compartment-bin`}
        onClick={handleSelectBinConfiguration}
        elevation={2}
        sx={{
          border: "8px solid",
          borderColor: isSelected ? "primary.main" : "transparent",
          backgroundColor: isSelected
            ? "background.lightBlue"
            : "background.gray",
          p: 2
        }}
      >
        <AutostoreBin
          state={"Port Closed"}
          numberOfColumns={binConfiguration?.verticalCompartmentCount || 1}
          numberOfRows={binConfiguration?.horizontalCompartmentCount || 1}
          pickQuantity=""
          pickCompartment={null}
        />
      </Paper>
    </>
  );
};
