import Container from "@mui/material/Container";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

type PlaceholderInheritedProps = { viewTitle?: ViewNameTranslation };

export default function Placeholder(props: PlaceholderInheritedProps) {
  const { viewTitle } = props;

  useNavbar({ viewTitle });

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      ...
    </Container>
  );
}
