import { PickOrderRequest } from "~/types/api";

import { pepsiApi } from "./pepsiApi";

export const pickOrderApi = pepsiApi.injectEndpoints({
  endpoints: (builder) => ({
    createPepsiOrder: builder.mutation<
      void,
      { clientId: Guid; fulfillmentCenterId: Guid } & PickOrderRequest
    >({
      query: ({ clientId, fulfillmentCenterId, ...order }) => ({
        url: `/v1/pickorder`,
        method: "POST",
        params: {
          clientId,
          fulfillmentCenterId
        },
        body: order
      })
    })
  }),
  overrideExisting: false
});
