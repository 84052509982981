import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import InventoryAdjustDialogComponent, {
  InventoryToModify
} from "~/features/inventoryAdjustDialog/InventoryAdjustDialog";

import usePortStatus from "~/hooks/usePortStatus";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import {
  generateLocationNameFromInventorySummary,
  isAutostoreView
} from "~/lib/helpers";
import {
  binNotAtPort,
  closeBin,
  fetchPortStatus,
  getNextBin,
  resetPortBinData,
  setPortStatus
} from "~/redux/actions";
import {
  clearInventoryToModify,
  findInventoryByAutostoreBinNumber,
  getInventoryReport,
  getVariantData
} from "~/redux/actions/inventory";
import {
  selectPortStateByPort,
  selectSelectedInventoryBin
} from "~/redux/selectors/autostoreSelectors";
import {
  selectAdjustingInventory,
  selectInventoryAtPort,
  selectInventorySummariesToDisplay,
  selectInventorySummaryToDisplay,
  selectIsAdjustingBins,
  selectMultiportSelectedCompartment,
  selectSelectedInventoryAtPort,
  selectSelectedSummaries
} from "~/redux/selectors/inventorySelectors";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { AutostoreBinConfigurationDto, InventorySummaryDto } from "~/types/api";

import {
  setIsInventoryAdjustDialogOpen,
  setIsFetchingBin,
  setPortPollingActive,
  setSelectedAdjustingSummary,
  setSelectedSummaries
} from "./inventory.slice";
import { useGridSelector } from "./useGridSelector";

type Props = {
  handleFetchAllInventory: () => Promise<void>;
  handleFetchPortStatus: (portId: number) => Promise<void>;
  onClose: () => Promise<void>;
};

/**
 * The inventory adjust dialog used by inventory pages
 * */
export function InventoryAdjustDialog({
  handleFetchAllInventory,
  handleFetchPortStatus,
  onClose
}: Props) {
  const locationInfo = useLocation();
  const { search } = locationInfo;
  const isAutostoreInventoryView = isAutostoreView(search);
  const dispatch = useAppDispatch();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const { autostoreGridOverride } = useGridSelector();

  const [autostoreBinConfiguration, setAutostoreBinConfiguration] =
    useState<AutostoreBinConfigurationDto | null>(null);
  const [getBinsIndex, setGetBinsIndex] = useState(0);

  const binAtPort = useAppSelector((state) => state.autostore.binAtPort);
  const currentSelectedBin = useAppSelector(selectSelectedInventoryBin);
  const currentEmptyBin = useAppSelector(
    (state) => state.autostore.currentEmptyBin
  );
  const isAdjustingBins = useAppSelector(selectIsAdjustingBins);
  const isInventoryAdjustDialogOpen = useAppSelector(
    (state) => state.inventoryNew.isInventoryAdjustDialogOpen
  );
  const inventoryAtPort = useAppSelector(selectInventoryAtPort);
  const inventorySummaryToDisplay = useAppSelector(
    selectInventorySummaryToDisplay
  );
  const isFetchingBin = useAppSelector(
    (state) => state.inventoryNew.isFetchingBin
  );
  const portStateByPort = useAppSelector(selectPortStateByPort);
  const requestedAutostoreBin = useAppSelector(
    (state) => state.autostore.requestedAutostoreBin || null
  );
  const selectedCompartment = useAppSelector(
    selectMultiportSelectedCompartment
  );
  const selectedAdjustingInventory = useAppSelector(selectAdjustingInventory);
  const selectedInventoryAtPort = useAppSelector(selectSelectedInventoryAtPort);
  const selectedSummaries = useAppSelector(selectSelectedSummaries);
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const siteAllPortIds = useAppSelector(
    (state) => state.workstations.siteAllPortIds
  );
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const summariesToDisplay = useAppSelector(selectInventorySummariesToDisplay);
  const updatedInventoryToModify = useAppSelector(
    (state) => state.inventory.inventoryToModify
  );
  const updatedInventoryToModifyCount = useAppSelector(
    (state) => state.inventory.inventoryToModify?.count
  );

  const [activeSelectedCompartment, setActiveSelectedCompartment] = useState<
    number | undefined
  >(selectedCompartment);

  const currentSelectedPortId = currentSelectedBin?.portId;
  const currentSelectedBinConfig = currentSelectedBin?.nextBinConfiguration;
  const currentSelectedBinNumber =
    selectedAdjustingInventory?.autostoreBinNumber;

  const { areAllPortsOpen, portStateByPortArray } = usePortStatus(
    portStateByPort,
    siteAllPortIds,
    currentSelectedPortId
  );

  const invSumToModify = isAdjustingBins
    ? selectedInventoryAtPort
    : inventorySummaryToDisplay;

  let currentBinIdCount: number | string = "";
  if (currentEmptyBin !== null && invSumToModify?.count) {
    currentBinIdCount = "";
  } else if (invSumToModify && invSumToModify.count) {
    currentBinIdCount = invSumToModify.count.value;
  }

  let selectedLocationName = "";
  if (invSumToModify) {
    selectedLocationName =
      invSumToModify.binType === "autostore"
        ? "Autostore"
        : generateLocationNameFromInventorySummary(invSumToModify);
  }

  const selectedInventoryToModify: InventoryToModify = invSumToModify
    ? {
        binType: invSumToModify.binType,
        binId: invSumToModify.binId,
        inventoryId: invSumToModify.inventoryId,
        locationName: selectedLocationName,
        count: invSumToModify.count,
        committedCount: invSumToModify.committedCount,
        expiration: invSumToModify.expiration,
        manufactureDate: invSumToModify.manufactureDate,
        lotNumber: invSumToModify.lotNumber,
        variantId: invSumToModify.variantId
      }
    : null;

  const selectedAutostoreGridId = siteWorkstation
    ? siteWorkstation.autostoreGridId
    : autostoreGridOverride;

  const numberOfColumns =
    autostoreBinConfiguration?.verticalCompartmentCount || 1;
  const numberOfRows =
    autostoreBinConfiguration?.horizontalCompartmentCount || 1;
  const pickCompartment = selectedInventoryAtPort?.autostoreCompartmentNumber
    ? selectedInventoryAtPort.autostoreCompartmentNumber - 1
    : null;
  const pickQuantity = updatedInventoryToModifyCount
    ? updatedInventoryToModifyCount.value
    : currentBinIdCount;

  useEffect(() => {
    setAutostoreBinConfiguration(requestedAutostoreBin);
  }, [requestedAutostoreBin]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isAdjustingBins && getBinsIndex !== 0) {
      setGetBinsIndex(0);
    }
  });

  useEffect(() => {
    if (
      isInventoryAdjustDialogOpen &&
      invSumToModify?.autostoreCompartmentNumber
    ) {
      setActiveSelectedCompartment(
        invSumToModify.autostoreCompartmentNumber - 1
      );
    }
  }, [invSumToModify?.autostoreCompartmentNumber, isInventoryAdjustDialogOpen]);

  const handleGetNextBin = async (portId?: number) => {
    // Get port status in order to close bin and call next bin
    const portStatus = await dispatch(fetchPortStatus({ portId }));

    if (portStatus) {
      const { selectedTask } = portStatus;
      await dispatch(
        closeBin({
          binId: portStatus.selectedBin,
          taskId: selectedTask,
          portId: portStatus.portId
        })
      );
      await dispatch(getNextBin({ portId, shouldSoftFail: true }));
      if (!isAdjustingBins) {
        siteWorkstation?.ports.forEach((port) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          handleFetchPortStatus(port.portId);
        });
        if (!shouldListenToGridEvents) {
          dispatch(setPortPollingActive(true));
        } else {
          dispatch(binNotAtPort());
        }
        setGetBinsIndex(getBinsIndex + 1);
      }
    }

    if (!isFetchingBin) {
      dispatch(setIsFetchingBin(true));
    }
    // Get port status again in order to update portState's ready to be false while calling 'next bin'
    const data = await dispatch(fetchPortStatus({ portId }));
    if (!data) return;
    dispatch(setPortStatus(data));

    const portIsOpen = data.mode.toLowerCase().includes("open");
    const portIsReady = data.isReady;

    // the right bin is here and open.  Enable the adjust button.
    if (!isAdjustingBins && portIsOpen && portIsReady) {
      if (shouldListenToGridEvents && !binAtPort) return;
      dispatch(setIsFetchingBin(false));
    }
  };

  const closeBinForAllPorts = async () => {
    await Promise.all(
      portStateByPortArray.map((portResponse) => {
        if (
          portResponse.getPortResponse.selectedBin &&
          portResponse.getPortResponse.isReady
        ) {
          return dispatch(
            closeBin({
              binId: portResponse.getPortResponse.selectedBin,
              portId: portResponse.getPortResponse.portId,
              taskId: portResponse.getPortResponse.selectedTask
            })
          );
        }
        return Promise.resolve();
      })
    );
    dispatch(resetPortBinData());
  };

  const getIsAdjustInventoryModalDisabled = () => {
    if (!isAutostoreInventoryView) {
      return false;
    }
    return isAdjustingBins ? false : isFetchingBin;
  };

  const getActiveTaskGroupCompartments = (
    summaries: InventorySummaryDto[] | undefined
  ): number[] | undefined => {
    if (!summaries || !updatedInventoryToModify) return undefined;
    return summaries
      ?.filter(
        (product) =>
          product.autostoreBinNumber ===
          updatedInventoryToModify.autostoreBinNumber
      )
      .map((inv) =>
        inv.autostoreCompartmentNumber ? inv.autostoreCompartmentNumber - 1 : 0
      )
      .sort();
  };

  return (
    <InventoryAdjustDialogComponent
      open={isInventoryAdjustDialogOpen}
      onClose={async () => {
        await onClose();
        dispatch(clearInventoryToModify());
        dispatch(setIsInventoryAdjustDialogOpen(false));
        dispatch(setSelectedAdjustingSummary(null));
      }}
      autostoreBinConfiguration={currentSelectedBinConfig}
      disabled={getIsAdjustInventoryModalDisabled()}
      invToModify={selectedInventoryToModify}
      selectedVariant={selectedVariant || null}
      currentSelectedPortId={currentSelectedPortId}
      refreshCb={async ({ inventoryId, inventoryWasEmptied }) => {
        if (isAdjustingBins) {
          if (selectedSummaries.length > 1) {
            dispatch(
              setSelectedSummaries(
                selectedSummaries.filter(
                  (summary) => summary.inventoryId !== inventoryId
                )
              )
            );
            await handleGetNextBin(currentSelectedPortId);
          } else {
            await closeBinForAllPorts();
            await handleFetchAllInventory();
          }
        }
        const binNumber = isAdjustingBins
          ? currentSelectedBinNumber
          : inventoryAtPort[0].bin.autostoreBin?.autostoreBinId;
        if (
          !inventoryWasEmptied &&
          inventoryAtPort.length &&
          inventoryAtPort[0].bin.autostoreBin &&
          selectedAutostoreGridId &&
          binNumber
        ) {
          await dispatch(
            findInventoryByAutostoreBinNumber(
              selectedAutostoreGridId,
              binNumber
            )
          );
        }
        if (!isAdjustingBins && inventoryWasEmptied) {
          await handleGetNextBin();
        }
        if (!isAdjustingBins && selectedVariant) {
          await dispatch(getInventoryReport(selectedVariant?.variantId));
        }
      }}
      canceledReason=""
      isAdjustOnMain={false}
      setCurrentSelectedInventoryRecord={(summary: InventorySummaryDto) =>
        dispatch(setSelectedAdjustingSummary(summary))
      }
    >
      {pickQuantity &&
        areAllPortsOpen &&
        updatedInventoryToModify?.autostoreBinNumber && (
          <AutostoreBin
            state={!areAllPortsOpen ? "Port Closed" : "Bin Opened"}
            numberOfColumns={
              autostoreBinConfiguration?.verticalCompartmentCount ||
              numberOfColumns || // for cypress test
              1
            }
            numberOfRows={
              autostoreBinConfiguration?.horizontalCompartmentCount ||
              numberOfRows || // for cypress test
              1
            }
            pickCompartment={
              pickCompartment === undefined ? null : pickCompartment
            }
            pickQuantity={pickQuantity}
            binId={updatedInventoryToModify.autostoreBinNumber}
            hideBinId
            selectedCompartment={activeSelectedCompartment}
            activeTaskGroupCompartments={getActiveTaskGroupCompartments(
              summariesToDisplay
            )}
            highlightActiveCompartment
            onBinClick={async (compartment) => {
              if (
                compartment === undefined ||
                selectedCompartment === compartment
              )
                return;
              const compartmentBinProducts = summariesToDisplay
                ?.filter(
                  (product) =>
                    product.autostoreBinNumber ===
                    updatedInventoryToModify.autostoreBinNumber
                )
                .find(
                  (sum) => sum.autostoreCompartmentNumber === compartment + 1
                );

              if (!compartmentBinProducts) return;
              await dispatch(
                getVariantData({
                  variantId: compartmentBinProducts?.variantId,
                  binId: compartmentBinProducts?.binId,
                  limit: 50,
                  offset: 0,
                  autostoreGridId: isAutostoreView(window.location.search)
                    ? selectedAutostoreGridId
                    : undefined,
                  inventoryId: compartmentBinProducts?.inventoryId
                })
              );
            }}
          />
        )}
    </InventoryAdjustDialogComponent>
  );
}
