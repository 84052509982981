/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { ProgressButton } from "@qubit/autoparts";
import speech from "speech-synth";

window.speech = speech;

export function SpeechSimulator() {
  const encourage = () => {
    speech.say(
      "Good job picking, picker.  You are amazing and people like you.",
      "Google UK English Male"
    );
  };

  const scorn = () => {
    speech.say(
      "Picker, please pick up the pace.  You are bringing shame on the company.",
      "Google UK English Male"
    );
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 50
      }}
    >
      <ProgressButton
        data-testid="another-button"
        buttonSize="small"
        emphasis="high"
        responsive
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginRight: 5 }}
        onClick={() => {
          encourage();
        }}
      >
        encourage
      </ProgressButton>
      <ProgressButton
        data-testid="another-button"
        buttonSize="small"
        emphasis="high"
        responsive
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginRight: 5 }}
        onClick={() => {
          scorn();
        }}
      >
        scorn
      </ProgressButton>
    </div>
  );
}

export default SpeechSimulator;
