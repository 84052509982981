import { MeasuredValueDto, ToteSummaryDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const totesApi = warehouseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTotes: builder.query<
      ToteSummaryDto[],
      { batchId?: Guid; orderId?: Guid }
    >({
      query: ({ batchId, orderId }) => ({
        url: `/totes`,
        method: "GET",
        params: { batchId, orderId }
      }),
      providesTags: ["tote"]
    }),
    stageTotes: builder.mutation<
      void,
      {
        toteIds: string[];
        putWallId: string;
        putWallLaneId: string;
      }
    >({
      query: (queryArg) => ({
        url: `/totes/stage`,
        method: "POST",
        body: queryArg
      }),
      invalidatesTags: ["tote", "batch"]
    }),
    loadTotes: builder.mutation<void, { toteIds: Guid[] }>({
      query: ({ toteIds }) => {
        return {
          url: `/totes/load`,
          method: "POST",
          body: toteIds
        };
      },
      invalidatesTags: ["tote", "batch"]
    }),
    split: builder.mutation<
      void,
      { currentToteId: Guid; picksIdsToTransfer: Guid[] }
    >({
      query: ({ currentToteId, picksIdsToTransfer }) => {
        return {
          url: `/totes/${currentToteId}/split`,
          method: "POST",
          body: {
            picksIdsToTransfer
          }
        };
      },
      invalidatesTags: ["tote"]
    }),
    transfer: builder.mutation<
      void,
      {
        originalToteId: Guid;
        newToteId: Guid;
        pickId: Guid;
        qty: MeasuredValueDto;
      }
    >({
      query: ({ originalToteId, newToteId, pickId, qty }) => {
        return {
          url: `/totes/${originalToteId}/pick-transfers`,
          method: "POST",
          body: {
            deleteToteWhenEmpty: true,
            newToteId,
            pickTransfers: [
              {
                pickId,
                quantityToTransfer: qty
              }
            ]
          }
        };
      },
      invalidatesTags: ["tote"]
    }),
    verifyTotes: builder.mutation<void, { toteIds: Guid[] }>({
      query: ({ toteIds }) => {
        return {
          url: `/totes/verify`,
          method: "POST",
          body: toteIds
        };
      },
      invalidatesTags: ["tote", "batch"]
    }),
    placeLabel: builder.mutation<void, { toteId: string; workstationId: Guid }>(
      {
        query: ({ toteId, workstationId }) => {
          return {
            url: `/totes/${toteId}/place-label`,
            method: "POST",
            body: {
              workstationId
            }
          };
        }
      }
    ),
    scanLabel: builder.mutation<
      void,
      {
        toteId: string;
        autostorePortId?: number;
        autostoreGridId?: string;
        workstationId?: string;
      }
    >({
      query: ({ toteId, autostorePortId, autostoreGridId, workstationId }) => {
        return {
          url: `/totes/${toteId}/scan-label`,
          method: "POST",
          body: {
            autostorePortId,
            autostoreGridId,
            workstationId
          }
        };
      }
    }),
    confirmTotePosition: builder.mutation<
      void,
      { toteId: Guid; gridId: Guid; portId: number; workstationId?: Guid }
    >({
      query: ({ toteId, gridId, portId, workstationId }) => {
        return {
          url: "/totes/position-confirmed",
          method: "POST",
          body: {
            toteId,
            gridId,
            portId,
            workstationId
          }
        };
      }
    }),
    confirmAllTotePositions: builder.mutation<
      void,
      { gridId: Guid; batchId: Guid; workstationId?: Guid }
    >({
      query: ({ gridId, batchId, workstationId }) => {
        return {
          url: "/totes/confirm-all-positions-on-port",
          method: "POST",
          body: {
            gridId,
            batchId,
            workstationId,
            ptlModuleId: 1
          }
        };
      }
    })
  }),
  overrideExisting: false
});
