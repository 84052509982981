// due to this table not appearing like a normal table (header is data), it will not use AutostoreTable
// until the view is redesigned
import {
  Typography,
  Grid,
  Collapse,
  useMediaQuery,
  Container,
  TableCell,
  Skeleton
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { mobileWidth, useToast, TinyIconGenerator } from "@qubit/autoparts";
import { isGuid } from "is-guid";
import { Fragment, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { useAppDispatch, useAppSelector } from "~/app/store";

import PickProgressBar from "~/features/pickBatches/PickProgressBar";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { matchesUpc, getDisplayNameFc } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  combineTotesAndLoosePicks,
  getPickId,
  getToteOrLoosePickId,
  getType
} from "~/lib/toteOrLoosePick";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetLoosePicksQuery } from "~/redux/warehouse/loosePicks.openApi";
import { useGetOrdersQuery } from "~/redux/warehouse/orders.hooks";
import { useGetPicksQuery } from "~/redux/warehouse/pick.hooks";
import {
  useGetTotesQuery,
  useTransferMutation
} from "~/redux/warehouse/totes.hooks";
import { MeasuredValueDto, PickDto } from "~/types/api";

import OrderItemRow from "./OrderItemRow";
import { OrderToolbar } from "./OrderToolbar";
import {
  selectPick,
  setIsTransferModalOpen,
  setOpenToteOrLoosePickId,
  setTransferQty
} from "./shipOrder.slice";
import { useOpenTotePicks } from "./useOpenTotePicks";

type Props = { viewTitle?: ViewNameTranslation };

export function ShipOrderTable({ viewTitle }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);
  const { errorToast, successToast } = useToast();

  const { orderId = "" } = useParams<{
    orderId: Guid;
  }>();

  const { setToolbar } = useNavbar({ viewTitle });

  const isTransferModalOpen = useAppSelector(
    (state) => state.shipOrder.isTransferModalOpen
  );
  const openToteOrLoosePickId = useAppSelector(
    (state) => state.shipOrder.openToteOrLoosePickId
  );
  const selectedPickId = useAppSelector(
    (state) => state.shipOrder.selectedPick?.pickId
  );
  const transferQty = useAppSelector((state) => state.shipOrder.transferQty);
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const { data: orders, isFetching: isFetchingOrders } = useGetOrdersQuery(
    {
      orderId
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: picks, isFetching: isFetchingPicks } = useGetPicksQuery(
    {
      orderId
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: loosePicks, isFetching: isFetchingLoosePicks } =
    useGetLoosePicksQuery(
      {
        orderId
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: totes, isFetching: isFetchingTotes } = useGetTotesQuery(
    {
      orderId
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isFetchingPicks) {
      dispatch(selectPick(null));
      dispatch(setOpenToteOrLoosePickId(null));
    }
  }, [dispatch, isFetchingPicks]);

  const [transfer] = useTransferMutation();

  const totesAndLoosePicks = combineTotesAndLoosePicks(
    totes || [],
    loosePicks || []
  );

  const order = orders?.at(0);

  const openTotePicks = useOpenTotePicks(orderId);

  const selectedPick =
    !!selectedPickId && picks?.find((pick) => pick.pickId === selectedPickId);
  const canMovePick = selectedPick && selectedPick.status === "Completed";
  const isLoosePickSelected =
    loosePicks &&
    loosePicks?.length > 0 &&
    loosePicks.find((lp) => lp.loosePickId === openToteOrLoosePickId);

  useEffect(() => {
    if ((!!canMovePick || !!openToteOrLoosePickId) && !isLoosePickSelected) {
      setToolbar(<OrderToolbar />);
    } else {
      setToolbar(null);
    }
  }, [canMovePick, isLoosePickSelected, openToteOrLoosePickId, setToolbar]);

  const handlePickTransfer = useCallback(
    async (
      originalToteId: Guid,
      newToteId: Guid,
      pickId: Guid,
      qty: MeasuredValueDto
    ) => {
      try {
        await transfer({ originalToteId, newToteId, pickId, qty }).unwrap();
        dispatch(selectPick(null));
        dispatch(setIsTransferModalOpen(false));
        successToast("Transferred pick", {
          description: `Transferred ${qty.value}`
        });
      } catch (err: unknown) {
        errorToast(getMessageFromRtkError(err));
      }
    },
    [dispatch, errorToast, successToast, transfer]
  );

  useKeyDownHandler();
  useBarcodeScanner<Guid | PickDto | null>({
    findScanMatch: (buffer: string) => {
      // scan tote (open tote or confirm pick transfer)
      if (isGuid(buffer)) {
        return buffer;
      }
      // scan pick to move
      if (openTotePicks) {
        const matchedPick = openTotePicks.find((p) =>
          matchesUpc(p.allUpcs, buffer)
        );
        return matchedPick || null;
      }
      return null;
    },
    processScanMatch: async (toteIdOrPick) => {
      if (!toteIdOrPick) {
        return null;
      }

      // scan tote
      if (typeof toteIdOrPick === "string" && isGuid(toteIdOrPick)) {
        // scan new tote to conirm move
        if (isTransferModalOpen && selectedPick && selectedPickId) {
          await handlePickTransfer(
            selectedPick.putToteId || selectedPick.assignedToteId || "",
            toteIdOrPick,
            selectedPickId,
            transferQty || selectedPick.quantity
          );
        } else {
          // scan to open tote
          dispatch(setOpenToteOrLoosePickId(toteIdOrPick));
          dispatch(selectPick(null));
        }
      } else if (toteIdOrPick) {
        // scan to select pick
        dispatch(selectPick(toteIdOrPick as PickDto));
      }
      return toteIdOrPick;
    },
    deps: [picks, orderId, openTotePicks]
  });

  const renderLoosePickOrToteRows = () => {
    const rows = totesAndLoosePicks.map((toteOrLoosePick) => {
      const toteOrLoosePickId = getToteOrLoosePickId(toteOrLoosePick);
      const loosePickId = getPickId(toteOrLoosePick);
      const isExpanded = openToteOrLoosePickId === toteOrLoosePickId;
      const totePicks = picks?.filter(
        (pick) =>
          (pick.putToteId || pick.assignedToteId) === toteOrLoosePickId ||
          pick.pickId === loosePickId
      );
      const completedPicks = totePicks?.filter(
        (pick) => pick.status.toLowerCase() === "completed"
      );

      return (
        <Fragment key={toteOrLoosePickId}>
          <TableRow
            key={toteOrLoosePickId}
            sx={{
              cursor: "pointer"
            }}
            selected={isExpanded}
            onClick={() => {
              dispatch(selectPick(null));
              dispatch(setTransferQty(null));
              return isExpanded
                ? dispatch(setOpenToteOrLoosePickId(null))
                : dispatch(setOpenToteOrLoosePickId(toteOrLoosePickId));
            }}
          >
            <TableCell
              style={{
                width: 30
              }}
            >
              <Grid container justifyContent="center" alignItems="center">
                <TinyIconGenerator
                  tempZone={
                    toteOrLoosePick.temperatureZone.toLowerCase() as
                      | "ambient"
                      | "chilled"
                      | "frozen"
                  }
                  type={getType(toteOrLoosePick)}
                  key={toteOrLoosePickId || ""}
                  status={toteOrLoosePick.status}
                />
              </Grid>
            </TableCell>
            <TableCell>{toteOrLoosePick.status}</TableCell>
            <TableCell align="center" size="small">
              <PickProgressBar
                totalPicks={totePicks?.length || 0}
                completedPicks={completedPicks?.length || 0}
                labelOutside={!isMobile}
              />
            </TableCell>
          </TableRow>
          <TableRow style={{ cursor: "pointer" }}>
            <TableCell colSpan={3} style={{ padding: 0 }}>
              <Collapse in={isExpanded}>
                {totePicks?.map((item) => {
                  const name = item.name;
                  const qty = item.quantity.value;
                  const isPickSelected = selectedPickId?.includes(item.pickId);
                  return (
                    <OrderItemRow
                      key={item.pickId}
                      productName={name}
                      productQty={qty}
                      itemId={item.pickId}
                      isSelected={isPickSelected}
                      setSelected={() =>
                        dispatch(selectPick(isPickSelected ? null : item))
                      }
                      pick={item}
                    />
                  );
                })}
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      );
    });

    const unassignedPicks = picks?.filter(
      (pick) =>
        !pick.assignedToteId &&
        !pick.putToteId &&
        !loosePicks?.find((lp) => lp.pickId === pick.pickId)
    );
    const unassignedPickRows = (
      <>
        {unassignedPicks?.map((up) => {
          const name = up.name;
          const qty = up.quantity.value;
          const isPickSelected = selectedPickId?.includes(up.pickId);
          return (
            <TableRow
              style={{ cursor: "pointer" }}
              key={up.pickId}
              selected={isPickSelected}
            >
              <TableCell colSpan={3}>
                <OrderItemRow
                  productName={name}
                  productQty={qty}
                  itemId={up.pickId}
                  isSelected={isPickSelected}
                  setSelected={() =>
                    dispatch(selectPick(isPickSelected ? null : up))
                  }
                  pick={up}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );

    let unbatchedOrderItemRows = null;
    if (!picks?.length && !!order) {
      unbatchedOrderItemRows = (
        <>
          {order?.lineItems.map((li) => (
            <TableRow style={{ cursor: "pointer" }} key={li.productId}>
              <TableCell colSpan={3}>
                <OrderItemRow
                  productName={
                    getDisplayNameFc(
                      li.variantName,
                      li.productName,
                      usersFulfillmentCenter
                    ) ?? ""
                  }
                  productQty={li.quantity.value}
                  itemId={li.productId}
                />
              </TableCell>
            </TableRow>
          ))}
        </>
      );
    }

    return (
      <>
        {rows}
        {unassignedPickRows}
        {unbatchedOrderItemRows}
      </>
    );
  };

  return (
    <Container>
      <Box marginTop="24px">
        <Table aria-label="cart table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                <Typography>{order?.customerName || ""}</Typography>
                {order?.externalOrderId && (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {order?.externalOrderId}
                  </Typography>
                )}
              </TableCell>
              <TableCell align="center">
                {order && (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {`(${totesAndLoosePicks.length}) ${t(
                      "totes and loose picks"
                    )} `}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetchingPicks ||
            isFetchingOrders ||
            isFetchingLoosePicks ||
            isFetchingTotes ? (
              <TableRow
                data-testid="loading-skeleton"
                sx={{ height: "100%", width: "100%" }}
              >
                {[1, 2, 3, 4, 5].map((num) => {
                  return (
                    <TableCell
                      key={`skeleton-row-${num}`}
                      sx={{
                        display: "flex",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        height: "30px",
                        width: "100%",
                        border: `2px solid transparent`,
                        boxSizing: "border-box"
                      }}
                    >
                      {["50%", "50%"].map((width, i) => {
                        return (
                          <Skeleton
                            key={`skeleton-column-${i}`}
                            sx={{
                              height: "100%",
                              width,
                              variant: "rectangular"
                            }}
                          ></Skeleton>
                        );
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            ) : (
              renderLoosePickOrToteRows()
            )}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
}
