import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * The height of the fixed navbar that we know ahead of time.
 *
 * 💀Do not expose this outside of ShellContent 💀
 * */
const navBarHeight = "48px";

/**
 * Wraps content rendered inside the shell with the appropriate offset from the navbar.
 */
export const ShellContent = styled(Box)(() => ({
  paddingTop: navBarHeight
}));
