import { skipToken } from "@reduxjs/toolkit/query";

import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { setPickedQuantityModalStatus } from "~/features/autostorePicking/confirmPickQuantityModal/confirmPickQuantityModal.slice";
import {
  setAbleToPickQty,
  setIsOosModalOpenedAfterQtyModal,
  setOutOfStockDialogStatus,
  setOutOfStockReasonCode
} from "~/features/autostorePicking/outOfStockDialog/outOfStockDialog.slice";
import {
  displayPickData,
  getAxiosErrorMessage,
  getHoldTypeOptions,
  showPickToLight
} from "~/lib/helpers";
import {
  setIsPickQuantityConfirmed,
  setNextPickingBinLoading,
  updatePickingState
} from "~/redux/actions";
import { clearUserMessage, setUserMessage } from "~/redux/actions/site";
import {
  selectIsOosModalOpenedAfterQtyModal,
  selectIsPickQuantityConfirmed
} from "~/redux/selectors/autostoreSelectors";
import {
  selectAbleQtyToPick,
  selectOutOfStockReasonCode
} from "~/redux/selectors/outOfStockSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  selectSitePortId,
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import {
  useGetFocusedPickQuery,
  useGetPortStateQuery,
  useOutOfStockPickMutation
} from "~/redux/warehouse/autostorePicking.hooks";
import { useLazyGetPickQuery } from "~/redux/warehouse/pick.hooks";

type AutostoreOutOfStockHookProps = {
  handleMoveNextPick: (args: { reporter: string }) => Promise<void>;
};

export default function useAutostoreOutOfStock({
  handleMoveNextPick
}: AutostoreOutOfStockHookProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const confirmPickQuantityModalPickedQuantity = useAppSelector(
    (state) => state.confirmPickQuantityModalSlice.pickedQuantity
  );
  const confirmPickQuantityModalSelectedBinId = useAppSelector(
    (state) => state.confirmPickQuantityModalSlice.selectedBinId
  );
  const oosModalStatusAfterQtyModal = useAppSelector(
    selectIsOosModalOpenedAfterQtyModal
  );
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const workstationId = useAppSelector(selectWorkstationId);
  const sitePortId = useAppSelector(selectSitePortId);
  const clientConfig = useAppSelector(selectClientConfig);
  const outOfStockReasonCode = useAppSelector(selectOutOfStockReasonCode);
  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);
  const initialAbleToPickQty = useAppSelector(selectAbleQtyToPick);
  const [ableToPickQty, setAbleToPickQuantity] = useState<number>(
    initialAbleToPickQty || 0
  );
  const showSkeleton = useRef(false);
  const { data: currentPick } = useGetFocusedPickQuery(
    workstationId ? { workstationId: workstationId } : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );
  const { data: portState } = useGetPortStateQuery(
    workstationId ? { workstationId } : skipToken
  );

  const [fetchPick] = useLazyGetPickQuery();
  const [outOfStockPick] = useOutOfStockPickMutation();

  const {
    ap_excludeRecalledHold,
    ap_includeDamagedBinHold,
    ap_includeDirtyBinHold,
    ap_includeMisconfiguredBinHold
  } = clientConfig;

  const pickQuantityConfirmationEnabled =
    !!fulfillmentCenter && fulfillmentCenter.pickQuantityConfirmationEnabled;

  const backButtonShown =
    !!fulfillmentCenter &&
    fulfillmentCenter.pickQuantityConfirmationEnabled &&
    oosModalStatusAfterQtyModal;

  // state that should indicate if the active pick upc is scanned
  // for clients that do not use the barcode scanning flow, this value defaults to 'true'
  const isPickUpcScanned =
    !!fulfillmentCenter &&
    (!fulfillmentCenter.pickQuantityConfirmationEnabled ||
      (fulfillmentCenter.pickQuantityConfirmationEnabled &&
        isPickQuantityConfirmed));

  const outOfStockReasonCodes = getHoldTypeOptions(
    ap_excludeRecalledHold,
    ap_includeDamagedBinHold,
    ap_includeDirtyBinHold,
    ap_includeMisconfiguredBinHold,
    true
  )
    ?.map((option) => ({
      text: t(`${option.reasonCode}`),
      value: option.reasonCode
    }))
    .filter((holdType) => !holdType.text.toLowerCase().includes("bin"));

  const currentPickAmount = currentPick?.quantity.value || 0;

  const handleQuantityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const amount = (event.target.value && Number(event.target.value)) || 0;
      setAbleToPickQuantity(
        amount >= 0 && amount < currentPickAmount ? amount : 0
      );
    },
    [currentPickAmount, setAbleToPickQuantity]
  );

  const handleButtonClick = useCallback(
    (increment: number): void => {
      setAbleToPickQuantity((pickAmount) => {
        if (
          ableToPickQty + increment >= 0 &&
          ableToPickQty + increment < currentPickAmount
        ) {
          return pickAmount + increment;
        }
        return pickAmount;
      });
    },
    [ableToPickQty, currentPickAmount, setAbleToPickQuantity]
  );

  const closeModalCallback = useCallback(() => {
    if (pickQuantityConfirmationEnabled) {
      dispatch(
        setPickedQuantityModalStatus({
          isShown: false,
          pickedQuantity: null,
          selectedBinId: null
        })
      );
      if (oosModalStatusAfterQtyModal) {
        dispatch(setIsOosModalOpenedAfterQtyModal(false));
      }
    }
    dispatch(setOutOfStockDialogStatus(false));
  }, [dispatch, oosModalStatusAfterQtyModal, pickQuantityConfirmationEnabled]);

  const backButtonCallback = useCallback(() => {
    dispatch(setOutOfStockDialogStatus(false));
    dispatch(setIsOosModalOpenedAfterQtyModal(false));
    if (currentPick) {
      dispatch(
        setPickedQuantityModalStatus({
          isShown: true,
          pickedQuantity: confirmPickQuantityModalPickedQuantity,
          selectedBinId: confirmPickQuantityModalSelectedBinId
        })
      );
    }
  }, [
    currentPick,
    dispatch,
    confirmPickQuantityModalPickedQuantity,
    confirmPickQuantityModalSelectedBinId
  ]);

  // useCallback here since without it,
  // the function within debounce was still called multiple times.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOutOfStockCb = useCallback(
    async (ableToPickQty: number | null) => {
      if (!currentPick) return;
      // If we set any partial amount
      // we will handle split/oos/confirm in one step on confirm
      // Otherwise, if zero, let's oos right away
      if (ableToPickQty && ableToPickQty !== currentPickAmount && currentPick) {
        try {
          dispatch(setAbleToPickQty(ableToPickQty));

          // If the Out Of Stock modal is opened from the multi-qty modal
          // This means that the product barcode has already been scanned
          if (oosModalStatusAfterQtyModal) {
            dispatch(setIsPickQuantityConfirmed(true));
          }

          // PTL should display the pickable amount if the product barcode is scanned
          if (sitePortId && (isPickUpcScanned || oosModalStatusAfterQtyModal)) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            displayPickData({
              portId: sitePortId,
              pickId: currentPick.pickId,
              batchId: currentPick.batchId,
              gridId: selectedAutostoreGridId,
              quantity: ableToPickQty
            });
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            dispatch(
              setUserMessage({
                title:
                  getAxiosErrorMessage(err) || "Out Of Stock process failed",
                severity: "error"
              })
            );
          }
        }
      } else if (ableToPickQty === 0 && currentPick) {
        try {
          const pick = await fetchPick(currentPick.pickId).unwrap();
          if (!pick) return;

          if (pick.status.toLowerCase() !== "completed") {
            await outOfStockPick({
              pickId: currentPick.pickId,
              pickBinId: currentPick.binId || "",
              autostoreGridId: selectedAutostoreGridId || "",
              autostorePortId: sitePortId || -1,
              hold: outOfStockReasonCode
            });

            if (outOfStockReasonCode !== "out of stock") {
              setOutOfStockReasonCode("out of stock");
            }
          }

          // After a successful full OOS, we should update picking state
          // In order to have updated information about tote progress bar
          const pickingState = await dispatch(updatePickingState({}));

          // if all of the picks are terminal, we shouldn't automatically call nextPickingBin
          // the batch complete modal should be opened and user can select if he wants the next batch from there
          // we also exclude the current pick from the search since it was fully out of stocked
          if (
            pickingState?.allPicks.every((x) =>
              x.picks
                .filter((p) => p.pickId !== pick.pickId)
                .every(
                  (p) =>
                    p.status.toLowerCase() === "canceled" ||
                    p.status.toLowerCase() === "picked"
                )
            )
          ) {
            return;
          }

          dispatch(setNextPickingBinLoading(true));

          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          handleMoveNextPick({
            reporter: "out of stock click"
          });
        } catch (err) {
          if (axios.isAxiosError(err)) {
            dispatch(
              setUserMessage({
                title:
                  getAxiosErrorMessage(err) || "Out Of Stock process failed",
                severity: "error"
              })
            );
          }
        }
      }
    },
    [
      currentPick,
      currentPickAmount,
      dispatch,
      fetchPick,
      handleMoveNextPick,
      isPickUpcScanned,
      outOfStockPick,
      outOfStockReasonCode,
      selectedAutostoreGridId,
      sitePortId,
      oosModalStatusAfterQtyModal
    ]
  );

  const confirmOutOfStock = useCallback(async (): Promise<void> => {
    // This message should be displayed only during the full OOS process
    if (ableToPickQty === 0) {
      dispatch(
        setUserMessage({
          title: `Finishing ${t("out of stock")} process`,
          severity: "info",
          id: "finishing-out-of-stock-process",
          autohideDuration: 10000
        })
      );
    }
    try {
      showSkeleton.current = true;
      await handleOutOfStockCb(ableToPickQty);
    } finally {
      if (ableToPickQty === 0)
        dispatch(clearUserMessage("finishing-out-of-stock-process"));
      if (pickQuantityConfirmationEnabled && ableToPickQty > 0) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        showPickToLight(
          portState?.binConfiguration?.configurationType || 1,
          currentPick?.compartmentNumber || 1,
          selectedAutostoreGridId || "",
          sitePortId || 1,
          ableToPickQty,
          confirmPickQuantityModalSelectedBinId ||
            currentPick?.autostoreBinId ||
            1
        );
      }
      closeModalCallback();
    }
  }, [
    ableToPickQty,
    closeModalCallback,
    currentPick?.autostoreBinId,
    currentPick?.compartmentNumber,
    dispatch,
    handleOutOfStockCb,
    pickQuantityConfirmationEnabled,
    confirmPickQuantityModalSelectedBinId,
    portState?.binConfiguration?.configurationType,
    selectedAutostoreGridId,
    sitePortId,
    t,
    showSkeleton
  ]);

  const handleOutOfStockReasonCodeChange = useCallback(
    (reasonCode: string) => {
      dispatch(setOutOfStockReasonCode(reasonCode));
    },
    [dispatch]
  );

  useEffect(() => {
    setAbleToPickQuantity(initialAbleToPickQty || 0);
  }, [initialAbleToPickQty]);

  return {
    backButtonShown,
    currentPickAmount,
    outOfStockReasonCode,
    outOfStockReasonCodes,
    ableToPickQty,
    showSkeleton,
    setAbleToPickQuantity,
    closeModalCallback,
    handleOutOfStockReasonCodeChange,
    handleQuantityChange,
    handleButtonClick,
    confirmOutOfStock,
    backButtonCallback
  };
}
