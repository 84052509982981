import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { sendUserTaskSelectedEvent } from "~/redux/actions/userEvents";
import { selectUserCanDecant } from "~/redux/selectors/authSelectors";
import {
  selectThisWorkstation,
  selectWorkstationHasInductionRole
} from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export type Props = {
  route: string;
};

export function InductionButton({ route }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search }: { search: string } = useLocation();
  const dispatch = useAppDispatch();
  const trackedPageName = useMixpanelPageName();

  const isInductionRoleFound = useAppSelector(
    selectWorkstationHasInductionRole
  );
  const disableDecanting = !useAppSelector(selectUserCanDecant);
  const hasWorkstation = !!useAppSelector(selectThisWorkstation);

  const onInductionButtonClicked = async () => {
    if (!disableDecanting && (isInductionRoleFound || !hasWorkstation)) {
      await dispatch(sendUserTaskSelectedEvent("INDUCTION"));
      navigate({ pathname: route, search });
      mixpanelTrack({
        type: "Button Click",
        label:
          route === "/autostore-putaway"
            ? "Induction V1 Button"
            : "Induction V2 Button",
        trackedPageName
      });
    }
  };

  const alert =
    (!isInductionRoleFound && t("no induction role found")) || undefined;

  return (
    <MainButton
      alert={alert}
      onClick={onInductionButtonClicked}
      disabled={(!isInductionRoleFound && hasWorkstation) || disableDecanting}
    >
      {t("nav.link.induction")}
    </MainButton>
  );
}
