import { Box } from "@mui/material";
import { formatUtcDate, AutostoreTable } from "@qubit/autoparts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { warehouseService } from "~/api/warehouse";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { configurationTypeToString } from "~/lib/helpers";

import { StoreState } from "~/redux/reducers";
import { BinMaintenanceAssignment } from "~/types/api";

const mapStateToProps = (state: StoreState) => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId
});

const connector = connect(mapStateToProps, {});

type BinCleaningViewInheritedProps = { viewTitle?: ViewNameTranslation };
type PropsFromRedux = ConnectedProps<typeof connector>;
type BinCleaningViewProps = PropsFromRedux & BinCleaningViewInheritedProps;

export function CleaningBinView(props: BinCleaningViewProps) {
  const { selectedAutostoreGridId, viewTitle } = props;

  const { t } = useTranslation();

  const [tableData, setTableData] = useState<BinMaintenanceAssignment[]>([]);

  useNavbar({ viewTitle });

  const getTableData = async () => {
    if (selectedAutostoreGridId) {
      const response = await warehouseService.get<BinMaintenanceAssignment[]>(
        `/api/autostore-grid/${selectedAutostoreGridId}/get-flagged-bins`
      );
      setTableData(response.data);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <AutostoreTable<BinMaintenanceAssignment>
        headerColNames={[
          t("bin id"),
          t("bin configuration"),
          t("flag"),
          t("flagged date"),
          t("due date")
        ]}
        widthOfCols={["20%", "20%", "20%", "20%", "20%"]}
        rowId={(row: BinMaintenanceAssignment) => `${row.binId}`}
        renderColumns={[
          (row: BinMaintenanceAssignment) => row.binId.toString(),
          (row: BinMaintenanceAssignment) =>
            row.binConfiguration?.configurationType
              ? configurationTypeToString(
                  row.binConfiguration?.configurationType
                )
              : "",
          (row: BinMaintenanceAssignment) => (
            <Box>
              {row.flags
                .map((flag) => flag.charAt(0).toUpperCase() + flag.slice(1))
                .join(" ")}
            </Box>
          ),
          (row: BinMaintenanceAssignment) => formatUtcDate(row.lastFlaggedTime),
          (row: BinMaintenanceAssignment) => formatUtcDate(row.dueDate)
        ]}
        rowData={tableData}
        loading={false}
        noResults={tableData.length === 0}
      />
    </Box>
  );
}

export default connector(CleaningBinView);
