import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { stringify } from "qs";

import { getWarehouseServiceUrl } from "~/api/warehouse";
import { accessTokenFactory } from "~/features/login/login.slice";
import { StoreState } from "~/redux/reducers";

export const pepsiApiBase = fetchBaseQuery({
  baseUrl: `${getWarehouseServiceUrl()}/pepsi/api`,
  prepareHeaders: async (headers, api) => {
    const state = api.getState() as StoreState;
    const accessToken = await accessTokenFactory(
      state.login.accessToken,
      state.login.authMethod
    );
    headers.set("authorization", `Bearer ${accessToken}`);
  },
  paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
});

export const pepsiApiBaseWithRedirectOn401: typeof pepsiApiBase = async (
  ...args
) => {
  const result = await pepsiApiBase(...args);
  // if the server responds with a 401 response, redirect to the sign-in page
  if (result.error && result.error.status === 401) {
    window.location.href = "/logout";
  }
  return result;
};

export const pepsiApi = createApi({
  baseQuery: pepsiApiBaseWithRedirectOn401,
  endpoints: () => ({}),
  reducerPath: "pepsiApi"
});
